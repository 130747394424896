import React, { Fragment, useEffect, useState } from "react";
import { removeFolowUser, requestLevelList, requestGameStats, getOtherUserProfile } from "../apis";
import { goback } from "../component/common";
import url from "../constants/url";
import colors from "../constants/colors";
import Previous from "../assets/images/previous.svg";
import Dollar from "../assets/images/upload-card.svg";
import Coin from "../assets/images/coin.png";
import Rupee from "../assets/images/rupee.svg";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import Close from "../assets/images/close.svg";
import { kFormatter } from "../component/common";
import Progress from "react-progressbar";
import Loader from "../component/loader";
import { routehelp } from "../config/routehelp";

import SearchIcon from "../assets/images/search-rounded.png";
import ShareProfileIcon from "../assets/images/share-profile-icon.png";
import EditProfileIcon from "../assets/images/edit-profile-icon.png";
import InsightsIcon from "../assets/images/insights-icon.png";
import PremiumClose from "../assets/images/cancel_premium.png";

import SchoolIcon from "../assets/images/school.png";
import RankIcon from "../assets/images/rank-collegiate.png";
import TeamIcon from "../assets/images/members-collegiate.png";
import PointIcon from "../assets/images/point-collegiate.png";
import RightArrowIcon from "../assets/images/right_arrow.png";
import PremiumLogoBlack from "../assets/images/GoldPremium.png";
import Logo from "../assets/images/easypaisa_logo.png";
import { useTranslation, Trans } from "react-i18next";

import Advertisement from "../component/advertisement";

import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";
import { Line } from "react-chartjs-2";
import { RWebShare } from "react-web-share";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

function UserProfile(props) {
  const [allData, setAllData] = useState("");
  const [levelsList, setLevelsList] = useState("");
  const [infoTitle, setInfoTitle] = useState("");
  const [infoDesc, setInfoDesc] = useState("");
  const [showInfo, setShowInfo] = useState(false);
  const [gameStatsList, setGameStatsList] = useState([]);
  const [levelProgressValue, setLevelProgressValue] = useState(0);
  const { t } = useTranslation();

  const [premiumUser, setPremiumUser] = useState(JSON.parse(localStorage.getItem("premiumUser")));
  const [showPremiumPopup, setShowPremiumPopup] = useState(!JSON.parse(localStorage.getItem("premiumUser")));
  const [tagShowLoader, setTagShowLoader] = useState(true);

  const options = {
    responsive: true,
    fill: true,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        position: false,
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
    },
  };

  const labels = ["January", "February", "March", "April", "May", "June", "July"];

  const data = {
    labels,
    datasets: [
      {
        label: "Dataset 1",
        data: [200, 500, 300, 400, 700, 100, 500],
        borderColor: "rgb(255, 198, 9)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  useEffect(() => {
    fetchData();
    fetchLevelData();
  }, []);

  const getTotalCoin = (wallet) => {
    let index = wallet.findIndex((obj) => obj.currencyData?.code == "coin");

    if (index > -1) {
      return wallet[index].winningAmount ? wallet[index].winningAmount : 0;
    }
    return 0;
  };
  const getTotalDiamond = (wallet) => {
    let index = wallet.findIndex((obj) => obj.currencyData?.code == "diamond");

    if (index > -1) {
      return wallet[index].winningAmount ? wallet[index].winningAmount : 0;
    }
    return 0;
  };

  const fetchData = async () => {
    let allpage = [...JSON.parse(localStorage.getItem("currentPage"))];
    var count = allpage.findIndex((d) => d === window.location.pathname);
    count === -1 && allpage.push(window.location.pathname);
    localStorage.setItem("currentPage", JSON.stringify(allpage));

    var user_id = window.location.href.split("/")[window.location.href.split("/").length - 1];
    // console.log("user_id===>", user_id);
    let payload = {
      user: user_id,
    };
    let res = await getOtherUserProfile(payload);
    // console.log(res)
    if (res) {
      setAllData(res);
      // console.log(res?.item);
      if (res?.item?.wallet.length > 0) {
        localStorage.setItem("currentCoin", getTotalCoin(res?.item?.wallet));
        localStorage.setItem("currentDiamond", getTotalDiamond(res?.item?.wallet));
      }
      if (res.item.level) {
        // let currentLevelPoints = res.item.level?.level?.endPoint -  (res.item.level?.level?.startPoint - 1);

        // let userPointsAsPerLevel =  res.item.level?.points -  (res.item.level?.level?.startPoint - 1)
        let currentLevelPoints = res.item.level?.level?.endPoint - (res.item.level?.level?.startPoint - 1);

        let userPointsAsPerLevel = res.item.level?.points - (res.item.level?.level?.startPoint - 1);

        console.log("currentLevelPoints===>", currentLevelPoints);
        console.log("userPointsAsPerLevel===>", userPointsAsPerLevel);
        console.log("res.item.level?.points===>", res.item.level?.points);

        let lpVal = (userPointsAsPerLevel * 100) / currentLevelPoints;
        console.log("lpVal===>", lpVal);
        setLevelProgressValue(lpVal);
      }
      if (res?.item?.gameNames) {
        fetchGameStats(res?.item?.gameNames);
      }
    }
  };

  const fetchLevelData = async () => {
    let payload = {
      skip: 0,
      limit: 100,
      sort: "asc",
      sortBy: "num",
    };
    let res = await requestLevelList(payload);
    if (res && res.list) {
      setLevelsList(res.list);
    }
  };

  const fetchGameStats = async (gameNames) => {
    let arrGameStats = [];
    let games = [];
    let gameNamesArr = [];
    if (gameNames && gameNames.length > 0) {
      gameNames.forEach(async function (element) {
        console.log("element.game==>", element?.game);
        if (element.uniqueIGN != "" && element.uniqueIGN != undefined) {
          games.push(element?.game);
          gameNamesArr[element?.game.toString()] = element.uniqueIGN;
        }
      });

      var otherUserId = window.location.href.split("/")[window.location.href.split("/").length - 1];
      console.log("gameNamesArr==>", gameNamesArr);
      console.log("games==>", games);
      console.log("otherUserId===>", otherUserId);
      let res = await requestGameStats({
        games: games,
        appType: "webapp",
        user: otherUserId,
      });
      console.log("requestGameStats: ", res);
      // let res =  await requestGameStats({game: games[0]});
      if (res && res.list) {
        var gameDataArr = [];
        res.list.forEach(async function (element, index) {
          var gameDataDateArr = [];
          var gameDataPlayedArr = [];
          element.stateatistic.forEach(function (element1) {
            var d = new Date(element1.date);
            ///  var eleDate=d.getDate()+"/"+d.getMonth()
            gameDataDateArr.push(element1.date);
            gameDataPlayedArr.push(element1.played);
          });
          gameDataArr.push({
            avgRates: element.avgRates,
            game: element.game,
            played: element.played,
            uniqueIGN: gameNamesArr[element.game._id.toString()],
            data: {
              labels: gameDataDateArr,
              datasets: [
                {
                  label: "Played " + element.played,
                  data: gameDataPlayedArr,
                  borderColor: "rgb(255, 198, 9)",
                  backgroundColor: "rgb(255, 198, 9)",
                },
              ],
            },
          });
        });
        console.log("arrGameStats =>", gameDataArr);
        setGameStatsList(gameDataArr);
        // setGameStatsList(res.list);
      }
    }

    setTagShowLoader(false);
  };

  const onClickInfo = (title, desc) => {
    setShowInfo(true);
    setInfoTitle(title);
    setInfoDesc(desc);
  };

  const getLevelProgressValue = (level) => {
    let currentLevelPoints = level?.level?.endPoint - level?.level?.startPoint - 1;
    let userPointsAsPerLevel = level?.points - level?.level?.startPoint - 1;

    return userPointsAsPerLevel / currentLevelPoints;
  };

  const topLinkClick = (value) => {
    window.location.href = value;
  };

  const onClickCollegeViewDetail = (value) => {
    window.location.href = value;
  };

  const onClickFollowButton = () => {
    var user_id = window.location.href.split("/")[window.location.href.split("/").length - 1];
    let data = {
      action: allData?.item?.isFollow === true ? "unfollow" : "follow",
      followingUser: user_id,
    };
    console.log("Payload: ", data);
    removeFolowUserAPICall(data);
  };

  const removeFolowUserAPICall = async (data) => {
    let response = await removeFolowUser(data);
    console.log("Follow: ", response);

    var user_id = window.location.href.split("/")[window.location.href.split("/").length - 1];
    console.log("user_id===>", user_id);
    let payload = {
      user: user_id,
    };
    let res = await getOtherUserProfile(payload);
    console.log(res);
    if (res) setAllData(res);
  };

  console.log("gameStatsList==>", gameStatsList);
  const navigate = useNavigate();

  const insightsHandler = (id) => {
    if (premiumUser) navigate(`/profile-insights/${id}`);
    else {
      setShowPremiumPopup(true);
    }
  };
  return (
    <div className="inner-wrap custom_scroll_leader" style={{}} id="account_main">
      {tagShowLoader === true ? <Loader /> : ""}
      <div className="profile_main mCustomScrollbar _mCS_1" id={showInfo === true ? "popup_show" : ""}>
        <div className="all_sub_screens_div_related_to_ads">
          {/* Navigation Bar */}
          <div className="profile_top row">
            <div className="col-9">
              <div className="back-btn" style={{ marginLeft: "10px" }}>
                <a onClick={() => goback()}>
                  <svg id="previous" xmlns="http://www.w3.org/2000/svg" width="32.007" height="29.708" viewBox="0 0 32.007 29.708">
                    <g id="Group_3407" data-name="Group 3407" transform="translate(0 0)">
                      <g id="Group_3406" data-name="Group 3406" transform="translate(0 0)">
                        <path
                          id="Path_4454"
                          data-name="Path 4454"
                          d="M.48,103.773A1.649,1.649,0,0,0,0,104.934a1.632,1.632,0,0,0,.48,1.161L13.686,119.3a1.648,1.648,0,1,0,2.331-2.331L5.611,106.584H30.357a1.645,1.645,0,1,0,0-3.29H5.611l10.4-10.406a1.648,1.648,0,0,0-2.331-2.331Z"
                          transform="translate(0 -90.075)"
                          fill="#070B28"
                        />
                      </g>
                    </g>
                  </svg>
                </a>
              </div>
            </div>
            <div className="col-1 p-0">
              {/* Search */}
              {/* <Link to="#" style={{ height: 50, width: 40, justifyContent: 'center', alignItems: 'center' }}  
        onClick={() => topLinkClick(routehelp.searchuser)}
      >
          <img style={{ height: 24, width: 24 }} src={SearchIcon} />
          <p style={{ marginTop: 3, color: colors.black,  fontSize: 10 }} >Search</p>
      </Link> */}
            </div>
            <div className="col-1 p-0">{/* Share */}</div>
            <div className="col-1 p-0">
              {/* Edit */}
              {/* <Link  to="/profile-edit" style={{ height: 50, width: 40, justifyContent: 'center', alignItems: 'center' }} activeOpacity={0.8}     >
          <img style={{ height: 24, width: 24 }} src={EditProfileIcon} />
          <p style={{ marginTop: 3, color: colors.black, fontSize: 10 }} >Edit</p>
      </Link> */}
              <RWebShare
                data={{
                  text: `${t('Profile.join_text', { name: "easypaisa" })}`,
                  url: `${url.siteUrl}user-profile/${allData?.item?._id}`,

                  title: `${t('Profile.join_me', { name: "easypaisa" })}`,
                }}
                onClick={() => console.info("share successful!")}>
                <Link
                  to="#"
                  style={{
                    height: 50,
                    width: 40,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  activeOpacity={0.8}>
                  <img style={{ height: 24, width: 24 }} src={ShareProfileIcon} />
                  <p style={{ marginTop: 3, color: colors.black, fontSize: 10 }}>{t("leaderboard.share")}</p>
                </Link>
                {/* <div className="row friendship_items">
                  <img style={{ height: 24, width: 24 }} src={ShareProfileIcon} />
                  <p style={{ marginTop: 3, color: colors.black, fontSize: 10 }}>
                    Share
                  </p>
                </div> */}
                {/* <div className="row friendship_items">
                  <div className="col-12">
                    <div className="box winning_breakup_popup_toggle">
                      <div className="icon">
                        <img src={ShareProfileIcon} alt="" />
                      </div>
                      Share..
                    </div>
                  </div>
                </div> */}
              </RWebShare>
            </div>
          </div>

          <div className="profile_first row">
            <div className="col-6 avatar_detail p-0">
              <div
                className="col p-0 profile-ff"
               >
                <div className="d-flex align-items-center profile-ml-20">
                  {allData?.item?.isPremium && (
                    <div style={{ height: "28px", width: "25px" }}>
                      <img
                        style={{
                          width: "28px",
                          height: "25px",
                          marginLeft: "20px",
                          marginRight: "20px",
                        }}
                        src={PremiumLogoBlack}
                      />{" "}
                    </div>
                  )}
                  <h4
                    className="f-20"
                    style={{
                      color: "#fff",
                      marginLeft: "30px",
                      marginTop: "5px",
                    }}>
                    {allData?.item?.gamerjiName === undefined || allData?.item?.gamerjiName === null ? (
                      ""
                    ) : allData?.item?.gamerjiName.length > 10 ? (
                      <marquee>{allData?.item?.gamerjiName}</marquee>
                    ) : (
                      allData?.item?.gamerjiName
                    )}
                    {/* {allData?.item?.gamerjiName} */}
                  </h4>
                  {/* <div className="profile_gamerji_name_right_triangle"></div>
                  <div className="profile_gamerji_name_right_upper_triangle"></div> */}
                </div>
                <div className="row m-0 profile_follower_followings_row" onClick={() => onClickFollowButton()}>
                  <div className="col-12 d-flex flex-column align-items-center justify-content-center">
                    <div className="profile_followers_data">{allData?.item?.followers}</div>
                    <div className="profile_followers_title">{allData?.item?.isFollow ? t("woe.following") : t("woe.follow")}</div>
                  </div>
                </div>
              </div>

              <div className="level_box d-flex flex-column text-center">
                <img
                  src={url.imageUrl + allData?.item?.level?.level?.featuredImage?.default}
                  style={{
                    height: 73,
                    width: 70,
                    resizeMode: "contain",
                    alignSelf: "center",
                    marginTop: -30,
                  }}
                />
                <div
                  style={{
                    width: 175,
                    height: 30,
                    justifyContent: "center",
                    marginTop: 10,
                  }}>
                  <p
                    style={{
                      alignSelf: "center",
                      color: colors.black,
                      fontSize: 14,
                      fontWeight: "bold",
                    }}>
                    {allData?.item?.level?.level?.name}
                  </p>
                </div>
                <div
                  style={{
                    marginTop: 5,
                    marginLeft: 10,
                    marginRight: 10,
                    width: 156,
                  }}>
                  <div className="avalible_wrap">
                    <div className="inner">
                      <span style={{ width: levelProgressValue }}></span>
                    </div>
                    <p className="d-flex justify-content-between">
                      <span>{t("Profile.n_pts", { n: allData?.item?.level?.level?.startPoint })}</span>
                      <span>{t("Profile.n_pts", { n: allData?.item?.level?.level?.endPoint })}</span>
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    marginTop: 10,
                    width: 166,
                    textAlign: "center",
                    color: colors.black,
                    fontWeight: "bold",
                    fontSize: 12,
                  }}>
                  {t("Profile.points")} - {allData?.item?.level?.points}
                </div>
                <div className="profile_level_box_all_level">
                  <div className="row m-0 profile_level_row">
                    <div className="profile_level_left_upper_triangle"></div>
                    <div className="profile_level_left_triangle"></div>
                    <div className="profile_level_details">
                      {t("Profile.level")} - {allData?.item?.level?.level?.num}
                    </div>
                    <div className="profile_level_right_triangle"></div>
                    <div className="profile_level_right_upper_triangle"></div>
                  </div>
                  {/* <a
                    style={{
                      width: '100%', height: 30,
                      borderRadius: 20, justifyContent: 'center',
                      color: colors.white, cursor: 'pointer',
                      fontSize: 16, fontWeight: "bold", alignSelf: 'center',
                      paddingTop: 0, paddingBottom: 5, paddingLeft: 20, paddingRight: 20
                    }}
                    onClick={() => onClickInfo('LEVELS', 'Money deposited by you that you can only be used to buy a coins but cannot be withdrawn.')}
                  >
                    All Levels
                  </a> */}
                </div>
              </div>
            </div>

            <>
              {/* <div className="col-6 avatar_detail">
                <h4>{allData?.item?.gamerjiName}</h4>
                <div className="d-flex justify-content-center align-items-center"
                  style={{
                    width: '160px',
                    // height: 30, 
                    marginTop: 16,
                    marginLeft: 20,
                    padding: '5px 10px',
                    display: 'flex',
                    borderRadius: '50px',
                    // border: " solid 1px #e4b226", 
                    textAlign: 'center',
                    backgroundColor: 'var(--dark)',
                    color: 'var(--light)',
                    fontSize: '16px',
                    fontWeight: '600',
                    cursor: 'pointer',
                  }}
                  onClick={(e) => onClickFollowButton()}
                >
                  {allData?.item?.isFollow ? "Following " : "Follow "} ({allData?.item?.followers})
                </div>
                <div className="level_box d-flex flex-column text-center   pb-2">
                  <img src={url.imageUrl + allData?.item?.level?.level?.featuredImage?.default} style={{ height: 73, width: 70, resizeMode: 'contain', alignSelf: "center", marginTop: -30 }} />
                  <div style={{ width: 175, height: 30, justifyContent: 'center', marginTop: 10 }}>
                    <p style={{ alignSelf: 'center', color: colors.black, fontSize: 16, fontWeight: "bold" }}>Level - {allData?.item?.level?.level?.num}</p>
                    <p style={{ alignSelf: 'center', color: colors.black, fontSize: 14, fontWeight: "bold" }} > {allData?.item?.level?.level?.name}</p>

                  </div>
                  <div style={{ marginTop: 40, marginLeft: 10, marginRight: 10, width: 156 }} >
                    <Progress style={{ height: 5, width: '100%', backgroundColor: 'white', borderRadius: 2.5 }} completed={levelProgressValue} color={colors.red} />
                  </div>

                  <div style={{ marginTop: 4, marginLeft: 10, width: 156, height: 20, flexDirection: 'row', justifyContent: 'space-between', textAlign: "initial" }} className="row" >
                    <div className="col-6" style={{ color: colors.black, fontSize: 10 }} >{allData?.item?.level?.level?.startPoint} PTS</div>
                    <div className="col-6 " style={{ color: colors.black, fontSize: 10 }} >{allData?.item?.level?.level?.endPoint} PTS</div>
                  </div>

                  <div style={{ marginTop: 10, width: 166, textAlign: 'center', color: colors.black, fontWeight: "bold", fontSize: 12 }} >Points - {allData?.item?.level?.points}</div>
                </div>
              </div> */}
            </>

            <div className="col-6 avatar_img">
              <img src={url.imageUrl + allData?.item?.avatar?.img?.default} />
            </div>
          </div>

          <div className="profile_second">
            <div className="list-group center profile-tab" id="list-tab" role="tablist">
              <a className="list-group-item list-group-item-action active" id="list-home-list" data-toggle="list" href="#list-1">
                {t("Profile.status")}
              </a>
              {/* <a className="list-group-item list-group-item-action" id="list-profile-list" data-toggle="list" href="#list-2">
                {t("Profile.collegiate")}
              </a> */}
            </div>
            <div className="tab-content" id="nav-tabContent">
              <div className="tab-pane active" id="list-1">
                {gameStatsList && gameStatsList.length > 0
                  ? gameStatsList.map((item, index) => {
                      return (
                        <div
                          className="gap-3"
                          style={{
                            width: "85%",
                            margin: "30px auto 10px auto",
                          }}
                          key={index}>
                          <div className="row">
                            <div className="col-8">
                              <h5 className="game_name_h5">{item?.game?.name}</h5>
                              <h6>{item?.uniqueIGN}</h6>
                            </div>
                            <div className="col-2">
                              <h5 className="game_name_h5">{t("Profile.rank")}</h5>
                              <h6>{item?.avgRates}</h6>
                            </div>
                            <div className="col-2 game_insights_hover" onClick={() => insightsHandler(item?.game?._id)}>
                              {/* <Link to={premiumUser ?  `/profile-insights/${item?.game?._id}` : ""} className="game_insights_hover" style={{ color: colors.white, fontSize: 13 }} >
                            <img style={{ height: 32, width: 32 }} src={InsightsIcon} />
                            <h6>Insights</h6>
                          </Link> */}
                              <img
                                style={{
                                  height: 32,
                                  width: 32,
                                  marginLeft: "14px",
                                }}
                                src={InsightsIcon}
                              />
                              <h6>{t("Profile.insights")}</h6>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col profile-stats-box">
                              <p>
                                {t("home.played")} : {item?.played}
                              </p>
                              <Line options={options} data={item.data} />
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : (
                    <div className="d-flex justify-content-center" style={{}}>
                      <div
                        style={{
                          marginTop: "10px",
                          padding: "20px",
                          color: "#F92C2C",
                          fontSize: "18px",
                          fontWeight: "1000",
                        }}
                      >
                        {t('Profile.no_stats_available')}
                      </div>
                    </div>
                  )}
              </div>
              <div className="tab-pane" id="list-2">
                <div className="d-flex justify-content-center" style={{}}>
                  <div
                    style={{
                      marginTop: "10px",
                      padding: "20px",
                      color: "#F92C2C",
                      fontSize: "20px",
                      fontWeight: "1000",
                    }}>
                    {allData?.college?.name ? (
                      <h5
                        style={{
                          fontWeight: "600",
                          marginTop: "10px",
                          color: "#fff",
                          textAlign: "center",
                        }}>
                        {allData?.college?.name}
                      </h5>
                    ) : (
                      <span>{t("Profile.no_collage_is_selected")}</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {showInfo === true && (
            <div className="custom-popup rules info_acc custom_scroll show" id="contact_popup">
              <div className="popup-head">
                <h3 style={{ color: colors.black, fontWeight: "bold" }}>{infoTitle}</h3>
                <button className="close-popup" onClick={() => setShowInfo(false)}>
                  <img src={Close} alt="" />
                </button>
              </div>
              <div className="popup-body bg-yellow">
                <p className="mb-3 mx-2 my-2" style={{ height: 400 }}>
                  <table className="table">
                    <thead className="table-thead">
                      <tr>
                        <th
                          style={{
                            fontWeight: "bold",
                            fontSize: 18,
                            color: colors.white,
                          }}>
                          {t("Profile.level")}
                        </th>
                        <th
                          style={{
                            fontWeight: "bold",
                            fontSize: 18,
                            color: colors.white,
                          }}>
                          {t("Profile.badge")}
                        </th>
                        <th
                          style={{
                            fontWeight: "bold",
                            fontSize: 18,
                            color: colors.white,
                          }}>
                          {t("Profile.points")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {levelsList !== null ? (
                        levelsList?.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td className="align-middle">{item?.num}</td>
                              <td className="align-middle">
                                <img
                                  style={{
                                    marginLeft: 8,
                                    width: 40,
                                    height: 40,
                                    resizeMode: "contain",
                                  }}
                                  src={url.imageUrl + item?.featuredImage?.default}
                                />{" "}
                                {item?.name}
                              </td>
                              <td className="align-middle">
                                {item?.startPoint} {t("tournaments.to")} {item?.endPoint}{" "}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <Loader />
                      )}
                    </tbody>
                  </table>
                </p>
              </div>
            </div>
          )}
          {showPremiumPopup === true && (
            <div className="tc_popup_bg">
              <div className={`custom-popup  confirm custom_scroll join show premium_active rules`} id="contact_popup">
                <div className={`popup-head  premium_active`}>
                  {/* <marquee> */}
                  <h3> </h3>
                  {/* </marquee> */}

                  <button className="close-popup" onClick={() => setShowPremiumPopup(false)}>
                    <img src={PremiumClose} alt="" />
                  </button>
                </div>
                <div className="ps_header" style={{ height: "150px" }}>
                  <div className="ps_logo">
                    <img src={Logo} />
                  </div>
                  <div className="ps_name">{t("tournaments.premium")}</div>
                </div>
                <div className="ps_popup_main">
                  <div className="ps_popup_p">
                    <p>
                      <Trans
                        i18nKey="premium.as_a_gamerji_user_you_will_be_able_to_unlock_view_more_insights_regarding_your_gameplay"
                        t={t}
                        components={[
                          <Link className="link" to={routehelp.premiumSubscription}>
                            {" "}
                            {t("premium.as_a_gamerji_user_you_will_be_able_to_unlock_view_more_insights_regarding_your_gameplay")}{" "}
                          </Link>,
                        ]}
                      />
                    </p>
                  </div>

                  <div className="ps_popup_p">
                    <p>{t("premium.premium_users_also_get_access_to_a_host_additional_benefits")}</p>
                  </div>
                </div>

                <button
                  className={`btn premium_active_btn`}
                  onClick={() => navigate(routehelp.premiumSubscription)}
                  style={{
                    textAlign: "center",
                    width: "80%",
                    margin: "0 auto",
                    marginBottom: "30px",
                  }}>
                  {t("Profile.explore_gamerji_premium")}
                </button>
              </div>
            </div>
          )}
        </div>
        {!premiumUser && (
          <Advertisement
            screen={
              localStorage.getItem("apk_screens_list") === null
                ? { code: "no_results" }
                : JSON.parse(localStorage.getItem("apk_screens_list")).otherUserProfile
            }
            screen_div_class_name=".all_sub_screens_div_related_to_ads"
            screen_type="sub"
          />
        )}
      </div>
    </div>
  );
}

export default UserProfile;
