export const events = {
     
  all_games_menu:"all_games_menu",
  coin_store_menu:"coin_store_menu",
  reward_store_menu:"reward_store_menu",
  leaderboard_menu:"leaderboard_menu",
  crm_menu:"crm_menu",
  premium_menu:"premium_menu",
  banners:"banners",
  my_profile:"my_profile",
  join_via_invite_code:"join_via_invite_code",
  apply_promo_code:"apply_promo_code",
  easypaisa_points:"easypaisa_points",
  legality:"legality",
  logout:"logout",
terms_of_use_tab:"terms_of_use_tab",
privacy_policy_tab:"privacy_policy_tab",
gamenamebox:"gamenamebox",
apply_promo_code_submit:"apply_promo_code_submit",
join_via_invite_submit:"join_via_invite_submit",
view_insights:"view_insights",
view_all_levels:"view_all_levels",
share_profile:"share_profile",
profile_submit_button:"profile_submit_button",
profile_edit:"profile_edit",
premium_pop_up:"premium_pop_up",
raise_a_complaint:"raise_a_complaint",
games:"games",
ticket_box:"ticket_box",
chat:"chat",
subcription_plan:"subcription_plan",
daily_login_reward_claim:"daily_login_reward_claim",
payment_status_popup:"payment_status_popup",
request_call_back:"request_call_back",
select_call_back_time:"select_call_back_time",
schedule_call:"schedule_call",
today_tab:"today_tab",
weekly_tab:"weekly_tab",
monthly_tab:"monthly_tab",
all_tab:"all_tab",
search:"search",
coin_transaction:"coin_transaction",
diamond_transaction:"diamond_transaction",
reward_tab:"reward_tab",
my_reward_tab:"my_reward_tab",
redeem_rewards:"redeem_rewards",
packs_tab:"packs_tab",
bundles_tab:"bundles_tab",
avatars_tab:"avatars_tab",

buy_packs :"buy_packs",
packs_pay_now:"packs_pay_now",
buy_bundle:"buy_bundle",
bundles_pay_now:"bundles_pay_now",
buy_avatar:"buy_avatar",
redeem_avatar:"redeem_avatar",

featured_tournaments:"featured_tournaments",
tournaments:"tournaments",
how_to_join:"how_to_join",
change_username:"change_username",
change_username_AddUpdate:"change_username_AddUpdate",
my_contests:"my_contests",
contest_join_now:"contest_join_now",
join_contests:"join_contests",
contest_winners:"contest_winners",

contest_room_id:"contest_room_id",
contest_password:"contest_password",
share_with_friends:"share_with_friends",

tournament_join_now:"tournament_join_now",
join_tournament:"join_tournament",
tournament_rules:"tournament_rules",
tournament_timing_selection:"tournament_timing_selection",
next:"next",
tournament_share_with_friends:"tournament_share_with_friends",
tournament_room_id:"tournament_room_id",
tournament_passsword:"tournament_passsword",
mycontests_contests:"mycontests_contests",
mycontests_tournaments:"mycontests_tournaments",


  };
  