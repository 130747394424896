import React from 'react'

const CreatePayNow = ({transactionId, createTimeTn, resultCode, message, amount, symbol}) => {
    console.log(symbol)
  return (
    <div className="tc_popup_bg">
          <div
            className={`custom-popup  confirm custom_scroll join show premium_active rules`}
            id="contact_popup"
          >

            <div className={`popup-head  premium_active`}>
              {/* <marquee> */}
              <h3>  <div className='ps_name'>Confirm Popup</div> </h3>
              {/* </marquee> */}

              {/* <button
                className="close-popup"
                onClick={() => setPaynow(false)}
              >
                <img src={PremiumClose} alt="" />
              </button> */}
            </div>

            <div className="ps_popup_main">
              <div className="ps_popup_p">
                <p>Transaction Details</p></div>
              <p className="text-white">Transaction Id: {transactionId}</p>
              <p className="text-white">Time: {createTimeTn}</p>
              {
               resultCode == 'F' ?
               <>
               <p>{message}</p>
               <p>Please try again!</p>
               <button className={`btn premium_active_btn`} onClick={()=> {
                 window.location.href = `/xsolla-status?paymentStatus=F&&paymentTransactionID=${transactionId}&&resultCode=4000`
               }}>
               {/* <div dangerouslySetInnerHTML={{ __html: `<a  onClick="mp.createPayment(${transactionId})">Cancel${coinAmount}</a>` }} /> */}
               Cancel

             </button>
             </>
             :
             <>
             {/* <p>{message}</p> */}
             {
              localStorage.getItem('emode') == "off" ? 
              <button className={`btn premium_active_btn`} onClick={()=>  window.location.href = `/xsolla-status?paymentStatus=S&&paymentTransactionID=${transactionId}&&resultCode=9000`}>
           Pay Now   {symbol} {amount}
            </button>
          
            :
           
              <button className={`btn premium_active_btn`} >
              <div dangerouslySetInnerHTML={{ __html: `<a  onClick="mp.createPayment(${transactionId})">Pay Now ${symbol} ${amount}</a>` }} />
 
            </button>
           
             }
            
           </>

              }
             
              {/* <p>{transactionId}</p> */}
            </div>

          </div>
        </div>
  )
}

export default CreatePayNow