import React, { useEffect, useState, useRef } from "react";
import Loader from "../../component/loader";
import { Link, useParams } from "react-router-dom";
import rightArrow from "../../assets/images/right-arrow.svg";
import Previous from "../../assets/images/previous.svg";
import PIcon1 from "../../assets/images/p_icon_1.png";
import Podium from "../../assets/images/podium.svg";
import Gamer from "../../assets/images/gamer.svg";
import PIcon2 from "../../assets/images/p_icon_2.png";
import { getStatusScrim, scrimLeaderBoard, updateWinners } from "../../apis";
import url from "../../constants/url";
import { ToastContainer, toast } from "react-toastify";
import Success from "../../assets/images/success-payment.png";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Button } from "react-bootstrap";
import PremiumLogoBlack from "../../assets/images/GoldPremium.png";
import { useTranslation } from "react-i18next";
import Cookies from 'js-cookie'

const ScrimLeaderBoard = () => {
  const params = useParams();
  const [activeTab, setActiveTab] = useState(0);
  const [open, setOpen] = useState(false);
  const [storedRank, setStoredRank] = useState([]);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [rankArray, setRankArray] = useState([]);
  const [idArray, setIdArray] = useState([]);
  const [win, setWin] = useState(localStorage.getItem("win"));
  const [status, setStatus] = useState("");
  const {t} = useTranslation()
  const currentLang = Cookies.get('i18next') || 'EN'

  const [rankSubmitted, setRankSubmitted] = useState(false);
  const [totalPage, setTotalPage] = useState(1);
  const [premiumUser, setPremiumUser] = useState(
    JSON.parse(localStorage.getItem("premiumUser"))
  );

  const fetchStatus = async () => {
    console.log("called");
    let res = await getStatusScrim({
      scrimId: params?.id,
    });
    console.log(res);
    if (res.success) {
      setStatus(res?.data?.status);
      console.log(status);
    } else {
      showError(res.data[0].msg);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const showSuccess = (error) => {
    toast.success(error, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const check = () => {
    var rankCount = 0;
    for (let key in rankArray) {
      console.log(rankArray[key]);
      if (rankArray[key]) {
        rankCount += 1;
      }

      if (rankArray[key] > rankArray.length) {
        return "Please Enter upto " + rankArray.length + " rank";
      }
    }
    console.log(rankCount);
    if (rankCount == 0) {
      return "Please Enter at least 1 rank";
    }
    let uniqueItems = [...new Set(rankArray)];
    if (uniqueItems.length !== rankArray.length) {
      return "Please Enter unique ranks";
    }
  };

  const Confirmation = () => {
    return (
      <div>
        <div
          style={{
            color: "#82878D",
            fontWeight: "800",
            textAlign: "center",
            marginTop: "15px",
            fontSize: "1.2rem",
          }}
        >
          {" "}
          Are you sure you want to move further?{" "}
        </div>

        <div className="detailsButton">
          <button className="rpBtn" onClick={handleClose}>
            EDIT
          </button>
          <button
            className="rpBtn"
            style={{ backgroundColor: "#070b28" }}
            onClick={yesHandler}
          >
            SAVE
          </button>
        </div>
      </div>
    );
  };
  const yesHandler = async () => {
    console.log("called");
    setOpen(false);

    var data = [];
    for (let i = 0; i < rankArray.length; i++) {
      var obj = {
        _id: idArray[i],
        rank: rankArray[i],
      };
      data = [...data, obj];
      console.log(data);
    }
    var res = await updateWinners({
      _id: params?.id,
      winners: [...data],
    });
    if (res.success) {
      console.log(res);
      setWin(false);
      showSuccess("Ranks are Submitted successfully");
    } else {
      showError(res?.data[0].msg);
    }
    // let res = await statusUpdate({
    //   status:'Canceled'
    // }, params?.id)
    // console.log(res)
    // navigate('/private-scrim')
    setRankSubmitted(true);
  };

  useEffect(() => {
    fetchStatus();

    fetchData(page);
  }, []);

  useEffect(() => {
    fetchStatus();

    fetchData(page);
  }, [rankSubmitted]);

  let fetchData = async (page) => {
    console.log(page, totalPage);
    let res = await scrimLeaderBoard({
      scrimId: params?.id,
      page: page,
      limit: 10,
      sortBy: "createdAt",
      sort: "asc",
    });
    console.log(res.data);
    if (res.success) {
      setTotalPage(res.totalPages);
      setData([...data, ...res.data]);
      if (res.data[0]?.rank) {
        setStoredRank(res.data);
        console.log(res.data);

        console.log("called");

        setWin(false);
      }

      console.log(res.data);
      //     var d = [
      //       {
      //         "_id": "1",
      //         "userId": "GSGJ000000001",
      //         "usersInGameName": "hello1",
      //         "gamerJiName": "GamerJiFever",
      //         "levels": null
      //     },
      //     {
      //       "_id": "2",
      //       "userId": "GSGJ000000001",
      //       "usersInGameName": "Fever1",
      //       "gamerJiName": "GamerJiFever",
      //       "levels": null
      //   },
      //   {
      //     "_id": "2",
      //     "userId": "GSGJ000000001",
      //     "usersInGameName": "Fever2",
      //     "gamerJiName": "GamerJiFever",
      //     "levels": null
      // }
      //     ]
      //     setData(d)
      console.log(rankArray, idArray);
    } else {
      showError(res?.data[0]?.msg);
    }
  };

  const SaveButton = () => {
    return (
      <div className="bottom_btn">
        <div className="btn btn-dark main-btn">
          Save
          <img src={rightArrow} alt="" className="mCS_img_loaded" />
        </div>
      </div>
    );
  };

  const RankSubmittedText = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={Success}
          style={{
            width: "20px",
            height: "20px",
            marginTop: "15px",
            marginRight: "5px",
          }}
        />
        <div
          style={{
            color: "#070b28",
            fontWeight: "900",
            textAlign: "center",
            marginTop: "15px",
            fontSize: "1.2rem",
          }}
        >
          {" "}
          Ranks are Submitted successfully
        </div>
      </div>
    );
  };

  const AddmoreButton = () => {
    return (
      <div className="">
        <button
          className="btn  bg-primary  scrim-leaderboard-btn  "
          onClick={loadHandler}
        >
          Add more
        </button>
      </div>
    );
  };

  const showError = (err) => {
    console.log("showeeror", err);
    toast.error(err, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const loadHandler = () => {
    let p = page;
    setPage(page + 1);
    fetchData(p + 1);
    console.log(data);
  };
  const onChangeHandler = (e, id, ind) => {
    console.log(id, ind, rankArray);
    let arr = [...rankArray];
    console.log(arr);
    arr[ind] = parseInt(e.target.value);
    console.log(arr);
    setRankArray([...arr]);
    let arra1 = [...idArray];
    arra1[ind] = id;
    setIdArray([...arra1]);
  };
  const saveHandler = async () => {
    var error = check();
    if (error) {
      showError(error);
    } else {
      setOpen(true);
    }
  };
  return (
    <>
      <ToastContainer />
      <div
        className=" bg-primary  scrim-heading"
        style={{
          height: "50px",
          marginBottom: "1em",
          margintop: 0,
        }}
      >
        <div className="inner inner-title">
          <img
            src={Gamer}
            style={{
              width: "30px",
              height: "30px",
              marginRight: "0.3rem",
              marginLeft: "10px",
            }}
            alt="picon1"
          />
          <b>{t('contest_details.username')}</b>
        </div>
        <div className="inner inner-title">
          <img
            src={Podium}
            style={{ width: "30px", height: "30px", marginRight: "10px" }}
            alt="picon1"
          />
          <b>{t('contest_details.rank')}</b>
        </div>
      </div>
      <div
        className="scrim-body"
        style={{
          height: "500px",
          marginBottom: "1em",
          margintop: 0,
        }}
      >
        {data.length == 0 ? (
          <h6
            style={{ textAlign: "center", marginTop: "45%", fontSize: "20px" }}
          >
            {t('notFound.no_records_found')}
          </h6>
        ) : (
          <div
            className={`${activeTab === 2 ? "active" : ""}`}
            style={{
              border: "0.5px solid #dfe4e9",
              borderRadius: "20px",
              boxShadow: "0 3px 6px #b4b6c4",
            }}
          >
            {data.map((d, ind) => (
              <div className="leaderboard-list">
                <div className="item">
                  <div className="inner">
                    <img
                      src={url.imageUrl + d?.levels?.featuredImage?.default}
                      style={currentLang?.toLowerCase() == "ar" ? {
                        width: "30px",
                        height: "30px",
                        marginLeft: "0.3rem",
                      } : {
                        width: "30px",
                        height: "30px",
                        marginRight: "0.3rem",
                      }}
                      alt=""
                    />
                  </div>
                  <div>
                    <div className="username">
                      <b>{d.usersInGameName}</b>
                      <small>{d.gamerJiName}</small>
                    </div>
                    {d?.isPremium ? (
                      <div className="ps_player" style={{ right: "60%" }}>
                        <img
                          style={currentLang?.toLowerCase() == "ar" ? {
                            width: "28px",
                            height: "25px",
                            marginRight: "20px",
                            marginTop:'6px'
                          } : {
                            width: "28px",
                            height: "25px",
                            marginLeft: "20px",
                            marginTop:'6px'
                          }}
                          src={PremiumLogoBlack}
                        />{" "}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="item">
                  <input
                    maxLength={100}
                    value={!d?.rank ? "-" : d?.rank ? d?.rank : "-"}
                    disabled="disabled"
                  />
                </div>
              </div>
            ))}
          </div>
        )}
        {page < totalPage ? (
          <button
            className="btn  bg-primary  scrim-leaderboard-btn  "
            onClick={loadHandler}
          >
             {t('contest.load_more')}
          </button>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default ScrimLeaderBoard;
