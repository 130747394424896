import { Link, useLocation, useParams } from "react-router-dom";
import { useState } from "react";
import rightArrow from "../../assets/images/right-arrow.svg";
import { useEffect } from "react";
import { getGameRules, getHowToCreateScrim, getScrimById, updateScrimById, viewScrimById } from "../../apis";
import { RunCircle } from "@mui/icons-material";
import TextEditor from "./TextEditor";
import { constants } from "../../constants/constants";


export const NextButtonRules = ({checkValidation,err , data, scrimData,id}) => {

  const formSubmit = async()=>{
    var newTime = data['time'].split(" ")
      console.log(newTime, newTime[0],newTime[1], newTime[2])
      if(newTime[1] == "PM"){
        var temp = newTime[0].split(":")
        temp[0] = parseInt(temp[0])+12
        newTime[0] = temp[0].toString() + ":" + temp[1]
      }
      newTime = newTime[0]  + ":00"
      console.log(newTime)
      var newDate = data['date'] + " " + newTime 
      // var newDate = data.payload['date'] + " " + newTime + ":00"
      const [dateString, timeString] = newDate.split(' ')
      const [day, month, year] = dateString.split('/')
      const [hour, minute, second] = timeString.split(':')
      const dateObj = new Date(+year, +month - 1, +day, +hour, +minute, +second)
        console.log(dateObj.toISOString())
            console.log( dateObj)
      console.log("new date=>",newDate);
      var datee = year+"-"+month+"-"+day+" "+hour+":"+minute+":"+second
      console.log(datee,new Date(datee))
    data = {...data, date:dateObj.toISOString(), time:dateObj.toISOString() }
   
    const res = await updateScrimById({
        "id" : id,
        "data" : data
    })
    console.log(res)
    if(res)
      window.location.href = "/manage-scrim/" + id;
  }
  
  return (
    <div className="bottom_btn" onClick={checkValidation}>
      {
         id? (<Link to={!err ? "/create-scrim":"/manage-scrim"}  state={{payload:data,"id":id}} className="btn btn-dark main-btn" onClick={formSubmit}>
        Save
        <img src={rightArrow} alt="" className="mCS_img_loaded" />
      </Link>)
      :
      <Link to={!err ? "/create-scrim":"/scrim-summary"}  state={{payload:data}} className="btn btn-dark main-btn">
        Next
        <img src={rightArrow} alt="" className="mCS_img_loaded" />
      </Link>
}
    </div>
  );
};

export const GamesRules = ({showError, payload, scrimData}) => {
  const params = useParams()
  console.log(params)
  

  const [rulesData, setRulesData] = useState('');
  const [error,setError] = useState('');
  
  const game = localStorage.getItem('activeGameId')

  let hostedBy = localStorage.getItem(constants.profileLite)
  hostedBy = JSON.parse(hostedBy)?.item?.user_id
  let createdBy = hostedBy
  const [data, setData] = useState({...payload,'rules':rulesData,'hostedBy':hostedBy,'createdBy':createdBy,'game':game,'updatedBy':createdBy})
 
  console.log(data,payload)
  const check = ()=>{
    console.log(rulesData)
    
  //   if(rulesData.length === 0){
  //     let value = 'Please Enter Games Rules';
  //     return value
  // }
    }
    
    useEffect(()=>{
      
    
      if(params?.id){

        fetchScrimData(params?.id)
    }
    else
      fetchData()
    },[])

    let fetchScrimData = async(id)=>{
      
    
        let res = await getScrimById({
          id:id
        })
        if(res){
          console.log(res)
          setRulesData(res.data[0]?.param?.rules)
        
      }
    }

    let fetchData = async ()=>{

      const res = await getGameRules({
        "game":game
    })
    if(res){
      console.log(res)
      setRulesData(res.data[0].rules)
      console.log(rulesData)
    }
    }
  
  useEffect(()=>{
    let v = check()
    // console.log(v)
    if(v){
      console.log("vv")
      setError(v)
    }
    
    
  },[error,check])
  const checkValidation = () =>{
    console.log(rulesData)
    if(rulesData){
      setError('')
      setRulesData(rulesData)
      console.log(rulesData)
      setData({ ...data,'rules':rulesData})
      console.log(data)
    }
  
    if(!rulesData && error)
    showError(error)
    
    
  }

  return (
    <main className="scrim-basicDetails">
      {/* <textarea
        placeholder="Add your rules here"
        className="scrim-input rules-input"
        value={rulesData}
        onChange={(e) =>setRulesData(e.target.value)}
        
      ></textarea> */}

  {/* text editor using draft js */}

<TextEditor  value={scrimData ? scrimData.rules : rulesData}  setRulesData={setRulesData} readOnly={false}/>

{/* text editor end */}
      <div style={{marginTop:'15%'}}>
        <span>*NOTE</span> : Rules above are Gamerji's default rules for this
        game. If you would like to add/remove something, please do so and
        continue
      </div>

      <NextButtonRules checkValidation = {checkValidation} err={rulesData} data={{...data, 'rules':rulesData}} scrimData={scrimData} id={params?.id} />
    </main>
  );
};
