import React from "react";
import ReactDOM from "react-dom";
import "react-intl-tel-input/dist/main.css";
import { Row, Col } from "react-bootstrap";
import {
  allGames,
  getUpdateProfile,
} from "../apis";
import url from '../constants/url';
import { constants } from "../constants/constants";
import Cancel from "../assets/images/round_not_qualified.png";
import RightArrow from "../assets/images/right-arrow.svg";

import Advertisement from "../component/advertisement";

import './more.css';
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";

class ChooseYourFavouriteGame extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      all_games_data: [],
      temp_games_data: [],
      fav_games_data: [],
      selected_game: [],
    };

    // this.onChangeInviteCode = this.onChangeInviteCode.bind(this);
    this.onClickSubmit = this.onClickSubmit.bind(this);
    this.onClickOnGame = this.onClickOnGame.bind(this);
  }

  componentDidMount() {
    this.allGamesApiCall();
  }

  // For in game name asking again again
  // unique ign
  // tournamtnt / contest / all games, join now, 

  async allGamesApiCall() {
    let data = {
      pagination: {
        pageNo: 1,
        recordPerPage: 100,
        sortBy: "order",
        sortDirection: "asc"
      }
    };
    let response = await allGames(data);
    console.log('Choose Your Favourite Game: ', response);
    console.log('Fav: ', JSON.parse(localStorage.getItem(constants.profileFavGames))?.favoriteGames);

    var games_id = [];
    var games_id1 = [];
    JSON.parse(localStorage.getItem(constants.profileFavGames))?.favoriteGames.forEach(element => {
      if (games_id.includes(element) !== true)
        games_id.push(element);
      games_id1.push(element);
    })
    console.log('Fav: ', games_id)

    this.setState({
      all_games_data: response.success ? response.data : [],
      fav_games_data: games_id,
      temp_games_data: games_id1,
    })
  }

  async getUpdateProfileAPICall(data) {
    let response = await getUpdateProfile(data);
    console.log('Submit Favourite Games: ', response);
    const { t } = this.props;
    if (response.success === true) {
      localStorage.setItem(constants.profileFavGames,JSON.stringify({success:true,favoriteGames:response.item.favoriteGames}))
      toast.success(t('success_Favourite_Game_updated_successfully'), {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      this.props.onClickCancelIconCYFG();
    }
  }

  onClickOnGame(value) {
    var temp_fav_games_data = this.state.fav_games_data;
    var temp_selected_games_data = this.state.selected_game;
    var index = temp_fav_games_data.indexOf(value._id);
    var index1 = temp_selected_games_data.indexOf(value._id);

    if (temp_fav_games_data.includes(value._id) && index > -1) {
      temp_fav_games_data.splice(index, 1);
      temp_selected_games_data.splice(index1, 1);
    }
    else {
      temp_fav_games_data.push(value._id);
      temp_selected_games_data.push(value._id);
    }

    this.setState({ selected_game: temp_selected_games_data, })
    this.setState({ fav_games_data: temp_fav_games_data, })
  }

  onClickSubmit() {
    const { t } = this.props;
    console.log("selected_game", this.state.selected_game.length);
    // if (this.state.fav_games_data.length > 0) {
    if (this.state.fav_games_data.length > 0) {

      if (this.state.selected_game.length > 0) {
        var data = { favoriteGames: this.state.fav_games_data, };

        this.getUpdateProfileAPICall(data);
      }
      else {
        console.log("this.state.temp_games_data.length", this.state.temp_games_data.length);
        console.log("this.state.fav_games_data.length", this.state.fav_games_data.length);
        if (this.state.temp_games_data.length > this.state.fav_games_data.length) {
          var data = { favoriteGames: this.state.fav_games_data, };

          this.getUpdateProfileAPICall(data);
        }
        else {
          toast.error(t('error_Please_select_at_least_one_more_game'), {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
    }
    else {
      toast.error(t('error_select_atleast_one_game'), {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  render() {
		const { t } = this.props;

    return (
      <div className="cyfg_main_div">
        <div className="cyfg_popup">
          <div className="cyfg_header">
            <div className=""></div>
            <div className="cyfg_header_text"></div>
            <div className="cyfg_cancel_icon">
              <img src={Cancel} onClick={(e) => this.props.onClickCancelIconCYFG()} />
            </div>
          </div>

          <div className="cyfg_body">
            <div className="all_sub_screens_div_related_to_ads">
              <div className="cyfg_body_scrollable_component">
                <div className="cyfg_text_normal">{t('right_hand_drop_down.select_your')}</div>
                <div className="cyfg_text_big">{t('right_hand_drop_down.favourite_game')}</div>
                <Row className="cyfg_scrollable_game_list_component">
                  {
                    this.state.all_games_data?.map((value, i) => {
                      let style = {}
                      if (this.state.fav_games_data.includes(value._id))
                        style = { border: '3px solid var(--color_green)' }
                      else
                        style = { border: '3px solid var(--color_black)' }

                      return (
                        <Col className="cyfg_game_component_col" xl={6} lg={6} md={6} sm={6} xs={12} key={i}>
                          <div className="cyfg_game_component" style={style} onClick={(e) => this.onClickOnGame(value)}>
                            <div className="cyfg_game_img"
                              style={{ backgroundImage: 'url(' + `"${url.imageUrl}${value.featuredImage.default}"` + ')' }}></div>
                            <div className="cyfg_game_gradient"></div>
                            <div className="cyfg_game_name">{value.name}</div>
                            {
                              this.state.fav_games_data.includes(value._id) &&
                              (
                                <div className="cyfg_game_selected_icon">
                                  <i className="fa fa-check-circle" />
                                </div>
                              )
                            }
                          </div>
                        </Col>
                      )
                    })
                  }
                </Row>
                <button className="cyfg_button" onClick={(e) => this.onClickSubmit()}>
                {t('right_hand_drop_down.submit')} <img src={RightArrow} alt="" className="mCS_img_loaded" />
                </button>
              </div>
            </div>
            {
               !JSON.parse(localStorage.getItem("premiumUser")) && 
            
            <Advertisement
              screen={
                localStorage.getItem('apk_screens_list') === null ?
                  { code: 'no_results' }
                  :
                  JSON.parse(localStorage.getItem('apk_screens_list')).selectFavoriteGamePopup
              }
              screen_div_class_name='.all_sub_screens_div_related_to_ads'
              screen_type='sub'
            />
  }
          </div>
        </div>
      </div>
    );
  }
}
export default  withTranslation()(ChooseYourFavouriteGame);

// export default DailyLoginRewards;
