import React from "react";
import rightArrow from "../assets/images/right-arrow.svg";

import ReactDOM from "react-dom";
import "react-intl-tel-input/dist/main.css";
// import { Row, Col } from "react-bootstrap";
import { getApplyContestCode, getApplyEventCode, onCreateScrim } from "../apis";
import { getProfiledateFormat, formatAMPM, checkDobNstateConditions } from "../component/common";
import { dateFormat } from "../component/common";
import url from "../constants/url";
import { constants } from "../constants/constants";
import Lottie from "reactjs-lottie";
import successCheck from "../assets/images/success.json";

import Cancel from "../assets/images/round_not_qualified.png";
import RightArrow from "../assets/images/right-arrow.svg";
import Close from "../assets/images/close.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ContestStructure from "../component/ContestStructure";
import TournamentsStructure from "../component/tournaments-structure";

import Advertisement from "../component/advertisement";

import "./more.css";
import { ScrimSquadRegistration } from "./Scrims/ScrimSquadRegistration";
import SquadRegistration from "./SquadRegistration";
import DobNStates from "./dobnstate";
import { withTranslation } from 'react-i18next';
import { events } from "../utils/appEvents";
import { eventTracking } from "../firebaseAnalytics";

class JoinViaInviteCode extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      jvic_data: [],
      jvic_data_current: [],
      scrimJoin: false,
      invite_code: "",
      joinData: [],
      applyMsg: "",
      scrimJoin: false,
      scrimData: [],
      progressWidth: 0,
      showConfirm: false,
      joinViaPopup: true,
      tag_tournamentJoin: false,
      showTeam: false,
      players: [],
      constestJoin: false,
      currentName:'',
      showdobnstate:false,
      dobNstateScreenName:'tournaments-contests',
      gameId:'',
    };

    this.onChangeInviteCode = this.onChangeInviteCode.bind(this);
    this.onClickSubmit = this.onClickSubmit.bind(this);
    this.findUserName = this.findUserName.bind(this);
    this.getdobnstate = this.getdobnstate.bind(this)
    this.setShowTeam = this.setShowTeam.bind(this)
  }
  setShowTeam = ()=> {
    this.setState({showTeam: false})
  }
  findUserName = (gameid) => {

    // console.log("gameid", gameid);
    let gameNames = JSON.parse(localStorage.getItem("userInGameNames"))
   
    if (gameNames) {
      let index = gameNames.findIndex(obj => obj.game == gameid)
      if (index > -1) {
        this.setState({currentname:gameNames[index].userINGameName})
        return gameNames[index].userINGameName || ""
      } else {
        return ""                                                                 
      }
    }
  }
  cancelDobNstate = () => {
    this.setState({showdobnstate:false})
 
  };
  getdobnstate = (tag) => {
    // setShowjoinpopup(tag);
    var gameid = localStorage.getItem("activeGameId")
    this.findUserName(gameid)
    this.setState({showdobnstate:false})
   
  }
  getjoin = async () => {
    console.log("get join",this.state.currentName)
    // this.getdobnstate()
    var data = this.findUserName(localStorage.getItem('activeGameId'))
console.log(data)
    if (checkDobNstateConditions(this.state.dobNstateScreenName) || data == '' || data == undefined){
      console.log("get join",)
      this.setState({showdobnstate:true})
    }
    else{
    this.setState({
      scrimJoin: false,
      showConfirm: true,
      joinViaPopup: false,
    });
  }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.scrimData !== this.state.scrimData) {
      console.log("props?.allData==>", this.state.scrimData);
      if (this.state.scrimData) {
        var per =
          (100 * this.state.scrimData?.noOfJoinedPlayer) /
            this.state.scrimData?.noOfPlayerCapacity +
          "%";
        this.setState({
          progressWidth: per,
          scrimData: this.state.scrimData,
        });
      }
    }
  }

  onJoinScrim = async () => {


    const gameNames = JSON.parse(localStorage.getItem("userInGameNames"));
    var user_data = JSON.parse(localStorage.getItem(constants.profileLite));
    var players_data = JSON.parse(localStorage.getItem("players_data"));
    // console.log(user_data);

    let data = {
      scrimId: this.state.scrimData?._id,
      userId: user_data.item.user_id,
      usersInGameName: gameNames?.[0].userINGameName,
      gamerJiName: user_data?.item.gamerjiName,
      mobileNo :user_data?.item?.phone ? user_data?.item?.phone : user_data?.item?.email
    };

    if (
      this.state.scrimData?.gameType?.players &&
      parseInt(this.state.scrimData?.gameType?.players) > 1
    ) {
      console.log("if entrer");
      var numArray = [];
      var selectedPlayer = [];
      for (
        var i = 2;
        i <= parseInt(this.state.scrimData?.gameType?.players);
        i++
      ) {
        numArray.push(i);

        selectedPlayer.push({
          userId: null,
          inGameName: null,
          gamerJiName: user_data?.item.gamerjiName,
          country: null,
          mobileNo: null,
        });
      }

      this.setState({
        players: selectedPlayer.length,
      });

      console.log(selectedPlayer.length);

      console.log("he shjy");
      this.setState({ showTeam: true });
      //console.log("he shjy",this.);

      this.setState({
        joinData: data,
        showConfirm: false,
        // showTeam: true,
        players: selectedPlayer,
      });
      // setShowScrimTeam(true);
    } else {
      let res = await onCreateScrim(data);
      console.log(res);
      // setPlayers(res.gameType)
      {
        res.success === true
          ? (window.location.href =
              "/scrim-details/" + this.state.scrimData?._id)
          : toast.error(res.data.data[0]?.msg, {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
      }
    }
  };

  componentDidMount() {
    // this.getListofDailyRewardsApiCall();
  }

  onChangeInviteCode(e) {
    // console.log(e.target.value)
    this.setState({ invite_code: e.target.value });
  }

  // findUserName = () => {
  //   // let activeGameId = localStorage.getItem("activeGameId");
  //   let activeGameId = localStorage.getItem("activeGameId");
  //   console.log("activeGameId", activeGameId);
  //   let gameNames = JSON.parse(localStorage.getItem("userInGameNames"));
  //   console.log("gameNames", gameNames);
  //   if (gameNames) {
  //     let index = gameNames.findIndex((obj) => obj.game === activeGameId);
  //     console.log("index gamename", index);
  //     if (index >= 0) {
  //       console.log(
  //         "index gameNames[index].userINGameName",
  //         gameNames[index].userINGameName
  //       );

  //       this.setState.currentName(gameNames[index].userINGameName)
  //       return gameNames[index].userINGameName || "";
  //     } else {
  //       return "";
  //     }
  //   }
  // };
  onClickSubmit() {
    const {t} = this.props;
    console.log(this.state.invite_code);
    if (this.state.invite_code !== "") {
      eventTracking(events.join_via_invite_submit,{EP_PromoCodeID:this.state.invite_code});

      this.applyCode();
      // this.setState({joinViaPopup: false})
      // this.props.onClickCancelIconJVIC()
    } else {
      toast.error(t('error_enter_invite_code'), {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  async applyCode() {
    var user_data = JSON.parse(localStorage.getItem(constants.profileLite));
    // console.log("user_data", user_data);

    let payload = {
      search: {
        user: user_data?.item?.user_id,
      },
    };
    let res = await getApplyContestCode(
      this.state.invite_code.toUpperCase(),
      payload
    );
    // console.log("Code Data:=> ", res.data);
    if (res?.type === "scrim") {
      console.log("its scrim");
    }
    if (res?.data && res?.success) {
      let data = {};
      if (res?.data?.isSingle) {
        localStorage.setItem("activeGameId", res?.data?.game);

        data = res?.data;
      } else {
        localStorage.setItem('activeGameId', res?.item?.saasGameId)
          localStorage.setItem('activeGame', res?.item?.game?.name)
        data = {
          id: res?.item?._id,
          img: url.imageUrl + res?.item?.featuredImage?.default, // not present
          coin: url.imageUrl + res?.item?.currency?.img?.default,
          title: res?.item?.title,
          contestsList: res?.item?.contestsList, // not present
          totalPlayers: res?.item?.totalPlayers,
          isJoined: res?.item?.isJoined,
          totalJoinedPlayers: res?.item?.totalJoinedPlayers,
          shortCode: res?.item?.shortCode,
          gameName: res?.item?.game?.name, // not present
          levelNote: res?.item?.game?.settings?.levelNote, // not present
          currency: res?.item?.currency,
          gameId: res?.item?.game?._id, // not present
          winners: res?.item?.totalWinners,
          gameType: res?.item?.gameType?.name,
          gameTypePlayers: res?.item?.gameType?.players,

          hostedby: res?.item?.host?.name,
          hostrate: res?.item?.host?.rate,
          entryFee: res?.item?.entryFee,
          titles: res?.item?.titles,
          rules: res?.item?.rules, // not present
          allrounds: res?.item?.rounds, // not present
          round: res?.item?.rounds?.length, // not present
          winningCoin: res?.item?.totalWinningsDistributedCoin, // not present
          startDate: res?.item?.startDate, // not present
          endDate: res?.item?.endDate, // not present
          roundDatetime: res?.item?.rounds[0]?.matches, // not present
          prizepool: res?.item?.prizepool,
          prizePool: res?.item?.prizePool,
          pointpool: res?.item?.pointpool,
          createdAt: dateFormat(res?.item?.createdAt), // not present
          updatedAt: dateFormat(res?.item?.updatedAt), // not present
          saasGameId:res?.item?.saasGameId
        };
      }
      // setApplyMsg('Code Applied Successfully.');
      // setCode("");
      // setJoinData(data);
      // console.log("Code Data", res?.item)
      // setTournamnetJoin(true);
      // console.log(res);
      if (res?.type === "scrim") {
        let data = res?.data;
        console.log("Code Data", data);
        localStorage.setItem("activeGameId", data?.game?._id)
        this.setState({
          scrimJoin: true,
          scrimData: data,
        });
        localStorage.setItem('activeGameId', data?.game?._id)
          localStorage.setItem('activeGame', data?.game?.name)
        localStorage.setItem("scrimData", JSON.stringify(data));

        // console.log(joinData)
      } else {
        // console.log("contest");
        // console.log("Code Data", data);
        this.setState({
          joinData: data,
          constestJoin: true,
          // tournamnetJoin: true,
        });
      }

      // this.setState(
      //   {
      //     invite_code: "",
      //     joinData: data,
      //   },
      //   () => {
      //     this.setState({ tag_tournamentJoin: true });
      //   }
      // );
      // await getjoin(res?.item);
    } else {
      // setApplyMsg(res?.data?.errors?.[0]?.msg);
      // this.setState({ applyMsg: res?.data?.errors?.[0]?.msg, });

      let data = { code: this.state.invite_code.toUpperCase() , user:JSON.parse(localStorage.getItem(constants.profileLite))?.item?.user_id};
      let res = await getApplyEventCode(data);
      if (res?.item) {
        data = {};
        if (res?.item?.isSingle) {
          data = res?.item;
        } else {
          localStorage.setItem('activeGame', res?.item?.gameAry[0]?.name);
          localStorage.setItem('tournamentId', res?.item?._id);
          localStorage.setItem('activeGameId', res?.item?.gameAry[0]?._id);
          localStorage.setItem("activeGameType", JSON.stringify(res?.item?.gameTypeAry?.name));

          data = {
            id: res?.item?._id,
            img: url.imageUrl + res?.item?.featuredImage?.default, // not present
            coin: url.imageUrl + res?.item?.currency?.img?.default,
            title: res?.item?.title,
            contestsList: res?.item?.contestsList, // not present
            totalPlayers: res?.item?.totalPlayers,
            isJoined: res?.item?.isJoined,
            totalJoinedPlayers: res?.item?.totalJoinedPlayers,
            shortCode: res?.item?.shortCode,
            gameName: res?.item?.gameAry[0]?.name, // not present
            levelNote: res?.item?.game?.settings?.levelNote, // not present
            currency: res?.item?.currency[0],
            gameId: res?.item?.gameAry[0]?._id,
            winners: res?.item?.totalWinners,
            gameType: res?.item?.gameTypeAry?.name,
            gameTypePlayers: res?.item?.gameTypeAry?.players,
            gameTypeExtraPlayers: res?.item?.gameTypeAry?.extraPlayers, // not present

            hostedby: res?.item?.host?.name,
            hostrate: res?.item?.host?.rate,
            entryFee: res?.item?.entryFee,
            titles: res?.item?.titles,
            rules: res?.item?.rules, // not present
            allrounds: res?.item?.rounds, // not present
            round: res?.item?.rounds?.length, // not present
            winningCoin: res?.item?.totalWinningsDistributedCoin, // not present
            startDate: res?.item?.startDate, // not present
            endDate: res?.item?.endDate, // not present
            roundDatetime: res?.item?.rounds[0]?.matches, // not present
            prizepool: res?.item?.prizePoolInfo,
            prizePool: res?.item?.prizePool,
            pointpool: res?.item?.pointpool,
            inGameName: this.findUserName(res?.item?.gameAry[0]?._id),
            createdAt: dateFormat(res?.item?.createdAt), // not present
            updatedAt: dateFormat(res?.item?.updatedAt), // not present
            rewardDisplayText: res?.item?.rewardDisplayText
          };
        }
        this.setState(
          {
            invite_code: "",
            joinData: data,
          },
          () => {
            this.setState({ tag_tournamentJoin: true });
          }
        );
      } else {
        toast.error(res?.data?.errors?.[0]?.msg, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }

  Scrimdetail = () => {
    window.location.href = "/scrim-details/" + this.state.scrimData?._id;
    this.setState({
      scrimJoin: false,
      joinViaPopup: false,
    });
  };

  // Tournaments - Tournament/all (One box)
  createTournamentSingleBox(data, index) {
    console.log("data",data)
    return (
      <TournamentsStructure
        allFeaturedData={data}
        key={index}
        isFromJoinViaCode={true}
      />
    );
  }
  // Contests - Tournament/{id} (all box)
  createContestSingleBox(data, index) {
    return <ContestStructure allData={data} key={index} />;
  }

  // Join Now Popup - Click on Apply Code - Tournament/all - Tournament/{id}
  createAfterApplyCodePopup() {
    return (
      <div className="tc_popup_bg">
        <div className="custom-popup home-featured show" id="contact_popup">
          <div className="popup-head bg-light">
            <h3>
              {this.state.joinData?.isSingle === true
                ? this.state.joinData.title
                : this.state.joinData?.gameName}
            </h3>
            <button
              className="close-popup"
              onClick={() =>
                this.setState({
                  tag_tournamentJoin: false,
                  constestJoin: false,
                })
              }
            >
              <img src={Close} alt="" />
            </button>
          </div>
          <div className="popup-body">
            {this.state.joinData?.isSingle === true
              ? this.createContestSingleBox(this.state.joinData, 1)
              : this.createTournamentSingleBox(this.state.joinData, 1)}
          </div>
        </div>
      </div>
    );
  }

  createScrimApplyCodePopup() {
    const {t} = this.props;
    return (
      <div className="tc_popup_bg">
        <div className="custom-popup home-featured show " id="contact_popup">
          <div className="popup-head bg-light">
            <h3>
              {this.state.scrimData?.title.length > 30 ? (
                <marquee>{this?.state?.scrimData?.title}</marquee>
              ) : (
                <>{this?.state?.scrimData?.title}</>
              )}{" "}
            </h3>
            <button
              className="close-popup"
              onClick={() =>
                this.setState({
                  scrimJoin: false,
                })
              }
            >
              <img src={Close} alt="" />
            </button>
          </div>
          <div className="popup-body">
            <div
              className="contest-list contest-tournament-list game_tounament "
              style={{ margin: "20px auto 0px" }}
            >
              {console.log(this.state.showdobnstate)}
              {
                  this.state.showdobnstate ?
                    <DobNStates
                      submitDobNstate={this.getdobnstate}
                      cancelDobNstate={this.cancelDobNstate}
                      screen={this.state.dobNstateScreenName}
                      gameId={this?.state?.scrimData?.gameType}
                    /> : ""
      }
              <div className="box scrimDetail">
                <h3
                  style={{
                    fontSize: "16px",
                    fontWeight: "800",
                    marginTop: "4px",
                  }}
                >
                  {/* {scrimData?.title} */}
                  <span className="span">
                    <div to="/create-scrim">
                      {/* <img
                        // src={Pencil}
                        alt="Edit"
                        style={{ color: "black !important" }}
                      /> */}
                    </div>
                  </span>
                </h3>

                <div
                  className="row tournament_contest_row"
                  style={{ marginBottom: "-20px" }}
                >
                  <div className="col-12">
                    <div className="inner_row">
                      <div className="inner_item">
                        { t('contest_details.date') }
                        <b>
                          {getProfiledateFormat(this.state.scrimData?.date)}
                        </b>
                      </div>
                      <div className="inner_item">
                      { t('contest_details.time') }{" "}
                        <b>
                          {formatAMPM(new Date(this.state.scrimData?.time))}
                        </b>
                      </div>

                      <div className="inner_item">
                        {this.state.scrimData?.title1?.name}
                        <b>{this.state.scrimData?.title1?.value}</b>
                      </div>
                      {this.state.scrimData?.title2 ? (
                        <div className="inner_item">
                          {this.state.scrimData?.title2?.name}{" "}
                          <b>{this.state.scrimData?.title2?.value}</b>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="inner_row bg-dark">
                      <div className="inner_item inner_item_medium">
                      { t('contest_details.players') } <br />{" "}
                        <b>{this.state.scrimData?.noOfPlayerCapacity}</b>
                      </div>
                      <div className="inner_item inner_item_medium">
                      { t('tournaments.winners') } <br /> <b>{this.state.scrimData?.noOfWinner}</b>
                      </div>
                      <div className="inner_item inner_item_medium">
                      { t('contest_details.id') } <br /> <b>{this.state.scrimData?.shortCode}</b>
                      </div>
                    </div>

                    <div className="avalible_wrap">
                      <div className="inner">
                        <span
                          style={{
                            width: this.state.progressWidth,
                          }}
                        ></span>
                      </div>
                      <p
                        className="d-flex justify-content-between text-light"
                        style={{ paddingBottom: "1rem" }}
                      >
                        <span className="">
                          {/* {this.state.scrimData?.noOfPlayerCapacity -
                            this.state.scrimData?.noOfJoinedPlayer}{" "}
                          players remaining */}
                          { t('contest_details.players_remaining',{players: this.state.scrimData?.noOfPlayerCapacity -
                            this.state.scrimData?.noOfJoinedPlayer}) }
                        </span>
                        <span className="">
                          {/* {this.state.scrimData?.noOfJoinedPlayer} players
                          joined */}
                          { t('contest_details.players_joined',{players: this.state.scrimData?.noOfJoinedPlayer}) }
                        </span>
                      </p>
                    </div>
                    <div className="contest_foot p-2">
                      <div className="inner pl-2">
                        <span className="">{ t('private_contest.hosted_by') }</span>
                        <marquee>
                          <b>
                            {" "}
                            {this.state.scrimData?.hostedBy} (
                            <i className="fas fa-star text-dark"></i>)
                          </b>
                        </marquee>
                      </div>
                      {this.state.scrimData?.noOfJoinedPlayer >=
                      this.state.scrimData?.noOfPlayerCapacity ? (
                        <div className="inner pointer">
                          <a className="contest_joining_popup">{ t('tournaments.full') }</a>
                        </div>
                      ) : this.state.scrimData?.isJoined === true ? (
                        <div className="inner pointer">
                          <a className="d-flex justify-content-center align-items-center">
                            <b
                              className="contest_joining_popup d-flex justify-content-center align-items-center"
                              // onClick={() => joinedContest()}
                            >
                              <div
                                className="d-flex justify-content-center align-items-center"
                                style={{ height: "25px" }}
                                onClick={() => this.Scrimdetail()}
                              >
                                { t('tournaments.joined') }
                              </div>

                              <div
                                className="tick-icon"
                              >
                                <Lottie
                                  options={{
                                    animationData: successCheck,
                                    w: "20px",
                                  }}
                                />
                              </div>
                            </b>
                          </a>
                        </div>
                      ) : (
                        <div
                          className="inner pointer"
                          onClick={() => this.getjoin(this.state.scrimData)}
                        >
                          <a className="contest_joining_popup">{ t('tournaments.join_now') }</a>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {t} = this.props;

    return (
      <div>
        {this.state.joinViaPopup === true && (
          <div className="jvic_main_div">
            {/* <ToastContainer /> */}
            <div className="jvic_popup">
              <div className="jvic_header">
                <div className=""></div>
                <div className="jvic_header_text">{t('right_hand_drop_down.join_via_invite_code')}</div>
                <div className="jvic_cancel_icon">
                  <img
                    src={Cancel}
                    onClick={(e) => this.props.onClickCancelIconJVIC()}
                  />
                </div>
              </div>

              <div className="jvic_body">
                <div className="all_sub_screens_div_related_to_ads">
                  <div className="jvic_body_scrollable_component">
                    <div className="jvic_text">{t('right_hand_drop_down.invite_code')}</div>
                    <input
                      className="jvic_input"
                      placeholder={t('right_hand_drop_down.enter_invite_code')}
                      value={this.state.invite_code.toUpperCase()}
                      onChange={this.onChangeInviteCode}
                    />
                    <button
                      className="jvic_button"
                      onClick={(e) => this.onClickSubmit()}
                    >
                      {t('right_hand_drop_down.submit')}{" "}
                      <img src={RightArrow} alt="" className="mCS_img_loaded" />
                    </button>
                    {/* <p className="error">{this.state.applyMsg}</p> */}
                  </div>

                  {this.state.tag_tournamentJoin === true &&
                    this.createAfterApplyCodePopup()}
                </div>

                {this.state.showConfirm === true && (
                  <div className="tc_popup_bg">
                    <div
                      className="custom-popup rules confirm custom_scroll join show scrim-applyCode-Popup"
                      id="contact_popup"
                    >
                      <div className="popup-head">
                        {/* <marquee> */}
                        <h3> {this.state.scrimData?.title} </h3>
                        {/* </marquee> */}

                        <button
                          className="close-popup"
                          onClick={() => this.stateshowConfirm(false)}
                        >
                          <img src={Close} alt="" />
                        </button>
                      </div>
                      <div className="popup-body bg-yellow">
                        <div className="all_sub_screens_div_related_to_ads">
                          <p className="game_det">
                            <span>{this.state.scrimData?.gameType?.name}</span>
                            <span>
                              {getProfiledateFormat(this.state.scrimData?.date)}{" "}
                              {}
                              {formatAMPM(new Date(this.state.scrimData?.time))}
                            </span>
                          </p>
                          <p className="game_det">
                            <span>
                             {t('private_contest.hosted_by')}: {this.state.scrimData?.hostedBy}
                            </span>
                            <span></span>
                          </p>

                          {/* <button
            className="btn btn-dark main-btn next scrim-tournament-btn"
            onClick={() => onJoinScrim()}
          >
            JOIN SCRIM{" "}
            <img
              // src={rightArrow}
              alt=""
              className="mCS_img_loaded"
            />
          </button> */}
                        </div>
                        {
                           !JSON.parse(localStorage.getItem("premiumUser")) && 
                        
                        <Advertisement
                          screen={
                            localStorage.getItem("apk_screens_list") === null
                              ? { code: "no_results" }
                              : JSON.parse(
                                  localStorage.getItem("apk_screens_list")
                                ).joinContestWalletPopup
                          }
                          screen_div_class_name=".all_sub_screens_div_related_to_ads"
                          screen_type="sub"
                        />
  }
                      </div>
                    </div>
                  </div>
                )}

                {this.state.scrimJoin === true &&
                  this.createScrimApplyCodePopup()}
                {this.state.constestJoin === true &&
                  this.createAfterApplyCodePopup()}
                  {
                     !JSON.parse(localStorage.getItem("premiumUser")) && 
                  
                <Advertisement
                  screen={
                    localStorage.getItem("apk_screens_list") === null
                      ? { code: "no_results" }
                      : JSON.parse(localStorage.getItem("apk_screens_list"))
                          .joinViaInviteCodePopup
                  }
                  screen_div_class_name=".all_sub_screens_div_related_to_ads"
                  screen_type="sub"
                />
  }
              </div>
            </div>
          </div>
        )}
        {this.state.showConfirm === true && (
          <div className="tc_popup_bg">
            <div
              className="custom-popup rules confirm custom_scroll join show scrim-applyCode-Popup"
              id="contact_popup"
            >
              <div className="popup-head">
                {/* <marquee> */}
                <h3>
                  {this.state.scrimData?.title.length > 30 ? (
                    <marquee>{this.state.scrimData?.title}</marquee>
                  ) : (
                    <>{this.state.scrimData?.title}</>
                  )}{" "}
                </h3>
                {/* </marquee> */}

                <button
                  className="close-popup"
                  onClick={() =>
                    {
                    this.setState({
                      showConfirm: false,
                    })
                    this.props.onClickCancelIconJVIC()
                  }
                  }
                >
                  <img src={Close} alt="" />
                </button>
              </div>
              <div className="popup-body bg-yellow">
                <div className="all_sub_screens_div_related_to_ads">
                  <p className="game_det">
                    <span>
                      {this.state.scrimData?.game?.name}-
                      {this.state.scrimData?.gameType?.name}
                    </span>
                    <span>
                      {getProfiledateFormat(this.state.scrimData?.date)} {}
                      {formatAMPM(new Date(this.state.scrimData?.time))}
                    </span>
                  </p>
                  <p className="game_det">
                    <span>{t('private_contest.hosted_by')}: {this.state.scrimData?.hostedBy}</span>
                    <span></span>
                  </p>

                  
                </div>
                <div class="d-flex justify-content-center align-items-center">
                <button
                    className="btn btn-dark main-btn next scrim-tournament-btn"
                    onClick={() => this.onJoinScrim()}
                  >
                    {t('contest.join_contest')}{" "}
                    <img src={rightArrow} alt="" className="mCS_img_loaded" />
                  </button>
                </div>
{
   !JSON.parse(localStorage.getItem("premiumUser")) && 

                <Advertisement
                  screen={
                    localStorage.getItem("apk_screens_list") === null
                      ? { code: "no_results" }
                      : JSON.parse(localStorage.getItem("apk_screens_list"))
                          .joinContestWalletPopup
                  }
                  screen_div_class_name=".all_sub_screens_div_related_to_ads"
                  screen_type="sub"
                />
  }
              </div>
            </div>
          </div>
        )}
        {this.state.showTeam === true && (
          <ScrimSquadRegistration 
          players={this.state.players.length} 
          setShowTeam ={ this.setShowTeam}
          />
        )}
      </div>
    );
  }
}

export default withTranslation()(JoinViaInviteCode);
