import { useState } from "react";
import { useTranslation } from "react-i18next";

export const GameCategoryPopup = (props) => {
  const { onSelectItem } = props;
  const categoryList = props.categoryList;
  const [close, setClose] = useState(true);
  const {t} = useTranslation()


 

  const closeModal = () => {
    setClose(false);
  };
  const handleItem = (item) => {
    console.log(item._id);
    onSelectItem(item);
    closeModal();
  };

  return (
<div>
    {close && (
        <div className={` country_list_popup_component left ${close ? "show" : "hide"}`}>
        <div className="country_list_popup">
          <div className="country_list_popup_header">
            <div></div>
            <div className="country_list_popup_header_text">{t('error_select_game_category')}</div>

            <div className="button-section" >
            <button  onClick={() => closeModal()}  className="close-button"></button>  
            </div>
          </div>
  
          <div className="country_list_popup_body">
            {categoryList?.map((item, index) => {
              return (
                <div
                  className="country_list_popup_body_text"
                  key={index}
                  value={item?._id}
                  onClick={() => handleItem(item)}
               
                >
                  {item.name}
  
               
                </div>
              );
            })}
          </div>
        </div>
      </div>
    )}
</div>
  );
};
