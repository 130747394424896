import { getScrimByUserId } from "../../apis";
import CopyIcon from "../../assets/images/copy-icon.svg";
import { useEffect, useState } from "react";
import Loader from "../loader";
import NoContests from "../../assets/images/Group_8748.png";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Cookies from 'js-cookie'
import { constants } from "../../constants/constants";

export const JoinedScrimCard = () => {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const {t} = useTranslation()
  const currentLang =  Cookies.get('i18next') || 'EN'

  useEffect(() => {
    fetchData(page);
  }, []);
  let fetchData = async (page) => {
    console.log("called");
    setLoader(true);
    let userName = JSON.parse(localStorage.getItem(constants.profileLite))?.item
      ?.user_id;
    let res = await getScrimByUserId({
      userid: userName,
      game: localStorage.getItem("activeGameId"),
      pagination: {
        pageNo: page,
        recordPerPage: 10,
        sortBy: "createdAt",
        sortDirection: "asc",
      },
    });
    if (res.success) {
      setTotalPage(res.totalPages);
      setData([...data, ...res?.data]);
      console.log(res?.data);
    }
    console.log("called", loader);
    setLoader(false);
  };

  const loadHandler = () => {
    setPage(page + 1);

    fetchData(page);
  };
  console.log(data);
  const openScrimDetails = (scrimId) => {
    window.location.href = `/scrim-details/${scrimId}`;
  };
  if (loader) return <Loader />;
  return (
    <>
      {data?.length > 0 ? (
        <>
          {data?.map((ele) => (
            <div className="contest-list contest-tournament-list game_tounament">
              <p className="status_contest bg-primary">{ele.status}</p>

              <div className="box" style={{ cursor: "pointer" }}>
                {ele?.title.length > 32 ? (
                  <h3
                    style={{
                      fontSize: "16px",
                      fontWeight: "800",
                      marginTop: "4px",
                    }}
                  >
                    <marquee> {ele?.title}</marquee>
                  </h3>
                ) : (
                  <h3
                    style={{
                      fontSize: "16px",
                      fontWeight: "800",
                      marginTop: "4px",
                    }}
                  >
                    {ele?.title}
                  </h3>
                )}

                <div className="row tournament_contest_row">
                  <div
                    className="col-12"
                    onClick={() => openScrimDetails(ele.scrimId)}
                  >
                    <div className="inner_row">
                      <div className="inner_item">
                        {t('contest_details.date')}{" "} <b>{moment(ele?.date).format("DD/MM/YYYY")}</b>
                      </div>
                      <div className="inner_item">
                      {t('contest_details.time')} <b>{moment(ele?.time).format("hh:mm a")}</b>
                      </div>
                      {ele?.titles?.[0] ? (
                        <div className="inner_item">
                          { ele?.titles?.[0]?.name }{" "}
                          <b>{ele?.titles?.[0]?.value}</b>
                        </div>
                      ) : (
                        ""
                      )}
                      {ele?.titles?.[1] ? (
                        <div className="inner_item">
                          { ele?.titles?.[1]?.name }{" "}
                          <b>{ele?.titles?.[1]?.value}</b>
                        </div>
                      ) : (
                        ""
                      )}
                      {/* {ele?.titles?.slice(0, 2).map((ele) => (
                        <div className="inner_item">
                          {ele?.name} <b>{ele?.value}</b>
                        </div>
                      ))} */}
                    </div>
                    <div className="inner_row bg-dark">
                      <div className="inner_item inner_item_medium divider-white">
                        {t('contest_details.players')} <br /> <b>{ele?.noOfPlayerCapacity}</b>
                      </div>
                      <div className="inner_item inner_item_medium divider-white">
                      {t('tournaments.winners')} <br /> <b>{ele.noOfWinner}</b>
                      </div>
                      <div className="inner_item inner_item_medium divider-white">
                      {t('contest_details.id')} <br /> <b>{ele?.shortCode}</b>
                      </div>
                    </div>

                    <div className="avalible_wrap">
                      <div className="inner">
                        <span
                          style={{
                            width: `${
                              (100 * ele?.noOfJoinedPlayer) /
                              ele?.noOfPlayerCapacity
                            }%`,
                          }}
                        ></span>
                      </div>
                      <p className="d-flex justify-content-between text-light">
                        <span className="">
                          {t('contest_details.players_remaining', {players: ele?.noOfPlayerCapacity - ele?.noOfJoinedPlayer})}
                        </span>
                        <span className="">
                          {t('contest_details.players_joined', {players: ele?.noOfJoinedPlayer})}
                        </span>
                      </p>
                    </div>
                  </div>

                  <div className="contest_foot contest_details_foot bg-secondary text-light  scrim-card-padding-top scrim-card-padding-bottom scrimcard">
                    <div className="inner inner-text ">
                      <span
                        style={{ textAlign: "center" }}
                        className="scrim-card-padding-bottom"
                      >
                        {t('private_contest.hosted_by')} - {ele.gamerjiName}
                      </span>
                      <span
                        style={{
                          fontSize: "12px",
                          borderTop: "1px solid white",
                          textAlign: "center",
                          justifyContent: "center",
                        }}
                        className="scrim-card-padding-top flex"
                      >
                        {" "}
                        <span className="inner_item inner_item_medium joined-scrim-right-border">
                        {t('contest_details.room_id')} <br />{" "}
                          {ele?.roomIdPassword?.id ? (
                            <b>
                              {" "}
                              {ele?.roomIdPassword?.id}
                              <img
                                src={CopyIcon}
                                alt=""
                                style={currentLang?.toLowerCase() == "ar" ? { cursor: "pointer", marginRight: "5px" } : { cursor: "pointer", marginLeft: "5px" }}
                                onClick={() => {
                                  navigator.clipboard.writeText(ele?.roomId);
                                }}
                              />
                            </b>
                          ) : (
                            <>-</>
                          )}
                        </span>
                        <span className="inner_item inner_item_medium">
                        {t('contest_details.password')} <br />{" "}
                          {ele?.roomIdPassword?.password ? (
                            <b>
                              {" "}
                              {ele?.roomIdPassword?.password}
                              <img
                                src={CopyIcon}
                                alt=""
                                style={currentLang?.toLowerCase() == "ar" ? { cursor: "pointer", marginRight: "5px" } : { cursor: "pointer", marginLeft: "5px" }}
                                onClick={() => {
                                  navigator.clipboard.writeText(ele?.roomId);
                                }}
                              />
                            </b>
                          ) : (
                            <>-</>
                          )}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      ) : (
        <div className="no_tournament">
          <img src={NoContests} style={{ height: "50%" }} />
        </div>
      )}

      {page < totalPage ? (
        <div className="padding-top2">
          <button className="btn scrim-leaderboard-btn  " onClick={loadHandler}>
          {t('contest.load_more')}
          </button>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
