import React, { Fragment, useEffect, useState } from "react";
import RightArrow from "../assets/images/right-arrow.svg";
import { TermsData } from "../apis";
import ReactHtmlParser from "react-html-parser";
import Previous from "../assets/images/previous.svg";
import Loader from "../component/loader";
import { Link } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import { events } from "../utils/appEvents";
import { eventTracking } from "../firebaseAnalytics";

function TermsCondition() {
  const [termsData, setTermsData] = useState(false);
  const [loader, setLoader] = useState(false);
  const {t} = useTranslation()

  useEffect(async () => {
    eventTracking(events.terms_of_use_tab);
    setLoader(true);
    let res = await TermsData();
    if (res) {
      setTermsData(res?.item?.description);
    }
    setLoader(false);
  }, []);



  return (
    <div className="inner-wrap">
      <div className="header">
        <div className="back-btn">
          <Link to="/more">
            <img src={Previous} alt="" />
          </Link>
        </div>
        <h2>{t('right_hand_drop_down.terms_of_use')}</h2>
        <div className="wallet-btn"></div>
      </div>
      <div className="body mCustomScrollbar _mCS_1 p-4">
        {loader === true ? <Loader /> : <div className="terms_main">
          {
            termsData ? ReactHtmlParser(termsData)
            : <h5 className="text-center">
              No Records Found
            </h5>

          }
           {/* {ReactHtmlParser(termsData)}  */}
           
           </div>}
      </div>
    </div>
  );
}

export default TermsCondition;
