import React, { Fragment, useState, useEffect } from 'react';
import Previous from "../assets/images/previous.svg";
import Wallet from "../assets/images/wallet.svg";
import MoneyIcon2 from "../assets/images/money-icon-2.svg";
import Coin from "../assets/images/coin.png";
import Pending from "../assets/images/pending-payment.png";
import Fail from "../assets/images/fail-payment.png";
import Success from "../assets/images/success-payment.png";
import { Link } from 'react-router-dom';
import { loadStripe } from "@stripe/stripe-js";
import { Elements, useStripe } from "@stripe/react-stripe-js";
// import CheckoutForm from "../component/CheckoutForm";
import colors from "../constants/colors";
import helperFunc from '../helperFuncs';
import Moment from 'react-moment';
import {
	getClientSecret,
	getCoinStoreDetails,
	createStripeTransactionWeb,
	createStripePaymentIntentWeb,
	returnPaymentData
} from "../apis";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
//const stripe = require('stripe')('sk_test_51KezD8SB4g2oK7dcab4nYHmaAPcYpqQaBTjUjOHtpbTWDaRkGeXL3jV7wEbA7zYusiFIP8nhaDtTIaYh6DiyebbX00kJvnt6oi');
var stripeAPIKey = helperFunc.getStripeAPIKey()

const stripePromise = loadStripe(stripeAPIKey);
//const stripePromise = loadStripe("pk_test_51KezD8SB4g2oK7dcsmm8TRlmVJFipgCPClcZnnO2xfywtgLG0UKyxEQ99qSO0fPXDWVOO4zQVlifn5uvSwZoldYE00PqEStE5G");


function PaymentStatus(props) {
	//	const stripe = useStripe();
	const [clientSecret, setClientSecret] = useState("");
	const [paymentStatus, setPaymentStatus] = useState("");
	const [transaction, setTransaction] = useState("");
	const [coinStore, setCoinStore] = useState("");
	const [coinStoreObjID, setCoinStoreID] = useState("");
	// useEffect(() => {
	// 	// const clientSecretFromURL = new URLSearchParams(window.location.search).get(
	// 	// 	"payment_intent_client_secret"
	// 	// 	  );
	// 	// 	  console.log("payment_intent_client_secret== ==>",clientSecretFromURL)
	// 	// 	  if(clientSecretFromURL){
	// 	// 		//createStripeTransactionWeb(clientSecretFromURL)
	// 	// 	  }

	// }, []);
	const createStripeTransaction = async (clientSecretFromURL, coinStoreId) => {
		// var coinStoreId =
		// window.location.href.split("/")[
		//   window.location.href.split("/").length - 1
		// ];
		//	console.log("coinStoreObjID==>res?.item===>",coinStoreId);
		//	console.log("clientSecretFromURL==>res?.item===>",clientSecretFromURL);
		let res = await createStripeTransactionWeb({
			coinStore: coinStoreId,
			clientSecret: clientSecretFromURL
		});
		if (res) {

			console.log("createStripeTransactionWeb==>res?.item===>", res?.item);
			await returnPaymentData({
				"orderId": res.item.itemTransaction.transactionUniqueID,
				"orderAmount": res.item.itemTransaction.amount,
				"paymentMode": 'Card',
				"txStatus": res.item.itemTransaction.paymentStatus,
				"referenceId": res.item.itemTransaction.paymentRefID,
				"txTime": res.item.itemTransaction.paymentTimestamp
			});
			return res;
			// setClientSecret(res?.item?.clientSecret);

		}
	};
	//   const fetchClientSecretData = async () => {
	// 	var paymentStatusURL =
	//     window.location.href.split("?")[0];
	// 	var coinStoreId =
	//     paymentStatusURL.split("/")[
	// 		paymentStatusURL.split("/").length - 1
	//     ];
	// 	let res = await getClientSecret({coinStore:coinStoreId});
	// 	if (res) {
	// 		setClientSecret(res?.item?.clientSecret);

	// 	}
	//   };

	//   const onSubmitPayment = async () => {
	// 	// var coinStoreId =
	//     // window.location.href.split("/")[
	//     //   window.location.href.split("/").length - 1
	//     // ];
	// 	// setCoinStoreID(coinStoreId);
	// 	// let res = await createStripePaymentIntentWeb({coinStore:coinStoreId});
	// 	// if (res) {
	// 	// 	//setCoinStore(res);
	// 	// 	console.log("res?.item==>",res);
	// 	// 	// const cardDetails1 = {
	//     //     //     number: '4242424242424242',
	//     //     //     expMonth: 10,
	//     //     //     expYear: 23,
	//     //     //     cvc: '888',
	//     //     // }
	// 	// 	// stripe.confirmCardPayment(
	// 	// 	// 	res?.item?.clientSecret,
	// 	// 	// 	{
	// 	// 	// 	  payment_method: {card: cardDetails1},
	// 	// 	// 	}
	// 	// 	//   ).then(function(result) {
	// 	// 	// 	if (result.error) {
	// 	// 	// 	  // Display error.message in your UI.
	// 	// 	// 	} else {
	// 	// 	// 	  // The payment has succeeded
	// 	// 	// 	  // Display a success message
	// 	// 	// 	}
	// 	// 	//   });

	// 	// }
	//   };
	const fetchCoinStoreData = async () => {
		// var coinStoreId =
		// window.location.href.split("/")[
		//   window.location.href.split("/").length - 1
		// ];
		var paymentStatusURL =
			window.location.href.split("?")[0];
		var coinStoreId =
			paymentStatusURL.split("/")[
			paymentStatusURL.split("/").length - 1
			];
		console.log("coinStoreId==>", coinStoreId);
		setCoinStoreID(coinStoreId);
		let res = await getCoinStoreDetails(coinStoreId);
		if (res) {
			setCoinStore(res);
			console.log("res?.item==>", res);
			const clientSecretFromURL = new URLSearchParams(window.location.search).get(
				"payment_intent"
			);
			const paymentStatusFromURL = new URLSearchParams(window.location.search).get(
				"redirect_status"
			);
			setPaymentStatus(paymentStatusFromURL);
			console.log("payment_intent== ==>", clientSecretFromURL)
			if (clientSecretFromURL) {
				var resTrans = await createStripeTransaction(clientSecretFromURL, coinStoreId);
				setTransaction(resTrans?.item?.itemTransaction);
				console.log("resTrans==>", resTrans);
			}

		}
	};
	const appearance = {
		theme: 'stripe',
	};
	const options = {
		clientSecret,
		appearance,
	}
	useEffect(() => {
		fetchCoinStoreData();
		//fetchClientSecretData();
	}, []);

	const getPopuBackgroundColor = () => {
		switch (paymentStatus) {
			case "succeeded":
				return '#36DE4C';

			case "processing":
				return '#FF9544';

			case "requires_payment_method":
				return '#FF4646';

			default:
				return '#FF4646';

		}

	}
	const goback = () => {
		window.location.href = "/coin-store";
	};
	return (
		<Fragment>
			<div className="header" style={{ marginTop: "25px", color: colors.white }}>
				<div className="row">
					<div className="back-btn col-2 text-center">
						<a onClick={() => goback()}>
							<img src={Previous} alt="" />
						</a>
					</div>
					<h3
						className="col-8 text-center"
						style={{ marginBottom: "20px", fontSize: "20px" }}
					>
						Payment Status
					</h3>
					<div className="wallet-btn col-2"></div>
				</div>

			</div>
			<div className="body mCustomScrollbar _mCS_1" style={{ height: "100%", backgroundColor: getPopuBackgroundColor() }}>


				<div className="current_balance_box shadow-secondary bg-secondary">
					<img src={Coin} alt="" />
					{coinStore?.item?.coins}
					<b className="ml-auto"> {coinStore?.currency?.symbol} {coinStore?.item?.amount}</b>
				</div>
				<div className="row  ml-0 mr-0">
					<div className="col-md-12">
						{(() => {
							switch (paymentStatus) {
								case "succeeded":
									return <PaymentStatusItemSuccess coinStore={coinStore} transaction={transaction} />;

								case "processing":
									return <PaymentStatusItemPending coinStore={coinStore} transaction={transaction} />;

								case "requires_payment_method":
									return <PaymentStatusItemFailed coinStore={coinStore} transaction={transaction} />;

								default:
									return <PaymentStatusItemFailed coinStore={coinStore} transaction={transaction} />;

							}
						})()}
					</div>


				</div>
				<div className="bottom_btn">
					{/* <button  onClick={() => onSubmitPayment()}   className="btn btn-dark main-btn">Save Card & Proceed<img src="images/right-arrow.svg" alt="" className="mCS_img_loaded"/></button> */}
				</div>





			</div>
		</Fragment>
	)
}

const PaymentStatusItemPending = (props) => {
	const goCoinStore = () => {
		window.location.href = "/coin-store";
	};
	var transaction = props.transaction;
	var coinStore = props.coinStore;
	return (<div style={{ backgroundColor: "#FF9544", textAlign: 'center', height: "100%" }} className="mt-0">




		{/* Status Icon */}
		<img style={{ marginTop: 42, height: 100, width: 100 }} src={Pending} />

		{/* Title */}
		<div style={{ marginTop: 34, color: colors.black, fontSize: 36 }} >Pending</div>

		{/* Amount */}
		{
			<div style={{ marginTop: 25, color: colors.black, fontSize: 30 }} >{coinStore?.currency?.symbol} {coinStore?.item?.amount}</div>
		}

		{/* Date Time */}
		<div style={{ marginTop: 8, color: colors.black, fontSize: 14 }} > <Moment format="DD/MM/YYYY">
			{transaction?.createdAt}
		</Moment>   |  <Moment format="hh:mm A">
				{transaction?.createdAt}
			</Moment></div>

		{/* Transaction ID */}
		{
			<div style={{ marginTop: 16, color: colors.black, fontSize: 14 }} >Transaction ID : #{transaction?.transactionUniqueID}</div>
		}
		{/* <Text style={{ marginTop: 16, color: colors.black, fontSize: 14 }} >Your coin balance will be credited soon! {paymentStatus}</Text> */}
		{/* Payment Status */}
		<div style={{ marginTop: 16, color: colors.black, fontSize: 14 }} >Your coin balance will be credited soon!</div>


		{/* Done */}
		<button className="btn btn-dark main-btn next mt-4 mb-4 ml-2" style={{ width: "96%" }} onClick={() => goCoinStore()}>DONE <img src="/static/media/right-arrow.eb845750.svg" alt="" className="mCS_img_loaded" /></button>


	</div>)
}
const PaymentStatusItemSuccess = (props) => {
	const goCoinStore = () => {
		window.location.href = "/coin-store";
	};
	var transaction = props.transaction;
	var coinStore = props.coinStore;
	return (<div style={{ backgroundColor: "#36DE4C", textAlign: 'center', height: "100%" }} className="mt-0">




		{/* Status Icon */}
		<img style={{ marginTop: 42, height: 100, width: 100 }} src={Success} />

		{/* Title */}
		<div style={{ marginTop: 34, color: colors.black, fontSize: 36 }} >Success!</div>

		{/* Amount */}
		{
			<div style={{ marginTop: 25, color: colors.black, fontSize: 30 }} >{coinStore?.currency?.symbol} {coinStore?.item?.amount}</div>
		}

		{/* Date Time */}
		<div style={{ marginTop: 8, color: colors.black, fontSize: 14 }} > <Moment format="DD/MM/YYYY">
			{transaction?.createdAt}
		</Moment>   |  <Moment format="hh:mm A">
				{transaction?.createdAt}
			</Moment></div>

		{/* Transaction ID */}
		{
			<div style={{ marginTop: 16, color: colors.black, fontSize: 14 }} >Transaction ID : #{transaction?.transactionUniqueID}</div>
		}
		{/* <Text style={{ marginTop: 16, color: colors.black, fontSize: 14 }} >Your coin balance will be credited soon! {paymentStatus}</Text> */}
		{/* Payment Status */}
		<div style={{ marginTop: 16, color: colors.black, fontSize: 14 }} >Your coin balance will be credited soon!</div>


		{/* Done */}
		<button className="btn btn-dark main-btn next mt-4 mb-4 ml-2" style={{ width: "96%" }} onClick={() => goCoinStore()}>DONE <img src="/static/media/right-arrow.eb845750.svg" alt="" className="mCS_img_loaded" /></button>


	</div>)
}

const PaymentStatusItemFailed = (props) => {
	const goCoinStore = () => {
		window.location.href = "/coin-store";
	};
	var transaction = props.transaction;
	var coinStore = props.coinStore;
	return (<div style={{ backgroundColor: "#FF4646", textAlign: 'center', height: "100%" }} className="mt-0">




		{/* Status Icon */}
		<img style={{ marginTop: 42, height: 100, width: 100 }} src={Fail} />

		{/* Title */}
		<div style={{ marginTop: 34, color: colors.black, fontSize: 36 }} >Failure!</div>

		{/* Amount */}
		{
			<div style={{ marginTop: 25, color: colors.black, fontSize: 30 }} >{coinStore?.currency?.symbol} {coinStore?.item?.amount}</div>
		}

		{/* Date Time */}
		<div style={{ marginTop: 8, color: colors.black, fontSize: 14 }} > <Moment format="DD/MM/YYYY">
			{transaction?.createdAt}
		</Moment>   |  <Moment format="hh:mm A">
				{transaction?.createdAt}
			</Moment></div>

		{/* Transaction ID */}
		{
			<div style={{ marginTop: 16, color: colors.black, fontSize: 14 }} >Transaction ID : #{transaction?.transactionUniqueID}</div>
		}
		{/* <Text style={{ marginTop: 16, color: colors.black, fontSize: 14 }} >Your coin balance will be credited soon! {paymentStatus}</Text> */}
		{/* Payment Status */}
		{/* <div style={{ marginTop: 16, color: colors.black, fontSize: 14 }} >Your coin balance will be credited soon!</div>
		 */}

		{/* Done */}
		<button className="btn btn-dark main-btn next mt-4 mb-4 ml-2" style={{ width: "96%" }} onClick={() => goCoinStore()}>DONE <img src="/static/media/right-arrow.eb845750.svg" alt="" className="mCS_img_loaded" /></button>


	</div>)
}

export default PaymentStatus;