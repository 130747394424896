import React, { Fragment, useEffect, useState } from "react";
import Logo from "../assets/images/logo.svg";
import OtpImg from "../assets/images/otp-image.svg";
import "react-intl-tel-input/dist/main.css";
import { Link } from "react-router-dom";
import {
  signIn,
  onSignUp,
  signInInternational,
  signUpInternational,
  emailOtp,
  signInEmail,
} from "../apis";
import SignUp from "./signup";
import rightArrow from "../assets/images/right-arrow.svg";
import Adjust from "@adjustcom/adjust-web-sdk";
import Loader from "../component/loader";

function OTPemail(props) {
  const [otpNo, setOtpNo] = useState([0, 0, 0, 0, 0, 0]);
  const [otpNumber, setOtpNumber] = useState("");
  const [timer, setTimer] = useState(30);
  const [errorMsg, setErrorMsg] = useState("");
  const [loader, setLoader] = useState(false);

  console.log("props", props);
  useEffect(() => {
    var count = timer > 0 && setInterval(() => setTimer(timer - 1), 1000);
    return () => clearInterval(count);
  }, [timer]);

  function getCodeBoxElement(index) {
    return document.getElementById("codeBox" + index);
  }
  
  useEffect(() => {
 
    if (props.email === undefined ) {
      window.location.href = "/signin-email";
    } 
  }, []);

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    props.onBack()
  }

  useEffect(() => {
    // window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);  
    };
  }, []);


  async function onSubmit() {
    console.log("jey")
    setErrorMsg("");
    // setLoader(true);

    let data = {
      type: "validateOTP",
      email: props.email,
      otp: otpNumber,
    };
    console.log(data);

    if (localStorage.getItem("currentOTPtype") === "login") {
      let response = await emailOtp(data);
      setLoader(false);
      if (response && response?.success == true) {
        localStorage.setItem("gamerjiToken", response.token);
        localStorage.setItem("countryId", JSON.stringify(response?.item?.address?.country));

        window.location.href = "/";
      } else {
        setErrorMsg(
          response?.data?.errors
            ? response?.data?.errors?.[0]?.msg
            : "Something went wrong!"
        );
      }
    } else {
      let response = await onSignUp(data);
      setLoader(false);
      if (response && response?.success == true) {
        localStorage.setItem("gamerjiToken", response.token);
        localStorage.setItem("gamerjiUserInfo", JSON.stringify(response));
        localStorage.setItem("countryId", JSON.stringify(response?.item?.address?.country));

        window.location.href = "/sign-up";
      } else {
        setErrorMsg(
          response?.data?.errors
            ? response?.data?.errors?.[0]?.msg
            : "Something went wrong!"
        );
      }
    }
    //  if(localStorage.getItem('currentOTPtype') === 'login') { //response.item.isDetailsFilled === true

    //       let response = await signInInternational(data);
    //       setLoader(false);
    //       if (response && response?.status ==true) {
    //         // Adjust.trackEvent({
    //         //   eventToken: 'nxuhll'
    //         // })
    //         localStorage.setItem('gamerjiToken', response.token);
    //         window.location.href = '/';
    //       } else {
    //         setErrorMsg(response?.data?.errors ? response?.data?.errors?.[0]?.msg : 'Something went wrong!');
    //       }

    //     } else {
    //       let response = await signUpInternational(data);
    //       setLoader(false);
    //       if (response && response?.status ==true) {
    //         // Adjust.trackEvent({
    //         //   eventToken: 'nxuhll'
    //         // })
    //         localStorage.setItem('gamerjiToken', response.token);
    //         localStorage.setItem('gamerjiUserInfo', JSON.stringify(response));
    //         window.location.href = '/sign-up';
    //       }
    //       } else {
    //         setErrorMsg(response?.data?.errors ? response?.data?.errors?.[0]?.msg : 'Something went wrong!');

    //     }

    //  var isOTPVerified = await props.confimOTP(otpNumber, data);
    //  if(isOTPVerified !==undefined){
    //   // setLoader(false);
    //  }

    // if (isOTPVerified != undefined) {
    //   if (isOTPVerified === true) {
    //     data.isNewUser = true;
    //     data.isVerified = true;

    //     if (localStorage.getItem('currentOTPtype') === 'login') {//response.item.isDetailsFilled === true

    //       let response = await signInInternational(data);
    //       setLoader(false);
    //       if (response && response?.status ==true) {
    //         // Adjust.trackEvent({
    //         //   eventToken: 'nxuhll'
    //         // })
    //         localStorage.setItem('gamerjiToken', response.token);
    //         window.location.href = '/';
    //       } else {
    //         setErrorMsg(response?.data?.errors ? response?.data?.errors?.[0]?.msg : 'Something went wrong!');
    //       }

    //     } else {
    //       let response = await signUpInternational(data);
    //       setLoader(false);
    //       if (response && response?.status ==true) {
    //         // Adjust.trackEvent({
    //         //   eventToken: 'nxuhll'
    //         // })
    //         localStorage.setItem('gamerjiToken', response.token);
    //         localStorage.setItem('gamerjiUserInfo', JSON.stringify(response));
    //         window.location.href = '/sign-up';
    //       }
    //       } else {
    //         setErrorMsg(response?.data?.errors ? response?.data?.errors?.[0]?.msg : 'Something went wrong!');

    //     }

    //   } else {
    //     setErrorMsg('OTP went wrong!');
    //   }

    // }
    // if (props.code == "+91" || props.code == "91") {
    //   // login - register
    //   var currentOTPtype = localStorage.getItem('currentOTPtype');

    //   let response = currentOTPtype === 'signup' ? await signUpInternational(data) : await signInInternational(data);

    //   console.log('res::', response);
    //   if (response && response?.status === undefined) {
    //     // Adjust.trackEvent({
    //     //   eventToken: 'nxuhll'
    //     // })
    //     localStorage.setItem('gamerjiToken', response.token);
    //     if (localStorage.getItem('currentOTPtype') === 'login') {//response.item.isDetailsFilled === true
    //       window.location.href = '/';
    //     } else {
    //       window.location.href = '/sign-up';
    //     }
    //   } else {
    //     setErrorMsg(response?.data?.errors ? response?.data?.errors?.[0]?.msg : 'Something went wrong!');
    //   }
    // } else {

    //   var currentOTPtype = localStorage.getItem('currentOTPtype');

    //   let response = currentOTPtype === 'signup' ? await signUpInternational(data) : await signInInternational(data);

    //   console.log('res::', response);
    //   if (response && response?.status === undefined) {
    //     // Adjust.trackEvent({
    //     //   eventToken: 'nxuhll'
    //     // })
    //     localStorage.setItem('gamerjiToken', response.token);
    //     if (localStorage.getItem('currentOTPtype') === 'login') {//response.item.isDetailsFilled === true
    //       window.location.href = '/';
    //     } else {
    //       window.location.href = '/sign-up';
    //     }
    //   } else {
    //     setErrorMsg(response?.data?.errors ? response?.data?.errors?.[0]?.msg : 'Something went wrong!');
    //   }

    //   var isOTPVerified = await props.confimOTP(otpNumber, data);

    //   if (isOTPVerified != undefined) {
    //     if (isOTPVerified === true) {
    //       data.isNewUser = true;
    //       data.isVerified = true;

    //       if (localStorage.getItem('currentOTPtype') === 'login') {//response.item.isDetailsFilled === true

    //         let response = await signInInternational(data);

    //         if (response && response?.status === undefined) {
    //           // Adjust.trackEvent({
    //           //   eventToken: 'nxuhll'
    //           // })
    //           localStorage.setItem('gamerjiToken', response.token);
    //           window.location.href = '/';
    //         } else {
    //           setErrorMsg(response?.data?.errors ? response?.data?.errors?.[0]?.msg : 'Something went wrong!');
    //         }

    //       } else {
    //         let response = await signUpInternational(data);

    //         if (response) {
    //           // Adjust.trackEvent({
    //           //   eventToken: 'nxuhll'
    //           // })
    //           localStorage.setItem('gamerjiToken', response.token);
    //           localStorage.setItem('gamerjiUserInfo', JSON.stringify(response));
    //           window.location.href = '/sign-up';
    //         } else {
    //           setErrorMsg(response?.data?.errors ? response?.data?.errors?.[0]?.msg : 'Something went wrong!');
    //         }

    //       }

    //     } else {
    //       setErrorMsg('OTP went wrong!');
    //     }

    //   }

    // }

    // if(localStorage.getItem('confirmationResult') !==undefined){
    //   var confirmationResult = localStorage.getItem('confirmationResult');
    //   confirmationResult.confirm(otpNo.join('')).then((result) => {
    //     // User signed in successfully.
    //     console.log("result==>",result);
    //     const user = result.user;
    //     // ...
    //   }).catch((error) => {
    //     // User couldn't sign in (bad verification code?)
    //     // ...
    //   });
    // }
  }

  async function onKeyUpEvent(index, event) {
    const eventCode = event.which || event.keyCode;
    let temp = [...otpNo];
    if (getCodeBoxElement(index).value.length === 1) {
      temp[index - 1] = event.target.value;
      setOtpNo(temp);
      if (index !== 6) {
        getCodeBoxElement(index + 1).focus();
      } else {
        getCodeBoxElement(index).blur();
        // let data = {
        //   "username": props.phonenumber,
        //   "otp": temp.join(''),
        //   "type": "validateOTP"
        // }
        // let response = await signIn(data);
        // if(response){
        //   localStorage.setItem('gamerjiToken', response.token);
        //   if(response.item.isDetailsFilled === true) {

        window.location.href = "/";
        //   } else {
        window.location.href = "/sign-up";
        //   }
        // }
        // setRedirect(true);
      }
    }
    if (eventCode === 8 && index !== 1) {
      getCodeBoxElement(index - 1).focus();
    }
  }

  async function resetOTP() {
    let data = {
      email: props.email,
      type: "otpRequest",
    };

    let response = await emailOtp(data);
    setTimer(30);
  }

  function onFocusEvent(index) {
    for (var item = 1; item < index; item++) {
      const currentElement = getCodeBoxElement(item);
      if (!currentElement.value) {
        currentElement.focus();
        break;
      }
    }
  }

  const onChangeOtpNumber = (val) => {
    val.length <= 6 && setOtpNumber(val);
  };

  return (
    <Fragment>
      <div className="header">
        <div className="logo">
          <Link to="">
            <img
              src={Logo}
              className="d-block mx-auto"
              alt=""
              style={{ width: "150px" }}
            />
          </Link>
        </div>
      </div>
      <div
        className="body mCustomScrollbar _mCS_1"
        style={{ paddingTop: "30px", height: `100%` }}
      >
        {/* <div className="login-image mx-auto mt-5 pb-4">
              <img src={OtpImg} className="d-block" alt='' />
            </div> */}
        <div className="otp-title">Enter Verification Code</div>
        <p className="text-center mb-1">
          We have sent a verification code on your email <br />
          <b>{props.email}</b>
        </p>
        <p className="text-center">Enter the verification code you received</p>

        <input
          className="otp_input_single_box text-dark"
          placeholder="Enter Verification Code"
          type="number"
          onKeyDown={(e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
          onChange={(e) => onChangeOtpNumber(e.target.value)}
          value={otpNumber}
        />

        <>
          {/* <div className="otp-field">
          <div className="field">
            <input
              id="codeBox1"
              type="number"
              maxlength="1"
              autoFocus={true}
              onKeyUp={(event) => onKeyUpEvent(1, event)}
              onFocus={() => onFocusEvent(1)}
            />
          </div>
          <div className="field">
            <input
              id="codeBox2"
              type="number"
              maxlength="1"
              onKeyUp={(event) => onKeyUpEvent(2, event)}
              onFocus={() => onFocusEvent(2)}
            />
          </div>
          <div className="field">
            <input
              id="codeBox3"
              type="number"
              maxlength="1"
              onKeyUp={(event) => onKeyUpEvent(3, event)}
              onFocus={() => onFocusEvent(3)}
            />
          </div>
          <div className="field">
            <input
              id="codeBox4"
              type="number"
              maxlength="1"
              onKeyUp={(event) => onKeyUpEvent(4, event)}
              onFocus={() => onFocusEvent(4)}
            />
          </div>
          <div className="field">
            <input
              id="codeBox5"
              type="number"
              maxlength="1"
              onKeyUp={(event) => onKeyUpEvent(5, event)}
              onFocus={() => onFocusEvent(5)}
            />
          </div>
          <div className="field">
            <input
              id="codeBox6"
              type="number"
              maxlength="1"
              onKeyUp={(event) => onKeyUpEvent(6, event)}
              onFocus={() => onFocusEvent(6)}
            />
          </div>
        </div> */}
        </>

        <div className="row">
          <button
            style={{ width: "90%", margin: "0px auto 20px auto" }}
            className="btn btn-dark main-btn"
            onClick={() => onSubmit()}
          >
            SUBMIT <img src={rightArrow} alt="" className="mCS_img_loaded" />
          </button>
        </div>
        {errorMsg !== "" && <p className="text-center error">{errorMsg}</p>}
        <p className="text-center" style={{ marginBottom: "0.5rem" }}>
          Didn't receive the Verification Code?
        </p>
        {timer === 0 ? (
          <p className="text-center">
            <a className="text-secondary pointer" onClick={resetOTP}>
              <b>
                <u>Resend Verification Code</u>
              </b>
            </a>
          </p>
        ) : (
          <p className="text-center">
            Request for a new one in {timer} Seconds
          </p>
        )}
      </div>
    </Fragment>
  );
}
export default OTPemail;
