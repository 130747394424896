import React from "react";
import ReactDOM from "react-dom";
import "react-intl-tel-input/dist/main.css";
import url from '../constants/url';

import Advertisement from "../component/advertisement";

import './world-of-esports.css';
import Previous from "../assets/images/previous.svg";


export default class WOEIndividualEsportsNews extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }

  componentDidMount() {

  }

  returnDate(date_str) {
    let year = date_str.split('-')[0];
    let month = date_str.split('-')[1];
    let date = date_str.split('-')[2];

    switch (month.toString()) {
      case '01': month = 'Jan'; break;
      case '02': month = 'Feb'; break;
      case '03': month = 'March'; break;
      case '04': month = 'April'; break;
      case '05': month = 'May'; break;
      case '06': month = 'June'; break;
      case '07': month = 'July'; break;
      case '08': month = 'Aug'; break;
      case '09': month = 'Sep'; break;
      case '10': month = 'Oct'; break;
      case '11': month = 'Nov'; break;
      case '12': month = 'Dec'; break;
    }

    return date.toString() + ' ' + month.toString() + ' ' + year.toString();
  }

  createWOEEsportsNews() {
    console.log(this.props.individual_esports_news);
    return (
      <div className="inner-wrap woe_main_div_screen_3">
        <div className="header">
          <div
            className="back-btn"
            // style={{ transform: 'rotate(180deg)', cursor: 'pointer' }}
            onClick={(e) => this.props.onChangeWOEActiveScreen(0)}
          >
            <img src={Previous} alt="" />

            {/* <svg xmlns="http://www.w3.org/2000/svg" width="21.334" height="18.014" viewBox="0 0 21.334 18.014">
              <g id="ico" transform="translate(21.334 18.013) rotate(-180)">
                <path id="union" d="M8.321,17.721.479,9.879A1,1,0,0,1,.125,8.541,1.005,1.005,0,0,1,.411,8.2L8.321.293A1,1,0,0,1,9.735,1.706L3.417,8.025H20.334a1,1,0,1,1,0,2H3.454l6.281,6.281a1,1,0,0,1-1.414,1.415Z" transform="translate(0 0)" fill="#fff" />
              </g>
            </svg> */}
          </div>
          <h2>ESPORTS NEWS</h2>
          <div className="wallet-btn">
            {/* <Link to="">
              <img src={Wallet} alt="" />
              Wallet
            </Link> */}
          </div>
        </div>

        <div className="body bottom-btn-body woe_main_div_screen_3_body d-flex justify-content-center" style={{ paddingTop: '0', }}>
          <div className="all_sub_screens_div_related_to_ads">
            <div className="woe_individual_esports_news">
              <img className="woe_individual_esports_news_image" src={`${url.imageUrl}${this.props.individual_esports_news.img.default}`} />

              <div className="woe_individual_esports_news_title">
                {this.props.individual_esports_news.title}
              </div>

              <div className="woe_individual_esports_news_published">
                Published: {this.returnDate(this.props.individual_esports_news.createdAt.split('T')[0])}
              </div>

              <div
                className="woe_individual_esports_news_description"
                dangerouslySetInnerHTML={{ __html: this.props.individual_esports_news.description }}
              ></div>
            </div>


           

          </div>
          {
             !JSON.parse(localStorage.getItem("premiumUser")) && 
          
          <Advertisement
            screen={
              localStorage.getItem('apk_screens_list') === null ?
                { code: 'no_results' }
                :
                JSON.parse(localStorage.getItem('apk_screens_list')).esportsNewsDetails
            }
            screen_div_class_name='.all_sub_screens_div_related_to_ads'
            screen_type='sub'
          />
  }
        </div>
      </div>
    )
  }

  render() {
    return (
      this.createWOEEsportsNews()
    );
  }
}
// export default WOEIndividualEsportsNews;
