import React from "react";
import "react-intl-tel-input/dist/main.css";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import roller from "../assets/images/rolling.gif";
import RightArrow from "../assets/images/right-arrow.svg";
import Error from "../component/error";
import { constants } from "../constants/constants";
import Coin from "../assets/images/coin.png";

import { getUserBasicsDetails, getUserProfile, getUserProfileLite, requestLinkBankAccount, requestLinkUpiAccount, requestVerifyVPA, requestWithdrawal } from "../apis";
import url from "../constants/url";

import Previous from "../assets/images/previous.svg";
import RightArrowLight from "../assets/images/right-arrow.svg";
import Cancel from "../assets/images/round_not_qualified.png";
import WinningTrophy from "../assets/images/ic_winning_trophy.png";
import BankAccount from "../assets/images/ic_bank_account.png";
import UPI from "../assets/images/ic_upi.png";
import "./Withdrawal.css";
import { useTranslation, Trans, withTranslation } from "react-i18next";

class WithdrawalWeb extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      amount: "",
      winningAmount: 0,
      withdrawalMinAmount: 0,
      withdrawalMaxAmount: 0,
      userProfileData: {},
      userBankData: {},
      userUpiData: {},
      loader: false,
      account_number: "",
      repeat_account_number: "",
      bank_name: "",
      ifsc_code: "",
      account_holder_name: "",
      withdrawal_mode: "",
      withdrawal_method_bank_active: false,
      withdrawal_method_upi_active: false,
      upi_id: "",
      repeat_upi_id: "",
      bank_name_upi: "",
      account_holders_name_upi: "",
      error: "",

      coinStore: {},
      verifyVpaData: {},
      verifyVpaDataShow: false,

      tag_bank_account_popup: false,
      tag_upi_popup: false,
      tag_upi_link_btn: false,
    };

    this.onClickWithdrawNow = this.onClickWithdrawNow.bind(this);
    this.onClickSubmitBankAccountData = this.onClickSubmitBankAccountData.bind(this);
    this.onClickSubmitUPIData = this.onClickSubmitUPIData.bind(this);
  }

  getWinningAmount = (wallet) => {
    let index = wallet.findIndex((obj) => obj.currencyData.code == "inr" || obj.currencyData.code == "INR");

    if (index > -1) {
      return wallet[index].winningAmount ? wallet[index].winningAmount : 0;
    }
    return 0;
  };

  fetchUserProfileData = async () => {
    let profileLiteResponse = await getUserProfileLite();
    
    if(profileLiteResponse){
      localStorage.setItem(constants.profileLite, JSON.stringify(profileLiteResponse));
      let winningAmount = this.getWinningAmount(profileLiteResponse?.item?.wallet);
      this.setState({ winningAmount: winningAmount });
      this.setState({ userProfileData: profileLiteResponse?.item });
      this.setState({ userBankData: profileLiteResponse?.item?.easebuzzBankInfo });
      this.setState({ userUpiData: profileLiteResponse?.item?.easebuzzUpiInfo });
      this.setState({
        withdrawalMinAmount: parseInt(profileLiteResponse?.WithdrawalMinAmount),
      });
      this.setState({
        withdrawalMaxAmount: parseInt(profileLiteResponse?.WithdrawalMaxAmount),
      });

    }
    let profileBasicResposne = await getUserBasicsDetails();
    if(profileBasicResposne){
      localStorage.setItem(constants.profileBasics, JSON.stringify(profileBasicResposne));
    }

    // let res = await getUserProfile();
    // if (res) {
    //   console.log("res?.item==>", res);

    //   let winningAmount = this.getWinningAmount(res?.item?.wallet);

    //   this.setState({ userProfileData: res?.item });
    //   this.setState({ userBankData: res?.item?.easebuzzBankInfo });
    //   this.setState({ userUpiData: res?.item?.easebuzzUpiInfo });
    //   this.setState({ winningAmount: winningAmount });
    //   this.setState({
    //     withdrawalMinAmount: parseInt(res?.WithdrawalMinAmount),
    //   });
    //   this.setState({
    //     withdrawalMaxAmount: parseInt(res?.WithdrawalMaxAmount),
    //   });
    // }
  };
  componentDidMount() {
    this.fetchUserProfileData();
    // this.cfBankListAPICall();
  }
  onClickWithdrawMethod(mode) {
    if (mode === "1") {
      this.setState({
        withdrawal_mode: "1",
        withdrawal_method_bank_active: true,
        withdrawal_method_upi_active: false,
      });
    }
    if (mode === "2") {
      this.setState({
        withdrawal_mode: "2",
        withdrawal_method_bank_active: false,
        withdrawal_method_upi_active: true,
      });
    }
  }
  async onClickWithdrawNow() {
    const { t } = this.props;

    if (this.state.amount === "") {
      toast.error(t("error_Please_enter_a_valid_withdrawal_amount"), {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false;
    }
    if (parseInt(this.state.amount) < this.state.withdrawalMinAmount) {
      toast.error(t("error_Minimum_withdrawal_limit") + this.state.withdrawalMinAmount + ".", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false;
    }
    if (parseInt(this.state.amount) > this.state.withdrawalMaxAmount) {
      toast.error(t("error_Please_Enter_Maximum_Amount") + this.state.withdrawalMaxAmount + ".", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false;
    }
    if (parseInt(this.state.amount) > this.state.winningAmount) {
      toast.error(t("error_Please_Enter_Minimum_Amount_then") + this.state.winningAmount + ".", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false;
    }
    if (this.state.withdrawal_mode !== "") {
      if (this.state.withdrawal_mode === "1") {
        if (this.state.userBankData && this.state.userBankData.status && this.state.userBankData.status === "validate") {
          let payload = {
            amount: parseInt(this.state.amount),
            mode: "1",
          };
          let res = await requestWithdrawal(payload);
          if (res) {
            console.log("res?.item==>", res);
            if (res?.success === true) {
              toast.success(t("success_Withdrawal_request_submitted_successfully"), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              this.fetchUserProfileData();
            } else {
              toast.error(t("error_Oops_something_went_wrong_please_try_again"), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              return false;
            }
          }
        } else {
          toast.error(t("error_Please_Link_your_Bank_Account"), {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return false;
        }
      }
      if (this.state.withdrawal_mode === "2") {
        if (this.state.userUpiData && this.state.userUpiData.status && this.state.userUpiData.status === "validate") {
          let payload = {
            amount: parseInt(this.state.amount),
            mode: "2",
          };
          let res = await requestWithdrawal(payload);
          if (res) {
            console.log("res?.item==>", res);
            if (res?.success === true) {
              toast.success(t("success_Withdrawal_request_submitted_successfully"), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              this.fetchUserProfileData();
            } else {
              toast.error(t("error_Oops_something_went_wrong_please_try_again"), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              return false;
            }
          }
        } else {
          toast.error(t("error_Please_Link_your_UPI_Account"), {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return false;
        }
      }
    } else {
      toast.error(t("error_Please_Select_withdrawal_method"), {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false;
    }
  }

  async onClickSubmitBankAccountData() {
    const { t } = this.props;

    if (this.state.account_number === "") {
      toast.error(t("error_Please_enter_your_account_number"), {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false;
    }
    if (this.state.repeat_account_number === "") {
      toast.error(t("error_Please_confirm_the_account_number"), {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false;
    }
    if (this.state.account_number !== this.state.repeat_account_number) {
      toast.error(t("error_Account_Number_is_Mismatched"), {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false;
    }
    if (this.state.bank_name === "") {
      toast.error(t("error_Enter_bank_name"), {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false;
    }
    if (this.state.ifsc_code === "") {
      toast.error(t("error_Enter_IFSC_code"), {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false;
    }
    if (this.state.account_holder_name === "") {
      toast.error(t("error_Enter_account_holders_name"), {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false;
    }
    var payload = {
      accountNumber: this.state.account_number,
      bankName: this.state.bank_name,
      ifsc: this.state.ifsc_code,
      accountName: this.state.account_holder_name,
    };

    let res = await requestLinkBankAccount(payload);
    if (res) {
      console.log("res?.item==>", res);
      if (res?.success === true) {
        toast.success(t("success_Bank_Account_Details_successfully_Linked"), {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        this.setState({ tag_bank_account_popup: false });
        this.fetchUserProfileData();
      } else {
        // res?.data?.errors[0].msg
        toast.error(res?.data?.errors[0].msg, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // toast.error('Please Enter Valid Bank Account Details.', {
        //   position: "top-center",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        // });
      }
    }
  }
  async onClickVerifyVPA() {
    const { t } = this.props;

    if (this.state.upi_id === "") {
      toast.error(t("error_Enter_UPI_ID"), {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false;
    } else {
      this.setState({ loader: true });
      console.log("gvwdjh");
      var payload = {
        vpa: this.state.upi_id,
      };

      let res = await requestVerifyVPA(payload);

      console.log("res==>", res);
      if (res && res?.success == true) {
        this.setState({ loader: false });
        if (res && res?.item?.data?.vpa_status === "valid") {
          this.setState({
            tag_upi_link_btn: true,
            verifyVpaData: res?.item?.data,
            verifyVpaDataShow: true,
          });
        } else {
          this.setState({
            tag_upi_link_btn: false,
            verifyVpaData: res?.item?.data,
            verifyVpaDataShow: true,
          });
        }
      } else {
        this.setState({ loader: false });
        this.setState({
          error: res?.data?.errors ? res?.data?.errors?.[0]?.msg : "Something Went Wrong! Please try again ...",
        });
      }
    }
  }
  async onClickSubmitUPIData() {
    const { t } = this.props;
    if (this.state.upi_id === "") {
      toast.error(t("error_Enter_UPI_ID"), {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false;
    }
    // if (this.state.repeat_upi_id === "") {
    //   toast.error('Please Enter Repete UPI ID.', {
    //     position: "top-center",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    //   return false;

    // }
    // if (this.state.upi_id !== this.state.repeat_upi_id) {
    //   toast.error('UPI ID is Mismatched.', {
    //     position: "top-center",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    //   return false;

    // }
    // if (this.state.bank_name_upi === "") {
    //   toast.error('Please Enter Bank Name.', {
    //     position: "top-center",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    //   return false;

    // }

    let resCheck = await requestVerifyVPA({ vpa: this.state.upi_id });
    console.log("resCheck==>", resCheck);
    if (resCheck && resCheck?.success == true) {
      if (resCheck && resCheck?.item?.data?.vpa_status === "valid") {
        var payload = {
          upiID: this.state.upi_id,
          // bankName:this.state.bank_name_upi,

          accountName: resCheck?.item?.data?.vpa_name,
        };

        let res = await requestLinkUpiAccount(payload);
        if (res) {
          console.log("res?.item==>", res);
          if (res?.success === true) {
            toast.success(t("success_UPI_Details_successfully_Linked"), {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            this.setState({ tag_upi_popup: false });
            this.fetchUserProfileData();
          } else {
            toast.error(res?.data?.errors[0].msg, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            // toast.error('Please Enter Valid UPI Details.', {
            //   position: "top-center",
            //   autoClose: 5000,
            //   hideProgressBar: false,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: true,
            //   progress: undefined,
            // });
          }
        }
        this.setState({
          tag_upi_link_btn: true,
          verifyVpaData: resCheck?.item?.data,
          verifyVpaDataShow: true,
        });
      } else {
        toast.error(t("error_Please_enter_a_valid_UPI_ID"), {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        this.setState({
          tag_upi_link_btn: false,
          verifyVpaData: resCheck?.item?.data,
          verifyVpaDataShow: true,
        });
        return false;
      }
    } else {
    }
  }

  createBankAccountPopup() {
    const { t } = this.props;

    return (
      <div className="wd_popup_main_div">
        <div className="wd_popup_popup">
          <div className="wd_popup_header">
            <div className=""></div>
            <div className="wd_popup_header_text">{t("withDrawakWeb.bank_transfer")}</div>
            <div className="wd_popup_cancel_icon">
              <img
                src={Cancel}
                onClick={() => {
                  this.setState({ tag_bank_account_popup: false });
                }}
              />
            </div>
          </div>

          <div className="wd_popup_body">
            <div className="all_sub_screens_div_related_to_ads">
              <div className="wd_popup_body_scrollable_component">
                <div className="wd_popup_title">{t("bank_details")}</div>

                {/* UPI ID */}
                <div className="withdrawal_input_component">
                  <label className="withdrawal_input_label">{t("withDrawakWeb.account_number")}</label>
                  <input
                    className="withdrawal_input"
                    placeholder={t("msg_enter_account_number")}
                    value={this.state.account_number}
                    onChange={(e) => {
                      this.setState({ account_number: e.target.value });
                    }}
                  />
                </div>

                {/* Repeat UPI ID */}
                <div className="withdrawal_input_component">
                  <label className="withdrawal_input_label">{t("withDrawakWeb.repeat_account_number")}</label>
                  <input
                    className="withdrawal_input"
                    placeholder={t("msg_enter_repeat_account_number")}
                    value={this.state.repeat_account_number}
                    onChange={(e) => {
                      this.setState({ repeat_account_number: e.target.value });
                    }}
                  />
                </div>

                {/* Bank Name */}
                <div className="withdrawal_input_component">
                  <label className="withdrawal_input_label">{t("withDrawakWeb.bank_name")}</label>
                  <input
                    className="withdrawal_input"
                    placeholder={t("error_Enter_bank_name")}
                    value={this.state.bank_name}
                    onChange={(e) => {
                      this.setState({ bank_name: e.target.value });
                    }}
                  />
                </div>

                {/* IFSC Code */}
                <div className="withdrawal_input_component">
                  <label className="withdrawal_input_label">{t("withDrawakWeb.ifsc_code")}</label>
                  <input
                    className="withdrawal_input"
                    placeholder={t("error_Enter_IFSC_code")}
                    value={this.state.ifsc_code}
                    onChange={(e) => {
                      this.setState({ ifsc_code: e.target.value });
                    }}
                  />
                </div>

                {/* Account Holder's Name */}
                <div className="withdrawal_input_component">
                  <label className="withdrawal_input_label">{t("withDrawakWeb.account_holder_name")}</label>
                  <input
                    className="withdrawal_input"
                    placeholder={t("msg_enter_account_holder_name")}
                    value={this.state.account_holder_name}
                    onChange={(e) => {
                      this.setState({ account_holder_name: e.target.value });
                    }}
                  />
                  <div className="withdrawal_input_note">
                    {t("withDrawakWeb.minimum_n_maximum_allow_per_day", {
                      MinAmount: this.state.withdrawalMinAmount,
                      MaxAmount: this.state.withdrawalMaxAmount,
                    })}
                    {/* Minimum ₹{this.state.withdrawalMinAmount} and Maximum ₹
                    {this.state.withdrawalMaxAmount} allowed per day. */}
                  </div>
                </div>

                <button className="withdrawal_submit_button" onClick={() => this.onClickSubmitBankAccountData()}>
                  {t("withDrawakWeb.link_bank_account")} <img src={RightArrowLight} alt="" className="mCS_img_loaded" />
                </button>
              </div>
            </div>
            {/* <Advertisement
              screen={
                localStorage.getItem('apk_screens_list') === null ?
                  { code: 'no_results' }
                  :
                  JSON.parse(localStorage.getItem('apk_screens_list')).dobAndStateValidation
              }
              screen_div_class_name='.all_sub_screens_div_related_to_ads'
              screen_type='sub'
            /> */}
          </div>
        </div>
      </div>
    );
  }

  createUPIPopup() {
    const { t } = this.props;

    return (
      <div className="wd_popup_main_div">
        <div className="wd_popup_popup">
          <div className="wd_popup_header">
            <div className=""></div>
            <div className="wd_popup_header_text">{t("withDrawakWeb.upi_transfer")}</div>
            <div className="wd_popup_cancel_icon">
              <img
                src={Cancel}
                onClick={() => {
                  this.setState({ tag_upi_popup: false });
                }}
              />
            </div>
          </div>

          <div className="wd_popup_body">
            <div className="all_sub_screens_div_related_to_ads">
              <div className="wd_popup_body_scrollable_component">
                <div className="wd_popup_title">{t("withDrawakWeb.upi_details")}</div>

                {/* UPI ID */}
                <div className="withdrawal_input_component mb-5">
                  <label className="withdrawal_input_label">{t("withDrawakWeb.upi_id")}</label>
                  <div class="code-field" style={{ height: "42px", width: "100%" }}>
                    <input
                      type="text"
                      className="withdrawal_input"
                      placeholder={t("error_Enter_UPI_ID")}
                      value={this.state.upi_id}
                      onChange={(e) => {
                        this.setState({ upi_id: e.target.value });
                      }}
                    />

                    <button
                      type="submit"
                      className="text-dark"
                      style={{
                        backgroundColor: "#FFC609",
                        height: "55px",
                        width: "140px",
                        fontSize: "18px",
                      }}
                      disabled={this.state.loader}
                      onClick={() => this.onClickVerifyVPA()}>
                      <b>{!this.state.loader && "Verify"}</b>
                      {this.state.loader && <img src={roller} alt="" style={{ maxWidth: 30 }} />}
                    </button>
                  </div>

                  {/* <input className="withdrawal_input"
                    placeholder="Enter UPI ID"
                    value={this.state.upi_id}
                    onChange={(e) => { this.setState({ upi_id: e.target.value, }) }}
                  />
                   <button
                          type="submit"
                          className="text-dark withdrawal_verify_button"
                          style={{ backgroundColor: '#FFC609' }}
                          
                          onClick={() => this.onClickVerifyVPA()}
                        >
                          <b>APPLY</b>
                        </button> */}
                </div>
                {this.state.verifyVpaDataShow === true ? (
                  <div className="withdrawal_input_component">
                    {this.state.verifyVpaData.vpa_status === "valid" ? (
                      <div className="withdrawal_input_note_green">{this.state.verifyVpaData.vpa_name}</div>
                    ) : (
                      <div className="withdrawal_input_note_red">{t("withDrawakWeb.invalid_upi_id")}</div>
                    )}

                    {/* <label className="withdrawal_input_label"><b>Status:</b> {this.state.verifyVpaData.vpa_status}</label>
                  <label className="withdrawal_input_label"><b>VPA Name:</b> {this.state.verifyVpaData.vpa_name}</label> */}
                  </div>
                ) : (
                  ""
                )}
                {/* Repeat UPI ID */}
                {/* <div className="withdrawal_input_component">
                  <label className="withdrawal_input_label">Repeat UPI ID</label>
                  <input className="withdrawal_input"
                    placeholder="Enter Repeat UPI ID"
                    value={this.state.repeat_upi_id}
                    onChange={(e) => { this.setState({ repeat_upi_id: e.target.value, }) }}
                  />
                </div> */}

                {/* Bank Name */}
                {/* <div className="withdrawal_input_component">
                  <label className="withdrawal_input_label">Bank Name</label>
                  <input className="withdrawal_input"
                    placeholder="Enter Bank Name"
                    value={this.state.bank_name_upi}
                    onChange={(e) => { this.setState({ bank_name_upi: e.target.value, }) }}
                  />
                </div> */}

                {/* Account Holder's Name */}

                {this.state.error !== "" && <Error error={this.state.error} />}

                <div className="withdrawal_input_component">
                  {/* <label className="withdrawal_input_label">Account Holder's Name</label>
                  <input className="withdrawal_input"
                    placeholder="Enter Account Holder's Name"
                    value={this.state.account_holders_name_upi}
                    onChange={(e) => { this.setState({ account_holders_name_upi: e.target.value, }) }}
                  /> */}
                  <div className="withdrawal_input_note">
                    {/* Minimum ₹{this.state.withdrawalMinAmount} and Maximum ₹
                    {this.state.withdrawalMaxAmount} allowed per day. */}
                    {t("withDrawakWeb.minimum_n_maximum_allow_per_day", {
                      MinAmount: this.state.withdrawalMinAmount,
                      MaxAmount: this.state.withdrawalMaxAmount,
                    })}
                  </div>
                </div>
                {this.state.tag_upi_link_btn === true ? (
                  <button className="withdrawal_submit_button" onClick={() => this.onClickSubmitUPIData()}>
                    {t("withDrawakWeb.link_up")} <img src={RightArrowLight} alt="" className="mCS_img_loaded" />
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
            {/* <Advertisement
              screen={
                localStorage.getItem('apk_screens_list') === null ?
                  { code: 'no_results' }
                  :
                  JSON.parse(localStorage.getItem('apk_screens_list')).dobAndStateValidation
              }
              screen_div_class_name='.all_sub_screens_div_related_to_ads'
              screen_type='sub'
            /> */}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { t } = this.props;
    return (
      <div className="withdrawal_main_div">
        <ToastContainer />
        {this.state.tag_bank_account_popup === true && this.createBankAccountPopup()}
        {this.state.tag_upi_popup === true && this.createUPIPopup()}
        <div className="withdrawal_header">
          <div className="withdrawal_back_arrow_component">
            <div className="back-btn">
              <Link to="/account">
                <img src={Previous} alt="" />
              </Link>
            </div>
          </div>
          <div className="withdrawal_header_text">{t("withDrawakWeb.Withdrawal")}</div>
          <div className=""></div>
        </div>

        <div className="withdrawal_body">
          <div className="all_main_screens_div_related_to_ads">
            <div className="withdrawal_body_scrollable_component">
              <div className="withdrawal_account_details">
                {/* <div className=""> */}
                <div>
                  <img src={WinningTrophy} alt="" className="coin_img_medium mr-2" />
                  {t("withDrawakWeb.your_winnings")}
                </div>
                <div> ₹{this.state.winningAmount}</div>
                {/* </div> */}
              </div>

              <div className="withdrawal_input_component">
                <label className="withdrawal_input_label">{t("withDrawakWeb.Amount")}</label>
                <input
                  type="number"
                  className="withdrawal_input"
                  placeholder={t("withDrawakWeb.enter_amount")}
                  value={this.state.amount}
                  onChange={(e) => {
                    this.setState({ amount: e.target.value });
                  }}
                />

                <div className="withdrawal_input_note">
                  {/* Minimum ₹{this.state.withdrawalMinAmount} and Maximum ₹
                  {this.state.withdrawalMaxAmount} allowed per day. */}
                  {t("withDrawakWeb.minimum_n_maximum_allow_per_day", {
                    MinAmount: this.state.withdrawalMinAmount,
                    MaxAmount: this.state.withdrawalMaxAmount,
                  })}
                </div>
              </div>

              <div className="withdrawal_method_component">
                <div className="withdrawal_method_title">{t("withDrawakWeb.select_withdrawal_method")}</div>
                {this.state.userBankData && this.state.userBankData.status && this.state.userBankData.status === "validate" ? (
                  <div
                    className={
                      this.state.withdrawal_method_bank_active === true
                        ? "withdrawal_methods_box withdrawal_methods_box_bg"
                        : "withdrawal_methods_box"
                    }
                    onClick={() => {
                      this.onClickWithdrawMethod("1");
                    }}>
                    <div className="withdrawal_method_name">
                      <img src={BankAccount} className="coin_img_medium mr-2" /> {t("withDrawakWeb.bank_account")}
                    </div>
                    <div className="withdrawal_method_status_row">
                      <b>{t("withDrawakWeb.Name")}</b> : {this.state.userBankData.accountName}
                    </div>
                    <div className="withdrawal_method_status_row">
                      <b>{t('withDrawakWeb.bank_name')}</b> : {this.state.userBankData.bankName}
                    </div>
                    <div className="withdrawal_method_status_row">
                      <b>{t('withDrawakWeb.acc')}</b> : {this.state.userBankData.accountNumber}
                    </div>
                    <div className="withdrawal_method_status_row">
                      <b>{t('withDrawakWeb.IFSC')}</b> : {this.state.userBankData.ifsc}
                    </div>
                  </div>
                ) : (
                  <div
                    className="withdrawal_methods"
                    onClick={() => {
                      this.setState({ tag_bank_account_popup: true });
                    }}>
                    <div className="withdrawal_method_name">
                      <img src={BankAccount} className="coin_img_medium mr-2" /> {t('withDrawakWeb.bank_account')}
                    </div>
                    <div className="withdrawal_method_status">{t('withDrawakWeb.link_account')}</div>
                  </div>
                )}

                {this.state.userUpiData && this.state.userUpiData.status && this.state.userUpiData.status === "validate" ? (
                  <div
                    className={
                      this.state.withdrawal_method_upi_active === true ? "withdrawal_methods_box withdrawal_methods_box_bg" : "withdrawal_methods_box"
                    }
                    onClick={() => {
                      this.onClickWithdrawMethod("2");
                    }}>
                    <div className="withdrawal_method_name">
                      <img src={BankAccount} className="coin_img_medium mr-2" /> {t('withDrawakWeb.upi_account')}
                    </div>
                    <div className="withdrawal_method_status_row">
                      <b>{t('withDrawakWeb.Name')}</b> : {this.state.userUpiData.accountName}
                    </div>
                    {/* <div className="withdrawal_method_status_row">
                  <b>Bank Name</b> : {this.state.userUpiData.bankName} 
                  </div> */}
                    <div className="withdrawal_method_status_row">
                      <b>{t('withDrawakWeb.upi_id')}</b> : {this.state.userUpiData.upiID}
                    </div>
                  </div>
                ) : (
                  <div
                    className="withdrawal_methods"
                    onClick={() => {
                      this.setState({ tag_upi_popup: true });
                    }}>
                    <div className="withdrawal_method_name">
                      <img src={UPI} className="coin_img_medium mr-2" /> {t('withDrawakWeb.upi_payment')}
                    </div>
                    <div className="withdrawal_method_status">{t('withDrawakWeb.link_account')}</div>
                  </div>
                )}
              </div>

              <button className="withdrawal_submit_button" onClick={() => this.onClickWithdrawNow()}>
                <div>{t("withDrawakWeb.withdraw_now")}</div>
                <img src={RightArrowLight} className="" />
              </button>
            </div>
          </div>
          {/* <Advertisement
            screen={
              localStorage.getItem('apk_screens_list') === null ?
                { code: 'no_results' }
                :
                JSON.parse(localStorage.getItem('apk_screens_list')).worldOfEsports
            }
            screen_div_class_name='.all_main_screens_div_related_to_ads'
            screen_type='main'
          /> */}
        </div>
      </div>
    );
  }
}

export default withTranslation()(WithdrawalWeb);
