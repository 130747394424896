import moment from "moment";
import CopyIcon from "../../assets/images/copy-icon.svg";
import { useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import { constants } from "../../constants/constants";
import { useTranslation } from "react-i18next";

export const ScrimSummaryCard = ({ data }) => {
  const title1 = data?.title1;
  const title2 = data?.title2;
  const { t } = useTranslation();

  // console.log(data.payload,dropdown)
  return (
    <div className="contest-list contest-tournament-list game_tounament ">
      <div className="box" style={{ cursor: "pointer", marginTop: "14px", marginBottom: "3px" }}>
        <div style={{ fontSize: "16px", fontWeight: "800", marginTop: "4px" }} className="scrim-summary-card">
          <h3>{data?.title?.length > 32 ? <marquee>{data.title}</marquee> : <>{data.title}</>}</h3>
          {/* <span className="pointer">ID: {contest?.shortCode}</span> */}
        </div>
        <div className="row tournament_contest_row" style={{ marginBottom: "-20px" }}>
          <div className="col-12">
            <div className="inner_row">
              <div className="inner_item">
                {t("contest.date")} <b>{data?.date}</b>
              </div>
              <div className="inner_item">
                {t("contest.time")} <b>{data?.time}</b>
              </div>

              <div className="inner_item">
                {title1?.name.toLowerCase() === "map" ? t("contest.map") : title1?.name} <b>{title1?.value}</b>
              </div>
              {title2 ? (
                <div className="inner_item">
                  {title2?.name.toLowerCase() === "type" ? t("contest.type") : title2?.name} <b>{title2?.value}</b>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="inner_row bg-dark br-20 scrim-summary-card">
              <div className="inner_item inner_item_medium">
                {t('contest_details.players')} <br /> <b>{data?.noOfPlayerCapacity}</b>
              </div>
              <div className="inner_item inner_item_medium">
                {t('contest.winners')} <br /> <b>{data?.noOfWinner}</b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export const ScrimlistSingleCard = ({ data }) => {
  const { t } = useTranslation();

  const copyableString = useRef();
  const showSuccess = (error) => {
    toast.success(error, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const copiedHandler = (e) => {
    navigator.clipboard.writeText(copyableString?.current?.value);
    showSuccess("Copied.....");
  };
  return (
    <>
      <ToastContainer />
      <div className="contest-list contest-tournament-list game_tounament " style={{ margin: "20px auto 0px" }}>
        <div className="box scrimDetail">
          <h3 style={{ fontSize: "16px", fontWeight: "800", marginTop: "4px" }}>
            {data?.title?.length > 32 ? <marquee> {data?.title}</marquee> : <>{data?.title}</>}
          </h3>

          <div className="row tournament_contest_row" style={{ marginBottom: "-20px" }}>
            <div className="col-12">
              <div className="inner_row">
                <div className="inner_item">
                  {t("contest_details.date")} <b>{moment(data?.date).format("DD/MM/YYYY")}</b>
                </div>
                <div className="inner_item">
                  {t("contest_details.time")} <b>{moment(data?.time).format("hh:mm a")}</b>
                </div>
                {data?.titles?.slice(0, 2).map((item) =>
                  item ? (
                    <div className="inner_item">
                      {item?.name.toLowerCase() === "map"
                        ? t("contest.map")
                        : item?.name.toLowerCase() === "perspective"
                        ? t("contest.perspective")
                        : item?.name}{" "}
                      <b>{item?.value}</b>
                    </div>
                  ) : (
                    ""
                  )
                )}
              </div>
              <div className="inner_row bg-dark">
                <div className="inner_item inner_item_medium">
                  {t("contest_details.players")} <br /> <b>{data?.noOfPlayerCapacity}</b>
                </div>
                <div className="inner_item inner_item_medium">
                  {t("contest.winners")} <br /> <b>{data?.noOfWinner}</b>
                </div>
                <div className="inner_item inner_item_medium">
                  {t("contest.id")} <br /> <b>{data?.shortCode}</b>
                </div>
              </div>

              <div className="avalible_wrap">
                <div className="inner">
                  <span
                    style={{
                      width: `${(100 * data?.noOfJoinedPlayer) / data?.noOfPlayerCapacity}%`,
                    }}></span>
                </div>
                <p className="d-flex justify-content-between text-light" style={{ paddingBottom: "1rem" }}>
                  <span className="">{t("contest_details.players_remaining", { players: data?.noOfPlayerCapacity - data?.noOfJoinedPlayer })}</span>
                  <span className="">{t("contest_details.players_joined", { players: data?.noOfJoinedPlayer })}</span>
                </p>
              </div>
              <div className="contest_foot contest_details_foot bg-secondary text-light  scrim-card-padding-top scrim-card-padding-bottom scrimcard">
                <div className="inner inner-text ">
                  <span style={{ textAlign: "center" }} className="scrim-card-padding-bottom">
                    {t("private_contest.hosted_by")} - {data.hostedByName}
                  </span>
                  <span
                    style={{
                      fontSize: "12px",
                      borderTop: "1px solid white",
                      textAlign: "center",
                      justifyContent: "center",
                    }}
                    className="scrim-card-padding-top flex">
                    {" "}
                    <span className="inner_item inner_item_medium joined-scrim-right-border">
                      {t("private_contest.room_id")} <br />{" "}
                      {data?.room?.id ? (
                        <b>
                          {data?.room?.id}{" "}
                          <img
                            src={CopyIcon}
                            alt=""
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              navigator.clipboard.writeText(data?.room?.id);
                              toast.info(t('info_Copied'), {
                                position: "top-center",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                              });
                            }}
                          />
                        </b>
                      ) : (
                        "-"
                      )}
                    </span>
                    <span className="inner_item inner_item_medium ">
                      {t("private_contest.password")} <br />{" "}
                      {data?.room?.password ? (
                        <b>
                          {data?.room?.password}{" "}
                          <img
                            src={CopyIcon}
                            alt=""
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              navigator.clipboard.writeText(data?.room?.password);
                              toast.info(t('info_Copied'), {
                                position: "top-center",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                              });
                            }}
                          />
                        </b>
                      ) : (
                        "-"
                      )}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
