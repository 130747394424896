import { goback } from "../../component/common";

import NoTournaments from "../../assets/images/Group_8752.png";
import NoScrim from "../../assets/images/Group_8748.png"
import { Link, useNavigate } from "react-router-dom";
import rightArrow from "../../assets/images/right-arrow.svg";
import { ScrimlistCard } from "../../component/ScrimComponent/ScrimlistCard";
import { Howtocreatescrimbs } from "./Howtocreatescrims";
import Previous from "../../assets/images/previous.svg"
import { scrimList } from "../../apis";
import { useEffect, useState } from "react";
import Loader from "../../component/loader";
import { useTranslation } from "react-i18next";
import { constants } from "../../constants/constants";

export const ScrimButton = () => {
  const {t} = useTranslation()
   localStorage.removeItem('payload')
  return (
    <div className="bottom_btn">
          <Link to="/create-scrim" className="btn btn-dark main-btn">
          {t('private_contest.create_a_contest')}<img src={rightArrow} alt="" className="mCS_img_loaded" />
          </Link>
        </div>
    // <div className="create-scrim-btn">
    //   <Link to="/create-scrim" className="btn btn-dark main-btn">
    //     Create A Scrim
    //     <img src={rightArrow} alt="" className="mCS_img_loaded" />
    //   </Link>
    // </div>
  );
};

export const PrivateScrims = () => {
  // console.log("called")
  const navigate = useNavigate();
  const [data, setData] = useState([])
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(10)
  const [loader, setLoader] = useState(false)
  const {t} = useTranslation()

  useEffect(()=>{
    fetchData(page)
  },[])
  
  let fetchData = async(page) =>{
    setLoader(true)
    let userName = JSON.parse(localStorage.getItem(constants.profileLite))?.item?.user_id
    console.log(localStorage.getItem(constants.profileLite))
    try{

    
    let res = await scrimList({
      "user":userName,
    "page":page,
    "limit":10,
    "sortBy":"createdAt",
    "sort":"desc",
    "game" : localStorage.getItem('activeGameId')
    // "fromDate":"2023-04-10T07:06:08.186Z",
    // "toDate": "2023-07-16T07:07:37.680Z"
    })
    if(res){
        setTotalPage(res?.totalPages)
        console.log("res?.data",res?.data)
        setData([...data, ...res?.data])
        setLoader(false)
    }
  }
  catch(error){
    setLoader(false)
    console.log(error)
  }
    
  
  }
  const loadHandler = ()=>{
    let p = page
      setPage(page+1)
     
      fetchData(p + 1)
    }
    // if(loader) return ( <>{console.log("loader",loader)} <Loader /></>)
  return (
   
    
    <div className="inner-wrap" id="my_contest_main_inner">
       {loader ? <Loader /> : 
    <>
   
      <div className="header">
        <div className="back-btn">
          <a onClick={() => navigate(-1)}>
            <img src={Previous} alt="" />
          </a>
        </div>
        
        <h2>{t('private_contest.private_contest')}</h2>
        <div className="wallet-btn">
          {/* <Link to="/">
            <img src={Wallet} alt="" />
            Wallet
          </Link> */}
        </div>
      </div>
      <div className="body mCustomScrollbar _mCS_1 bottom-btn-body mycontest_main pt-0">
        <div className="all_sub_screens_div_related_to_ads">
          <div className="tab-content h-100" id="nav-tabContent">
          {console.log(data.length)}
            {
              
              data.length == 0 ?
              (
                <div className="no_tournament">
              {/* <img src={NoTournaments} style={{ height: "50%" }} /> */}
              <img src={NoScrim} style={{ height: "50%" }} />
              <p
                className="text-center w-100"
                style={{ marginTop: "14px", fontSize: "19px" }}
              >{t('private_contest.it_seems_like_you_havent_created_any_contest_yet')}</p>
          
             

              <Link to="/how-to-create-scrims" className="scrim-link">
              <b className="scrim-link">{t('private_contest.how_to_create_a_contest')}</b>
              </Link>
             

              <ScrimButton />
            </div>
              )
              :
              <>
              <ScrimlistCard  data={data}/>
              {
                page < totalPage ? 
                <div className="padding-top2">
                <button  className="btn scrim-leaderboard-btn  " onClick={loadHandler}>
                  
          
                {t('contest.load_more')}
          
                </button>
                </div>
                : ""
              }
              </>
            }
            
            
            <ScrimButton/>
          </div>
        </div>
      </div>
      </>
}
    </div>
    
  );
};
