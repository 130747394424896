import React from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import "react-intl-tel-input/dist/main.css";
import {
  html5CategoriesList, 
  html5GamesList,
} from "../../apis";
import { kFormatter,  } from "../../component/common";
import url from '../../constants/url';
import Loader from "../../component/loader";

import Previous from "../../assets/images/previous.svg";
import GameIconSmall1 from "../../assets/images/game-icon-small1.svg";
import Cancel from "../../assets/images/round_not_qualified.png";
import RightArrow from "../../assets/images/right-arrow.svg";

import JoinFreeGameWalletPopup from "./JoinFreeGameWalletPopup";
import Advertisement from "../../component/advertisement";
import { handleTextAnimation } from "../../component/textAnimation";

import './FreeGames.css';

export default class FreeGameType extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      free_game_category: {}, 
      free_game_type_data: [], 

      selected_game_data: [], 

      tag_free_game_category_loader: true, 
      tag_free_game_type_loader: true, 
      tag_free_game_join_game_wallet_popup: false, 
    };
    
    this.onChangeJoinGameWalletPopup = this.onChangeJoinGameWalletPopup.bind(this);
    this.onClickAnyGame = this.onClickAnyGame.bind(this);
  }

  componentDidMount() {
    this.html5CategoriesListAPICall();
    this.html5GamesListAPICall();
  }

  componentDidUpdate() {
    // console.log('update')
    handleTextAnimation();
  }

  onChangeJoinGameWalletPopup(tag) {
    this.setState({ tag_free_game_join_game_wallet_popup: tag, })
  }

  onClickAnyGame(value) {
    this.setState({ selected_game_data: value, }, () => {
      this.onChangeJoinGameWalletPopup(true);
    });
  }

  async html5CategoriesListAPICall() {
    var free_game_type_id = window.location.href.split("/")[
      window.location.href.split("/").length - 1
    ];
    let data = {
      sortby: 'order', 
      sort: 'asc', 
    }
    var response = await html5CategoriesList(data);
    console.log('Free Games Categories: ', response);
    if(response) {
      var fg_category =  response?.list?.find((value) => value?._id === free_game_type_id);
      
      this.setState({ free_game_category: fg_category, });
    }

    this.setState({ tag_free_game_category_loader: false, });
  }

  async html5GamesListAPICall() {
    var free_game_type_id = window.location.href.split("/")[
      window.location.href.split("/").length - 1
    ];
    
    let data = {
      skip: 0,
      limit: 100,
      html5Category: free_game_type_id,
      sortby: 'order', 
      sort: 'asc', 
    }
    var response = await html5GamesList(data);
    console.log('Free Games Type: ', response);
    if(response) {
      this.setState({ free_game_type_data: response?.list, });
    }

    this.setState({ tag_free_game_type_loader: false, });
  }

  render() {
    return (
      <div className="fg_type_main_div">
        {
          (this.state.tag_free_game_category_loader === true ||
          this.state.tag_free_game_type_loader === true) && 
          <Loader />
        }
        {
          this.state.tag_free_game_join_game_wallet_popup === true && 
          <JoinFreeGameWalletPopup 
            free_game_category={this.state.free_game_category}
            selected_game_data={this.state.selected_game_data}
            onChangeJoinGameWalletPopup={this.onChangeJoinGameWalletPopup}
          />
        }

        <div className="fg_type_header">
          <div className="fg_type_back_arrow">
            <Link to='/games-categories/freeGame001'>
              <img src={Previous} alt="" />
					  </Link>
          </div>
          <div className="fg_type_header_text">{this.state.free_game_category?.name}</div>
          <div className=""></div>
        </div>

        <div className="fg_type_body">
          <div className="all_sub_screens_div_related_to_ads">
            <div className="fg_type_body_scrollable_component">
              <Row className="p-0 m-0" style={{width:'100%'}}>
                {
                  this.state.free_game_type_data.map((value, i) => {
                    return(
                      <>
                      <Col className="d-flex justify-content-center align-items-center p-2 m-0" 
                        key={i} xl={4} lg={4} md={4} sm={4} xs={4}
                      >
                        <div className="fg_type_box" onClick={() => this.onClickAnyGame(value)}>
                          <div className="fg_type_title text_animation_container">
                            {value?.name.length < 17 ? value?.name : <marquee>{value?.name}</marquee>}
                            {/* {value?.name} */}
                          </div>
                          <div className="fg_type_image_joined_players">
                            <img className="fg_type_image" src={`${url.imageUrl}${value?.thumbImage?.default}`} />
                            <div className="fg_type_joined_players text_animation_container">
                              <img className="fg_GameIconSmall1_image" src={GameIconSmall1} alt="" />
                              <div className="fg_type_joined_players_numbers">
                                {kFormatter(value?.totalJoinedPlayers)}
                              </div>
                              Played
                            </div>
                            <div className="fg_type_image_joined_players_gradient"></div>
                          </div>
                        </div>
                      </Col>
                     
                      </>
                    )
                  })
                }
              </Row>
            </div>
          </div>
          {
             !JSON.parse(localStorage.getItem("premiumUser")) && 
          
          <Advertisement
              screen={
                localStorage.getItem('apk_screens_list') === null ?
                  { code: 'no_results' }
                  :
                  JSON.parse(localStorage.getItem('apk_screens_list')).gameType
              }
              screen_div_class_name='.all_sub_screens_div_related_to_ads'
              screen_type='sub'
            />
  }
        </div>
        
      </div>
    );
  }
}

// export default FreeGameType;
