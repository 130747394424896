import React from "react";
import "react-intl-tel-input/dist/main.css";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';

import Coin from "../assets/images/coin.png";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import CheckoutForm from "../component/CheckoutForm";

import {
  paymentOptions,
  getClientSecret,
  cfBankList,
  getCoinStoreDetails,
  requestCreateCoinStoreTransactions,
  requestCreateCFCoinStoreTransactions,
  requestCreateCFCoinStoreTransactionWeb,
  requestCFLink,
  createPhonepeTransaction,
  getSubscription,
  createSubscriptionTransaction
} from "../apis";
import url from '../constants/url';
import { Spinner } from 'react-bootstrap';
import Previous from "../assets/images/previous.svg";
import RightArrowLight from "../assets/images/right-arrow.svg";
import Cancel from "../assets/images/round_not_qualified.png";
import './PaymentGateway.css';
import helperFunc from '../helperFuncs';
const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isSelected ? '#F3F6F9' : isFocused ? '#F3F6F9' : '#fff',
      color: '#000',
      // cursor: isDisabled ? 'not-allowed' : 'default',
    };
  },
};

var stripeAPIKey = helperFunc.getStripeAPIKey()

const stripePromise = loadStripe(stripeAPIKey);
const appearance = {
  theme: 'stripe',
  variables: {

    borderRadius: '50px',
    // See all possible variables below
  }
};
// const options = {
//   clientSecret,
//   appearance,
// }
export default class PaymentGatewayWeb extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      upi_data: [],
      wallet_data: [],
      card_data: [],
      net_banking_data: [],

      card_holder_name: '',
      card_number: '',
      expiry_date: '',
      cvv: '',
      platform: '',
      bank_list: [],
      bank_selected_data: null,

      coinStore: {},
      clientSecret: "",

      tag_show_upi_section: false,
      tag_show_wallet_section: false,
      tag_show_card_section: false,
      tag_show_nb_section: false,

      tag_upi_more_less: false,
      tag_wallet_more_less: false,
      tag_card_popup: false,
      tag_net_banking_popup: false,
      cfTransaction: {},
      coinStoreId: '',
      subscriptionData: {}
    }

    this.onClickSubmitCardData = this.onClickSubmitCardData.bind(this);
    this.onClickPaymentOption = this.onClickPaymentOption.bind(this);
    this.onChangeSearchBank = this.onChangeSearchBank.bind(this);
    this.getSubscriptionDetails = this.getSubscriptionDetails.bind(this);

  }

  componentDidMount() {
    var platformStr = localStorage.getItem('platform');
    this.setState({ platform: platformStr });
    this.paymentOptionsAPICall();
    this.cfBankListAPICall();
    this.getSubscriptionDetails()
  }

  onClickSubmitCardData() {

  }

  async getSubscriptionDetails() {
    const id = window.location.href.split("/")[
      window.location.href.split("/").length - 1]
    let res = await getSubscription(id)
    console.log(res)
    if (res?.data) {
      this.setState({ subscriptionData: res?.data[0]?.param })
    }
  }

  onClickBack() {
    // console.log(this.state.platform,this?.coinStore?.item)
    if (this.state.platform && this.state.platform === "WebApp") {
      if (this?.coinStore?.item)
        window.location.href = "/coin-store";
      else {

        window.location.href = "/premium-subscription";
      }
    }
    if (this.state.platform && this.state.platform === "MobileApp") {

    }
  }

  async fetchClientSecretData() {
    var coinStoreId =
      window.location.href.split("/")[
      window.location.href.split("/").length - 1
      ];
    this.setState({ coinStoreId: coinStoreId })
    let res = await getClientSecret({ coinStore: coinStoreId });
    if (res) {
      this.setState({ clientSecret: res?.item?.clientSecret, })


    }
  }

  onChangeSearchBank(e) {
    console.log(e);
    this.setState({ bank_selected_data: e });
  }

  async onClickPaymentOption(orderType, item) {
    console.log("card payment", this.state.platform)
    if (orderType === "card") {
      // // if (item.pg === 'stripe') {
      // // console.log("this.state.coinStore==>",this.state.coinStore)
      // // window.location.href = "/payment-options-web/" + this.state.coinStore?.item?._id;
      // this.fetchClientSecretData();
      // this.setState({ tag_card_popup: true, })

      // // } else {
      // //   this.fetchClientSecretData();
      // //   this.setState({ tag_card_popup: true, })
      // // }

      // **************phone transaction*******************************

      if (this.state.coinStore?.item) {
        var coinStoreId = this.state.coinStore?.item?._id
        var countryData = JSON.parse(localStorage.getItem('userCountryObj'));
        console.log(coinStoreId, countryData, window.location.href.split("/")[
          window.location.href.split("/").length - 1])
        if (countryData.code === 'IN') {
          let res1 = await requestCreateCFCoinStoreTransactionWeb({ coinStore: coinStoreId, platform: "WebApp" });
          console.log(res1, '-------------');
          if (res1) {

            // setData(res1)
            console.log("res1?.item==>", res1);


            let data = await createPhonepeTransaction({
              'coinStore': coinStoreId
            })
            console.log("data", data?.data?.instrumentResponse?.redirectInfo?.url)
            if (data) {
              var url = data?.data?.instrumentResponse?.redirectInfo?.url
              console.log(url)
              window.location.href = url
            }

            // window.location.href = 'https://mercury-uat.phonepe.com/transact/simulator?token=LFUYYjAuXO5bnVLvLM8sOHUKdg3NNy2sO48Y7dcQfku'
          }
          else {
            console.log("Error")
          }



        }
      }

      else {
        console.log("subscription")
        var subscriptionId = window.location.href.split("/")[
          window.location.href.split("/").length - 1];
        let res = await createSubscriptionTransaction({
          "subscriptionId": subscriptionId,
          "platform": "WebApp"
        })
        console.log(res)
        localStorage.setItem('subscriptionId', subscriptionId)
        if (res?.success) {
          var url = res?.data?.instrumentResponse?.redirectInfo?.url
          console.log(url)
          window.location.href = url
        }

        // window.location.href = 'https://mercury-uat.phonepe.com/transact/simulator?token=LFUYYjAuXO5bnVLvLM8sOHUKdg3NNy2sO48Y7dcQfku'

      }



    }
    else {


      let orderToken = this.state.cfTransaction.order_token;
      let res = await requestCFLink({ orderType: orderType, paymentOption: item, orderToken: orderToken });
      if (res) {
        console.log()
        if (orderType === 'upi') {
          if (this.state.platform && this.state.platform === "WebApp") {
            console.log("res?.item?.data?.payload?.web", res?.item?.data?.payload?.web);
            window.location.href = res?.item?.data?.payload?.web;

          }
          else if (this.state.platform && this.state.platform === "MobileApp") {
            switch (item?.name) {
              case "bhim":
                window.location.href = res?.item?.data?.payload?.bhim;
                break;
              case "gpay":
                window.location.href = res?.item?.data?.payload?.gpay;
                break;
              case "paytm":
                window.location.href = res?.item?.data?.payload?.paytm;
                break;
              case "phonepe":
                window.location.href = res?.item?.data?.payload?.phonepe;
                break;

              default:
                window.location.href = res?.item?.data?.payload?.default;
            }
          }
        } else {
          // console.log(res?.item?.data);
          window.location.href = res?.item?.data?.url;
        }

        // this.setState({ coinStore : res, });
        //	setCoinStore(res);
        console.log("res?.item==>", res);

      }
    }

    // console.log("item==>", item);
    //  alert("test==>", item.name);
  }

  async paymentOptionsAPICall() {

    var coinStoreId =
      window.location.href.split("/")[
      window.location.href.split("/").length - 1
      ];
    // var coinStoreId="61b342291b389a76c866f013";
    let res = await getCoinStoreDetails(coinStoreId);
    if (res?.item) {
      this.setState({ coinStore: res });
      //	setCoinStore(res);
      console.log("res?.item==>", res);

    }
    var countryData = JSON.parse(localStorage.getItem('userCountryObj'));
    if (countryData.code === 'IN') {
      let res1 = await requestCreateCFCoinStoreTransactionWeb({ coinStore: coinStoreId, platform: "WebApp" });
      console.log(res1, '-------------');
      if (res1) {
        this.setState({ cfTransaction: res1?.order });
        //	setCoinStore(res);
        console.log("res1?.item==>", res1);

      }
    }
    var response = await paymentOptions();
    // console.log('paymentOptions: ', response);

    if (response && response?.list) {
      response?.list.forEach(element => {
        switch (element.name) {
          case 'upi':
            this.setState({ upi_data: element, tag_show_upi_section: true, });
            break;

          case 'wallet':
            this.setState({ wallet_data: element, tag_show_wallet_section: true, });
            break;

          case 'card':
            this.setState({ card_data: element, tag_show_card_section: true, });
            break;

          case 'nb':
            this.setState({ net_banking_data: element, tag_show_nb_section: true, });
            break;
        }
      });
    }
  }

  async cfBankListAPICall() {
    var response = await cfBankList();
    // console.log('cfBankList: ', response);

    if (response && response?.list) {
      let temp = [];

      response?.list.forEach(element => {
        temp.push({
          value: element.id,
          code: element.id,
          label: element.name,
        })
      });

      this.setState({ bank_list: temp, });
    }
  }

  createCardPopup() {
    return (
      <div className="pg_card_popup_main_div">
        <div className="pg_card_popup_popup">
          <div className="pg_card_popup_header">
            <div className=""></div>
            <div className="pg_card_popup_header_text">Credit / Debit Card</div>
            <div className="pg_card_popup_cancel_icon">
              <img src={Cancel} onClick={() => { this.setState({ tag_card_popup: false, }) }} />
            </div>
          </div>

          <div className="pg_card_popup_body">
            <div className="all_sub_screens_div_related_to_ads">
              <div className="pg_card_popup_body_scrollable_component">
                <div className="col-md-12">
                  {/* {this.state.clientSecret ? (
                    <Elements options={{
                      clientSecret: this.state.clientSecret,
                      appearance: appearance,
                    }} stripe={stripePromise}>
                      <CheckoutForm coinStoreObjID={this.state.coinStoreId} props={this.state.platform} />
                    </Elements>
                  ) : <Spinner animation="border" style={{ width: '40px', height: '40px', marginLeft: '50%' }} />
                  } */}
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    )
  }

  createNetBankingPopup() {
    return (
      <div className="pg_nb_popup_main_div">
        <div className="pg_nb_popup_popup">
          <div className="pg_nb_popup_header">
            <div className=""></div>
            <div className="pg_nb_popup_header_text">Net Banking</div>
            <div className="pg_nb_popup_cancel_icon">
              <img src={Cancel} onClick={() => { this.setState({ tag_net_banking_popup: false, }) }} />
            </div>
          </div>

          <div className="pg_nb_popup_body">
            <div className="all_sub_screens_div_related_to_ads">
              <div className="pg_nb_popup_body_scrollable_component">
                <div className="pg_nb_popup_search_component">
                  <Select
                    className="basic-multi-select"
                    classNamePrefix="select"

                    placeholder={`Search Bank List...`}

                    isMulti={false}
                    isDisabled={false}
                    isClearable={true}
                    isSearchable={true}
                    isLoading={false}
                    isRtl={false}
                    closeMenuOnSelect={true}

                    defaultValue={this.state.bank_selected_data}
                    // value={selectedBankOption}
                    options={this.state.bank_list}

                    styles={colourStyles}

                    onChange={(e) => this.onChangeSearchBank(e)}
                  />
                </div>

                <div className="pg_nb_popup_bank_list_component">
                  {
                    this.state.bank_list.map((value, i) => {
                      // console.log(this.state.bank_selected_data);
                      if (this.state.bank_selected_data !== null && this.state.bank_selected_data.value === value.value) {
                        return (
                          <a className="pg_link" onClick={() => this.onClickPaymentOption("nb", value)}>
                            <div className="pg_nb_popup_bank_details" key={i}>
                              <div className="pg_nb_popup_bank_anme">{value.label}</div>
                              {this.RightArrowDark()}
                            </div>
                          </a>
                        )
                      } else if (this.state.bank_selected_data === null) {
                        return (
                          <a className="pg_link" onClick={() => this.onClickPaymentOption("nb", value)}>
                            <div className="pg_nb_popup_bank_details" key={i}>
                              <div className="pg_nb_popup_bank_anme">{value.label}</div>
                              {this.RightArrowDark()}
                            </div>
                          </a>
                        )
                      }
                    })
                  }
                </div>
              </div>
            </div>
            {/* <Advertisement
              screen={
                localStorage.getItem('apk_screens_list') === null ?
                  { code: 'no_results' }
                  :
                  JSON.parse(localStorage.getItem('apk_screens_list')).dobAndStateValidation
              }
              screen_div_class_name='.all_sub_screens_div_related_to_ads'
              screen_type='sub'
            /> */}
          </div>
        </div>
      </div>
    )
  }

  RightArrowDark() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="21.334" height="18.014" viewBox="0 0 21.334 18.014">
        <g id="ico" transform="translate(21.334 18.013) rotate(-180)">
          <path id="union" d="M8.321,17.721.479,9.879A1,1,0,0,1,.125,8.541,1.005,1.005,0,0,1,.411,8.2L8.321.293A1,1,0,0,1,9.735,1.706L3.417,8.025H20.334a1,1,0,1,1,0,2H3.454l6.281,6.281a1,1,0,0,1-1.414,1.415Z" transform="translate(0 0)" fill="#000" />
        </g>
      </svg>
    )
  };

  createRecommendedSection() {
    return (
      <div className="pg_section">
        <div className="pg_section_header">
          Recommended
        </div>

        <div className="pg_section_body">
          <div className="pg_section_details_row">
            <div>Paytm</div>
            <div>Pay</div>
          </div>
        </div>
      </div>
    )
  }

  createUPISection() {
    return (
      this.state.tag_show_upi_section &&
      <div className="pg_section">
        <div className="pg_section_header">
          UPI
        </div>

        <div className="pg_section_body">
          <div className="pg_section_details_row row m-0">
            {
              this.state.upi_data?.list && this.state.upi_data?.list.map((value, i) => {
                if (i < 3 || this.state.tag_upi_more_less) {
                  return (
                    <div className="pg_section_details_col col-4" key={i}>
                      <div className="pg_section_details_img">
                        <a className="pg_link" onClick={() => this.onClickPaymentOption("upi", value)}>
                          <img className="pg_section_details_img" src={`${url.imageUrl}${value?.img?.default}`} />
                        </a>
                      </div>
                      {/* <div className="text-center">
                        {value?.label}
                      </div> */}
                    </div>
                  )
                }
              })
            }
          </div>

          <div className="pg_section_horizontal_divider"></div>

          {/* <div className="pg_section_more_less_details_row"
            onClick={() => { this.setState({ tag_upi_more_less: !this.state.tag_upi_more_less, }) }}
          >
            {
              this.state.tag_upi_more_less === true ?
                <div>Less</div>
                :
                <div>More</div>
            }
            <div>
              {this.RightArrowDark()}
            </div>
          </div> */}
        </div>
      </div>
    )
  }

  createWalletSection() {
    return (
      this.state.tag_show_wallet_section &&
      <div className="pg_section">
        <div className="pg_section_header">
          Wallet
        </div>

        <div className="pg_section_body">
          <div className="pg_section_details_row row m-0">
            {
              this.state.wallet_data?.list && this.state.wallet_data?.list.map((value, i) => {
                if (i < 3 || this.state.tag_wallet_more_less) {
                  return (
                    <div className="pg_section_details_col col-4" key={i}>
                      <div className="pg_section_details_img my-2">
                        <a className="pg_link" onClick={() => this.onClickPaymentOption("wallet", value)}>
                          <img className="pg_section_details_img" src={`${url.imageUrl}${value?.img?.default}`} />
                        </a>
                      </div>
                      {/* <div className="text-center">
                        {value?.label}
                      </div> */}
                    </div>
                  )
                }
              })
            }
          </div>

          <div className="pg_section_horizontal_divider"></div>

          <div className="pg_section_more_less_details_row"
            onClick={() => { this.setState({ tag_wallet_more_less: !this.state.tag_wallet_more_less, }) }}
          >
            {
              this.state.tag_wallet_more_less === true ?
                <div>Less</div>
                :
                <div>More</div>
            }
            <div>
              {this.RightArrowDark()}
            </div>
          </div>
        </div>
      </div>
    )
  }

  createCardsSection() {

    return (
      this.state.tag_show_card_section &&
      <div className="pg_section">
        <div className="pg_section_header">
          Cards
        </div>

        <div className="pg_section_body">
          <div className="pg_section_details_row"
            style={{ cursor: 'pointer', }}
            onClick={() => this.onClickPaymentOption("card", this.state.card_data)}

          >
            <div className="pg_section_plus_icon_component">
              <i className="fa fa-plus pg_section_plus_icon" aria-hidden="true"></i>
            </div>
            <div className="pg_section_card_details">
              <div className=""
                style={{
                  color: 'var(--color_black)',
                  textTransform: 'uppercase',
                  fontWeight: '600',
                }}
              >
                Credit / Debit Card
              </div>
              {/* <div className="">Save and pay via cards</div>
              <div className="">
                <img src="https://www.com" alt="image" />
              </div> */}
            </div>
            <div className="pg_section_right_arrow_icon_component">
              {this.RightArrowDark()}
            </div>
          </div>
        </div>
      </div>
    )
  }

  createNetBankingSection() {
    return (
      this.state.tag_show_nb_section &&
      <div className="pg_section">
        <div className="pg_section_header">
          Net Banking
        </div>

        <div className="pg_section_body">
          <div className="pg_section_details_row">
            {
              this.state.net_banking_data?.list && this.state.net_banking_data?.list.map((value, i) => {
                if (i < 3 || this.state.tag_wallet_more_less) {
                  return (
                    <div className="pg_section_details_col col-4" key={i}>
                      <div className="pg_section_details_img">
                        <a className="pg_link" onClick={() => this.onClickPaymentOption("nb", value)}>
                          <img className="pg_section_details_img" src={`${url.imageUrl}${value?.img?.default}`} />
                        </a>
                      </div>
                      {/* <div className="text-center">
                        {value?.label}
                      </div> */}
                    </div>
                  )
                }
              })
            }
          </div>

          <div className="pg_section_horizontal_divider"></div>

          <div className="pg_section_more_less_details_row"
            onClick={() => { this.setState({ tag_net_banking_popup: true, }) }}
          >
            <div>More</div>
            <div>
              {this.RightArrowDark()}
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="pg_main_div">
        <ToastContainer />
        {/* {this.state.tag_card_popup === true && this.createCardPopup()} */}
        {this.state.tag_net_banking_popup === true && this.createNetBankingPopup()}
        <div className="pg_header">
          <div className="pg_back_arrow_component">
            <div className="back-btn">
              {/* <a className="pg_link" onClick={() => this.onClickBack()}>
                <img src={Previous} alt="" />
              </a> */}
              <Link className="pg_link" to={-1}>
                <img src={Previous} alt="" />
              </Link>
            </div>
          </div>
          <div className="pg_header_text">
            Payment Options
          </div>
          <div className=""></div>
        </div>

        <div className="pg_body">

          <div className="all_main_screens_div_related_to_ads">
            <div className="pg_body_scrollable_component">
              <div className="pg_account_details">
                {/* <div className=""> */}
                <div>
                  {this.state.coinStore?.item ? <img src={Coin} alt="" className="coin_img_medium" /> : ""}

                  {this.state.coinStore?.item ? this.state.coinStore?.item?.coins : this.state.subscriptionData?.packName + " pack"}
                </div>

                <div>{this.state.coinStore?.currency ? this.state.coinStore?.currency?.symbol : this.state.subscriptionData?.currencyID?.symbol
                } {this.state.coinStore?.item ? this.state.coinStore?.item?.amount : this.state.subscriptionData?.amount}</div>
                {/* </div> */}
              </div>

              {/* {this.createRecommendedSection()} */}

              {/* {this.state.platform==="WebApp"} */}
              {/* {this.state.platform && this.state.platform === "MobileApp" ? */}
              {this.createUPISection()}
              {/* } */}
              {this.state.platform && this.state.platform === "MobileApp" ?
                this.createWalletSection() : ''
              }
              {this.createCardsSection()}
              {this.createNetBankingSection()}

            </div>
          </div>
          {/* <Advertisement
            screen={
              localStorage.getItem('apk_screens_list') === null ?
                { code: 'no_results' }
                :
                JSON.parse(localStorage.getItem('apk_screens_list')).worldOfEsports
            }
            screen_div_class_name='.all_main_screens_div_related_to_ads'
            screen_type='main'
          /> */}
        </div>
      </div>
    )
  }
}