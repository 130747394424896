import { constants } from "../../constants/constants";
import url from "../../constants/url";
import { useTranslation } from "react-i18next";

export const Captioncard = (props) => {
  var user_data = JSON.parse(localStorage.getItem(constants.profileLite));
  const gameNames = JSON.parse(localStorage.getItem("userInGameNames"));
  const { data } = props;
  // console.log("data uniqueIgn", data);
  const { t } = useTranslation()

  const countryObj = JSON.parse(localStorage.getItem("userCountryObj"))
  return (
    <div className="row squad_registration_caption_player_row p-0">
      <div className="col-12 m-0 p-0 scrim-squad-registration">
        <div className="scrim-squad-registration-title flex  gap2 ">
          <div className="width2 scrim-squad-registration-Caption text-align">
            {t('team_register.captain')}
          </div>
          <div style={{ paddingTop: '6px', paddingLeft: '30px' }} > {user_data?.item?.gamerjiName}</div>
        </div>
        <div className="scrim-squad-registration-title flex flex-direction gap padding10">
          <div className="flex ">
            <div className="width3 align-self f14">{t('team_register.in_game_name')}:</div>
            <div className="width7">
              <input
                // placeholder="i am stuti "
                className="scrim-squad-registration-input width "
                value={data?.uniqueIgn || data?.inGameName}
                disabled
              />
            </div>
          </div>

          {user_data?.item?.email !== "" && user_data?.item?.phone === undefined ? (
            <div>
              <div className="flex  ">
                <div className="width3 flex input-container">

                  <input
                    className="scrim-squad-registration-input width input-value-scrims"
                    type="text"
                    disabled
                    value="Email"
                  />
                </div>
                <div className=" width7">
                  <input
                    // placeholder="82197644999 "
                    className="scrim-squad-registration-input width"
                    value={user_data?.item?.email}
                    disabled
                  />
                </div>
              </div>
            </div>
          ) : (

            <div>
              {/* {console.log(">>>>>>>>>>>>>",user_data)} */}
              <div className="flex  ">
                <div className="width3 flex input-container">
                  <span className="input-container-icon">
                    <img
                      className="flag-icon"
                      src={
                        url.imageUrl +
                        countryObj?.flag
                          ?.default
                      }
                    />
                  </span>

                  <input
                    className="scrim-squad-registration-input width input-value-scrims"
                    type="text"
                    disabled
                    value={countryObj?.dialingCode}
                  />
                </div>
                <div className=" width7">
                  <input
                    // placeholder="82197644999 "
                    className="scrim-squad-registration-input width"
                    value={user_data?.item?.phone}
                    disabled
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div >
  );
};
