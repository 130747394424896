import React, { useEffect } from 'react'
import { createPhonepeTransaction, getCoinStoreDetails, getValues, paymentOptions, requestCreateCFCoinStoreTransactionWeb } from '../apis'
import { useState } from 'react'


const PhonePeResponse = () => {
  const [data, setData] = useState()
  const [loader, setShowLoader] = useState('')
  useEffect(()=>{
      console.log("called")
      paymentOptionsAPICall()
  },[])
  const paymentOptionsAPICall = async () =>  {

    var coinStoreId =
      window.location.href.split("/")[
      window.location.href.split("/").length - 1
      ];
    // var coinStoreId="61b342291b389a76c866f013";
    let res = await getCoinStoreDetails(coinStoreId);
    if (res) {
      // this.setState({ coinStore: res });
      //	setCoinStore(res);
      console.log("res?.item==>", res);

    }
    var countryData = JSON.parse(localStorage.getItem('userCountryObj'));
    console.log(coinStoreId,countryData )
    if(countryData.code === 'IN') {
      let res1 = await requestCreateCFCoinStoreTransactionWeb({ coinStore: coinStoreId, platform: "WebApp" });
      console.log(res1, '-------------');
      if (res1) {
        // this.setState({ cfTransaction: res1?.order });
        //	setCoinStore(res);
        setData(res1)
        console.log("res1?.item==>", res1);
        
        // 
        // const merchantId = "MERCHANTUAT"
        // const key ="099eb0cd-02cf-4e2a-8aca-3e6c6aff0399" ;
        // const index = 1
        // const url = 'https://api-preprod.phonepe.com/apis/pg-sandbox'
        // const redirectUrl = "http://localhost:3001/response"
        // const payload = {
        //   merchantTransactionId: res1?.item?.transactionUniqueID,
        // merchantUserId: res1?.item?.user,
        // amount: parseInt(res1?.item?.amount) * 100 ,
        // mobileNumber: res1?.order?.customer_details?.customer_phone,
        // merchantId :merchantId,
        // paymentInstrument: {
        //   type: "PAY_PAGE"
        // },
        // redirectUrl: redirectUrl,
        // redirectMode: "POST",
        // callbackUrl: redirectUrl,
        // }
        // console.log("payload", payload)
        // const payload_base64 = Buffer.from(JSON.stringify(payload),'utf8').toString("base64")
        // const hash = sha256(payload_base64 + "/pg/v1/pay" + key)
        // console.log(hash.toString(), payload_base64)
           
        // // const res2 = await getValues(payload)
        // // console.log("result",res2?.url?.instrumentResponse?.redirectInfo.url

        // // )
        // const requestData = {
        //   request: payload_base64
        // }
        
        // try{
        //   const response = await axios.post(url + "/pg/v1/pay", requestData, {
        //         headers: {
        //           'Content-Type': 'application/json',
        //           'X-VERIFY': hash.toString() + '###'+ index,
        //           'accept': 'application/json',
                  
        //         },
        //       });
        //       console.log(res)
        //       console.log(response.data.data)
              
              
        //     } catch (error) {
        //       console.error('Failed to initiate payment:', error);
              
        //     }

        let data = await createPhonepeTransaction({
          'coinStore':coinStoreId
        })
        console.log("data", data?.data?.instrumentResponse?.redirectInfo?.url )
        if(data){
          var url = data?.data?.instrumentResponse?.redirectInfo?.url
          window.location.href = url
        }
        // window.location.href = 'https://mercury-uat.phonepe.com/transact/simulator?token=LFUYYjAuXO5bnVLvLM8sOHUKdg3NNy2sO48Y7dcQfku'
      }
      else{
        console.log("Error")
      }
    }
    
    
  }

  return (
    <h2>Getting response</h2>
  )
}

export default PhonePeResponse