import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import SignUpBg from "../assets/images/signup-bg.png";
import { epCreateUser, getAllFreeAvatar, getUpdateProfile, getUserByOpenId } from '../apis';
import { constants } from '../constants/constants';
import RightArrow from "../assets/images/right-arrow.svg";
import { url } from '../constants';
import roller from "../assets/images/rolling.gif";
import Loader from '../component/loader';
import { useSearchParams } from 'react-router-dom';

const LandingPage = () => {
  // console.log("**************landing page",)
  const [allData, setAllData] = useState(null);
  const [allAvatars, setAllAvatars] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);
  const [avatarId, setAvatarId] = useState("");
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [isbusy, setIsBusy] = useState(false);
  const [loader, setLoader] = useState(false)
  const token = localStorage.getItem('gamerjiToken')
  const [searchParams, setSearchParams] = useSearchParams()
  console.log(searchParams.get('emode'), searchParams.get('openId'))
  const [showPopUp, setShowPopUp] = useState(false)

  const fieldChange = (e, key) => {
    var value = e.target.value;
    let temp = { ...allData };
    temp[key] = value;
    setAllData(temp);
  };
  const onSignUp = async () => {
    setIsBusy(true);
    var check = true;
  

    if (
      allData?.gamerjiName === "" ||
      allData?.gamerjiName === undefined ||
      allData?.gamerjiName === null
    ) {
      check = false;
      setIsBusy(false);
      toast.error(constants.error_Add_your_Gamerji_name, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    if (avatarId === "") {
      check = false;
      setIsBusy(false);
      toast.error(constants.error_Select_your_Gamerji_Avatar, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    if (check) {
      if (disableSubmit === true) {
        setIsBusy(false);
        setErrorMessage(true);
      } else {
        setErrorMessage(false);
        // console.log("userLoginData==>", userLoginData);

        let data = {
          avatar: avatarId,
        
          gamerjiName: allData?.gamerjiName,
          isDetailsFilled: true,
        
        };
        
        console.log(data)
        let response = await getUpdateProfile(data);
        if (response?.success) {
          localStorage.setItem("userCountryObj", JSON.stringify(response.country));
          setIsBusy(false);
          window.location.href = "/";
        } else {
          setIsBusy(false);
          toast.error(response?.data?.errors?.[0]?.msg, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
    }
  };
  const fetchAvatar = async() =>{
    try{

    
    let avatars = await getAllFreeAvatar({
      skip: 0,
      limit: 10,
      allAvatar: false,
    });
    // console.log("avatars: ", avatars);
    if (avatars) {
      let temp = [];
      avatars?.list?.map((item, index) => {
        temp.push({
          logo: item?.img?.default,
          name: item?.name,
          id: item?._id,
        });
      });
      setAllAvatars(temp);
      setLoader(false)
    }
  }
  catch(error){
    toast.error("Something went wrong!!")
  }
  }
  const fetchToken = async(body) =>{
    try{
      const res = await epCreateUser(body)
      // console.log(res)
      if(res.success){
        if(res.code == 'validation.signup.user_exist'){
          if(searchParams.get('emode'))
            localStorage.setItem('emode', searchParams.get('emode'))
          localStorage.setItem('gamerjiToken',res.token)
          window.location.href = '/'
        }
        else{
          if(searchParams.get('emode'))
          localStorage.setItem('emode', searchParams.get('emode'))
          localStorage.setItem('gamerjiToken',res.token)
          window.location.href = '/sign-up'
        }
      }
     

    }
    catch(error){
     console.log(error)
    }
    finally{
      setLoader(false)
    }
  }
  const fetchOpenId = async ()=>{
    try{
      let body = {
        openId : searchParams.get('openId')
      }
      const res = await getUserByOpenId(body)
      // console.log(res)
      if(res?.code == "validation.signup.not_exist"){
        return true
      }
      else {
        return false
      }
    }
    catch(error){

    }
  }
  useEffect(async ()=>{
    setLoader(true)
   
    if (token)
   
    fetchAvatar()
  else{
try{


    // console.log(showPopUp)
    const ress = await fetchOpenId()
    console.log(ress)
    if(ress ){
   
    setShowPopUp(true)
     
    }
    else{
      let body = {
        authcode:localStorage.getItem('authCode')
      }
      fetchToken(body)
      setShowPopUp(false)
    // window.location.href = '/'
    }
    if(showPopUp){
      let body = {
        authcode:localStorage.getItem('authCode')
      }
      fetchToken(body)
      setShowPopUp(false)
    }
  }
  catch(error){
    console.log(error)
  }
  finally{
    setLoader(false)
  }
}
  },[])
  return (
    <div className="signup-main">
      <ToastContainer />
      {
        loader ? <Loader />
        :
       
      <></>
  
      // <div className="body bg-dark" style={{ paddingTop: "0" }}>
      //   {
      //      !showPopUp ? <>

      //      {/* <p className='text-white'>Please click here!!!!</p>
      //      <button>
      //      <div dangerouslySetInnerHTML={{__html:`<a  onLoad="mp.getUserAuthCode(['OPEN_ID','UNION_ID','USER_NAME','USER_BIRTHDAY','USER_MSISDN'])">Login</a>`}}/>
      //      </button>
      //      <button>
      //      <div dangerouslySetInnerHTML={{__html:`<a  onClick="mp.getUserAuthCode(['OPEN_ID','UNION_ID','USER_NAME','USER_BIRTHDAY','USER_MSISDN'])">Enter Here</a>`}}/>

      //      </button> */}
      //      <div></div>
      //      </>
      //      :
      //      {
           
      //      }
      //   }
        
      // </div>
}
    </div>
  )
}

export default LandingPage
