import { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { constants } from "../constants/constants";
import { checkUserDetails } from "../apis";
import url from "../constants/url";
import { useTranslation } from "react-i18next";

export const SquadPlayerCard = (props) => {


  const { t } = useTranslation()

  const {
    total_players,
    extra_players,
    playersData,
    onClickApply,
    onChangePhone,
    onClickRemove,
    onClickAddEmail,
    onClickRemoveEmail,
    disabledCards
  } = props;

  const [activeTabs, setActiveTabs] = useState({});

  const [phone, setPhone] = useState("");

  const [selectedContest, setSelectedContest] = useState("");
  const countryObj = JSON.parse(localStorage.getItem("userCountryObj"))
  // console.log(playersData);

  const findUserName = () => {
    let activeGameId = localStorage.getItem("activeGameId");
    // console.log("activeGameId", activeGameId);
    let gameNames = JSON.parse(localStorage.getItem("userInGameNames"));
    console.log("gameNames", gameNames);
    if (gameNames) {
      let index = gameNames.findIndex((obj) => obj.game === activeGameId);
      // console.log("index gamename", index);
      if (index >= 0) {
        // console.log(
        //   "index gameNames[index].userINGameName",
        //   gameNames[index].userINGameName
        // );
        return gameNames[index].userINGameName || "";
      } else {
        return "";
      }
    }
  };

  useEffect(() => {
    let allFeaturedData = JSON.parse(localStorage.getItem("joinTounament"));
    var uniqueI = "";

    var gameid =
      window.location.href.split("/")[
      window.location.href.split("/").length - 1
      ];

    var game_ID =
      gameid === "all" ? allFeaturedData?.gameId : allFeaturedData?.game?._id;

    // console.log("allFeaturedData: ", allFeaturedData);
    // console.log("allFeaturedData?.gameId: ", allFeaturedData?.gameId);
    // console.log("allFeaturedData?.game?._id: ", allFeaturedData?.game?._id);
    // let games = JSON.parse(
    //   localStorage.getItem(constants.profileLite)
    // )?.item?.gameNames.find((val) => val?.game === game_ID);
    // console.log("games", games);
    uniqueI = findUserName();

    for (let i = 0; i < total_players + extra_players; i++) {
      playersData.push({
        user_id: "",
        gamerjiName: "",
        levelImage: "",
        in_game_name: "",
        isClickOnApplyPlayer: false,
        phone: "",
      });
    }

    // console.log(playersData);

    const user_data = JSON.parse(localStorage.getItem(constants.profileLite));
    setPlayers_data(playersData);
    // console.log(
    //   "🚀 ~ file: squadPlayerCard.js:74 ~ useEffect ~ players_data:",
    //   playersData
    // );

    setSelectedContest(selectedContest);
  }, []);

  const handleTabClick = (event, cardId) => {
    console.log(event.target.value);
    setActiveTabs((prevTabs) => ({
      ...prevTabs,
      [cardId]: event.target.value,
    }));

  };

  //   console.log(players_data);

  //   //   var scrim_data = JSON.parse(localStorage.getItem("scrimData"));
  //   //   console.log(scrim_data);

  const AddButton = ({ index }) => {
    return (
      <a class="scrim-add-btn pointer" onClick={() => onClickApply(index)}>
        {t('team_register.add')}
      </a>
    );
  };

  const RemoveButton = ({ index }) => {
    return (
      <a class="scrim-remove-btn pointer" onClick={() => onClickRemove(index)}>
        {t('team_register.remove')}
      </a>
    );
  };

  const AddEmailButton = ({ index }) => {
    return (
      <a class="scrim-add-btn pointer" onClick={() => onClickAddEmail(index)}>
        {t('team_register.add')}
      </a>
    );
  };

  const RemoveEmailButton = ({ index }) => {
    return (
      <a
        class="scrim-remove-btn pointer"
        onClick={() => onClickRemoveEmail(index)}
      >
        {t('team_register.remove')}
      </a>
    );
  };

  const [players_data, setPlayers_data] = useState(() => {
    const initialData = [];
    console.log(total_players);
    for (let i = 0; i < total_players + extra_players; i++) {
      initialData.push({
        user_id: "",
        gamerjiName: "",
        levelImage: "",
        in_game_name: "",
        isClickOnApplyPlayer: false,
        phone: "",
      });
    }

    return initialData;
    // console.log(players_data);
  });

  // console.log(players_data);

  const onchangeEmail = (e, index) => {
    console.log(e.target.value);
    let phone = (playersData[index].email = e.target.value);
    setPhone(phone);
    console.log(playersData);
    setPlayers_data(playersData);
  };

  //   console.log(players_data);

  return (
    <div className="row squad_registration_caption_player_row p-0 gap">
      {playersData?.map((player, index) => {
        const cardId = `card-${index}`;
        const activeTab = activeTabs[cardId] || "Mobile";
        console.log(index, total_players)
        return (
          <div className="col-12 m-0 p-0 scrim-squad-registration" key={index}>
            <div className="scrim-squad-registration-title flex gap2 ">
              {index < total_players ? (
                <div className="width2 scrim-squad-registration-Caption text-align">
                  {t('team_register.player', { n: index + 2 })}
                </div>
              ) : (
                <div className="width2 scrim-squad-registration-Caption text-align">
                  {t('team_register.player', { n: index + 2 })}  (Optional)
                </div>
              )}

              {/* <div className="flex gap">
                <label>
                  <input
                    type="radio"
                    name={`option-${cardId}`}
                    value="Mobile"
                    onChange={(event) => handleTabClick(event, cardId)}
                    checked={activeTab === "Mobile"}
                    disabled={disabledCards.includes(index)}
                  />
                  <span>{t('team_register.via_mobile')}</span>
                </label>
                <label>
                  <input
                    type="radio"
                    name={`option-${cardId}`}
                    value="Email"
                    // onClick={() => handleTabClick("Email", cardId)}
                    onChange={(event) => handleTabClick(event, cardId)}
                    checked={activeTab === "Email"}
                    disabled={disabledCards.includes(index)}
                  />
                  <span>{t('team_register.via_email')}</span>
                </label>
              </div> */}
            </div>
            <div className="scrim-squad-registration-title flex flex-direction gap padding10">
              {activeTab === "Email" && (
                <div className="flex">
                  <div className="width3 flex input-container">
                    <input
                      className="scrim-squad-registration-input input-value-scrims"
                      type="text"
                      value="Email"
                      disabled
                    />
                  </div>
                  <div className="width7 relative">
                    <input
                      className="scrim-squad-registration-input width"
                      placeholder={t('team_register.enter_email')}
                      value={player?.email}
                      onChange={(e) => onchangeEmail(e, index)}
                      type="text"
                      disabled={player?.isClickOnApplyPlayer}
                    />
                    {player?.isClickOnApplyPlayer === true ? (
                      <RemoveEmailButton index={index} />
                    ) : (
                      <AddEmailButton index={index} />
                    )}
                  </div>
                </div>
              )}
              {activeTab === "Mobile" && (
                <div className="flex  ">
                  <div className="width3 flex input-container">
                    <span className="input-container-icon"><img
                      style={{
                        width: "16px",
                        height: "16px",
                        marginLeft: "12px",
                      }}
                      src={
                        url.imageUrl +
                        countryObj?.flag
                          ?.default
                      }
                    />
                    </span>
                    <input
                      className="scrim-squad-registration-input  input-value-scrims"
                      type="text"
                      disabled
                      value={countryObj?.dialingCode}
                    />
                  </div>
                  <div className=" width7 relative">
                    <input
                      placeholder={t('team_register.enter_mobile_number')}
                      className="scrim-squad-registration-input width"
                      value={player?.playerPhone}
                      onChange={(e) => onChangePhone(e, index)}
                      type="number"
                      onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                      maxLength={10}
                      disabled={player?.isClickOnApplyPlayer}
                    />
                    {player?.isClickOnApplyPlayer === true ? (
                      <RemoveButton index={index} />
                    ) : (
                      <AddButton index={index} />
                    )}
                  </div>
                </div>
              )}
              <div className="flex">
                <div className="width3 align-self f14">{t('team_register.in_game_name')}:</div>
                <div className="width7">
                  <input
                    className="scrim-squad-registration-input width"
                    value={
                      player?.inGameName === null ||
                        player?.inGameName === undefined
                        ? "-"
                        : player?.inGameName
                    }
                  />
                </div>
              </div>
              <div className="flex">
                <div className="width3 align-self f14">{t('team_register.gamerji_name')}:</div>
                <div className="width7">
                  <input
                    className="scrim-squad-registration-input width"
                    value={
                      player?.gamerjiName === null ||
                        player?.gamerjiName === undefined
                        ? "-"
                        : player?.gamerjiName
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
