import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { gameTypes, howToJoinGame, getGamesDetails, getUserInGameName, addUserInGameName } from "../apis";
import Previous from "../assets/images/previous.svg";
import url from "../constants/url";
import { dateFormat, goback } from "../component/common";
import allGames from "../assets/images/all-games.svg";
import rightArrow from "../assets/images/right-arrow.svg";
import Close from "../assets/images/close.svg";
import Loader from "../component/loader";
import { routehelp } from "../config/routehelp";
import ReactHtmlParser from "react-html-parser";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Advertisement from "../component/advertisement";
import { constants } from "../constants/constants";
import { useTranslation } from "react-i18next";
import { events } from "../utils/appEvents";
import { eventTracking } from "../firebaseAnalytics";
import { useProfile } from "../component/ProfileComponent/ProfileContext";

function SingleGame(props) {
  const [loader, setLoader] = useState(false);
  const [allData, setAllData] = useState([]);

  const [gameid, setGameid] = useState('all');
  const [gameData, setGameData] = useState('all');

  const [title, setTitle] = useState("");
  const [showContacts, setShowContacts] = useState(false);
  const [type, setType] = useState("");
  const [tounamentsImg, setTounamentsImg] = useState("");
  const [currentName, setcurrentName] = useState("");
  const [newName, setnewtName] = useState("");
  const [howtoJoinTitle, setHowtoJoinTitle] = useState("");
  const [howtoJoin, setHowtoJoin] = useState("");
  const [showHowtoJoin, setShowHowtoJoin] = useState(false);
  const [error, setError] = useState(false);
  const [gameNames, setGameNames] = useState([])
  const {t} = useTranslation()
  const {walletDetails} = useProfile()
  const location = useLocation()
  const state = location.state
  useEffect(() => {
    fetchData();
    setTitle(localStorage.getItem("activeGame")?.trim())
  }, []);

  let findUserName = async(gameid) => {
    // let gameNames = JSON.parse(localStorage.getItem("userInGameNames"))
    // console.log("gameid", gameid);
  
    // if (gameNames) {
    //   let index = gameNames?.findIndex(obj => obj?.game == gameid)
    //   if (index > -1) {
    //     setcurrentName(gameNames[index].userINGameName)
    //     return gameNames[index].userINGameName || ""
    //   } else {
    //     return ""
    //   }
    // }
    
    //removed localstorage names
    let payload = {
      "search": {
        "user": walletDetails?.user_id,
        "game": localStorage.getItem('activeGameId')
      }
    }
  
    let gameNamesData = await getUserInGameName(payload)
    // console.log(gameNamesData,"*****************",gameNamesData?.data?.[0]?.userINGameName)
    setcurrentName(gameNamesData?.data?.[0]?.userINGameName) ;
  }

  const 
  
  
  
  fetchData = async () => {
    setLoader(true);

    let allpage = [...JSON.parse(localStorage.getItem("currentPage"))];
    var count = allpage.findIndex((d) => d === window.location.pathname);
    count === -1 && allpage.push(window.location.pathname);
    localStorage.setItem("currentPage", JSON.stringify(allpage));

    var gameid =
      window.location.href.split("/")[
      window.location.href.split("/").length - 1
      ];
    setGameid(gameid);
    let allimgs = JSON.parse(localStorage.getItem("tournamentImgs"));
    let featured = allimgs.find((val) => val.id === gameid);
    setTounamentsImg(featured?.tournamentFeaturedImage);
    // localStorage.setItem("activeGameId", featured?.id);
    setType(
      window.location.href.split("/")[
      window.location.href.split("/").length - 2
      ]
    );

    let payload = {
      search: {
        gameId: gameid,
      },
      pagination: {
        sortby: 'order', sort: 'asc'
      }
    }
    let response = await gameTypes(payload);
    if (response) {
      // console.log("Single-Game: ", response)
      let temp = [];
      let gametype = [];
      response?.data?.length &&
        response?.data?.map(async (item, index) => {
          gametype.push({ id: item?._id, name: item?.name });
          temp.push({
            id: item._id,
            img: url.imageUrl + item?.displayImage?.default,
            name: item?.name,
            // createdAt: dateFormat(item?.createdAt),
            // updatedAt: dateFormat(item?.updatedAt),
          });
        });
        setAllData(temp);
      localStorage.setItem("activeGameType", JSON.stringify(gametype));
      if( state){
        localStorage.setItem("activeGame",state?.name);
        localStorage.setItem("activeGameId",state?._id);
        localStorage.setItem("activeTournamnetId",state?.tournamentGameID);
        localStorage.setItem("isTournament",state?.isTournament);
  
      }
      setTitle(state?.name || localStorage.getItem("activeGame"));
      
      
      setLoader(false)
    }
    else{
      setLoader(false)
    }

    // response = await getGamesDetails(gameid);
    // console.log('Game_Details: ', response);
    // JSON.parse(localStorage.setItem("Game_Details", response));


    // if (response) {

    //   setGameData(response?.data);
    //   //setTitle(response?.data?.name);
    //   // JSON.parse(localStorage.setItem("activeGameData", response?.data));
    //   //localStorage.setItem("activeGame", response?.data?.name);
    //  // localStorage.setItem("activeGameId", response?.data?._id);
    //  // localStorage.setItem("activeTournamnetId", response?.data?.tournamentGameID);
    //  // localStorage.setItem("Game_Details", JSON.stringify(response?.data));
 
    //   // payload = {
    //   //   "search": {
    //   //     "user": user_data?.item?.user_id,
    //   //   }
    //   // }

    //   // let userInGameNameResponse = await getUserInGameName(payload)
    //   // console.log("userInGameNameResponse", userInGameNameResponse);
    //   // setGameNames(userInGameNameResponse.data)
    //   // localStorage.setItem("userInGameNames", JSON.stringify(userInGameNameResponse.data));

     
    //   //setcurrentName(igname ? igname?.uniqueIGN : "");
    //   setLoader(false);
    // }
  };

  const fetchDataOpen = () => {
    // fetchData();
    eventTracking(events.change_username , {EP_ADDIGN : currentName,EP_GameName:localStorage.getItem("activeGameName"), EP_GameID:localStorage.getItem('activeGameId')});
    findUserName()
    setShowContacts(true);
  };

  const onSubmit = async () => {
    if (newName !== "") {
       //   ?.item?.gameNames;
      // // allgameNames?.filter(val => val?.game !== localStorage.getItem("activeGameId"));

      // const uniqueIds = allgameNames.filter(
      //   (ele, ind) =>
      //     ind !==
      //     allgameNames.findIndex(
      //       (elem) => elem.game === localStorage.getItem("activeGameId")
      //     )
      // );

      // uniqueIds.push({
      //   game: localStorage.getItem("activeGameId"),
      //   uniqueIGN: newName,
      // });

      // let data = {
      //   gameNames: uniqueIds,
      // };
      // var user_data =JSON.parse(localStorage.getItem(constants.profileLite));
      eventTracking(events.change_username_AddUpdate , {EP_CurrentIGN : currentName, EP_NewIGN :newName,EP_CurrentIGN : currentName,EP_GameName:localStorage.getItem("activeGameName"), EP_GameID:localStorage.getItem('activeGameId')});

      let payload = {
        // gameNames: gameNames
        user: walletDetails.user_id,
        game: localStorage.getItem("activeGameId"),
        userINGameName: newName
      }

      let res = await addUserInGameName(payload);
      if (res) {
         GetInGameNames()
        setcurrentName(newName)
        setShowContacts(false);
        toast.success(t('success_Game_Username_updated_successfully'), {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      setnewtName("");
    } else {
      //   setError(true);
      toast.error(t('success_Please_enter_Gamerji_username'), {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false;
    }
  };

  const GetInGameNames = async () => {
    var user_data = JSON.parse(localStorage.getItem(constants.profileLite));

    let payload = {
      "search": {
        "user": user_data?.item?.user_id,
      }
    }

    let userInGameNameResponse = await getUserInGameName(payload)
    console.log("userInGameNameResponse", userInGameNameResponse);
    if (userInGameNameResponse?.success)
      localStorage.setItem("userInGameNames", JSON.stringify(userInGameNameResponse.data));
  }

  const onClickJoin = async () => {
    eventTracking(events.how_to_join,{EP_GameName:localStorage.getItem("activeGameName"), EP_GameID:localStorage.getItem('activeGameId')})
    setShowHowtoJoin(true);
    var gameid =
      window.location.href.split("/")[
      window.location.href.split("/").length - 1
      ];
    // console.log("state",state)
    let res = await howToJoinGame({ game: state._id } );
    if (res) {
      setHowtoJoin(res?.item?.content);
      setHowtoJoinTitle(res?.item?.title);
    }
  };

  const toggleContact = () => {
    setShowContacts(false);
    setError(false);
  };
  const navigate = useNavigate()
  return (
    <>
      {showHowtoJoin === false ? (
        <div className="inner-wrap">
          <ToastContainer />
          <div className="header">
            <div className="back-btn">
              <a onClick={() => navigate('/')}>
                <img src={Previous} alt="" />
              </a>
            </div>
            <h2 style={{ textTransform: 'capitalize' }}>{localStorage.getItem('activeGameName')}</h2>
            <div className="wallet-btn"></div>
          </div>
          <div className="body mCustomScrollbar _mCS_1 bottom-btn-body"
            id={showContacts === true ? "popup_show" : ""} style={{ paddingTop: '20px' }}
          >
            {/* <div className="all_sub_screens_div_related_to_ads"> */}
            <h2
              className="title"
              style={{ cursor: "pointer" }}
              onClick={onClickJoin}
            >
              <span className="icon">
                <img src={allGames} alt="" />
              </span>
              { t('tournaments.how_to_join_a_contest') }
            </h2>
            {loader ? (
              <Loader />
            ) : (
              <div className="games_items games_big_items">
                {(state?.isTournament || JSON.parse(localStorage.getItem("isTournament")) === true) && (
                  <div className="item">
                    {/* // eslint-disable-next-line */}
                    <Link to={`/tournament/all`} className="d-block" onClick={()=>{
                      eventTracking(events.tournaments,{EP_GameName:localStorage.getItem("activeGameName"), EP_GameID:localStorage.getItem('activeGameId')});
                    }}>
                      <div className="box">
                        <h3>{ t('header.tournaments') }</h3>
                        <div className="img">
                          <img src={tounamentsImg} alt="" />
                        </div>
                      </div>
                    </Link>
                  </div>
                )}
                {/* {console.log()} */}
                {allData?.map((item, index) => {
                  return (
                    <div className="item" key={index}>
                      {/* // eslint-disable-next-line */}
                      <Link to={`/tournament/${item.id}`} className="d-block">
                        <div className="box">
                          <h3>{item.name}</h3>
                          <div className="img">
                            <img src={item.img} alt="" />
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                })}
                <p className="text-center w-100" style={{ marginTop: '14px', fontSize: '19px', }}>
                { t('tournaments.add_change_game',{game_name: `${title}`}) } <br />
                  <b onClick={() => {fetchDataOpen(); }} className="redClick">
                  { t('tournaments.click_here') }
                  </b>
                </p>
              </div>
            )}

            <div className="bottom_btn" style={{ marginBottom: '75px', }}>
              <Link to={routehelp.mycontest} className="btn btn-dark main-btn" onClick={()=>{
                eventTracking(events.my_contests,{EP_GameName:localStorage.getItem("activeGameName"), EP_GameID:localStorage.getItem('activeGameId')});
              }} >
              { t('tournaments.my_contest') } <img src={rightArrow} alt="" className="mCS_img_loaded" />
              </Link>
            </div>

            {/* Not included in the Project */}
            {/* {
              gameData.code?.toLowerCase() != 'cgl' ? 
              <div className="bottom_btn" style={{ marginBottom: '20px', }}>
              <Link to={routehelp.privateScrims} className="btn btn-dark main-btn">
              { t('tournaments.host_private_contest') } <img src={rightArrow} alt="" className="mCS_img_loaded" />
              </Link>
            </div> : ""
            }
             */}

            {showContacts === true && (
              <div className="custom-popup show" id="contact_popup">
                <div className="popup-head">
                  <h3>{ t('tournaments.add_change_game',{game_name: `${title}`}) }</h3>
                  <button className="close-popup" onClick={() => toggleContact()}>
                    <img src={Close} alt="" />
                  </button>
                </div>
                <div className="popup-body" style={{ marginBottom: '70px', }}>
                  <div className="all_sub_screens_div_related_to_ads">
                    {
                      currentName &&
                      <div className="form-group">
                        <label style={{ fontSize: '16px', }}>{ t('tournaments.current_active_name',{activeGame: `${title}`}) }</label>
                        <input
                          type="text"
                          value={currentName}
                          disabled={true}
                          className="form-control"
                          placeholder=""
                        />
                      </div>
                    }
                    <div className="form-group">
                      {/* {console.log(title)} */}
                      <label style={{ fontSize: '16px', }}>{ t('tournaments.new_username',{activeGame: `${title}`}) }</label>
                      <input
                        type="text"
                        className="form-control"
                        value={newName}
                        maxLength={32}
                        onChange={(e) => setnewtName(e.target.value)}
                        placeholder={ t('tournaments.enter_new_active_name',{activeGame: `${title}`}) }
                      />
                      {error === true && (
                        <p className="text-secondary">{ t('tournaments.enter_new_active_name',{activeGame: `${title}`}) }.</p>
                      )}
                    </div>
                    {/* <div className="form-group">
                        <label>{title} ID</label>
                        <input type="text" className="form-control" placeholder="" />
                      </div> */}
                    <div className="form-group">
                      <p className="text-secondary text-center form-note" style={{ fontSize: '14px', fontWeight: '600', }}>
                      {/* { t('contest.note_you_can_change_username_only_once') } */}
                      </p>
                    </div>
                    <button className="btn btn-dark main-btn" onClick={onSubmit}>
                    { t('contest.submit') } <img src={rightArrow} alt="" />
                    </button>
                  </div>
                  {
                     !JSON.parse(localStorage.getItem("premiumUser")) && 
                  
                  <Advertisement
                    screen={
                      localStorage.getItem('apk_screens_list') === null ?
                        { code: 'no_results' }
                        :
                        JSON.parse(localStorage.getItem('apk_screens_list')).addChangeUsernamePopup
                    }
                    screen_div_class_name='.all_sub_screens_div_related_to_ads'
                    screen_type='sub'
                  />
}
                </div>
              </div>
            )}
            {/* </div>
            <Advertisement
              screen={
                localStorage.getItem('apk_screens_list') === null ?
                  { code: 'no_results' }
                  :
                  JSON.parse(localStorage.getItem('apk_screens_list')).viewAllEsportsNews
              }
              screen_div_class_name='.all_sub_screens_div_related_to_ads'
              screen_type='sub'
            /> */}
          </div>
        </div>
      ) : (
        <div className="inner-wrap">
          <div className="header">
            <div className="back-btn">
              <a onClick={() => {setShowHowtoJoin(false);}}>
                <img src={Previous} alt="" />
              </a>
            </div>
            <h2>{ t('contest.how_to_join') }</h2>
            <div className="wallet-btn"></div>
          </div>
          <div className="body mCustomScrollbar _mCS_1 bottom-btn-body">
            <div className="all_sub_screens_div_related_to_ads">
              {howtoJoin?.length > 0 ? (
                howtoJoin?.map((item, index) => {
                  return (
                    <div className="mb-1" key={index}>
                      {item?.type === "text" ? (
                        <p>{ReactHtmlParser(item?.content)}</p>
                      ) : (
                        <img className="mb-3" src={item?.content} />
                      )}
                    </div>
                  );
                })
              ) : (
                <p className="text-center">NOT FOUND HOW TO JOIN ?</p>
              )}
            </div>
            {/* <Advertisement
              screen={
                localStorage.getItem('apk_screens_list') === null ?
                  { code: 'no_results' }
                  :
                  JSON.parse(localStorage.getItem('apk_screens_list')).viewAllEsportsNews
              }
              screen_div_class_name='.all_sub_screens_div_related_to_ads'
              screen_type='sub'
            /> */}
          </div>
        </div>
      )}
    </>
  );
}
export default SingleGame;
