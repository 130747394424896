import React, { Fragment, useEffect, useState } from "react";
import Logo from "../assets/images/EPLOGO.png";
import RightArrow from "../assets/images/right-arrow.svg";
import roller from "../assets/images/rolling.gif";
import "react-intl-tel-input/dist/main.css";
import { Link, useNavigate } from "react-router-dom";
import { Spinner } from 'react-bootstrap';
import Geocode from "react-geocode";
// import Geolocation from "react-geolocation";
import { ToastContainer, toast } from "react-toastify";
// import { GoogleLogin } from 'react-google-login';
import {
  countryList,
  checkAppUser,
  getsignUpCode,
  onSignUp,
  PrivacyData,
  signIn,
  TermsData,
  signInInternational,
  geolocation,
  requestGoogleLogin,
} from "../apis";
import OTP from "./otp";
import url from "../constants/url";
// import Select from "react-select";
import ReactHtmlParser from "react-html-parser";
import Previous from "../assets/images/previous.svg";
import Loader from "../component/loader";
import Error from "../component/error";
// import { getAuth, signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
// import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom';
import { useTranslation, Trans } from "react-i18next";


function SignIn(props) {


  const navigate = useNavigate();

  const [phonenumber, setPhonenumber] = useState("");
  const [dialCode, setDialCode] = useState("");
  const [countries, setCountries] = useState([]);

  const [isCountryAvailable, setIsCountryAvailable] = useState(false);
  const tagAutomaticCountrySelect = false;

  // const [loginData, setLoginData] = useState(
  //   localStorage.getItem('loginData')
  //     ? JSON.parse(localStorage.getItem('loginData'))
  //     : null
  // );
  // const [campaignId, setCampaignId] = useState(false);

  const [tag_countries_dialcode_popup, setTagCountriesDialcodePopup] = useState(false);
  const [countries_dialcode, setCountriesDialcode] = useState({ value: '', lable: '', name: '' });
  const { t } = useTranslation()

  const [allCountriesList, setAllCountriesList] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [errorCodeMessage, setErrorCodeMessage] = useState('');
  const [loader, setLoader] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [allDialCode, setAllDialCode] = useState({});
  const [confirmationResult, setConfirmationResult] = useState({});
  const [termsData, setTermsData] = useState(false);
  const [privacyData, setPrivacyData] = useState(false);
  const [isbusy, setIsBusy] = useState(false);
  const [error, setError] = useState("");
  const scopes = ["Jaimin"]
  useEffect(async () => {
    let data = {
      filter: {},
      sort: "asc",
      sortBy: "name",
      skip: 0,
      limit: 10,
      page: 1,
    };
    let response = await countryList(data);

    if (tagAutomaticCountrySelect) {
      // getGeolocation(response);

      let location = await geolocation();
      console.log('location: ', location);

      if (response && response?.list && response?.list?.length > 0 && location) {

        let check = true;
        setAllCountriesList(response?.list);
        // setCountries(options);

        response?.list?.forEach(element => {
          if (element?.code === location.country_code) {
            check = false;
            const imgUrl = url.imageUrl + element?.flag?.default;
            setDialCode(element?.dialingCode);
            setCountriesDialcode({
              value: element?.dialingCode,
              label: (
                <div>
                  <img src={imgUrl} style={{ width: "15px" }} /> {element?.dialingCode}
                </div>
              ),
              name: (
                <div>
                  <img src={imgUrl} style={{ width: "25px", marginRight: '10px' }} /> <b>{element?.name}</b> ({element?.dialingCode})
                </div>
              ),
            })
          }
        });

        if (check)
          setCountryData(response);
        else
          setIsCountryAvailable(true);
      } else {
        setCountryData(response);
      }


    } else {
      if (response) {
        console.log(response)
        const options = [];
        response?.list?.forEach((item, index) => {
          setAllCountriesList(response?.list);
          const imgUrl = url.imageUrl + item?.flag?.default;
          options.push({
            value: item?.dialingCode,
            label: (
              <div>
                <img src={imgUrl} style={{ width: "15px" }} /> {item?.dialingCode}
              </div>
            ),
            name: (
              <div>
                <img src={imgUrl} style={{ width: "25px", marginRight: '10px' }} /> <b>{item?.name}</b> ({item?.dialingCode})
              </div>
            ),
          });
        });
        let index = null
        options.filter((item, ind) => {
          if (item?.value == "+91") {
            index = ind
            return index
          }
        })
        setCountriesDialcode(options?.[index])
        // console.log(options?.[0])

        setAllDialCode(options?.[index]);
        setDialCode(options?.[index]?.value);
        setCountries(options);
      }
    }
  }, []);

  const getGeolocation = (response) => {
    navigator.geolocation.getCurrentPosition(
      async function (position) {
        // console.log(position);

        let city = null, state = null, country = null, countryCode = null;

        Geocode.setApiKey("AIzaSyBTTJ4vqHYAzqRfLUo-6rU6qhH4GNYPlO8");

        await Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
          (location) => {
            // console.log(location);
            const address = location.results[0].formatted_address;
            for (let i = 0; i < location.results[0].address_components.length; i++) {
              for (let j = 0; j < location.results[0].address_components[i].types.length; j++) {
                switch (location.results[0].address_components[i].types[j]) {
                  case "locality":
                    city = location.results[0].address_components[i].long_name;
                    break;
                  case "administrative_area_level_1":
                    state = location.results[0].address_components[i].long_name;
                    break;
                  case "country":
                    country = location.results[0].address_components[i].long_name;
                    countryCode = location.results[0].address_components[i].short_name;
                    break;
                }
              }
            }
            // console.log(city, state, country, countryCode);
            // console.log(address);
          },
          (error) => {
            console.error(error);
          }
        );

        // let location = await geolocation();
        // // console.log('location: ', location)

        if (response && response?.list && response?.list?.length > 0 && countryCode) {

          let check = true;
          setAllCountriesList(response?.list);
          // setCountries(options);

          response?.list?.forEach(element => {
            if (element?.code === countryCode) {
              check = false;
              const imgUrl = url.imageUrl + element?.flag?.default;
              setDialCode(element?.dialingCode);
              setCountriesDialcode({
                value: element?.dialingCode,
                label: (
                  <div>
                    <img src={imgUrl} style={{ width: "15px" }} /> {element?.dialingCode}
                  </div>
                ),
                name: (
                  <div>
                    <img src={imgUrl} style={{ width: "25px", marginRight: '10px' }} /> <b>{element?.name}</b> ({element?.dialingCode})
                  </div>
                ),
              })
            }
          });

          if (check)
            setCountryData(response);
          else
            setIsCountryAvailable(true);
        } else {
          setCountryData(response);
        }
      },
      function (error) {
        setCountryData(response);
        console.error("Error Code = " + error.code + " - " + error.message);
      }
    );
  }

  const setCountryData = (response) => {
    if (response) {
      console.log(response)
      const options = [];
      response?.list?.forEach((item, index) => {
        setAllCountriesList(response?.list);
        const imgUrl = url.imageUrl + item?.flag?.default;
        options.push({
          value: item?.dialingCode,
          label: (
            <div>
              <img src={imgUrl} style={{ width: "15px" }} /> {item?.dialingCode}
            </div>
          ),
          name: (
            <div>
              <img src={imgUrl} style={{ width: "25px", marginRight: '10px' }} /> <b>{item?.name}</b> ({item?.dialingCode})
            </div>
          ),
        });
      });
      setCountriesDialcode(options?.[0])
      // console.log(options?.[0])

      setAllDialCode(options?.[0]);
      setDialCode(options?.[0]?.value);
      setCountries(options);
    }
  };

  const showError = (error) => {
    toast.error(error, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  const confimOTP = async (otp, data) => {
    confirmationResult.confirm(otp).then(async (result) => {
      // User signed in successfully.
      // debugger
      data.isNewUser = true;
      data.isVerified = true;
      console.log("data=IN=>", data);

      console.log("result==>", result);
      const user = result.user;
      let response = await signInInternational(data);
      //  setLoader(false);
      if (response) {
        // Adjust.trackEvent({
        //   eventToken: 'nxuhll'
        // })
        if (response.token) {
          localStorage.setItem('gamerjiToken', response.token);
          localStorage.setItem("countryId", JSON.stringify(response?.item?.address?.country));

          window.location.href = '/';

        } else {
          return response?.data?.errors;
          //  setError(response?.data?.errors ? response?.data?.errors?.[0]?.msg : 'Something went wrong!');
        }

      } else {
        return response?.data?.errors;
        /// setError(response?.data?.errors ? response?.data?.errors?.[0]?.msg : 'Something went wrong!');
      }
      return true;
      // ...
    }).catch((error) => {
      // setLoader(false);
      return error;
      //  setError(error ? error : 'Something went wrong!');
      console.log("error==>", error);
      //return false;
      // User couldn't sign in (bad verification code?)
      // ...
    });
  };

  const onLogin = async () => {
    // window.AF('pba', 'event', { eventType: 'EVENT', eventName: 'test new', eventValue: { "key1": 123, "key2": "test name" } });

    let data = {
      username: phonenumber,
      type: "otpRequest",
      phoneCode: dialCode,
      // 'password':'aaa'
    };

    console.log("onLogin==>", data);

    // let check_dialCode = true, check_phonenumber = true;
    if (!dialCode) {
      setError(t('error_please_enter_valid_mobile_number'));
      // showError(t('error_country_not_selected'));
      // check_dialCode = false;
      return;

    }
    if (!phonenumber) {
      setError(t('error_please_mobile_number'));
      // showError(t('error_please_mobile_number'));
      // check_phonenumber = false;
      return;
    }

    // if (!check_dialCode || !check_phonenumber)
    //   return;

    if (dialCode == "+91" || dialCode == "91") {

      if (phonenumber === "") {
        setError(t('error_please_enter_valid_mobile_number'));
      } else if (phonenumber?.length < 10) {
        setError(t('error_please_enter_valid_mobile_number'));
      } else {
        setIsBusy(true)
        let response = await signIn(data);
        // setLoader(false);
        if (response && response?.success === true) {
          setRedirect(true);
          setIsBusy(false)
          localStorage.setItem('currentOTPtype', 'login');
          let countryObj = allCountriesList?.filter(
            (val) => val?.dialingCode === dialCode
          )?.[0];
          localStorage.setItem('userCountryObj', JSON.stringify(countryObj));
          let newUrlIS = window.location.origin + "/otp";
          window.history.pushState({}, null, newUrlIS);
        } else {
          setIsBusy(false)
          setError(response?.data?.errors ? response?.data?.errors?.[0]?.msg : t('error_Oops_something_went_wrong_please_try_again'));
        }
      }



    } else {

      if (phonenumber === "") {
        setError(t('error_please_enter_valid_mobile_number'));
      } else if (phonenumber?.length < 8) {
        setError(t('error_please_enter_valid_mobile_number'));
      } else {
        // setLoader(true);
        setIsBusy(true)
        let response = await signIn(data);
        // setLoader(false);
        if (response && response?.success === true) {
          setRedirect(true);
          setIsBusy(false)
          localStorage.setItem('currentOTPtype', 'login');
          let countryObj = allCountriesList?.filter(
            (val) => val?.dialingCode === dialCode
          )?.[0];
          localStorage.setItem('userCountryObj', JSON.stringify(countryObj));
          let newUrlIS = window.location.origin + "/otp";
          window.history.pushState({}, null, newUrlIS);
        } else {
          setIsBusy(false)
          setError(response?.data?.errors ? response?.data?.errors?.[0]?.msg : t('error_Oops_something_went_wrong_please_try_again'));
        }
      }

    }

  };

  // const handleChangeCountry = (selectedOption) => {
  //   setDialCode(selectedOption?.value);
  //   setAllDialCode(selectedOption);
  // };

  const onChangePhone = (val) => {
    setError("");
    val.length <= 10 && setPhonenumber(val);
  };

  const onClickPrivacy = async () => {
    setLoader(true);
    let res = await PrivacyData();
    if (res) {
      setPrivacyData(res?.item?.description);
    }
    setShowTerms(false);
    setShowPrivacy(true);
    setLoader(false);
  };

  const onClickTerms = async () => {
    setLoader(true);
    let res = await TermsData();
    if (res) {
      setTermsData(res?.item?.description);
    }
    setShowPrivacy(false);
    setShowTerms(true);
    setLoader(false);
  };

  const goback = () => {
    setShowPrivacy(false);
    setShowTerms(false);
  };

  const fieldChange = (e) => {
    setCouponCode(e.target.value);
  };

  const getSignUpode = async () => {
    setErrorCodeMessage('');
    if (couponCode !== "") {
      let response = await getsignUpCode({ name: couponCode, type: "signupCode" });
      if (response && response?.status === undefined) {
        setErrorCodeMessage('success');
      } else {
        setErrorCodeMessage('notcorrect');
      }
    } else {
      setErrorCodeMessage('error');
    }
  };

  const signUp = async () => {
    let country = allCountriesList?.filter(
      (val) => val?.dialingCode === dialCode
    )?.[0]?._id;
    let data = {
      type: "otpRequest",
      username: phonenumber,
      phoneCode: dialCode,
      country: country,
      code: couponCode,
    };
    if (dialCode == "+91" || dialCode == "91") {
      let response = await onSignUp(data);
      if (response && response?.status === undefined) {
        setRedirect(true);
        localStorage.setItem('currentOTPtype', 'signup');
        let newUrlIS = window.location.origin + "/otp";
        window.history.pushState({}, null, newUrlIS);
      } else {
        console.log('err::', response)
        setError(response?.statusText ? response?.statusText : t('error_Oops_something_went_wrong_please_try_again'));
      }
    } else {

    }

  };

  const goSignUP = () => {
    setShowRegister(!showRegister);
    setPhonenumber('');
    setError('');
    navigate("/register");

  };

  // const responseGoogleOnSuccess = async (googleData) => {
  //   console.log("googleData mobile in 123", googleData);
  //   setLoginData(googleData);
  //   localStorage.setItem('loginData', JSON.stringify(googleData));
  //   let payload = {
  //     "googleData": googleData.profileObj,
  //     // "campaignId": campaignId,
  //     "platform": "webapp",
  //   }
  //   let response = await requestGoogleLogin(payload);
  //   if (response) {
  //     if (response?.success === true) {
  //       localStorage.setItem('gamerjiToken', response.token);
  //       localStorage.setItem("countryId", JSON.stringify(response?.item?.address?.country));
  //       if (response.item.isSingup === true) {
  //         localStorage.setItem('gamerjiUserInfo', JSON.stringify(response));
  //         window.location.href = '/sign-up';
  //       } else {
  //         window.location.href = '/';
  //       }
  //     } else {
  //       setError(response?.data?.errors ? response?.data?.errors?.[0]?.msg : t('error_Oops_something_went_wrong_please_try_again'));
  //     }



  //     // console.log("responseGoogleOnSuccess====response==>", response);
  //     //  setLoginData(response);
  //     // localStorage.setItem('loginData', JSON.stringify(response));
  //   }
  //   // const res = await fetch('/api/google-login', {
  //   //   method: 'POST',
  //   //   body: JSON.stringify({
  //   //     token: googleData.tokenId,
  //   //   }),
  //   //   headers: {
  //   //     'Content-Type': 'application/json',
  //   //   },
  //   // });

  //   // const data = await res.json();


  //   console.log("responseGoogleOnSuccess==>", googleData);
  // };

  // const responseGoogleOnFailure = (response) => {
  //   console.log("responseGoogleOnFailure==>", response);
  //   setError(response?.data?.errors ? response?.data?.errors?.[0]?.msg : t('error_Oops_something_went_wrong_please_try_again'));
  // };

  // const handleLogout = () => {
  //   localStorage.removeItem('loginData');
  //   setLoginData(null);
  // };

  // const goSignUPPhone = () => {
  //   setShowRegister(!showRegister);
  //   setPhonenumber('');
  //   //  setDialCode("+91");
  //   setError('');
  //   // let newUrlIS = window.location.origin + "/";
  //   // window.history.pushState({}, null, newUrlIS);
  //   window.location.href = "/signin-email";

  // };

  const createCountryListPopup = (countries) => {
    // console.log(countries_dialcode)
    return (
      <div className="country_list_popup_component">
        <div className="country_list_popup">
          <div className="country_list_popup_header">
            <div></div>
            <div className="country_list_popup_header_text">Select Country</div>
            <div>
              <svg
                onClick={(e) => setTagCountriesDialcodePopup(false)}
                style={{ cursor: 'pointer', }}
                id="" data-name="cancel (1)" xmlns="http://www.w3.org/2000/svg" width="30.001" height="30" viewBox="0 0 34.001 34">
                <path id="Path_2963" data-name="Path 2963" d="M34,17A17,17,0,1,0,17,34,17,17,0,0,0,34,17Z" transform="translate(0 -0.003)" fill="#ef1f36" />
                <path id="Path_2964" data-name="Path 2964" d="M150.574,135.871l4.97-5.77a1.5,1.5,0,0,0-2.279-1.963l-4.676,5.428-4.676-5.428a1.5,1.5,0,1,0-2.279,1.963l4.97,5.77-4.97,5.77a1.5,1.5,0,0,0,2.279,1.963l4.676-5.428,4.676,5.428a1.5,1.5,0,0,0,2.279-1.963Z" transform="translate(-131.589 -118.87)" fill="#fff" />
              </svg>
            </div>
          </div>

          <div className="country_list_popup_body">
            {
              countries.map((value, i) => {
                return (
                  <div
                    className="country_list_popup_body_text" key={i}
                    onClick={(e) => {
                      setCountriesDialcode(value);
                      setDialCode(value.value);
                      setTagCountriesDialcodePopup(false);
                    }}
                  >
                    {value.name}
                    {/* Hello */}
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    )
  };

  return (
    <div className="inner-wrap register-padding">
      <ToastContainer />

      {!isCountryAvailable && tag_countries_dialcode_popup ? createCountryListPopup(countries) : ''}
      {redirect ? (
        <OTP code={dialCode} confimOTP={confimOTP} phonenumber={phonenumber} onBack={() => { setRedirect(false); setPhonenumber('') }} />
      ) : (
        <Fragment>
          {showTerms === true ? (
            loader === true ? (
              <Loader />
            ) : (
              <Fragment>
                <div className="header">
                  <div className="back-btn">
                    <a onClick={() => goback()}>
                      <img src={Previous} alt="" />
                    </a>
                  </div>
                  <h2>Terms & Conditions</h2>
                  <div className="wallet-btn"></div>
                </div>
                <div className="body mCustomScrollbar _mCS_1 bottom-btn-body">
                  {ReactHtmlParser(termsData)}
                </div>
              </Fragment>
            )
          ) : showPrivacy === true ? (
            loader === true ? (
              <Loader />
            ) : (
              <Fragment>
                <div className="header">
                  <div className="back-btn">
                    <a onClick={() => goback()}>
                      <img src={Previous} alt="" />
                    </a>
                  </div>
                  <h2>Privacy Policy</h2>
                  <div className="wallet-btn"></div>
                </div>
                <div className="body mCustomScrollbar _mCS_1 bottom-btn-body">
                  {ReactHtmlParser(privacyData)}
                </div>
              </Fragment>
            )
          ) : (
            <Fragment>
              {loader === true && <Loader />}
              <div className="header">
                <div className="logo">
                  <Link to="/">
                    <img src={Logo} className="d-block mx-auto" alt="" style={{ width: '150px', marginTop: "20px" }} />
                  </Link>
                </div>
              </div>
              <div className="body mCustomScrollbar _mCS_1 country_drop">
                <div className="otp-title" style={{ paddingTop: '30px' }}>Login</div>
                <div className="row mt-4">
                  <div className="col-4 col-md-4 pr-0">
                    <div className="form-group">
                      <label style={{ fontWeight: '400', color: 'var(--dark)' }}>Country Code</label>
                      {
                        tagAutomaticCountrySelect ?
                          isCountryAvailable ?
                            <div className="sign_in_country_code_popup_button text-center">
                              {countries_dialcode.label}
                            </div>
                            :
                            countries_dialcode.label ?
                              <button className="sign_in_country_code_popup_button"
                                onClick={(e) => { setTagCountriesDialcodePopup(true) }}
                              >
                                {countries_dialcode.label}
                                <svg className="" width="25" height="25" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path id="" d="M34 18.34a2 2 0 0 0-2.82 0L24 25.42l-7.08-7.08a2 2 0 1 0-2.82 2.84l8.48 8.48a2.003 2.003 0 0 0 2.19.438c.243-.101.464-.25.65-.438L34 21.18a2 2 0 0 0 0-2.84z" fill="#D3D3D3"></path>
                                </svg>
                              </button>
                              :
                              <div className="sign_in_country_code_popup_button">
                                <Spinner animation="border" style={{ width: '20px', height: '20px', }} />
                              </div>
                          :
                          <button className="sign_in_country_code_popup_button"
                            onClick={(e) => { setTagCountriesDialcodePopup(true) }}
                          >
                            {countries_dialcode?.label}
                            <svg className="" width="25" height="25" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path id="" d="M34 18.34a2 2 0 0 0-2.82 0L24 25.42l-7.08-7.08a2 2 0 1 0-2.82 2.84l8.48 8.48a2.003 2.003 0 0 0 2.19.438c.243-.101.464-.25.65-.438L34 21.18a2 2 0 0 0 0-2.84z" fill="#D3D3D3"></path>
                            </svg>
                          </button>
                      }
                    </div>
                  </div>
                  <div className="col-8 col-md-8">
                    <div className="form-group">
                      <label style={{ fontWeight: '400', color: 'var(--dark)' }}>Mobile Number</label>
                      <input
                        type="number"
                        onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                        className="form-control text-dark"
                        placeholder="Enter Mobile Number"
                        value={phonenumber}
                        onChange={(e) => onChangePhone(e.target.value)}
                        style={{ height: '42px' }}
                      />
                    </div>
                  </div>
                </div>
                {error !== "" && <Error error={error} />}
                <div className="form-group mt-2">
                  <div id="sign-in-button"></div>
                  <button onClick={onLogin} className="btn btn-dark main-btn" disabled={isbusy}>
                    LOGIN {!isbusy ? <img src={RightArrow} alt="" /> : <img src={roller} alt="" style={{ maxWidth: 30 }} />}
                  </button>
                </div>
                <a onClick="mp.getUserAuthCode(scopes)">Click</a>
                {/* <p className="text-center mt-2">OR</p> */}



                {/* <p className="text-center mt-2">
                  <GoogleLogin
                    clientId={
                      "454874517092-tk61r0pb6f79ecte31ugdcde36old1c5.apps.googleusercontent.com"
                    }
                    className="google-login"
                    onSuccess={responseGoogleOnSuccess}
                    onFailure={responseGoogleOnFailure}
                    cookiePolicy={"single_host_origin"}
                    theme={"dark"}
                    width={"460"}
                    height={"60"}
                    shape={"circle"}
                  // uxMode={'redirect'}
                  // redirectUri={"http://localhost:3006/register/gamerji-chirag"}
                  >
                    <span className="buttonText"> Sign in with Google</span>
                  </GoogleLogin>
                </p>

                <div className="form-group mt-2">
                  <button onClick={e => goSignUPPhone(e)}
                    className="btn login-mobile-btn main-btn">
                    LOGIN WITH EMAIL <img src={RightArrow} alt="" />
                  </button>
                </div> */}

                <p className="text-center mt-2">
                  Don't have an account?{" "}
                  <div
                    className="pointer  text-secondary"
                    onClick={() => goSignUP()}
                  >
                    Register!
                  </div>
                </p>
              </div>
            </Fragment>
          )}
        </Fragment>
      )}
    </div>
  );
}
export default SignIn;
