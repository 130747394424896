import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import {
  getApplyContestCode,
  getApplyEventCode,
  getContestList,
  getTournamentList,
  getUserInGameName,
  onCreateScrim,
  tournamentLists,
} from "../apis";
import Lottie from "reactjs-lottie";
import {
  getProfiledateFormat,
  formatAMPM,
  checkDobNstateConditions,
} from "../component/common";
import rightArrow from "../assets/images/right-arrow.svg";
import { dateFormat, goback } from "../component/common";
import Previous from "../assets/images/previous.svg";
// import successCheck from "../assets/images/success.json";
import successCheck from "../assets/images/success.json";
import Close from "../assets/images/close.svg";
import DropDownBlue from "../assets/images/drop_down_blue.png";
import NoContests from "../assets/images/Group_8748.png";
import NoTournaments from "../assets/images/Group_8752.png";
import url from "../constants/url";
import Loader from "../component/loader";
import ContestStructure from "../component/ContestStructure";
import TournamentsStructure from "../component/tournaments-structure";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Advertisement from "../component/advertisement";
import { ScrimSquadRegistration } from "./Scrims/ScrimSquadRegistration";
import DobNStates from "./dobnstate";
import { useTranslation } from "react-i18next";
import { constants } from "../constants/constants";
import { useNavigate } from "react-router";
import { eventTracking } from "../firebaseAnalytics";
import { events } from "../utils/appEvents";
import { useProfile } from "../component/ProfileComponent/ProfileContext";

function Tournaments(props) {
  const [loader, setLoader] = useState(false);
  const [loader1, setLoader1] = useState(false);
  const [tournamentJoin, setTournamnetJoin] = useState(false);
  const [progressWidth, setProgressWidth] = useState(0);
  const [scrimJoin, setScrimJoin] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [allFeaturedData, setAllFeaturedData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [joinData, setJoinData] = useState({});
  const [scrimData, setScrimData] = useState({});
  const [showScrimTeam, setShowScrimTeam] = useState(false);
  const [contestName, setContestName] = useState("");
  const [code, setCode] = useState("");
  const [applyMsg, setApplyMsg] = useState("");
  // const [gameNames, setGameNames] = useState([])
  // const [gameNames, setGameNames] = useState([])
  const [currentName, setCurrentName] = useState("");
  const [page, setPage] = useState(1);
  const [gameId, setGameId] = useState("");
  const [isLastPage, setIsLastPage] = useState(false);
  const [players, setPlayers] = useState([]);
  const [showdobnstate, setShowdobnstate] = useState(false);
  const { t } = useTranslation()
  const {walletDetails} = useProfile()
  useEffect(() => {
    // setGameNames(JSON.parse(localStorage.getItem("userInGameNames")));
    // findUserName()
    fetchData();
  }, []);

  const getjoin = async () => {
    // console.log("curenname", currentName, scrimData);
    var gameType = scrimData?.game?._id; 
    let ingamename = "";
    let gameNames = JSON.parse(localStorage.getItem("userInGameNames"));
    // console.log("gameNames", gameNames);
    if (gameNames) {
      let index = gameNames.findIndex((obj) => obj.game === gameType);
      // console.log("index gamename", index);
      if (index >= 0) {
        // console.log(
        //   "index gameNames[index].userINGameName",
        //   gameNames[index].userINGameName
        // );

        ingamename = gameNames[index].userINGameName;
      }
    }
    if (
      checkDobNstateConditions(dobNstateScreenName) ||
      ingamename == "" ||
      ingamename == undefined
    ) {
      // console.log();
      setShowdobnstate(true);
      // window.location.href = routehelp.dobnstate;
      // window.history.pushState(routehelp.dobnstate, null, '');
    } else {
      setScrimJoin(false);
      setShowConfirm(true); //
    }
  };

  let findUserName = () => {
    let activeGameId = localStorage.getItem("activeGameId");
    // console.log("activeGameId", activeGameId);
    let gameNames = JSON.parse(localStorage.getItem("userInGameNames"));
    // console.log("gameNames", gameNames);
    if (gameNames) {
      let index = gameNames.findIndex((obj) => obj.game === activeGameId);
      // console.log("index gamename", index);
      if (index >= 0) {
        // console.log(
        //   "index gameNames[index].userINGameName",
        //   gameNames[index].userINGameName
        // );

        setCurrentName(gameNames[index].userINGameName);
        return gameNames[index].userINGameName || "";
      } else {
        return "";
      }
    }
  };

  const fetchData = () => {
    setLoader(true);

    let allpage = [...JSON.parse(localStorage.getItem("currentPage"))];
    var count = allpage.findIndex((d) => d === window.location.pathname);
    count === -1 && allpage.push(window.location.pathname);
    localStorage.setItem("currentPage", JSON.stringify(allpage));

    var gameid =
      window.location.href.split("/")[
      window.location.href.split("/").length - 1
      ];

    console.log("gameid", gameid);
    setContestName(gameid === "all" ? t('header.tournaments') : findGameName(gameid));
    setGameId(gameid);
    gameid === "all"
      ? getTournamentListAPICall(1)
      : getContestListAPICall(gameid, 1);
    // console.log('gameid: ', gameid)
  };

  const findGameName = (gameid) => {
    let gameType = JSON.parse(localStorage.getItem("activeGameType"));
    // console.log("gameType", gameType);
    let gameName = gameType.find((val) => val?.id === gameid)?.name;
    if (!gameName) gameName = localStorage.getItem("activeGameTypeName");
    // console.log("gameName", gameName);

    return gameName;
  };

  const getTournamentListAPICall = async (page) => {
    let alldata = {
      skip: allFeaturedData.length,
      limit: 5,
      filter: {
        game: localStorage.getItem("activeGameId"),
        tournamentType: "gamerji",
        user: walletDetails?.user_id,
      },
    };

    let response = await tournamentLists(alldata);
    setPage(page);
    // console.log("TournamentList: ", response);
    // console.log("currentName: ", findUserName());

    if (response) {
      let temp_AllFeaturedData = [...allFeaturedData];
      if (response?.totalPages == page) setIsLastPage(true);
      console.log(response?.data?.length)
      response?.data?.length > 0 &&
        response?.data?.forEach((item) => {
          temp_AllFeaturedData.push({
            id: item?._id,
            img: url.imageUrl + item?.featuredImage?.default,
            title: item?.title,
            totalPlayers: item?.totalPlayers, // not present
            isJoined: item?.isJoined, // not present
            totalJoinedPlayers: item?.totalJoinedPlayers, // not present
            shortCode: item?.shortCode, // not present
            gameName: item?.gameAry?.name,
            gameId: item?.gameAry?._id,
            winners: item?.totalWinners, // not present
            gameType: item?.gameTypeAry?.name,
            gameTypePlayers: item?.gameTypeAry?.players, // not present
            gameTypeExtraPlayers: item?.gameTypeAry?.extraPlayers, // not present
            hostedby: item?.host?.name, // not present
            hostrate: item?.host?.rate, // not present
            entryFee: item?.entryFee, // not present
            titles: item?.titles, // not present
            roundsCount: item?.roundsCount, // not present
            startDate: item?.startDate,
            endDate: item?.endDate,
            prizePool: item?.prizePool,
            currency: item?.currency[0],
            rewardDisplayText: item?.rewardDisplayText
          });
        });

      setAllFeaturedData(temp_AllFeaturedData);
    }
    setLoader(false);
    
  };

  const getContestListAPICall = async (gameid, page) => {
    var user_data = JSON.parse(localStorage.getItem(constants.profileLite));
    setLoader1(true);
    // let data = {
    //   limit: 100,
    //   sort: "date",
    //   sortBy: "desc",
    //   filter: { gameType: gameid },
    // };

    let payload = {
      search: {
        gameType: gameid,
        game: localStorage.getItem("activeGameId"),
        user: user_data?.item?.user_id,
      },
      pagination: {
        pageNo: page,
        recordPerPage: 10,
        sortBy: "dateTime",
        sortDirection: "asc",
      },
    };
    let response = await getContestList(payload);
    // console.log("ContestList: ", response);
    setPage(page);
    if (response) {
      if (response && "data" in response) {
        const tempData = [...allData, ...response.data]
        setAllData(tempData);
        console.log(tempData);
        console.log(response.totalRecord);
        if (tempData.length === response.totalRecord) {
          setIsLastPage(true);
        }
      }
    }
    setLoader(false);
    setLoader1(false);
  };

  useEffect(() => {
    if (allData?.length > 0) {
      //  console.log("********************************allData", allData, allData?.[0]?.gameAry?.[0]?._id);
      localStorage.setItem("activeGameId", allData?.[0]?.gameAry?.[0]?._id);
      localStorage.setItem("activeGame", allData?.[0]?.gameAry?.[0]?.name);
    }
  }, [allData]);

  const applyCode = async () => {
    if (code === "") {
      // setApplyMsg(t('error_enter_invite_code'));
      toast.error(t('error_enter_invite_code'), {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      var user_data = JSON.parse(localStorage.getItem(constants.profileLite));
      eventTracking(events.join_via_invite_submit, { EP_InviteCode:code.toUpperCase()});

      let payload = {
        search: {
          user: user_data?.item?.user_id,
        },
      };
      let res = await getApplyContestCode(code.toUpperCase(), payload);
      // console.log("Code Data: ", res);
      if (res?.type === "scrim") {
        // console.log("its scrim", res?.game);
      }
      if (res?.data && res?.success) {
        let data = {};
        // console.log(data);
        if (res?.data?.isSingle) {
          data = res?.data;
        } else {
          // console.log("********setItem***************activeGameId", res?.item?.saasGameId)
          localStorage.setItem("activeGameId", res?.item?.saasGameId);
          localStorage.setItem("activeGame", res?.item?.game?.name);
          data = {
            id: res?.item?._id,
            img: url.imageUrl + res?.item?.featuredImage?.default, // not present
            coin: url.imageUrl + res?.item?.currency?.img?.default,
            title: res?.item?.title,
            contestsList: res?.item?.contestsList, // not present
            totalPlayers: res?.item?.totalPlayers,
            isJoined: res?.item?.isJoined,
            totalJoinedPlayers: res?.item?.totalJoinedPlayers,
            shortCode: res?.item?.shortCode,
            gameName: res?.item?.game?.name, // not present
            levelNote: res?.item?.game?.settings?.levelNote, // not present
            currency: res?.item?.currency,
            gameId: res?.item?.game?._id, // not present
            winners: res?.item?.totalWinners,
            gameType: res?.item?.gameType?.name,
            gameTypePlayers: res?.item?.gameType?.players,

            hostedby: res?.item?.host?.name,
            hostrate: res?.item?.host?.rate,
            entryFee: res?.item?.entryFee,
            titles: res?.item?.titles,
            rules: res?.item?.rules, // not present
            allrounds: res?.item?.rounds, // not present
            round: res?.item?.rounds?.length, // not present
            winningCoin: res?.item?.totalWinningsDistributedCoin, // not present
            startDate: res?.item?.startDate, // not present
            endDate: res?.item?.endDate, // not present
            roundDatetime: res?.item?.rounds[0]?.matches, // not present
            prizepool: res?.item?.prizepool,
            prizePool: res?.item?.prizePool,
            pointpool: res?.item?.pointpool,
            createdAt: dateFormat(res?.item?.createdAt), // not present
            updatedAt: dateFormat(res?.item?.updatedAt), // not present
            saasGameId: res?.item?.saasGameId,
          };
        }
        // setApplyMsg('Code Applied Successfully.');
        // console.log(res);
        if (res?.type === "scrim") {
          let data = res?.data;
          // console.log("Code Data", data);
          setScrimJoin(true);
          setScrimData(data);
          localStorage.setItem("activeGameId", data?.game?._id);
          localStorage.setItem("activeGame", data?.game?.name);

          localStorage.setItem("scrimData", JSON.stringify(data));

          // console.log(joinData)
        } else {
          // console.log("Code Data", data);
          setJoinData(data);
          setTournamnetJoin(true);
        }

        setCode("");
        setApplyMsg("");

        // await getjoin(res?.item);
      } else {
        // setApplyMsg(res?.data?.errors?.[0]?.msg);
        let data = {
          code: code.toUpperCase(),
          user: JSON.parse(localStorage.getItem(constants.profileLite))?.item
            ?.user_id,
        };
        let res = await getApplyEventCode(data);
        // console.log("tournamnet", res);
        if (res?.item) {
          data = {};
          if (res?.item?.isSingle) {
            data = res?.item;
          } else {
            //  console.log("***********^^^^^^^^^^^^^^^^^^^^^^^^^**************")
            localStorage.setItem("activeGameId", res?.item?.gameAry[0]?._id);
            localStorage.setItem("activeGame", res?.item?.gameAry[0]?.name);
            data = {
              id: res?.item?._id,
              img: url.imageUrl + res?.item?.featuredImage?.default, // not present
              coin: url.imageUrl + res?.item?.currency?.img?.default,
              title: res?.item?.title,
              contestsList: res?.item?.contestsList, // not present
              totalPlayers: res?.item?.totalPlayers,
              isJoined: res?.item?.isJoined,
              totalJoinedPlayers: res?.item?.totalJoinedPlayers,
              shortCode: res?.item?.shortCode,
              gameName: res?.item?.gameAry[0]?.name, // not present
              levelNote: res?.item?.game?.settings?.levelNote, // not present
              currency: res?.item?.currency[0],
              gameId: res?.item?.gameAry[0]?._id, // not present
              winners: res?.item?.totalWinners,
              gameType: res?.item?.gameTypeAry?.name,
              gameTypePlayers: res?.item?.gameTypeAry?.players,
              gameTypeExtraPlayers: res?.item?.gameTypeAry?.extraPlayers, // not present

              hostedby: res?.item?.host?.name,
              hostrate: res?.item?.host?.rate,
              entryFee: res?.item?.entryFee,
              titles: res?.item?.titles,
              rules: res?.item?.rules, // not present
              allrounds: res?.item?.rounds, // not present
              round: res?.item?.rounds?.length, // not present
              winningCoin: res?.item?.totalWinningsDistributedCoin, // not present
              startDate: res?.item?.startDate, // not present
              endDate: res?.item?.endDate, // not present
              roundDatetime: res?.item?.rounds[0]?.matches, // not present
              prizepool: res?.item?.prizePoolInfo,
              prizePool: res?.item?.prizePool,
              pointpool: res?.item?.pointpool,
              inGameName: findUserName(),
              createdAt: dateFormat(res?.item?.createdAt), // not present
              updatedAt: dateFormat(res?.item?.updatedAt), // not present
              saasGameId: res?.item?.gameAry[0]?._id,
              rewardDisplayText: res?.item?.rewardDisplayText
            };
          }
          setCode("");
          setApplyMsg("");
          setJoinData(data);
          // console.log("Code Data", res?.item)
          setTournamnetJoin(true);
        } else {
          toast.error(res?.data?.errors?.[0]?.msg, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
    }
  };

  useEffect(() => {
    // console.log("props?.allData==>", scrimData);
    if (scrimData) {
      var per =
        (100 * scrimData?.noOfJoinedPlayer) / scrimData?.noOfPlayerCapacity +
        "%";
      setProgressWidth(per);
      setScrimData(scrimData);
    }
  }, [scrimData]);

  // Tournaments - Tournament/all (One box)
  const createTournamentSingleBox = (data, index) => {
    return <TournamentsStructure allFeaturedData={data} key={index} />;
  };

  // Tournaments - Tournament/all (all box)
  const createTournaments = () => {
    // console.log("tournamnets ??", allFeaturedData);
    return allFeaturedData?.length > 0 ? (
      allFeaturedData?.map((item, index) => {
        return createTournamentSingleBox(item, index);
      })
    ) : (
      // No Tournaments
      <div className="no_tournament">
        <img src={NoTournaments} style={{ height: "50%" }} />
      </div>
    );
  };

  // Contests - Tournament/{id} (all box)
  const createContestSingleBox = (data, index) => {
    return <ContestStructure allData={data} key={index} />;
  };

  // Contests - Tournament/{id} (all box)
  const createContests = () => {
    // console.log("alldata", allData);
    // localStorage.setItem('activeGameId', allData[0]?.game?._id)
    // localStorage.setItem('activeGame',allData[0]?.game?.name)
    return (
      <>
        {allData?.length > 0 ? (
          allData?.map((item, index) => {
            return createContestSingleBox(item, index);
          })
        ) : (
          // No Contents
          <div className="no_tournament">
            <img src={NoContests} style={{ height: "50%" }} />
          </div>
        )}
      </>
    );
  };

  // Join Now Popup - Click on Apply Code - Tournament/all - Tournament/{id}
  const createAfterApplyCodePopup = () => {
    // console.log("joindata", joinData);
    return (
      <div className="tc_popup_bg">
        <div className="custom-popup home-featured show" id="contact_popup">
          <div className="popup-head bg-light">
            <h3>
              {joinData?.isSingle === true
                ? joinData.title
                : joinData?.gameName}
            </h3>
            <button
              className="close-popup"
              onClick={() => setTournamnetJoin(false)}
            >
              <img src={Close} alt="" />
            </button>
          </div>
          <div className="popup-body">
            {joinData?.isSingle === true
              ? createContestSingleBox(joinData, 1)
              : createTournamentSingleBox(joinData, 1)}
          </div>
        </div>
      </div>
    );
  };
  const dobNstateScreenName = "tournaments-contests";
  const getdobnstate = (tag) => {
    // setShowjoinpopup(tag);
    var gameid = localStorage.getItem("activeGameId");
    findUserName(gameid);
    setShowdobnstate(tag);
    setShowConfirm(true);
  };
  const cancelDobNstate = () => {
    setShowdobnstate(false);
    // setShowjoinpopup(false);
    setShowConfirm(false);
  };
  const createScrimApplyCodePopup = () => {
    return (
      <div className="tc_popup_bg">
        <div className="custom-popup home-featured show " id="contact_popup">
          <div className="popup-head bg-light">
            <h3>{scrimData?.title}</h3>
            <button className="close-popup" onClick={() => setScrimJoin(false)}>
              <img src={Close} alt="" />
            </button>
          </div>
          <div className="popup-body">
            <div
              className="contest-list contest-tournament-list game_tounament "
              style={{ margin: "20px auto 0px" }}
            >
              {showdobnstate ? (
                <DobNStates
                  submitDobNstate={getdobnstate}
                  cancelDobNstate={cancelDobNstate}
                  screen={dobNstateScreenName}
                />
              ) : (
                ""
              )}
              <div className="box scrimDetail">
                <h3
                  style={{
                    fontSize: "16px",
                    fontWeight: "800",
                    marginTop: "4px",
                  }}
                >
                  {scrimData?.title}
                  <span className="span">
                    <div to="/create-scrim">
                      {/* <img
                        // src={Pencil}
                        alt="Edit"
                        style={{ color: "black !important" }}
                      /> */}
                    </div>
                  </span>
                </h3>

                <div
                  className="row tournament_contest_row"
                  style={{ marginBottom: "-20px" }}
                >
                  <div className="col-12">
                    <div className="inner_row">
                      <div className="inner_item">
                        {t('contest_details.date')}
                        <b>{getProfiledateFormat(scrimData?.date)}</b>
                      </div>
                      <div className="inner_item">
                        {t('contest_details.time')} <b>{formatAMPM(new Date(scrimData?.time))}</b>
                      </div>

                      <div className="inner_item">
                        {scrimData.title1?.name}{" "}
                        <b>{scrimData.title1?.value}</b>
                      </div>
                      {scrimData?.title2 ? (
                        <div className="inner_item">
                          {scrimData.title2?.name}{" "}
                          <b>{scrimData.title2?.value}</b>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="inner_row bg-dark">
                      <div className="inner_item inner_item_medium">
                        {t('contest_details.players')} <br /> <b>{scrimData.noOfPlayerCapacity}</b>
                      </div>
                      <div className="inner_item inner_item_medium">
                        {t('tournaments.winners')} <br /> <b>{scrimData.noOfWinner}</b>
                      </div>
                      <div className="inner_item inner_item_medium">
                        {t('contest_details.id')} <br /> <b>{scrimData.shortCode}</b>
                      </div>
                    </div>

                    <div className="avalible_wrap">
                      <div className="inner">
                        <span
                          style={{
                            width: progressWidth,
                          }}
                        ></span>
                      </div>
                      <p
                        className="d-flex justify-content-between text-light"
                        style={{ paddingBottom: "1rem" }}
                      >
                        <span className="">
                          {" "}
                          {t('contest_details.players_remaining', {
                            players: scrimData?.noOfPlayerCapacity -
                              scrimData?.noOfJoinedPlayer
                          })}
                        </span>
                        <span className="">
                          {t('contest_details.players_joined', { players: scrimData?.noOfJoinedPlayer })}
                        </span>
                      </p>
                    </div>
                    <div className="contest_foot p-2">
                      <div className="inner pl-2">
                        <span className="">{t('private_contest.hosted_by')}</span>
                        <marquee>
                          <b>
                            {" "}
                            {scrimData?.hostedBy} (
                            {/* {Number(allData?.rate?.toFixed(2))} */}
                            <i className="fas fa-star text-dark"></i>)
                          </b>
                        </marquee>
                      </div>

                      {scrimData?.noOfJoinedPlayer >=
                        scrimData?.noOfPlayerCapacity ? (
                        <div className="inner pointer">
                          <a className="contest_joining_popup">{t('tournaments.full')}</a>
                        </div>
                      ) : scrimData?.isJoined === true ? (
                        <div className="inner pointer">
                          <a className="d-flex justify-content-center align-items-center">
                            <b
                              className="contest_joining_popup d-flex justify-content-center align-items-center"
                            // onClick={() => joinedContest()}
                            >
                              <div
                                className="d-flex justify-content-center align-items-center"
                                style={{ height: "25px" }}
                                onClick={() => Scrimdetail()}
                              >
                                {t('tournaments.joined')}
                              </div>

                              <div
                                className="tick-icon"
                              >
                                <Lottie
                                  options={{
                                    animationData: successCheck,
                                    w: "20px",
                                  }}
                                />
                              </div>
                            </b>
                          </a>
                        </div>
                      ) : (
                        <div
                          className="inner pointer"
                          onClick={() => getjoin(scrimData)}
                        >
                          <a className="contest_joining_popup">{t('tournaments.join_now')}</a>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const Scrimdetail = () => {
    window.location.href = "/scrim-details/" + scrimData?._id;
  };
  const onJoinScrim = async () => {
    const gameNames = JSON.parse(localStorage.getItem("userInGameNames"));
    var user_data = JSON.parse(localStorage.getItem(constants.profileLite));
    // console.log(user_data);
    let data = {
      scrimId: scrimData?._id,
      userId: user_data.item.user_id,
      usersInGameName: gameNames?.[0]?.userINGameName,
      gamerJiName: user_data?.item.gamerjiName,
      mobileNo: user_data?.item?.phone
        ? user_data?.item?.phone
        : user_data?.item?.email,
    };

    // console.log(scrimData);

    if (
      scrimData?.gameType?.players &&
      parseInt(scrimData?.gameType?.players) > 1
    ) {
      var numArray = [];
      var selectedPlayer = [];
      for (var i = 2; i <= parseInt(scrimData?.gameType?.players); i++) {
        numArray.push(i);

        selectedPlayer.push({
          userId: null,
          inGameName: null,
          gamerJiName: user_data?.item.gamerjiName,
          country: null,
          mobileNo: null,
        });
      }
      setPlayers(selectedPlayer.length);
      // console.log(selectedPlayer.length);
      setShowScrimTeam(true);
      setShowConfirm(false);
    } else {
      let res = await onCreateScrim(data);
      // console.log(res);
      // setPlayers(res.gameType)
      {
        res?.success === true
          ? (window.location.href = "/scrim-details/" + scrimData?._id)
          : toast.error(res.data.data[0]?.msg, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
      }
    }
  };

  const setShowTeam = () => {
    setShowScrimTeam(false);
  };
  const navigate = useNavigate()
  return (
    // {/* Main Full Screen */}
    <div className="inner-wrap">
      {loader ? <Loader /> : ""}

      {/* Main Header */}
      <div className="header">
        <div className="back-btn">
          <a onClick={() => navigate(`/tournaments/${localStorage.getItem('activeGameId')}`)}>
            <img src={Previous} alt="" />
          </a>
        </div>
        <h2 style={{ textTransform: "capitalize" }}>{contestName}</h2>
        <div className=""></div>
      </div>

      {/* Main Body */}
      <div
        className="body mCustomScrollbar _mCS_1"
        style={{ paddingTop: "20px" }}
      >
        <div
          className="all_sub_screens_div_related_to_ads"
          style={{ display: "inherit" }}
        >
          <ToastContainer />
          {/* Join Via Invite Code Box - Tournaments - Tournament/all  */}
          {/* {(allFeaturedData?.length > 0 || allData?.length > 0) && ( */}
          <div className="recent_transaction_items more_tab_items join_code">
            <div className="accordion" id="accordionExample">
              <div className="item">
                <div
                  className="accordion-header"
                  data-toggle="collapse"
                  data-target="#a_6"
                  aria-expanded="false"
                >
                  <div style={{ fontSize: "16px", fontWeight: "600" }}>
                    {t('right_hand_drop_down.join_via_invite_code')}
                  </div>
                  <span
                    className="icon"
                    style={{ width: "25px", height: "25px" }}
                  >
                    <img className="tc_jvic_arrow_img" src={DropDownBlue} />
                  </span>
                </div>
                <div
                  id="a_6"
                  className="txt mb-0 collapse"
                  data-parent="#accordionExample"
                >
                  <div className="join_code">
                    <input
                      type="text"
                      placeholder={t('right_hand_drop_down.enter_invite_code')}
                      value={code}
                      onChange={(e) => setCode(e.target.value.toUpperCase())}
                    />
                    <button className="apply_btn" onClick={applyCode}>
                      {t('login.apply')}
                    </button>
                  </div>
                  <p className="error">{applyMsg}</p>
                </div>
              </div>
            </div>
          </div>
          {/* // )} */}

          {/* Tournament/all or Tournament/{id} */}
          {window.location.href.split("/")[
            window.location.href.split("/").length - 1
          ] === "all"
            ? createTournaments()
            : createContests()}

          {!isLastPage && (
            <div className="load_more text-center">
              <button
                className="btn btn-primary"
                onClick={() => {
                  gameId === "all"
                    ? getTournamentListAPICall(page + 1)
                    : getContestListAPICall(gameId, page + 1);
                }}
              >
                {loader1 === true ? `${t('contest.loading')}...` : `${t('contest.load_more')}`}
              </button>
            </div>
          )}


          {/* Join Now Popup - Click on Apply Code - Tournament/all - Tournament/{id} */}
          {tournamentJoin === true && createAfterApplyCodePopup()}
          {scrimJoin === true && createScrimApplyCodePopup()}
        </div>

        {/* Join Popup - joinContestWalletPopup */}
        {showConfirm === true && (
          <div className="tc_popup_bg">
            <div
              className="custom-popup rules confirm custom_scroll join show scrim-applyCode-Popup"
              id="contact_popup"
            >
              <div className="popup-head">
                {/* <marquee> */}
                <h3> {scrimData?.title} </h3>
                {/* </marquee> */}

                <button
                  className="close-popup"
                  onClick={() => setShowConfirm(false)}
                >
                  <img src={Close} alt="" />
                </button>
              </div>
              {/* <div className="popup-body bg-yellow">
                <div className="all_sub_screens_div_related_to_ads">
                  <p className="game_det">
                    <span>{scrimData?.game?.name}-{scrimData?.gameType?.name}</span>
                    <span>
                      {getProfiledateFormat(scrimData?.date)} { }
                      {formatAMPM(new Date(scrimData?.time))}
                    </span>
                  </p>
                  <p className="game_det">
                    <span>Hosted By: {scrimData?.hostedBy}</span>
                    <span></span>
                  </p>
                </div>


                <button
                  className="close-popup"
                  onClick={() => setShowConfirm(false)}
                >
                  JOIN SCRIM{" "}
                  <img
                    src={rightArrow}
                    alt=""
                    className="mCS_img_loaded"
                  />
                </button>
              </div> */}
              <div className="popup-body bg-yellow">
                <div className="all_sub_screens_div_related_to_ads">
                  <p className="game_det">
                    <span>
                      {scrimData?.game?.name}-{scrimData?.gameType?.name}
                    </span>
                    <span>
                      {getProfiledateFormat(scrimData?.date)} { }
                      {formatAMPM(new Date(scrimData?.time))}
                    </span>
                  </p>
                  <p className="game_det">
                    <span>{t('private_contest.hosted_by')}: {scrimData?.hostedBy}</span>
                    <span></span>
                  </p>


                </div>
                <div class="d-flex justify-content-center align-items-center">
                  <button
                    className="btn btn-dark main-btn next scrim-tournament-btn"
                    onClick={() => onJoinScrim()}
                  >
                    {t('contest.join_contest')}{" "}
                    <img src={rightArrow} alt="" className="mCS_img_loaded" />
                  </button>
                </div>
                {
                  !JSON.parse(localStorage.getItem("premiumUser")) &&

                  <Advertisement
                    screen={
                      localStorage.getItem("apk_screens_list") === null
                        ? { code: "no_results" }
                        : JSON.parse(localStorage.getItem("apk_screens_list"))
                          .joinContestWalletPopup
                    }
                    screen_div_class_name=".all_sub_screens_div_related_to_ads"
                    screen_type="sub"
                  />
                }
              </div>
            </div>
          </div>
        )}
        {/* {console.log("Scrim Squad", showScrimTeam)} */}
        {showScrimTeam === true && (
          <ScrimSquadRegistration scrimData={scrimData} players={players} setShowTeam={setShowTeam} />
        )}

        {
          !JSON.parse(localStorage.getItem("premiumUser")) &&

          <Advertisement
            screen={
              localStorage.getItem("apk_screens_list") === null
                ? { code: "no_results" }
                : window.location.href.split("/")[
                  window.location.href.split("/").length - 1
                ] === "all"
                  ? JSON.parse(localStorage.getItem("apk_screens_list"))
                    .tournamentLists
                  : JSON.parse(localStorage.getItem("apk_screens_list"))
                    .contestsList
            }
            screen_div_class_name=".all_sub_screens_div_related_to_ads"
            screen_type="sub"
          />
        }
      </div>
    </div>
  );
}

export default Tournaments;
