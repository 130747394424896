import {
  Navbar,
  useProfile,
  Collegiate,
  Stats,
} from "../../component/ProfileComponent/index.js";
import { Link } from "react-router-dom";
import { colors, url } from "../../constants";

export const Profile1 = () => {
  const {
    showInfo,
    allData,
    levelProgressValue,
    onClickInfo,
    HandleTabClick,
    activeTab,
  } = useProfile();
  console.log(allData);
  return (
    <div className="inner-wrap custom_scroll_leader" id="account_main">
      <div
        className="profile_main mCustomScrollbar _mCS_1"
        id={showInfo === true ? "popup_show" : ""}
      >
        <div className="all_main_screens_div_related_to_ads">
          <Navbar />
          <div className="profile_first row">
            <div className="col-6 avatar_detail p-0">
              <div
                className="col p-0"
                style={{
                  border: "1px solid var(--dark)",
                  borderRadius: "0 20px 20px 0",
                  borderLeft: "none",
                }}
              >
                <div className="row m-0 profile_gamerji_name_row">
                  <h4 className="profile_gamerji_name">
                    {allData?.item?.gamerjiName === undefined ||
                    allData?.item?.gamerjiName === null ? (
                      ""
                    ) : allData?.item?.gamerjiName.length > 10 ? (
                      <marquee>{allData?.item?.gamerjiName}</marquee>
                    ) : (
                      allData?.item?.gamerjiName
                    )}
                  </h4>
                  <div className="profile_gamerji_name_right_triangle"></div>
                  <div className="profile_gamerji_name_right_upper_triangle"></div>
                </div>
                <Link to="/friends" className="text-dark">
                  <div className="row m-0 profile_follower_followings_row">
                    <div className="col-5 d-flex flex-column align-items-center justify-content-center">
                      <div className="profile_followers_data">
                        {allData?.item?.followers}
                      </div>
                      <div className="profile_followers_title">Followers</div>
                    </div>
                    <div className="col-2">
                      <div className="profile_follower_followings_divider"></div>
                    </div>
                    <div className="col-5 d-flex flex-column align-items-center justify-content-center">
                      <div className="profile_followings_data">
                        {allData?.item?.followings}
                      </div>
                      <div className="profile_followings_title">Followings</div>
                    </div>
                  </div>
                </Link>
              </div>

              <div className="level_box d-flex flex-column text-center">
                <img
                  src={
                    url.imageUrl +
                    allData?.item?.level?.level?.featuredImage?.default
                  }
                  style={{
                    height: 73,
                    width: 70,
                    resizeMode: "contain",
                    alignSelf: "center",
                    marginTop: -30,
                  }}
                />
                <div
                  style={{
                    width: 175,
                    height: 30,
                    justifyContent: "center",
                    marginTop: 10,
                  }}
                >
                  <p
                    style={{
                      alignSelf: "center",
                      color: colors.black,
                      fontSize: 14,
                      fontWeight: "bold",
                    }}
                  >
                    {allData?.item?.level?.level?.name}
                  </p>
                </div>
                <div
                  style={{
                    marginTop: 5,
                    marginLeft: 10,
                    marginRight: 10,
                    width: 156,
                  }}
                >
                  <div className="avalible_wrap">
                    <div className="inner">
                      <span style={{ width: levelProgressValue }}></span>
                    </div>
                    <p className="d-flex justify-content-between">
                      <span>{allData?.item?.level?.level?.startPoint} PTS</span>
                      <a>{allData?.item?.level?.level?.endPoint} PTS</a>
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    marginTop: 10,
                    width: 166,
                    textAlign: "center",
                    color: colors.black,
                    fontWeight: "bold",
                    fontSize: 12,
                  }}
                >
                  Points - {allData?.item?.level?.points}
                </div>
                <div className="profile_level_box_all_level">
                  <div className="row m-0 profile_level_row">
                    <div className="profile_level_left_upper_triangle"></div>
                    <div className="profile_level_left_triangle"></div>
                    <div className="profile_level_details">
                      Level - {allData?.item?.level?.level?.num}
                    </div>
                    <div className="profile_level_right_triangle"></div>
                    <div className="profile_level_right_upper_triangle"></div>
                  </div>

                  <a
                    style={{
                      width: "100%",
                      height: 30,
                      borderRadius: 20,
                      justifyContent: "center",
                      color: colors.white,
                      cursor: "pointer",
                      fontSize: 16,
                      fontWeight: "bold",
                      alignSelf: "center",
                      paddingTop: 0,
                      paddingBottom: 5,
                      paddingLeft: 20,
                      paddingRight: 20,
                    }}
                    onClick={() =>
                      onClickInfo(
                        "LEVELS",
                        "Money deposited by you that you can only be used to buy a coins but cannot be withdrawn."
                      )
                    }
                  >
                    All Levels
                  </a>
                </div>
              </div>
            </div>

            <div className="col-6 avatar_img">
              <img
                src={url.imageUrl + allData?.item?.avatar?.img?.default}
                style={{ width: "200px", maxWidth: "200px", minWidth: "200px" }}
              />
            </div>
          </div>
          <div
            className="profile_second"
            style={{ height: "calc(100% - 78%)" }}
          >
            <div
              className="list-group center profile-tab"
              id="list-tab"
              role="tablist"
            >
              <a
                className="list-group-item list-group-item-action active"
                id="list-home-list"
                data-toggle="list"
                onClick={() => HandleTabClick("Stats")}
              >
                Stats
              </a>
              <a
                className="list-group-item list-group-item-action"
                id="list-profile-list"
                data-toggle="list"
                onClick={() => HandleTabClick("Collegiate")}
              >
                Collegiate
              </a>
            </div>
            <div className="tab-content" id="nav-tabContent">
              {activeTab === "Stats" && <Stats />}
              {activeTab === "Collegiate" && <Collegiate />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
