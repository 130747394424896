import React from "react";
import ReactDOM from "react-dom";
import "react-intl-tel-input/dist/main.css";
import { html5GamesUpdateView, } from "../../apis";

import Cancel from "../../assets/images/round_not_qualified.png";
import RightArrow from "../../assets/images/right-arrow.svg";

// import Advertisement from "../../component/advertisement";

import './FreeGames.css';

export default class JoinFreeGameWalletPopup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      free_game_category: [], 
      selected_game_data: [], 
    };
    
    this.onClickJoinGame = this.onClickJoinGame.bind(this);
  }

  componentDidMount() {
    this.setState({
      free_game_category: this.props.free_game_category, 
      selected_game_data: this.props.selected_game_data, 
    })
  }

  onClickJoinGame() {
    // console.log(this.state.free_game_category)
    // console.log(this.state.selected_game_data)
    // this.html5GamesUpdateViewAPICall();
    window.location.href = '/game/' + this.state.selected_game_data?._id;
  }

  async html5GamesUpdateViewAPICall() {
    var data = {
      html5Category: this.state.free_game_category?._id,
      htmlGame: this.state.selected_game_data?._id,
      status: "start"
    }
    var response = await html5GamesUpdateView(data);
    console.log('Free Game Update View: ', response)
  }

  createJoinGamePopup() {
    return(
      <div className="fg_join_game_popup_main_div">
        <div className="fg_join_game_popup">
          <div className="fg_join_game_header">
            <div className="fg_join_game"></div>
            <div className="fg_join_game_header_text">
            {this.state.free_game_category.name}{' - '}{this.state.selected_game_data?.name}
            </div>
            <div className="fg_join_game_cancel_icon">
              <img src={Cancel} onClick={() => {this.props.onChangeJoinGameWalletPopup(false)}} />
            </div>
          </div>

          <div className="fg_join_game_body">
            <div className="all_sub_screens_div_related_to_ads">
              <div className="fg_join_game_body_scrollable_component">
                <div className="fg_join_game_confirmation_box">Confirmation</div>
                <div className="fg_join_game_wallet_component">
                  <div className="fg_join_game_entry_fee">Entry Fee</div>
                  <div className="fg_join_game_fee">Free</div>
                </div>
                <div className="fg_join_game_enjoy">
                  Enjoy Free Games on Gamerji
                </div>
                <button className="fg_join_game_button" onClick={() => this.onClickJoinGame()}>
                  Join Game <img src={RightArrow} alt="" className="mCS_img_loaded" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      this.createJoinGamePopup()
    );
  }
}

// export default JoinFreeGameWalletPopup;
