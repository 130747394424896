import React, { Fragment, useEffect, useState } from "react"

import {
  getCoinStoreList,
  getAllAvatarList,
  getAllRewardCategoryList,
  getAllRewardProductMyList,
  requestCreateCoinStoreTransactions,
  requestCreateCFCoinStoreTransactions,
  getApplyReward,
  getBuyAvatar,
  getUserProfile,
  getGenrateSignature,
  createXsollaTransaction
} from "../apis";
import { routehelp } from "../config/routehelp";
import Previous from "../assets/images/previous.svg";
import Wallet from '../assets/images/wallet.svg'
import url from "../constants/url";
import Dollar from "../assets/images/upload-card.svg";
import Coin from "../assets/images/coin.png";
import Diamond from "../assets/images/BLUE_Diamond.png";
import myRecentTransactionImage from '../assets/images/ic_transactions.png';
import Rupee from "../assets/images/rupee.svg";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import Cancel from "../assets/images/round_not_qualified.png";
import Close from "../assets/images/close.svg";
import { getConvertionDateTime, checkDobNstateConditions } from "../component/common";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SuccessPayment from "../assets/images/success-payment.png";
import PendingPayment from "../assets/images/pending-payment.png";
import FailPayment from "../assets/images/fail-payment.png";
import PurchasePackIcon from "../assets/images/purchase-pack-icon.png";
import AccountIcon from "../assets/images/account_icon.png";
import sha256 from 'crypto-js/sha256';
import helperFunc from '../helperFuncs';
import HmacSHA256 from 'crypto-js/hmac-sha256';
import Base64 from 'crypto-js/enc-base64';
import { loadStripe } from "@stripe/stripe-js";
import { useStripe } from "@stripe/react-stripe-js";
import Loader from "../component/loader";
import { constants } from "../constants/constants";
import DobNStates from "./dobnstate";
import Advertisement from "../component/advertisement";
import useRazorpay from "react-razorpay";
import { useTranslation } from "react-i18next";

const XsollaPayment = () => {
    const [showLoader, setShowLoader] = useState(false);
    const dobNstateScreenNameBuyItem = 'coin-store-buy-item';
  const dobNstateScreenNameRedeemItem = 'coin-store-redeem-item';

  const Razorpay = useRazorpay();
  const [allCoin1, setAllCoin1] = useState(null);
  const [allCoin2, setAllCoin2] = useState(null);
  const [allCoin3, setAllCoin3] = useState(null);
  const [allReward1, setAllReward1] = useState(null);
  const [allReward2, setAllReward2] = useState(null);
  const [activeTab, setActiveTab] = useState(1);
  const [activeCoinStoreTab, setActiveCoinStoreTab] = useState(0);
  const [activeReward, setActiveReward] = useState(0);
  const [userProfile, setUserProfile] = useState(null);
  const [redeemData, setRedeemData] = useState({});

  const [showRedeem, setShowRedeem] = useState(false);

  const [infoTitle, setInfoTitle] = useState("");
  const [infoDesc, setInfoDesc] = useState("");
  const [showInfo, setShowInfo] = useState(false);
  const [showBundlesInfo, setShowBundlesInfo] = useState(false);
  const [avatarData, setAvatarData] = useState({});
  const [bundlesData, setBundlesData] = useState({});
  const [cfOrderData, setCFOrderData] = useState({});

  const [tagShowDobNstate, setTagShowDobNstate] = useState(false);
  const [buyItemData, setBuyItemData] = useState([]);

  const [tagShowHowToRedeemPopup, setTagShowHowToRedeemPopup] = useState([]);
  const {t} = useTranslation()

  var userCountry = helperFunc.getUserCountry();
  useEffect(() => {
    const clientSecretFromURL = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );
    const coinStoreIdFromURL = new URLSearchParams(window.location.search).get(
      "coinStore"
    );

    if (clientSecretFromURL) {
      createStripeTransactionWeb(coinStoreIdFromURL, clientSecretFromURL)
    }

  }, []);

  const createStripeTransactionWeb = async (coinStoreIdFromURL, clientSecretFromURL) => {

    let res = await createStripeTransactionWeb({
      coinStore: coinStoreIdFromURL,
      clientSecret: clientSecretFromURL
    });
    if (res) {
      console.log("createStripeTransactionWeb==>res?.item===>", res?.item)
      //setClientSecret(res?.item?.clientSecret);

    }
  };

  useEffect(() => {
    localStorage.setItem('platform', 'WebApp');
    let isDefault = JSON.parse(localStorage.getItem('isDefault'))
    
    if(isDefault)
      fetchData();
    else virtualItems()
    fetchProfileData();
   }, []);


  const getTotalCoin = (wallet) => {
    let index = wallet.findIndex(obj => obj.currencyData?.code == 'coin')

    if (index > -1) {
      return wallet[index].winningAmount ? wallet[index].winningAmount : 0
    }
    return 0
  }
  const getTotalDiamond = (wallet) => {
    let index = wallet.findIndex(obj => obj.currencyData?.code == 'diamond')

    if (index > -1) {
      return wallet[index].winningAmount ? wallet[index].winningAmount : 0
    }
    return 0
  }


  const fetchProfileData = async () => {
    let res = await getUserProfile();
    if (res) {
      setUserProfile(res);
      console.log("res==>", res);
      localStorage.setItem('currentCoin', getTotalCoin(res?.item?.wallet));
      localStorage.setItem('currentDiamond', getTotalDiamond(res?.item?.wallet));

    }
  };

  const fetchData = async () => {
    let one = {
      filter: { coinType: "1" },
      skip: 0,
      limit: 10,
      sort: "asc",
      sortBy: "amount",
    };
    let res = await getCoinStoreList(one);
    if (res) {
      setAllCoin1(res?.list);
    }
    let two = {
      filter: { coinType: "2" },
      skip: 0,
      limit: 100,
      sort: "asc",
      sortBy: "amount",
    };
    let res1 = await getCoinStoreList(two);
    if (res1) {
      setAllCoin2(res1?.list);
    }
    let three = { skip: 0, limit: 100, sort: "asc", sortBy: "amount" };
    let res2 = await getAllAvatarList(three);
    if (res2) {
      setAllCoin3(res2?.list);
    }
    let four = { skip: 0, limit: 100, sort: "asc", sortBy: "order" };
    let res3 = await getAllRewardCategoryList(four);
    if (res3) {
      setAllReward1(res3?.list);
    }
    getMyRewardList()
  };

  const getMyRewardList = async () => {
    let five = { skip: 0, limit: 100, sort: "asc", sortBy: "createdAt" };
    let res4 = await getAllRewardProductMyList(five);
    if (res4) {
      console.log('setAllReward2: ', res4?.list)
      let temp = [];
      res4?.list.forEach(() => {
        temp.push(false);
      });
      setAllReward2(res4?.list);
      setTagShowHowToRedeemPopup(temp);
    }
  }

  const moveToPaymentOptionScreen = async (item) => {
    //  const userProfileObj = JSON.parse(userProfile);
    const userProfileObj = userProfile;
    console.log("userProfile.rzpKey==>", userProfile);
    // debugger
    //console.log(
    //   "userProfile.razorPayEnabled==>",
    //   userProfileObj.razorPayEnabled
    // );

    window.location.href = "/payment-gateway-web/" + item._id;
    // window.location.href = "/response/" + item._id;

    // if (userProfile?.country?.code === "IN") {
    //   // window.location.href = "/payment-gateway/" + item._id;
    //   if (userProfileObj?.razorPayEnabled === false) {
    //     //  alert(userProfileObj?.razorPayEnabled);

    //     let payload = {
    //       amount: item.amount,
    //       coinStore: item._id,
    //       paymentType: 17,
    //     };
    //     let createCFCoinStoreTransactionsResponse =
    //       await requestCreateCFCoinStoreTransactions(payload);

    //     if (
    //       createCFCoinStoreTransactionsResponse &&
    //       createCFCoinStoreTransactionsResponse.item
    //     )
    //       cashfreePayment(
    //         createCFCoinStoreTransactionsResponse.item,

    //       );

    //     // window.location.href = "/payment-options";
    //   } else {

    //     let payload = {
    //       amount: item.amount,
    //       coinStore: item._id,
    //       paymentType: 17,
    //     };
    //     let createCoinStoreTransactionsResponse =
    //       await requestCreateCoinStoreTransactions(payload);

    //     if (
    //       createCoinStoreTransactionsResponse &&
    //       createCoinStoreTransactionsResponse.item
    //     )
    //       razorpayPayment(
    //         createCoinStoreTransactionsResponse.item.order,
    //         userProfileObj.rzpKey
    //       );
    //   }

    // } else {
    //   window.location.href = "/payment-gateway-web/" + item._id;
    //  // window.location.href = "/payment-options/" + item._id;
    // }
  };

  const cashfreePayment = async (order) => {

    var cfdatakeysArr = ["appId", "orderId", "orderAmount", "orderCurrency",
      "orderNote", "customerName", "customerPhone", "customerEmail", "returnUrl", "notifyUrl"];
    var cfdataArr = [];
    cfdataArr["appId"] = "58874f6db6934ba819cab399247885";
    cfdataArr["orderId"] = order?.transactionUniqueID;
    cfdataArr["orderAmount"] = order?.amount;
    cfdataArr["orderCurrency"] = "INR";
    cfdataArr["orderNote"] = "Buy Coin";
    cfdataArr["customerName"] = userProfile?.item?.gamerjiName;
    cfdataArr["customerPhone"] = userProfile?.item?.phone;
    cfdataArr["customerEmail"] = userProfile?.item?.email;
    cfdataArr["returnUrl"] = url.publicUrl + "return-payment";
    cfdataArr["notifyUrl"] = url.publicUrl + "verify-payment";

    var signature = await genrateSignature(cfdatakeysArr, cfdataArr);
    var cfdata = {
      "appId": "58874f6db6934ba819cab399247885",
      "orderId": order?.transactionUniqueID,
      "orderAmount": order?.amount,
      "orderCurrency": "INR",
      "orderNote": "Buy Coin",
      "customerName": userProfile?.item?.gamerjiName,
      "customerPhone": userProfile?.item?.phone,
      "customerEmail": userProfile?.item?.email,
      "returnUrl": url.publicUrl + "return-payment",
      "notifyUrl": url.publicUrl + "verify-payment",
      "signature": signature
    };
    setCFOrderData(cfdata);
    document.getElementById("redirectForm").submit();
  };

  const razorpayPayment = async (order, rzpKey) => {
    console.log("userProfile.item==>", userProfile);
    //  const userProfileObj = JSON.parse(userProfile);
    const userProfileObj = userProfile;

    console.log("userProfile.item==>", userProfileObj.item);

    // window.location.href = "/payment-options";

    const options = {
      description: "Credits towards service",
      image: "https://www.gamerji.tech/leagueops/media/logos/256x256.png",
      currency: "INR",

      key: rzpKey,
      amount: "5000",
      //  amount: "order.amount",
      order_id: order.id,

      name: "Gamerji E-sports Private Limited",
      handler: function (response) {
        console.log("response=>", response);
        // alert(response.razorpay_payment_id);
        // alert(response.razorpay_order_id);
        // alert(response.razorpay_signature);
        toast.success(constants.success_Payment_successfull, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      },
      prefill: {
        email: userProfileObj.item.email || "",
        contact: userProfileObj.item.phone,
        name: userProfileObj.item.gamerjiName || "",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#070B28",
      },
    };

    const rzp1 = new Razorpay(options);

    rzp1.on("payment.failed", function (response) {
      // alert(response.error.code);
      // alert(response.error.description);
      // alert(response.error.source);
      // alert(response.error.step);
      // alert(response.error.reason);
      // alert(response.error.metadata.order_id);
      // alert(response.error.metadata.payment_id);
      toast.error(response.error.description, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });

    rzp1.open();
  };

  const goback = () => {
    if (userCountry?.code === "IN") {
      window.location.href = "/account";
    } else {
      window.location.href = "/";
    }
  };

  const onClickRedeem = (item) => {
    setShowRedeem(true);
    setRedeemData({
      id: item?._id,
      name: item?.name,
      description: item?.description,
      coinAmount: item?.coinAmount,
      img: item?.currency.img.default
    });
  };

  const OnClickRedeem = async () => {
    console.log('redeem_data: ', redeemData)
    let data = { product: redeemData?.id };
    setShowRedeem(false);
    setShowLoader(true);
    let res = await getApplyReward(data);
    console.log('Redeem Reward: ', res);
    setShowLoader(false);

    if (res) {
      if (res.success && res.success === true) {
        fetchProfileData();

        getMyRewardList()

        setActiveReward(1)
        toast.success(constants.success_Redeem_Reward_successfully, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        showError(res.data?.errors?.[0]?.msg);
      }
    } else {
      showError('Failed to Load response data.');
    }
  };

  const onClickInfo = (item) => {
    setShowInfo(true);
    setAvatarData(item);

  };

  const onClickBundlesInfo = (item) => {
    setShowBundlesInfo(true);
    setBundlesData(item);
  };

  const genrateSignature = async (itemKeys, signatureDataArr) => {
    var signatureData = "";

    itemKeys.sort();
    console.log("itemKeys==>", itemKeys);
    console.log("signatureDataArr==>", signatureDataArr);

    itemKeys.forEach((element, index) => {
      signatureData += element + signatureDataArr[element];

    })

    var hash = HmacSHA256(signatureData, "dd332db9b5c7010b7e5e834e5b5f2c9c9fda1145");
    var hashInBase64 = Base64.stringify(hash);
    console.log("hashInBase64==>", hashInBase64);

    return hashInBase64;
    console.log("hashInBase64==>", hashInBase64);
    // if(signatureDataArr.length>0 && signatureDataArr.length===item.length){
    //   let res = await getGenrateSignature(signatureDataArr);
    //   console.log("test==>",res);
    //   if (res) {
    //     return res;
    //   }
    // }

    //return signatureData;
    // return Base64.stringify(sha256(signatureData, "dd332db9b5c7010b7e5e834e5b5f2c9c9fda1145"));
  };

  const OnClickBuyAvatar = async (avatarId) => {

    let data = { avatar: avatarId };
    let res = await getBuyAvatar(data);
    console.log('Buy Avatar: ', res);
    if (res) {
      setShowInfo(false);
      if (res.success === true) {

        let three = { skip: 0, limit: 100, sort: "asc", sortBy: "amount" };
        let res2 = await getAllAvatarList(three);
        if (res2) {
          setAllCoin3(res2?.list);
        }

        toast.success(constants.success_Avatar_buy_successfully, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

      } else {
        toast.error(res.data?.errors?.[0]?.msg, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

      fetchProfileData();
    }
  };

  const onClickBuyItem = (item) => {
    localStorage.setItem('coinStoreId',item._id)
    setBuyItemData(item);
    // if(userProfile.item?.email === undefined || userProfile.item?.email === null ||
    //   userProfile.item?.email === "") {
    if (checkDobNstateConditions(dobNstateScreenNameBuyItem)) {

      setTagShowDobNstate(true);
    } else {
      
      moveToPaymentOptionScreen(item);
    }
  };

  const onClickRedeemItem = () => {
    // if(userProfile.item?.email === undefined || userProfile.item?.email === null ||
    //   userProfile.item?.email === "") {
    if (checkDobNstateConditions(dobNstateScreenNameRedeemItem)) {

      setTagShowDobNstate(true);
    } else {
      OnClickRedeem();
    }
  };

  const submitDobNstateForBuyItem = (tag) => {
    setTagShowDobNstate(tag);
    moveToPaymentOptionScreen(buyItemData);
  };

  const submitDobNstateForRedeemItem = (tag) => {
    setTagShowDobNstate(tag);
    OnClickRedeem();
  };

  const cancelDobNstate = () => {
    setTagShowDobNstate(false);
  };

  const createHowToRedeemPopup = (html_data, index) => {
    return (
      <div className="coin_store_htr_main_div">
        <ToastContainer />
        <div className="coin_store_htr_popup">
          <div className="coin_store_htr_header">
            <div className=""></div>
            <div className="coin_store_htr_header_text">How To Redeem</div>
            <div className="coin_store_htr_cancel_icon">
              <img src={Cancel}
                onClick={() => {
                  let temp = [...tagShowHowToRedeemPopup];
                  temp[index] = false;
                  setTagShowHowToRedeemPopup(temp);
                }} />
            </div>
          </div>

          <div className="coin_store_htr_body">
            <div className="all_sub_screens_div_related_to_ads">
              <div className="coin_store_htr_body_scrollable_component">
                <div className="coin_store_htr_text"
                  dangerouslySetInnerHTML={{ __html: html_data?.redemptionInstructions }}
                ></div>
              </div>
            </div>
            {/* <Advertisement
              screen={
                localStorage.getItem('apk_screens_list') === null ?
                  { code: 'no_results' }
                  :
                  JSON.parse(localStorage.getItem('apk_screens_list')).joinViaInviteCodePopup
              }
              screen_div_class_name='.all_sub_screens_div_related_to_ads'
              screen_type='sub'
            /> */}
          </div>
        </div>
      </div>
    )
  };

  const showError = (error) => {
    toast.error(error, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  // get Catolog 
  //  useEffect(()=>{
  //   virtualItems()
  //  },[])
  const [virtualItem, setVirtualItem] = useState([])
  const [currencyImg, setCurrencyImg] = useState('')
  const virtualItems = async() =>{
    setShowLoader(true)
    let countryObj = JSON.parse(localStorage.getItem('userCountryObj'))
    console.log(countryObj)
    console.log(url.imageUrl + countryObj?.flag?.default)
    setCurrencyImg(countryObj?.flag?.default)
    const query = new URLSearchParams({
      limit: '5',
      offset: '0',
      locale: 'en',
      'additional_fields[]': 'string',
      country: countryObj.code
    }).toString()
    const projectId = '216428' // '44056'
    const url1 = `https://store.xsolla.com/api/v2/project/${projectId}/items/virtual_items/group/1?${query}`
    // const url1=  `https://store.xsolla.com/api/v2/project/${projectId}/items/virtual_items?${query}`
    const res = await fetch(url1,{
      method: 'GET',
      // headers: {
      //   Authorization: 'Bearer <YOUR_JWT_HERE>'
      // }
    })
    const resp = await res.text()
    if(resp)
      setVirtualItem(JSON.parse(resp)?.items)
    console.log(JSON.parse(resp)?.items)
    setShowLoader(false)
  }

  // xsolla order creation 
  async function  orderCreation(item_sku, amount, coin) {
    
    let countryObj = JSON.parse(localStorage.getItem('userCountryObj'))
    let payload = {
      sku: item_sku,
      userCurrency: countryObj.code,
      // amount: parseInt(amount)
      amount:amount

    }
    let data = await createXsollaTransaction(payload)



    // const projectId =  '44056' // '216428' 
    // const itemSku = item_sku;
    // const merchantid = 400606;
    // const api_key = 'ea0f339acbd3b5c8b07923cd02f079612826ce4d'
    // const Authorization = Buffer.from(`${merchantid}:${api_key}`).toString('base64')
    // console.log(Buffer.from(`${merchantid}:${api_key}`).toString('base64'))
    // const Authorization = localStorage.getItem('gamerjiToken')
    // console.log(Authorization)
    
    // const resp = await fetch(
    //   `https://store.xsolla.com/api/v2/project/${projectId}/payment/item/${itemSku}`,
    //   {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //       // 'Authorization': 'Bearer '+ Authorization
    //     },
    //     body: JSON.stringify({
    //       sandbox: true, // for testing
    //       quantity: quantity,
    //       country: countryObj.code,
    //       settings: {
    //         ui: {
    //           size: 'large',
    //           theme: 'default',
    //           version: 'desktop',
    //           desktop: {
    //             header: {
    //               is_visible: true,
    //               visible_logo: true,
    //               visible_name: true,
    //               visible_purchase: true,
    //               type: 'normal',
    //               close_button: false
    //             }
    //           },
    //           mobile: {
    //             footer: {is_visible: true},
    //             header: {close_button: false}
    //           }
    //         },
    //         return_url: 'http://localhost:3006/xsolla-status',
    //         redirect_policy: {
    //           redirect_conditions: 'successful_or_canceled',
    //           autoredirect_from_status_page: true
    //         }
    //       },
        
    //       custom_parameters: {
    //         character_id: 'ingameUsername'
    //       }
    //     })
    //   }
    // );
  
    // const data = await resp.json();
    console.log(data);
    
    if(data?.success){
      let transactionUniqueID = data?.data?.transactionUniqueID
    let body = {
      transactionUniqueID:transactionUniqueID,
      coin: parseInt(coin),
      sku: item_sku,
      // amount:parseInt(amount)
      amount:amount

    }
    localStorage.setItem('body',JSON.stringify(body))
    let url = `https://sandbox-secure.xsolla.com/paystation4/?token=${data?.data?.token}`
    console.log(url)
    window.location.href = url
  }
  }

  // xsolla payment creation

  return (
    <Fragment>
      {showLoader && <Loader />}
      <div
        className="reward_main reward_first_main custom_scroll mCustomScrollbar _mCS_1"
        id={showRedeem === true || showBundlesInfo === true || showInfo === true ? "popup_show_redeem" : ""}
      >
        <ToastContainer />
        {
          tagShowDobNstate === true ?
            <DobNStates
              submitDobNstate={showRedeem === true ? submitDobNstateForRedeemItem :
                submitDobNstateForBuyItem}
              cancelDobNstate={cancelDobNstate}
              screen={showRedeem === true ? dobNstateScreenNameRedeemItem : dobNstateScreenNameBuyItem}
            /> : ''
        }
        <div className="reward_title">
          <div className="row">
            <div className="back-btn col-2 text-center"></div>
            <h3
              className="col-8 text-center"
              style={{ marginBottom: "20px", fontSize: "20px", fontWeight: '1000', }}
            >
              REWARD STORE
            </h3>
            <div className="wallet-btn col-2">
              {
                helperFunc.getUserCountry()?.code === "IN" ?
                  <a onClick={() => goback()} style={{ cursor: 'pointer', }}>
                    <img src={AccountIcon} alt="" />
                  </a>
                  : ""
              }
            </div>
          </div>

          <div className="row rewards_tab">
            <div
              className={`col-6 d-flex justify-content-center p-0 ${activeTab === 0 ? "active" : ""}`}
              onClick={() => setActiveTab(0)}
            >
              <p style={{ width: '100%', fontWeight: '600', }}>Rewards</p>
            </div>
            <div
              className={`col-6 d-flex justify-content-center p-0 ${activeTab === 1 ? "active" : ""}`}
              onClick={() => setActiveTab(1)}
            >
              <p style={{ width: '100%', fontWeight: '600', }}>Coin Store</p>
            </div>

          </div>

          <div className="row rewards_coin">
            <div className="mt-4 d-flex justify-content-center align-items-center"
              style={{
                padding: '10px 40px', width: '190px', borderTopRightRadius: '50px', borderBottomRightRadius: "50px", border: "1px solid #fff",
              }}>
              <div className="text-white d-flex align-items-center" style={{ fontSize: '22px', fontWeight: '600' }}>
                <img src={Diamond} className="coin_img_medium" style={{ marginRight: '10px' }} />{" "}
                {localStorage.getItem("currentDiamond")}
              </div>
            </div>
            <div className="mt-4 text-dark d-flex justify-content-center "
              style={{ padding: '10px 40px', width: '190px', margin: "auto", marginRight: "-5px", borderTopLeftRadius: '50px', borderBottomLeftRadius: "50px", border: "1px solid #fff" }}>
              <div className="text-white d-flex align-items-center" style={{ fontSize: '22px', fontWeight: '600' }}>
                <img src={Coin} className="coin_img_medium" style={{ marginRight: '10px' }} />{" "}
                {localStorage.getItem("currentCoin")}
              </div>
            </div>
          </div>

        </div>
        <div className="reward_second_main" style={{ paddingBottom: '30px' }}>
          <div className="all_sub_screens_div_related_to_ads" style={{ overflowY: 'inherit', overflowX: 'inherit', }}>
            {activeTab === 1 ? (
              <Fragment>
                <div
                  className="list-group bg-primary main-tab  coin_reward_tabs"
                  id="list-tab"
                  role="tablist"
                >
                  <a
                    className={`list-group-item list-group-item-action ${activeTab === 1 && activeCoinStoreTab === 0 ? "active" : "text-dark"}`}
                    id="list-home-list"
                    data-toggle="list"
                    // href="#list-1" 
                    onClick={() => setActiveCoinStoreTab(0)}
                  >
                    Packs
                  </a>
                  <a
                    className={`list-group-item list-group-item-action ${activeTab === 1 && activeCoinStoreTab === 1 ? "active" : "text-dark"}`}
                    id="list-profile-list"
                    data-toggle="list"
                    // href="#list-2" 
                    onClick={() => setActiveCoinStoreTab(1)}
                  >
                    Bundles
                  </a>
                  <a
                    className={`list-group-item list-group-item-action ${activeTab === 1 && activeCoinStoreTab === 2 ? "active" : "text-dark"}`}
                    id="list-profile-list"
                    data-toggle="list"
                    // href="#list-3" 
                    onClick={() => setActiveCoinStoreTab(2)}
                  >
                    Avatars
                  </a>
                </div>
                <div className="tab-content h-100" id="nav-tabContent">
                { console.log("*****************",virtualItem[0])}
                  {activeTab === 1 && activeCoinStoreTab === 0 && (
                    <div className="tab-pane h-100 active mx-4 d-flex flex-column justify-content-between">
                      <div className="row" style={{ marginTop: "10px" }}>
                        


                        {/* get Virtual Item */}
                        { console.log("*****************",virtualItem[0])}
                        {virtualItem?.length > 0  ? (
                          virtualItem?.map((item, index) => {
                            return (
                              <div className="col-4 packs_main text-center" style={{ padding: '5px' }} key={`allCoin1_${index}`}>
                                <div className="packs_first">
                                  <img src={PurchasePackIcon} />
                                  <p
                                    style={{ fontSize: "20px", fontWeight: "bold" }}
                                  >
                                    {item?.description}
                                    {/* 200 */}
                                  </p>
                                  <p>Coins</p>
                                </div>
                                <div
                                  className="packs_second"
                                  onClick={() => orderCreation(item?.sku, item?.price?.amount, item?.description)}
                                >
                                  Buy {item?.currency?.symbol} {item?.price?.amount}
                                </div>
                              </div>
                            );
                          })
                        ) : 
                        allCoin1 !== null ? (
                          allCoin1?.map((item, index) => {
                            return (
                              <div className="col-4 packs_main text-center" style={{ padding: '5px' }} key={`allCoin1_${index}`}>
                                <div className="packs_first">
                                  <img src={PurchasePackIcon} />
                                  <p
                                    style={{ fontSize: "20px", fontWeight: "bold" }}
                                  >
                                    {item?.coins}
                                  </p>
                                  <p>Coins</p>
                                </div>
                                <div
                                  className="packs_second"
                                  onClick={() => onClickBuyItem(item)}
                                >
                                  Buy {item?.currency?.symbol} {item?.amount}
                                </div>
                              </div>
                            );
                          })
                        )
                        :
                        (
                          <Loader />
                        )}

                        {/* End Virtual item */}
                        {
                          console.log("cfOrderData==>", cfOrderData)
                        }
                       
                      </div>
                      <div className="d-flex w-100 justify-content-between align-items-center bg-primary"
                        style={{ padding: '10px 20px', borderRadius: '50px', cursor: 'pointer', }}
                        onClick={() => {
                          localStorage.setItem('myRecentTransactions', 'coin');
                          window.location.href = routehelp.myRecentTransactions;
                        }}
                      >
                        <img src={myRecentTransactionImage} />
                        <p className="m-0" style={{ fontSize: '18px', fontWeight: '600', }}>My Recent Transaction</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="21.334" height="18.014" viewBox="0 0 21.334 18.014">
                          <g id="ico" transform="translate(21.334 18.013) rotate(-180)">
                            <path id="union" d="M8.321,17.721.479,9.879A1,1,0,0,1,.125,8.541,1.005,1.005,0,0,1,.411,8.2L8.321.293A1,1,0,0,1,9.735,1.706L3.417,8.025H20.334a1,1,0,1,1,0,2H3.454l6.281,6.281a1,1,0,0,1-1.414,1.415Z" transform="translate(0 0)" fill="#000" />
                          </g>
                        </svg>
                      </div>
                    </div>
                  )}

                  {activeTab === 1 && activeCoinStoreTab === 1 && (
                    <div className="tab-pane active mx-4">
                      <div className="row">
                        {allCoin2 !== null ? (
                          allCoin2?.map((item, index) => {
                            return (
                              <div className="col-6 bundles_main text-center my-3" key={`allCoin2_${index}`}>
                                <div className="bg-design"></div>
                                <div className="bundles_first">
                                  <img
                                    src={url.imageUrl + item?.avatar?.img?.default}
                                  />
                                  <p
                                    style={{
                                      fontSize: "20px",
                                      fontWeight: "bold",
                                      marginBottom: "0px",
                                      lineHeight: '2ch',
                                    }}
                                  >
                                    <img src={Coin} className="coin_img_avatar" />
                                    <span
                                      style={{ position: "relative", top: "2px" }}
                                    >
                                      {" "}{item?.coins}{" "}
                                    </span>
                                    <small>Coins</small>
                                  </p>
                                  <small style={{ lineHeight: '2ch' }}>+</small>
                                  <p style={{ lineHeight: '2ch' }}>{item?.avatar?.name}</p>
                                </div>
                               { console.log(item)}
                                {
                                  item.isPurchase ? 
                                    <div className="bundles_second">
                                      Bought
                                    </div>
                                      :
                                    <div className="bundles_second" onClick={() => onClickBundlesInfo(item)}>
                                      Buy {item?.currency?.symbol}
                                      {item?.amount}
                                    </div>  
                                }
                              </div>
                            );
                          })
                        ) : (
                          <Loader />
                        )}
                      </div>
                    </div>)
                  }

                  {activeTab === 1 && activeCoinStoreTab === 2 && (
                    <div className="tab-pane active mx-4">
                      <div className="avatars_main">
                        {allCoin3 !== null ? (
                          allCoin3?.map((item, index) => {
                            return (
                              <div className="avatar_cat_main row my-3" key={`allCoin3_${index}`}>
                                <img
                                  src={url.imageUrl + item?.icon?.default}
                                  className="avtara_cat_img"
                                />{" "}
                                <h4 className="avatar_cat_name">{item?.name}</h4>
                                <OwlCarousel
                                  className="main-slider owl-carousel owl-theme"
                                  autoWidth={false}
                                  items={2}
                                  loop={false}
                                  margin={0}
                                  stagePadding={50}
                                  autoplay={false}
                                  dots={false}
                                  nav={true}

                                >
                                  {item?.avatars?.length &&
                                    item?.avatars?.map((item1, index1) => {
                                      return (
                                        <div
                                          className="item"
                                          style={{ height: "100%" }}
                                          key={`item_avatars_${index}_${index1}`}
                                        >
                                          <div className="bg-design-avatars"></div>
                                          <div className="bundles_first">
                                            <img
                                              src={
                                                url.imageUrl + item1?.img?.default
                                              }
                                            />
                                            <p className="text-center my-2">
                                              <b>{item1?.name}</b>
                                            </p>
                                          </div>
                                          {!item1.isPurchase ?

                                            <div
                                              className="bundles_second"
                                              style={{ textAlign: "center" }}
                                            >
                                              <p
                                                style={{
                                                  display: "-webkit-inline-box",
                                                  color: "#fff",
                                                }}
                                                onClick={() => onClickInfo(item1)}
                                              >
                                                <span>Buy</span>
                                                <img
                                                  src={Coin}
                                                  className="coin_img mx-1"
                                                />
                                                <span>{item1?.coinAmount}</span>
                                              </p>
                                            </div>
                                            :
                                            <div
                                              className="bundles_second"
                                              style={{ textAlign: "center" }}
                                            >
                                              <p
                                                style={{
                                                  display: "-webkit-inline-box",
                                                  color: "#fff",
                                                }}
                                              >
                                                <span>Bought</span>
                                                {/* <img
                                                  src={Coin}
                                                  className="coin_img mx-1"
                                                />
                                                <span>{item1?.coinAmount}</span> */}
                                              </p>
                                            </div>
                                          }
                                        </div>
                                      );
                                    })}
                                </OwlCarousel>
                              </div>
                            );
                          })
                        ) : (
                          <Loader />
                        )}
                      </div>

                    </div>)
                  }
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <div
                  className="list-group bg-primary main-tab coin_reward_tabs"
                  id="list-tab"
                  role="tablist"
                >
                  <a
                    className={`list-group-item list-group-item-action ${activeTab === 0 && activeReward === 0 ? "active" : "text-dark"
                      }`}
                    id="list-home-list"
                    data-toggle="rewardlist"
                    // href="#rewardlist-1"
                    onClick={() => setActiveReward(0)}
                  >
                    Rewards
                  </a>
                  <a
                    className={`list-group-item list-group-item-action ${activeTab === 0 && activeReward === 1 ? "active" : "text-dark"
                      }`}
                    id="list-profile-list"
                    data-toggle="list"
                    // href="#rewardlist-2"
                    onClick={() => setActiveReward(1)}
                  >
                    My Rewards
                  </a>
                </div>
                <div className="tab-content" id="nav-tabContent">
                  {activeTab === 0 && activeReward === 0 && (
                    <div className={`active mx-4`}>
                      <div className="allReward1_main">
                        {allReward1 !== null ? (
                          allReward1?.length > 0 ?
                            allReward1?.map((item, index) => {
                              return (
                                <div className="reward_cat_main row my-3" key={`allReward1_${index}`}>
                                  <img
                                    src={url.imageUrl + item?.img?.default}
                                    className="avtara_cat_img"
                                    style={{ position: "relative", top: "-5px" }}
                                  />{" "}
                                  <h4 className="reward_cat_name">
                                    <span>{item?.name}</span>
                                  </h4>
                                  <OwlCarousel
                                    className="main-slider owl-carousel owl-theme"
                                    autoWidth={true}
                                    items={2}
                                    loop={false}
                                    margin={15}
                                    stagePadding={50}
                                    autoplay={false}
                                    dots={false}
                                    nav={true}

                                  >
                                    {item?.products?.length &&
                                      item?.products?.map((item1, index1) => {
                                        return (
                                          <div
                                            className="item"
                                            style={{
                                              width: "170px",
                                              height: "170px",
                                              paddingBottom: "5px",
                                              border: "1px solid #000",
                                              borderRadius: '25px',
                                            }}
                                            key={`item_products_${index}_${index1}`}
                                          >
                                            <div className="reward1_first">
                                              <img
                                                style={{
                                                  width: '100%',
                                                  height: '48%',
                                                  objectFit: 'cover',
                                                }}
                                                src={
                                                  url.imageUrl + item1?.img?.default

                                                }
                                              />
                                              <div className="w-100 d-flex">
                                                <p className="" style={{ marginTop: 2, marginLeft: 5 }}>
                                                  {item1?.name?.length > 18 ?
                                                    <marquee style={{ marginBottom: -5 }}><b>{item1?.name}</b></marquee>

                                                    :
                                                    <b>{item1?.name}</b>
                                                  }
                                                </p>
                                              </div>
                                              <div className="w-100 d-flex" style={{ marginTop: 0 }}>
                                                <p className="col-6" style={{ marginLeft: 0 }}>
                                                  {item1.isVoucherAmountShow ?
                                                    <b>{item1.country.currency.symbol}{' '}{item1.rewardVoucherAmount}</b>
                                                    :
                                                    ""
                                                  }
                                                </p>
                                                <p className="col-6 " style={{ marginRight: 5, marginLeft: -10 }}>

                                                  <b className="d-flex align-items-center"><img src={url.imageUrl + item1.currency.img.default} style={{ height: 10, width: 15, marginRight: 5 }} />{"  "}{item1.coinAmount}</b>

                                                </p>
                                              </div>
                                              <div className="p-0">
                                                <p
                                                  className="redeem_click text-white"
                                                  onClick={() =>
                                                    onClickRedeem(item1)
                                                  }
                                                >
                                                  Redeem
                                                </p>
                                              </div>
                                            </div>
                                            {/* <div className="reward1_gradient"></div> */}
                                            {/* <div className="reward1_second">
                                            <div className="d-flex flex-column">
                                              <div className="w-100 d-flex">
                                                <p className="">
                                                  <b>{item1?.name}</b>
                                                </p>
                                              </div>

                                              <div className="row w-100 d-flex m-0"
                                                style={{ backgroundColor: 'var(--dark)', }}
                                              >
                                                <div className="col-6 p-0 d-flex align-items-center">
                                                  <p
                                                    className="mx-2 pl-1 text-white"
                                                    style={{
                                                      display: "-webkit-inline-box",
                                                      lineHeight: "15px",
                                                    }}
                                                  >
                                                    <img
                                                      src={
                                                        url.imageUrl + item1.currency?.img?.default
                                                      }
                                                      className="mr-1"
                                                      style={{
                                                        width: "15px",
                                                        height: "15px",
                                                      }}
                                                    />
                                                    {item1?.coinAmount}
                                                  </p>
                                                </div>
                                                <div className="col-6 p-0">
                                                  <p
                                                    className="redeem_click text-white"
                                                    onClick={() =>
                                                      onClickRedeem(item1)
                                                    }
                                                  >
                                                    Redeem
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div> */}
                                          </div>
                                        );
                                      })}
                                  </OwlCarousel>
                                </div>
                              );
                            })
                            :  <div className="d-flex justify-content-centerr" style={{ position: "fixed", top: "50%", left: "26%", transform: "translate(-50%, -50%)" }}>
                            <div
                              style={{
                                padding: "20px",
                                // color: "#F92C2C",
                                fontSize: "18px",
                                fontWeight: "1000",
                              }}
                            >
                              No records found
                            </div>
                          </div>
                        ) : (
                          <Loader />
                        )}
                      </div>
                    </div>
                  )}

                  {activeTab === 0 && activeReward === 1 && (
                    <div className={`active mx-4"`} id="rewardlist-2">
                      <div className="allReward2_main">
                        {allReward2 !== null ? (
                          allReward2.length > 0 ?
                            allReward2?.map((item, index) => {
                              if (item && 'reward' in item) {
                                return (
                                  <div className="my_rewads_main bg-dark text-light" key={`allReward2_${index}`}>
                                    {tagShowHowToRedeemPopup[index] && createHowToRedeemPopup(item?.rewardVoucher, index)}
                                    <div
                                      className="row m-0"
                                      style={{ alignItems: "center", paddingBottom: '5px', }}
                                    >
                                      <div className="col-2 p-0 d-flex justify-content-center">
                                        <img
                                          src={
                                            url.imageUrl + item?.reward?.img?.default
                                          }
                                          className="coupe_img"
                                        />
                                      </div>
                                      <div className="col-10 pl-2 pr-0 mb-auto">
                                        <div className="row m-0 align-items-center justify-content-between">
                                          <h4 className="mb-0" style={{ fontWeight: '600', }}>
                                            <marquee>{item?.reward?.name}</marquee>
                                          </h4>
                                          <h5 className="mb-0 text-center my_rewards_pending_failed_success_status"
                                            style={{
                                              fontWeight: '600',
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'center',
                                            }}
                                          >
                                            {item?.deliveryStatus?.toLowerCase() === "delivered"
                                              && <img
                                                src={SuccessPayment}
                                                className="coin_img_myreward mr-1"
                                              />}
                                            {item?.deliveryStatus?.toLowerCase() === "pending"
                                              && <img
                                                src={PendingPayment}
                                                className="coin_img_myreward mr-1"
                                              />}
                                            {item?.deliveryStatus?.toLowerCase() === "PENDING"
                                              && <img
                                                src={PendingPayment}
                                                className="coin_img_myreward mr-1"
                                              />}
                                            {item?.deliveryStatus?.toLowerCase() == "failed"
                                              && <img
                                                src={FailPayment}
                                                className="coin_img_myreward mr-1"
                                              />}
                                            {" "}
                                            {item?.deliveryStatus?.toLowerCase() === "delivered"
                                              && "Success"}
                                            {item?.deliveryStatus?.toLowerCase() === "pending"
                                              && "Pending"}
                                            {item?.deliveryStatus?.toLowerCase() === "PENDING"
                                              && "Pending"}
                                            {item?.deliveryStatus?.toLowerCase() === "failed"
                                              && "Failed"}
                                          </h5>
                                        </div>
                                        <div className="row m-0 align-items-center">
                                          <div
                                            className=""
                                            style={{ borderRight: "1px solid #fff" }}
                                          >
                                            <h5 className="mb-0 text-center d-flex align-items-center"
                                              style={{ fontWeight: '600', paddingRight: '20px', }}
                                            >
                                              <img src={url.imageUrl + item?.currency.img.default}
                                                className="coin_img_myreward mr-2"
                                              />{" "}
                                              {item?.reward?.coinAmount}
                                            </h5>
                                          </div>
                                          <small style={{ paddingLeft: '20px', }}>
                                            {getConvertionDateTime(
                                              item?.createdAt
                                            )}
                                          </small>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row m-0 bg-primary text-dark reward_coin_second align-items-center"
                                      style={{ borderRadius: '10px', fontWeight: '700' }}
                                    >
                                      <div className="col-10 p-0">
                                        <div className="d-flex align-items-center p-1">
                                          <marquee>{item.redeemVoucherCode}</marquee>
                                        </div>
                                      </div>
                                      <div className="col-2 p-0 bg-secondary text-white"
                                        style={{ borderRadius: '0 10px 10px 0', cursor: 'pointer', }}
                                        onClick={() => {
                                          navigator.clipboard.writeText(
                                            item?.redeemVoucherCode
                                          );
                                          toast.info(t('info_Copied'), {
                                            position: "top-center",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                          });
                                        }}
                                      >
                                        <div className="d-flex align-items-center p-1 justify-content-center">
                                          COPY
                                        </div>
                                      </div>
                                    </div>
                                    {
                                      item?.deliveryStatus === "FAILED" ?
                                        <div className="row w-100 align-items-center justify-content-center"
                                          style={{
                                            height: '32px', margin: "0 auto", paddingTop: '5px',
                                          }}
                                        ></div>
                                        :
                                        <div className="row w-100 align-items-center justify-content-center"
                                          style={{
                                            margin: "0 auto", paddingTop: '5px',
                                            fontSize: '18px', fontWeight: '600',
                                            cursor: 'pointer',
                                          }}
                                          onClick={() => {
                                            let temp = [...tagShowHowToRedeemPopup];
                                            temp[index] = true;
                                            setTagShowHowToRedeemPopup(temp)
                                          }}
                                        >
                                          How To Redeem
                                        </div>
                                    }
                                  </div>
                                );
                              }

                            })
                            : <div className="d-flex justify-content-centerr" style={{ position: "fixed", top: "50%", left: "26%", transform: "translate(-50%, -50%)" }}>
                            <div
                              style={{
                                padding: "20px",
                                // color: "#F92C2C",
                                fontSize: "18px",
                                fontWeight: "1000",
                              }}
                            >
                              No records found
                            </div>
                          </div>
                        ) : (
                          <Loader />
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </Fragment>
            )}
          </div>
          {
             !JSON.parse(localStorage.getItem("premiumUser")) && 
          
          <Advertisement
            screen={
              localStorage.getItem('apk_screens_list') === null ?
                { code: 'no_results' }
                :
                JSON.parse(localStorage.getItem('apk_screens_list')).coinStore
            }
            screen_div_class_name='.all_main_screens_div_related_to_ads'
            screen_type='main'
          />
}
        </div>

        {showRedeem === true && (

          <div
            className="custom-popup home-featured show redeem_popup bg-light"
            id="contact_popup"
          >
            <div className="popup-head bg-light" style={{ margin: '-20px -20px 0px', }}>
              <h3>{redeemData?.name}</h3>
              <button className="close-popup" onClick={() => setShowRedeem(false)}>
                <img src={Close} alt="" />
              </button>
            </div>
            <div className="popup-body text-center mb-3">
              <div className="all_sub_screens_div_related_to_ads">
                <p className="mb-2">
                  <img src={url.imageUrl + redeemData?.img} className="coin_img" />{" "}
                  <b>{redeemData?.coinAmount}</b>
                </p>
                <p className="mb-2">{redeemData?.description}</p>
                <button
                  className="btn btn-primary"
                  style={{
                    width: 'fit-content', textTransform: "capitalize",
                    margin: '0 auto 50px auto', whiteSpace: 'nowrap',
                  }}
                  onClick={onClickRedeemItem}
                >
                  <img src={url.imageUrl + redeemData?.img} className="coin_img" /> {redeemData?.coinAmount}{" "}
                  Redeem
                </button>
              </div>
              {
                 !JSON.parse(localStorage.getItem("premiumUser")) && 
              
              <Advertisement
                screen={
                  localStorage.getItem('apk_screens_list') === null ?
                    { code: 'no_results' }
                    :
                    JSON.parse(localStorage.getItem('apk_screens_list')).redeemRewardsPopup
                }
                screen_div_class_name='.all_sub_screens_div_related_to_ads'
                screen_type='sub'
              />
}
            </div>
          </div>
        )}

        {showInfo === true && (
          <div
            className="custom-popup home-featured show redeem_popup bg-primary"
            id="contact_popup1"
          >
            <div className="popup-head bg-primary">

              <button className="close-popup" onClick={() => setShowInfo(false)}>
                <img src={Close} alt="" />
              </button>
            </div>
            <div className="popup-body text-center mb-3">
              <div className="bundles_first">
                <img
                  src={
                    url.imageUrl + avatarData?.img?.default
                  }
                  style={{ height: "250px" }}

                />
                <p className="text-center my-2 avatar-title-box">
                  <b>{avatarData?.name}</b>
                </p>
              </div>
              <div
                className="bundles_second_pop"
                style={{ textAlign: "center" }}
              >
                <p
                  style={{
                    display: "-webkit-inline-box",
                    color: "#fff",
                  }}
                  onClick={() => OnClickBuyAvatar(avatarData._id)}
                >
                  <img
                    src={Coin}
                    className="coin_img mx-1"
                  />

                  <span>{avatarData?.coinAmount}</span>
                </p>
              </div>
            </div>
          </div>
        )}

        {showBundlesInfo === true && (
          <div
            className="custom-popup home-featured show redeem_popup bg-primary"
            id="contact_popup1"
          >
            <div className="popup-head bg-primary">

              <button className="close-popup" onClick={() => setShowBundlesInfo(false)}>
                <img src={Close} alt="" />
              </button>
            </div>
            <div className="popup-body text-center mb-3">
              <div className="bundles_first">
                <img
                  src={
                    url.imageUrl + bundlesData?.avatar?.img?.default
                  }
                  style={{ height: "250px" }}

                />
                <p className="text-center my-2 bundle-title-box">
                  <p><b className="bundle-title-p">{bundlesData?.name}</b> </p>
                  <b>
                    <img
                      src={Coin}
                      className="coin_img mx-1"
                      style={{
                        width: "20px"
                      }}
                    /> {bundlesData?.coins} Coins + {bundlesData?.avatar?.name}</b>
                </p>
              </div>
              <div
                className="bundles_second_pop"
                style={{ textAlign: "center" }}
              >
                <p
                  style={{
                    display: "-webkit-inline-box",
                    color: "#fff",
                  }}
                  onClick={() => onClickBuyItem(bundlesData)} >

                  {bundlesData?.currency?.symbol}
                  <span>{bundlesData?.amount}</span>
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  )
}

export default XsollaPayment