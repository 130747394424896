import React, { Fragment, useState, useEffect } from 'react';
import Previous from "../assets/images/previous.svg";
import Wallet from "../assets/images/wallet.svg";
import MoneyIcon2 from "../assets/images/money-icon-2.svg";
import Coin from "../assets/images/coin.png";
import Pending from "../assets/images/pending-payment.png";
import Fail from "../assets/images/fail-payment.png";
import Success from "../assets/images/success-payment.png";
import { Link } from 'react-router-dom';
import { loadStripe } from "@stripe/stripe-js";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import CheckoutForm from "../component/CheckoutForm";
import colors from "../constants/colors";
import helperFunc from '../helperFuncs';
import Moment from 'react-moment';
import {
	getClientSecret,
	getCoinStoreDetails,
	createStripeTransactionWeb,
	createStripePaymentIntentWeb,
	getTransactionDetails,
	returnPaymentData
} from "../apis";
import "./pg-payment-status.css"
import { constants } from '../constants/constants';

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
//const stripe = require('stripe')('sk_test_51KezD8SB4g2oK7dcab4nYHmaAPcYpqQaBTjUjOHtpbTWDaRkGeXL3jV7wEbA7zYusiFIP8nhaDtTIaYh6DiyebbX00kJvnt6oi');
var stripeAPIKey = helperFunc.getStripeAPIKey()

const stripePromise = loadStripe(stripeAPIKey);
//const stripePromise = loadStripe("pk_test_51KezD8SB4g2oK7dcsmm8TRlmVJFipgCPClcZnnO2xfywtgLG0UKyxEQ99qSO0fPXDWVOO4zQVlifn5uvSwZoldYE00PqEStE5G");


function PGPaymentStatusWeb(props) {
	//	const stripe = useStripe();
	const [clientSecret, setClientSecret] = useState("");
	const [paymentStatus, setPaymentStatus] = useState("");
	const [transaction, setTransaction] = useState("");
	const [coinStore, setCoinStore] = useState("");
	const [coinStoreObjID, setCoinStoreID] = useState("");
	const [platform, setPlatform] = useState("");
	// useEffect(() => {
	// 	// const clientSecretFromURL = new URLSearchParams(window.location.search).get(
	// 	// 	"payment_intent_client_secret"
	// 	// 	  );
	// 	// 	  console.log("payment_intent_client_secret== ==>",clientSecretFromURL)
	// 	// 	  if(clientSecretFromURL){
	// 	// 		//createStripeTransactionWeb(clientSecretFromURL)
	// 	// 	  }

	// }, []);
 
	//   const fetchClientSecretData = async () => {
	// 	var paymentStatusURL =
	//     window.location.href.split("?")[0];
	// 	var coinStoreId =
	//     paymentStatusURL.split("/")[
	// 		paymentStatusURL.split("/").length - 1
	//     ];
	// 	let res = await getClientSecret({coinStore:coinStoreId});
	// 	if (res) {
	// 		setClientSecret(res?.item?.clientSecret);

	// 	}
	//   };

	//   const onSubmitPayment = async () => {
	// 	// var coinStoreId =
	//     // window.location.href.split("/")[
	//     //   window.location.href.split("/").length - 1
	//     // ];
	// 	// setCoinStoreID(coinStoreId);
	// 	// let res = await createStripePaymentIntentWeb({coinStore:coinStoreId});
	// 	// if (res) {
	// 	// 	//setCoinStore(res);
	// 	// 	console.log("res?.item==>",res);
	// 	// 	// const cardDetails1 = {
	//     //     //     number: '4242424242424242',
	//     //     //     expMonth: 10,
	//     //     //     expYear: 23,
	//     //     //     cvc: '888',
	//     //     // }
	// 	// 	// stripe.confirmCardPayment(
	// 	// 	// 	res?.item?.clientSecret,
	// 	// 	// 	{
	// 	// 	// 	  payment_method: {card: cardDetails1},
	// 	// 	// 	}
	// 	// 	//   ).then(function(result) {
	// 	// 	// 	if (result.error) {
	// 	// 	// 	  // Display error.message in your UI.
	// 	// 	// 	} else {
	// 	// 	// 	  // The payment has succeeded
	// 	// 	// 	  // Display a success message
	// 	// 	// 	}
	// 	// 	//   });

	// 	// }
	//   };
	const fetchCoinStoreData = async () => {
		// var coinStoreId =
		// window.location.href.split("/")[
		//   window.location.href.split("/").length - 1
		// ];
		var paymentStatusURL =
			window.location.href.split("?")[0];
		var coinStoreId =
			paymentStatusURL.split("/")[
			paymentStatusURL.split("/").length - 1
			];
		console.log("coinStoreId==>", coinStoreId);
		setCoinStoreID(coinStoreId);
		let res = await getCoinStoreDetails(coinStoreId);
		if (res) {
			setCoinStore(res);
			console.log("res?.item==>", res);
			const cf_id = new URLSearchParams(window.location.search).get(
				"cf_id"
			);
			const cf_token = new URLSearchParams(window.location.search).get(
				"cf_token"
			);

			const clientSecretFromURL = new URLSearchParams(window.location.search).get(
				"payment_intent"
			);
			const paymentStatusFromURL = new URLSearchParams(window.location.search).get(
				"redirect_status"
			);
			var countryData = JSON.parse(localStorage.getItem(constants.profileBasics)).country;
			// console.log("cf_id == ==>", cf_id)
			// console.log("cf_token== ==>", cf_token)
			if (cf_id) {
				var resTrans = await getTransactionDetails({ transactionUniqueID: cf_id });

				await returnPaymentData({
					"country": countryData._id,
					"orderId": resTrans.item.transactionUniqueID,
					"orderAmount": resTrans.item.amount,
					"paymentMode": resTrans.item.pgData.payment_group,
					"txStatus": resTrans.item.pgData.payment_status,
					"referenceId": resTrans.item.paymentRefID,
					"txTime": resTrans.item.pgData.payment_time,
					"signature": resTrans.item.paymentSignature
				});
				setTransaction(resTrans?.item);
				setPaymentStatus(resTrans?.item?.paymentStatus);
				console.log("resTrans==>", resTrans);
			}

			if(clientSecretFromURL) {
				await createStripeTransaction(clientSecretFromURL, coinStoreId, paymentStatusFromURL);
			}

		}
	};

	const createStripeTransaction = async (clientSecretFromURL, coinStoreId, paymentStatusFromURL) => {
		// var coinStoreId =
		// window.location.href.split("/")[
		//   window.location.href.split("/").length - 1
		// ];
		//	console.log("coinStoreObjID==>res?.item===>",coinStoreId);
		//	console.log("clientSecretFromURL==>res?.item===>",clientSecretFromURL);
		var countryData = JSON.parse(localStorage.getItem(constants.profileBasics)).country;
		let res = await createStripeTransactionWeb({
			coinStore: coinStoreId,
			clientSecret: clientSecretFromURL,
			country: countryData._id
		});
		// console.log(res.success);
		if (res.success) {

			// console.log("createStripeTransactionWeb==>res?.item===>", res?.item);
			await returnPaymentData({
				"country": countryData._id,
				"orderId": res.item.itemTransaction.transactionUniqueID,
				"orderAmount": res.item.itemTransaction.amount,
				"paymentMode": 'Card',
				"txStatus": res.item.itemTransaction.paymentStatus,
				"referenceId": res.item.itemTransaction.paymentRefID,
				"txTime": res.item.itemTransaction.paymentTimestamp
			});
			setPaymentStatus(paymentStatusFromURL);
			setTransaction(res?.item?.itemTransaction);
			
			// return res;
			// setClientSecret(res?.item?.clientSecret);

		} else {
			setPaymentStatus('FAILED');
		}
	};

	const appearance = {
		theme: 'stripe',
	};
	const options = {
		clientSecret,
		appearance,
	}
	useEffect(() => {
		var platformStr = localStorage.getItem('platform');
		setPlatform(platformStr)
		console.log("platform==>",platformStr);
		setTimeout(() => {
			fetchCoinStoreData();
		}, 10000);
		//fetchClientSecretData();
	}, []);

	// const getPopuBackgroundColor = () => {
	// 	switch (paymentStatus) {
	// 		case "succeeded":
	// 			return '#36DE4C';

	// 		case "processing":
	// 			return '#FF9544';

	// 		case "requires_payment_method":
	// 			return '#FF4646';

	// 		default:
	// 			return '#FF4646';

	// 	}

	// }
	const getPopuBackgroundColor = () => {
		switch (paymentStatus) {
			case "SUCCESS":
				return '#36DE4C';

			case "succeeded":
				return '#36DE4C';

			case "PENDING":
				return '#FF9544';

			case "processing":
				return '#FF9544';

			case "FAILED":
				return '#FF4646';

			case "requires_payment_method":
				return '#FF4646';

			default:
				return '#FF4646';

		}

	}
	const onClickBack = () => {
		if(platform && platform==="WebApp"){
			window.location.href = "/coin-store";
		}
		if(platform && platform==="MobileApp"){
			window.ReactNativeWebView.postMessage(JSON.stringify(coinStore))
		}
		//window.location.href = "/coin-store";
	};

	return (
		<div className="pg_ps_main_div">
			<div className="pg_ps_header">
				<div className="pg_ps_back_arrow_component">
					<div className="back-btn">
					<a className="pg_link" onClick={() => onClickBack()}>
                <img src={Previous} alt="" />
              </a>
						 
					</div>
				</div>
				<div className="pg_ps_header_text">
					Payment Status
				</div>
				<div className=""></div>
			</div>

			<div className="pg_ps_body">

				<div className="all_main_screens_div_related_to_ads">
					<div className="pg_ps_body_scrollable_component">

						<div className="pg_ps_account_details">
							{/* <div className=""> */}
							<div><img src={Coin} alt="" className="coin_img_medium" /> {coinStore?.item?.coins}</div>
							<div>{coinStore?.currency?.symbol} {coinStore?.item?.amount}</div>
							{/* </div> */}
						</div>

						<div className='pg_ps_status_component'>
							{(() => {
								switch (paymentStatus) {
									case "SUCCESS":
										return <PaymentStatusItemSuccess coinStore={coinStore} transaction={transaction} platform={platform}  />;

									case "succeeded":
										return <PaymentStatusItemSuccess coinStore={coinStore} transaction={transaction} platform={platform}  />;

									case "PENDING":
										return <PaymentStatusItemPending coinStore={coinStore} transaction={transaction} platform={platform}  />;

									case "processing":
										return <PaymentStatusItemPending coinStore={coinStore} transaction={transaction} platform={platform}  />;

									case "FAILED":
										return <PaymentStatusItemFailed coinStore={coinStore} transaction={transaction} platform={platform} />;

									case "requires_payment_method":
										return <PaymentStatusItemFailed coinStore={coinStore} transaction={transaction} platform={platform} />;

									default:
										return <PaymentStatusItemProcessing coinStore={coinStore} transaction={transaction} platform={platform}  />;
								}
							})()}
						</div>
					</div>
				</div>
				{/* <Advertisement
            screen={
              localStorage.getItem('apk_screens_list') === null ?
                { code: 'no_results' }
                :
                JSON.parse(localStorage.getItem('apk_screens_list')).worldOfEsports
            }
            screen_div_class_name='.all_main_screens_div_related_to_ads'
            screen_type='main'
          /> */}
			</div>

			{/* <div className="body mCustomScrollbar _mCS_1" style={{ height: "100%", backgroundColor: getPopuBackgroundColor() }}>
				<div className="current_balance_box shadow-secondary bg-secondary">
					<img src={Coin} alt="" />
					{coinStore?.item?.coins}
					<b className="ml-auto"> {coinStore?.currency?.symbol} {coinStore?.item?.amount}</b>
				</div>
				<div className="row  ml-0 mr-0">
					<div className="col-md-12">
						{(() => {
							switch (paymentStatus) {
								case "SUCCESS":
									return <PaymentStatusItemSuccess coinStore={coinStore} transaction={transaction}  platform={platform} />;

								case "PENDING":
									return <PaymentStatusItemPending coinStore={coinStore} transaction={transaction} platform={platform} />;

								case "FAILED":
									return <PaymentStatusItemFailed coinStore={coinStore} transaction={transaction} platform={platform} />;

								default:
									return <PaymentStatusItemFailed coinStore={coinStore} transaction={transaction} platform={platform} />;

							}
						})()}
					</div>
				</div>
				<div className="bottom_btn">
					{/* <button  onClick={() => onSubmitPayment()}   className="btn btn-dark main-btn">Save Card & Proceed<img src="images/right-arrow.svg" alt="" className="mCS_img_loaded"/></button>
				</div>
			</div> */}
		</div>
	)
}

const PaymentStatusItemPending = (props) => {
	const goCoinStore = () => {
		if(props.platform && props.platform==="WebApp"){
			window.location.href = "/coin-store";
		}
		if(props.platform && props.platform==="MobileApp"){
			window.ReactNativeWebView.postMessage(JSON.stringify(props))
		}
		//
		
	};
	var transaction = props.transaction;
	var coinStore = props.coinStore;
	return (<div style={{ backgroundColor: "#FF9544", textAlign: 'center', height: "100%", borderRadius: 'inherit', }} className="mt-0">




		{/* Status Icon */}
		<img style={{ marginTop: 42, height: 100, width: 100 }} src={Pending} />

		{/* Title */}
		<div style={{ marginTop: 34, color: colors.black, fontSize: 36 }} >Pending</div>

		{/* Amount */}
		{
			<div style={{ marginTop: 25, color: colors.black, fontSize: 30 }} >{coinStore?.currency?.symbol} {coinStore?.item?.amount}</div>
		}

		{/* Date Time */}
		<div style={{ marginTop: 8, color: colors.black, fontSize: 14 }} > <Moment format="DD/MM/YYYY">
			{transaction?.createdAt}
		</Moment>   |  <Moment format="hh:mm A">
				{transaction?.createdAt}
			</Moment></div>

		{/* Transaction ID */}
		{
			<div style={{ marginTop: 16, color: colors.black, fontSize: 14 }} >Transaction ID : #{transaction?.transactionUniqueID}</div>
		}
		{/* <Text style={{ marginTop: 16, color: colors.black, fontSize: 14 }} >Your coin balance will be credited soon! {paymentStatus}</Text> */}
		{/* Payment Status */}
		<div style={{ marginTop: 16, color: colors.black, fontSize: 14 }} >Your coin balance will be credited soon!</div>


		{/* Done */}
		<button className="btn btn-dark main-btn next mt-4 mb-4 ml-2" style={{ width: "96%" }} onClick={() => goCoinStore()}>DONE <img src="/static/media/right-arrow.eb845750.svg" alt="" className="mCS_img_loaded" /></button>


	</div>)
}
const PaymentStatusItemProcessing= (props) => {
	const goCoinStore = () => {
		if(props.platform && props.platform==="WebApp"){
			window.location.href = "/coin-store";
		}
		if(props.platform && props.platform==="MobileApp"){
			window.ReactNativeWebView.postMessage(JSON.stringify(props))
		}
		//
		
	};
	var transaction = props.transaction;
	var coinStore = props.coinStore;
	return (<div style={{ backgroundColor: "#FF9544", textAlign: 'center', height: "100%", borderRadius: 'inherit', }} className="mt-0">




		{/* Status Icon */}
		<img style={{ marginTop: 42, height: 100, width: 100 }} src={Pending} />

		{/* Title */}
		<div style={{ marginTop: 34, color: colors.black, fontSize: 36 }} >Processing...</div>

		{/* Amount */}
		{
			<div style={{ marginTop: 25, color: colors.black, fontSize: 30 }} >{coinStore?.currency?.symbol} {coinStore?.item?.amount}</div>
		}

		{/* Date Time */}
		<div style={{ marginTop: 8, color: colors.black, fontSize: 14 }} > <Moment format="DD/MM/YYYY">
			{transaction?.createdAt}
		</Moment>   |  <Moment format="hh:mm A">
				{transaction?.createdAt}
			</Moment></div>

		<div style={{ marginTop: 16, color: colors.black, fontSize: 14 }} >Your coin balance will be credited soon!</div>

		<div className="btn btn-dark main-btn next mt-4 mb-4 ml-2"></div>


	</div>)
}
const PaymentStatusItemSuccess = (props) => {
	const goCoinStore = () => {
		if(props.platform && props.platform==="WebApp"){
			window.location.href = "/coin-store";
		}
		if(props.platform && props.platform==="MobileApp"){
			window.ReactNativeWebView.postMessage(JSON.stringify(props))
		}
		//window.location.href = "/coin-store";
	};
	var transaction = props.transaction;
	var coinStore = props.coinStore;
	return (<div style={{ backgroundColor: "#36DE4C", textAlign: 'center', height: "100%", borderRadius: 'inherit', }} className="mt-0">




		{/* Status Icon */}
		<img style={{ marginTop: 42, height: 100, width: 100 }} src={Success} />

		{/* Title */}
		<div style={{ marginTop: 34, color: colors.black, fontSize: 36 }} >Success!</div>

		{/* Amount */}
		{
			<div style={{ marginTop: 25, color: colors.black, fontSize: 30 }} >{coinStore?.currency?.symbol} {coinStore?.item?.amount}</div>
		}

		{/* Date Time */}
		<div style={{ marginTop: 8, color: colors.black, fontSize: 14 }} > <Moment format="DD/MM/YYYY">
			{transaction?.createdAt}
		</Moment>   |  <Moment format="hh:mm A">
				{transaction?.createdAt}
			</Moment></div>

		{/* Transaction ID */}
		{
			<div style={{ marginTop: 16, color: colors.black, fontSize: 14 }} >Transaction ID : #{transaction?.transactionUniqueID}</div>
		}
		{/* <Text style={{ marginTop: 16, color: colors.black, fontSize: 14 }} >Your coin balance will be credited soon! {paymentStatus}</Text> */}
		{/* Payment Status */}
		<div style={{ marginTop: 16, color: colors.black, fontSize: 14 }} >Your coin balance will be credited soon!</div>


		{/* Done */}
		<button className="btn btn-dark main-btn next mt-4 mb-4 ml-2" style={{ width: "96%" }} onClick={() => goCoinStore()}>DONE <img src="/static/media/right-arrow.eb845750.svg" alt="" className="mCS_img_loaded" /></button>


	</div>)
}

const PaymentStatusItemFailed = (props) => {
	const goCoinStore = () => {
		if(props.platform && props.platform==="WebApp"){
			window.location.href = "/coin-store";
		}
		if(props.platform && props.platform==="MobileApp"){
			window.ReactNativeWebView.postMessage(JSON.stringify(props))
		}
		//window.location.href = "/coin-store";
	};
	var transaction = props.transaction;
	var coinStore = props.coinStore;
	return (<div style={{ backgroundColor: "#FF4646", textAlign: 'center', height: "100%", borderRadius: 'inherit', }} className="mt-0">




		{/* Status Icon */}
		<img style={{ marginTop: 42, height: 100, width: 100 }} src={Fail} />

		{/* Title */}
		<div style={{ marginTop: 34, color: colors.black, fontSize: 36 }} >Failure!</div>

		{/* Amount */}
		{
			<div style={{ marginTop: 25, color: colors.black, fontSize: 30 }} >{coinStore?.currency?.symbol} {coinStore?.item?.amount}</div>
		}

		{/* Date Time */}
		<div style={{ marginTop: 8, color: colors.black, fontSize: 14 }} > <Moment format="DD/MM/YYYY">
			{transaction?.createdAt}
		</Moment>   |  <Moment format="hh:mm A">
				{transaction?.createdAt}
			</Moment></div>

		{/* Transaction ID */}
		{/* {
			<div style={{ marginTop: 16, color: colors.black, fontSize: 14 }} >Transaction ID : #{transaction?.transactionUniqueID}</div>
		} */}
		{/* <Text style={{ marginTop: 16, color: colors.black, fontSize: 14 }} >Your coin balance will be credited soon! {paymentStatus}</Text> */}
		{/* Payment Status */}
		{/* <div style={{ marginTop: 16, color: colors.black, fontSize: 14 }} >Your coin balance will be credited soon!</div>
		 */}

		{/* Done */}
		<button className="btn btn-dark main-btn next mt-4 mb-4 ml-2" style={{ width: "96%" }} onClick={() => goCoinStore()}>DONE <img src="/static/media/right-arrow.eb845750.svg" alt="" className="mCS_img_loaded" /></button>


	</div>)
}

export default PGPaymentStatusWeb;