import React, { useEffect, useState } from "react";
import Cancel from "../../assets/images/round_not_qualified.png";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Picker from "react-mobile-picker";
import { useTranslation, Trans } from "react-i18next";

function generateHours(today, getDate, h) {
  var hr = [];
  var start = 1;
  // if (today === getDate){
  //   start =  h%12? h%12: 12
  // }

  for (let i = start; i <= 12; i++) {
    hr.push((i < 10 ? "0" : "") + i);
  }
  return hr;
}
function generateMinutes(today, getDate, min) {
  var mn = [];
  var start = 0;
  // console.log(today,getDate,today===getDate)
  // if (today === getDate){
  //   start =  min < 10 ? '0'+min : min
  // }
  for (let i = start; i < 60; i++) {
    mn.push((i < 10 ? "0" : "") + i);
  }
  return mn;
}
function generateAMPM(hr) {
  if (hr >= 12) return ["PM"];
  return ["AM", "PM"];
}
const TimePicker = ({
  cancel,
  getDate,
  getTime,
  todayDate,
  todayTime,
  showDateandTime,
}) => {
  console.log("calld TimePicker", todayTime);

  const [isCancel, setIsCancel] = useState(true);

  const hr = new Date().getHours();
  const min = new Date().getMinutes();
  var newTime = todayTime?.split(" ")
    // console.log(newTime, newTime[0], newTime[1], newTime[2])
    var temp = newTime?.[0]?.split(":")
  const [hours, setHours] = useState(temp[0])
  const [minutes, setMinutes] = useState(temp[1])
  const [amPm, setAmPm] = useState(newTime[1])

  useEffect(() =>{
    console.log("todayDate",todayDate, todayTime)
    var newTime = todayTime?.split(" ")
    // console.log(newTime, newTime[0], newTime[1], newTime[2])
    var temp = newTime[0]?.split(":")
    // if (newTime[1] == "PM" && temp[0]!=12) {
      
    //   temp[0] = temp[0]
      
    //   // newTime[0] = temp[0].toString() + ":" + temp[1]
    // }
    setHours(temp[0])
    setMinutes(temp[1])
    setAmPm(newTime[1])
    console.log(temp[0],temp[1], newTime[1])
    setValueGroups({
      hours:temp[0],
      minutes: temp[1],
      amPm:newTime[1]
    })
    console.log(valueGroups)
  },[])
  console.log(hours, minutes, amPm)
  const [valueGroups, setValueGroups] = useState({
    // hours: hr%12? hr%12: 12 ,
    // minutes: min < 10 ? '0'+min: min ,
    // amPm: hr >= 12? 'PM':'AM'
    hours: hours,
    minutes: minutes,
    amPm: amPm,
  });
  const [timePicked, setTimePicked] = useState(
    // valueGroups.hours + ":" + valueGroups.minutes + " " + valueGroups.amPm
  );
  const [optionGroups, setOptionGroups] = useState({
    hours: generateHours(todayDate, getDate, hr),
    minutes: generateMinutes(todayDate, getDate, min),
    amPm: generateAMPM(todayDate, getDate, hr, min),
  });
  console.log(optionGroups);
  const maskStyle = {
    display: isCancel ? "block" : "none",
  };

  const {t} = useTranslation()


  const isCancelHandler = () => {
    setIsCancel(false);
    showDateandTime();
  };
  const doneHandler = () => {
    console.log("clicked");
    console.log(valueGroups);
    console.log(timePicked)
    if(!timePicked) getTime(valueGroups.hours + ":" + valueGroups.minutes + " " + valueGroups.amPm)
    else getTime(timePicked);
    setIsCancel(false);
  };
  const handleChange = (name, value) => {
    console.log(name, "name value", value);

    setValueGroups({ ...valueGroups, [name]: value.toString() });
    let selectedTime = { ...valueGroups, [name]: value };
    setTimePicked(
      selectedTime.hours + ":" + selectedTime.minutes + " " + selectedTime.amPm
    );
    console.log(timePicked, valueGroups);

    // if(name==="hours" ){

    //   setOptionGroups({
    //     ...optionGroups,
    //     hours:generateHours(todayDate,getDate,hr)

    //   });
    // }
    // else if(name==="minutes" ){

    //   setOptionGroups({
    //     ...optionGroups,
    //     minutes:generateMinutes(todayDate,getDate,hr)

    //   });
    // }
    // else if(name==="amPm" ){

    //   setOptionGroups({
    //     ...optionGroups,
    //     amPm:generateAMPM(hr)

    //   });
    // }
  };

  return (
    // <h2>Hello</h2>
    <>
      <div className="jvic_main_div" style={maskStyle}>
        <div className="jvic_popup">
          <div className="">
            <div className="dobns_select_date flex">
              {" "}
              <div className="ios-datepicker-title">{t('private_contest.select_time')}</div>
              <div className="dobns_cancel_icon">
                <img src={Cancel} alt="Cancel" onClick={isCancelHandler} />
              </div>
            </div>
            <Picker
              optionGroups={optionGroups}
              valueGroups={valueGroups}
              onChange={(name, value) => handleChange(name, value)}
              wheel="natural"
            />
            <div className="doneBtn">
              {" "}
              <button onClick={doneHandler}>{t('crm.done')}</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TimePicker;
