import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router';
import colors from '../constants/colors';
import Moment from 'react-moment';
import Success from "../assets/images/success-payment.png";
import Previous from "../assets/images/previous.svg";
import Pending from "../assets/images/pending-payment.png";
import Fail from "../assets/images/fail-payment.png";
import sha256 from 'crypto-js/sha256';
import axios from 'axios';
import { phonepeStatus, updateSubscriptionTransaction } from '../apis';
import { useSearchParams } from 'react-router-dom';
import Loader from '../component/loader';
export const PhonePeSuccess = ({transactionId, amount, message,symbol}) => {
  console.log(symbol)
  const goCoinStore = () => {
    if(localStorage.getItem('platform') ==="WebApp"){
      if( localStorage.getItem('coinStoreId')){
        localStorage.removeItem('coinStoreId')
        window.location.href = "/coin-store"
      }
      else{
        localStorage.removeItem('subscriptionId')
        window.location.href = "/"
       
      }
    }
    if(localStorage.getItem('platform')==="MobileApp"){
      window.ReactNativeWebView.postMessage(JSON.stringify("transaction successful"))
    }
    
   
  
  };
  return (
    <>
      <div style={{ backgroundColor: "#36DE4C", textAlign: 'center', height: "80%", width: '80%', borderRadius: '40px' }} className="mt-0">


        {/* Status Icon */}
        <img style={{ marginTop: 42, height: 100, width: 100 }} src={Success} />

        {/* Title */}
        <div style={{ marginTop: 34, color: colors.black, fontSize: 36 }} >Success!</div>

        {/* Amount */}
        {
          <div style={{ marginTop: 25, color: colors.black, fontSize: 30 }} >
            {/* {coinStore?.currency?.symbol} {coinStore?.item?.amount} */}
           {symbol} {amount}
          </div>
        }

        {/* Date Time */}
        <div style={{ marginTop: 8, color: colors.black, fontSize: 14 }} > <Moment format="DD/MM/YYYY">
          {/* {transaction?.createdAt} */}
        </Moment>   |  <Moment format="hh:mm A">
            {/* {transaction?.createdAt} */}
          </Moment></div>

        {/* Transaction ID */}
        {
          <div style={{ marginTop: 16, color: colors.black, fontSize: 14 }} >
            Transaction ID :{transactionId}
            {/* #{transaction?.transactionUniqueID} */}
          </div>
        }
        {/* <Text style={{ marginTop: 16, color: colors.black, fontSize: 14 }} >Your coin balance will be credited soon! {paymentStatus}</Text> */}
        {/* Payment Status */}
        <div style={{ marginTop: 16, color: colors.black, fontSize: 14 }} >{message}</div>


        {/* Done */}
        <button className="btn btn-dark main-btn next  mb-4 ml-2" style={{ width: "96%", marginTop: '80%' }} onClick={() => goCoinStore()}>DONE <img src="/static/media/right-arrow.eb845750.svg" alt="" className="mCS_img_loaded" /></button>


      </div>
    </>
  )
}

const PaymentStatusItemPending = ({transactionId, amount, message, symbol}) => {
  const goCoinStore = () => {
    // if (props.platform && props.platform === "WebApp") {
    //   window.location.href = "/coin-store";
    // }
    // if (props.platform && props.platform === "MobileApp") {
    //   window.ReactNativeWebView.postMessage(JSON.stringify(props))
    // }
    //
    // window.location.href = "/coin-store";
    if(localStorage.getItem('platform') ==="WebApp"){
      if( localStorage.getItem('coinStoreId')){
        localStorage.removeItem('coinStoreId')
        window.location.href = "/coin-store"
      }
      else{
        localStorage.removeItem('subscriptionId')
        window.location.href = "/"
       
      }
    }
    if(localStorage.getItem('platform')==="MobileApp"){
      window.ReactNativeWebView.postMessage(JSON.stringify("transaction successful"))
    }

  };
  // var transaction = props.transaction;
  // var coinStore = props.coinStore;
  return (<div style={{ backgroundColor: "#FF9544", textAlign: 'center', height: "80%", width: '80%', borderRadius: '40px' }} className="mt-0">




    {/* Status Icon */}
    <img style={{ marginTop: 42, height: 100, width: 100 }} src={Pending} />

    {/* Title */}
    <div style={{ marginTop: 34, color: colors.black, fontSize: 36 }} >Pending</div>

    {/* Amount */}
    {
      <div style={{ marginTop: 25, color: colors.black, fontSize: 30 }} >
        {/* {coinStore?.currency?.symbol} */}
         {/* {coinStore?.item?.amount} */}
       { symbol} {amount}
        </div>
    }

    {/* Date Time */}
    <div style={{ marginTop: 8, color: colors.black, fontSize: 14 }} > <Moment format="DD/MM/YYYY">
      {/* {transaction?.createdAt} */}
    </Moment>   |  <Moment format="hh:mm A">
        {/* {transaction?.createdAt} */}
      </Moment></div>

    {/* Transaction ID */}
    {
      <div style={{ marginTop: 16, color: colors.black, fontSize: 14 }} >Transaction ID : #{transactionId}</div>
    }
    {/* <Text style={{ marginTop: 16, color: colors.black, fontSize: 14 }} >Your coin balance will be credited soon! {paymentStatus}</Text> */}
    {/* Payment Status */}
    <div style={{ marginTop: 16, color: colors.black, fontSize: 14 }} >{message}</div>


    {/* Done */}
    <button className="btn btn-dark main-btn next  mb-4 ml-2" style={{ width: "96%", marginTop: '80%' }} onClick={() => goCoinStore()}>DONE <img src="/static/media/right-arrow.eb845750.svg" alt="" className="mCS_img_loaded" /></button>


  </div>)
}
const PaymentStatusItemProcessing = ({transactionId, amount, message,symbol}) => {
  const goCoinStore = () => {
    // if (props.platform && props.platform === "WebApp") {
    //   window.location.href = "/coin-store";
    // }
    // if (props.platform && props.platform === "MobileApp") {
    //   window.ReactNativeWebView.postMessage(JSON.stringify(props))
    // }
    //
    if(localStorage.getItem('platform') ==="WebApp"){
      if( localStorage.getItem('coinStoreId')){
        localStorage.removeItem('coinStoreId')
        window.location.href = "/coin-store"
      }
      else{
        localStorage.removeItem('subscriptionId')
        window.location.href = "/"
       
      }
    }
    if(localStorage.getItem('platform')==="MobileApp"){
      window.ReactNativeWebView.postMessage(JSON.stringify("transaction successful"))
    }

  };
  // var transaction = props.transaction;
  // var coinStore = props.coinStore;
  return (<div style={{ backgroundColor: "#FF9544", textAlign: 'center', height: "80%", width: '80%', borderRadius: '40px' }} className="mt-0">




    {/* Status Icon */}
    <img style={{ marginTop: 42, height: 100, width: 100 }} src={Pending} />

    {/* Title */}
    <div style={{ marginTop: 34, color: colors.black, fontSize: 36 }} >Processing...</div>

    {/* Amount */}
    {
      <div style={{ marginTop: 25, color: colors.black, fontSize: 30 }} >
        {/* {coinStore?.currency?.symbol} */}
         {symbol} {amount}
         </div>
    }

    {/* Date Time */}
    <div style={{ marginTop: 8, color: colors.black, fontSize: 14 }} > <Moment format="DD/MM/YYYY">
      {/* {transaction?.createdAt} */}
    </Moment>   |  <Moment format="hh:mm A">
        {/* {transaction?.createdAt} */}
      </Moment></div>

    <div style={{ marginTop: 16, color: colors.black, fontSize: 14 }} >{message}</div>

    <div className="btn btn-dark main-btn next mt-4 mb-4 ml-2"></div>


  </div>)
}
const PaymentStatusItemFailed = ({transactionId, amount, message,symbol}) => {
  const goCoinStore = () => {

    if(localStorage.getItem('platform') ==="WebApp"){
      if( localStorage.getItem('coinStoreId')){
        localStorage.removeItem('coinStoreId')
        window.location.href = "/coin-store"
      }
      else{
        localStorage.removeItem('subscriptionId')
        window.location.href = "/"
       
      }
    }
    if(localStorage.getItem('platform')==="MobileApp"){
      window.ReactNativeWebView.postMessage(JSON.stringify("transaction successful"))
    }
  };
  // var transaction = props.transaction;
  // var coinStore = props.coinStore;
  return (<div style={{ backgroundColor: "#FF4646", textAlign: 'center', height: "80%", width: '80%', borderRadius: '40px' }} className="mt-0">




    {/* Status Icon */}
    <img style={{ marginTop: 42, height: 100, width: 100 }} src={Fail} />

    {/* Title */}
    <div style={{ marginTop: 34, color: colors.black, fontSize: 36 }} >Failure!</div>

    {/* Amount */}
    {
      <div style={{ marginTop: 25, color: colors.black, fontSize: 30 }} >
        {/* {coinStore?.currency?.symbol}  */}
       {symbol} {amount}</div>
    }

    {/* Date Time */}
    <div style={{ marginTop: 8, color: colors.black, fontSize: 14 }} > <Moment format="DD/MM/YYYY">
      {/* {transaction?.createdAt} */}
    </Moment>   |  <Moment format="hh:mm A">
        {/* {transaction?.createdAt} */}
      </Moment></div>

    {/* Transaction ID */}
    {/* {
			<div style={{ marginTop: 16, color: colors.black, fontSize: 14 }} >Transaction ID : #{transaction?.transactionUniqueID}</div>
		} */}
    {/* <Text style={{ marginTop: 16, color: colors.black, fontSize: 14 }} >Your coin balance will be credited soon! {paymentStatus}</Text> */}
    {/* Payment Status */}
    {/* <div style={{ marginTop: 16, color: colors.black, fontSize: 14 }} >Your coin balance will be credited soon!</div>
		 */}

    {/* Done */}
    <div style={{ marginTop: 16, color: colors.black, fontSize: 14 }} >{message}</div>
    <button className="btn btn-dark main-btn next  mb-4 ml-2" style={{ width: "96%", marginTop: '100%' }} onClick={() => goCoinStore()}>DONE <img src="/static/media/right-arrow.eb845750.svg" alt="" className="mCS_img_loaded" /></button>
    


  </div>)
}
const PhonepeStatus = () => {
  const location = useLocation();
const [data, setData] = useState({})
  const [loader, setLoader] = useState(false)
  const [platform, setPlatform] = useState('')
      console.log(window.location.href.split("/")[
        window.location.href.split("/").length - 2
        ])
        
    const [status, setStatus] = useState('PENDING')
  useEffect(async() => {
   
    var platformStr = localStorage.getItem('platform');
		setPlatform(platformStr)
    
     fetchData()
     
  },[])

  const fetchData = async() =>{
    setLoader(true)
    
      const coinStoreId = localStorage.getItem('coinStoreId')
      const subscriptionId =  localStorage.getItem('subscriptionId')

      var transactionId =
      window.location.href.split("/")[
      window.location.href.split("/").length - 1
      ];
      console.log(transactionId,subscriptionId)
      let res = null
      if(subscriptionId){
        let payload =  {
          subscriptionId: subscriptionId,
          country : JSON.parse(localStorage.getItem('userCountryObj'))?._id,
          transactionUniqueID: transactionId
      }
        res = await updateSubscriptionTransaction(payload)
      }
      else{
        let payload =  {
          coinStore: coinStoreId,
          country : JSON.parse(localStorage.getItem('userCountryObj'))?._id,
          transactionUniqueID: transactionId
      }
        res = await phonepeStatus(payload)
      }
      console.log(res.success)
      if(res?.success){
        setData(res)
        console.log(res?.data?.paymentStatus)
        setStatus(res?.data?.paymentStatus)
      }
      else{
        setData(res)
        setStatus("DECLINED")
      }
    //  localStorage.removeItem('coinStoreId')
    //  localStorage.removeItem('subscriptionId')

      setLoader(false)
  }
  const onClickBack = () => {
	
		if (platform === "WebApp") {
      if (localStorage.getItem('coinStoreId')){
        localStorage.removeItem('coinStoreId')
        window.location.href = "/coin-store";
      }
       
      else {
        localStorage.removeItem('subscriptionId')
        window.location.href = "/";
      }
    }


	};
  return (
    // <div style={{color:'white', width:'100%',height:'100%'}}>phonepeStatus</div>
    <>
      
      <div className="pg_main_div">

{
  platform == "WebApp" ? 
  <div className="pg_header">
          <div className="pg_back_arrow_component">
            <div className="back-btn">
              <a className="pg_link" onClick={() =>onClickBack()}>
                <img src={Previous} alt="" />
              </a>
            </div>
          </div>
         
          <div className="pg_header_text">
            Transaction Status
          </div>
          <div className=""></div>
        </div> : <></>
}
        

        <div className="pg_body">

          <div className="all_main_screens_div_related_to_ads">
          { loader ? <Loader /> :
            <div className="pg_body_scrollable_component" style={{marginTop:'10%'}}>
            
              {
                status === 'SUCCESS' ? <> <PhonePeSuccess transactionId={data?.data?.transactionId} amount={data?.data?.amount} message={data.message}  symbol={data?.data?.currency?.symbol}/> </>
                  : (status === 'ERROR' ? <> <PaymentStatusItemFailed transactionId={data?.data?.transactionId} amount={data?.data?.amount} message={data.message}  symbol={data?.data?.currency?.symbol} /></>
                    : status === 'PENDING' ? <><PaymentStatusItemPending transactionId={data?.data?.transactionId} amount={data?.data?.amount} message={data.message}  symbol={data?.data?.currency?.symbol}/></>
                      : status === 'DECLINED' ? <> <PaymentStatusItemFailed transactionId={data?.data?.transactionId} amount={data?.data?.amount} message={data?.msg}  symbol={data?.data?.currency?.symbol}/> </>
                        : status === 'CANCELLED' ? <> <h6>	Payment cancelled by the merchant using Cancel API</h6></>
                          : status === 'TRANSACTION_NOT_FOUND' ? <></>
                            : status === 'BAD_REQUEST' ? <><h6>Invalid request</h6></>
                              : status === 'AUTHORIZATION_FAILED' ? <> <h6>X-VERIFY header is incorrect</h6></>
                                : <PaymentStatusItemFailed transactionId={data?.data?.transactionId} amount={data?.data?.amount} message={data.message}  symbol={data?.data?.currency?.symbol} />)
              }



            </div>
}
          </div>

        </div>
      </div>

    </>

  )
}

export default PhonepeStatus