import React, { Fragment, useEffect, useState } from "react";
import Previous from "../assets/images/previous.svg";
import Loader from "../component/loader";
import "react-intl-tel-input/dist/main.css";
import { Link } from "react-router-dom";
import { getAllPoints, getAllPointsCategory } from "../apis";
import { useTranslation, Trans } from "react-i18next";
import Advertisement from "../component/advertisement";

function GamerjiPoints(props) {
  const [activeTab, setActiveTab] = useState(0);
  const [allTabs, setAllTabs] = useState([]);
  const [allData, setAllData] = useState(null);
  const [loader, setLoader] = useState(false);
  const {t} = useTranslation()

  const fetchPoints =  async () => {
    let data = { sortBy: "order", sort: "asc" };
    let res = await getAllPointsCategory(data);
    if (res) {
      // console.log('res: ', res)
      setAllTabs(res);
      setLoader(true);
      let data = { sortBy: "order", sort: "asc", pointCategory: res?.list?.[0]?._id };
      let res1 = await getAllPoints(data);
      if (res1) {
        setAllData(res1?.list);
        setLoader(false);
      }
    }
  }

  useEffect(()=>{
    fetchPoints()
  }
   , []);

  const onClickTab = async (item, index) => {
    setLoader(true);
    setActiveTab(index);
    let data = { sortBy: "order", sort: "asc", pointCategory: item?._id };
    let res = await getAllPoints(data);
    if (res) {
      setAllData(res?.list);
      setLoader(false);
    }
  };

  return (
    <div className="inner-wrap">
      <div className="header">
        <div className="back-btn">
          <Link to="/more">
            <img src={Previous} alt="" />
          </Link>
        </div>
        <h2>{t('sidebar.gamerji_points')}</h2>
        <div className="wallet-btn">
          {/* <Link to="">
            <img src={Wallet} alt="" />
            Wallet
          </Link> */}
        </div>
      </div>
      <div className="body bottom-btn-body">
        <div className="all_sub_screens_div_related_to_ads">
          <div className="gamerji_points_tab_main">
            {allTabs?.list?.map((item, index) => {
              return (
                <div
                  onClick={() => onClickTab(item, index)}
                  className={activeTab === index ? "active" : ""}
                  style={{ width: `${100 / allTabs?.count}%` }} 
                  key={index} 
                >
                  {item?.name}
                </div>
              );
            })}
          </div>

          <div className="recent_transaction_items more_tab_items points_tabs">
            <div className="accordion tournament-text" id="accordionExample">
              {loader === true ? <Loader /> :
                allData?.length === 0 ? <p>{t('right_hand_drop_down.no_points_category')}</p> :
                  allData?.map((item, index) => {
                    return (
                      <div className="item" key={index}>
                        <div
                          className="accordion-header"
                          data-toggle="collapse"
                          data-target="#a_2"
                          aria-expanded="false"
                        >
                          <span className="pointsname_gamerji">{item?.name}</span>
                          <span className="points_gamerji">{item?.points} {t('right_hand_drop_down.points')}</span>
                        </div>
                      </div>
                    );
                  })}
            </div>
          </div>
        </div>
        {
           !JSON.parse(localStorage.getItem("premiumUser")) && 
        
        <Advertisement
          screen={
            localStorage.getItem('apk_screens_list') === null ?
              { code: 'no_results' }
              :
              JSON.parse(localStorage.getItem('apk_screens_list')).gamerjiPoints
          }
          screen_div_class_name='.all_sub_screens_div_related_to_ads'
          screen_type='sub'
        />
}
      </div>
    </div>
  );
}
export default GamerjiPoints;
