import React, { Fragment, useEffect, useState } from "react";
import Logo from "../assets/images/logo.svg";
import RightArrow from "../assets/images/right-arrow.svg";
import "react-intl-tel-input/dist/main.css";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { GoogleLogin } from 'react-google-login';
import {
  countryList,
  checkAppUser,
  getsignUpCode,
  onSignUp,
  PrivacyData,
  signIn,
  TermsData,
  signInInternational,
  requestGoogleLogin,
} from "../apis";
import OTP from "./otp";
import url from "../constants/url";
import Select from "react-select";
import ReactHtmlParser from "react-html-parser";
import Previous from "../assets/images/previous.svg";
import Loader from "../component/loader";
import Error from "../component/error";
import { getAuth, signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import { useTranslation, Trans } from "react-i18next";


function SignInCampaign(props) {
  const [phonenumber, setPhonenumber] = useState("");
  const [dialCode, setDialCode] = useState("+91");
  const [countries, setCountries] = useState([]);
  const {t} = useTranslation()

  const [loginData, setLoginData] = useState(
    localStorage.getItem('loginData')
      ? JSON.parse(localStorage.getItem('loginData'))
      : null
  );
  const [campaignId, setCampaignId] = useState(false);

  const [tag_countries_dialcode_popup, setTagCountriesDialcodePopup] = useState(false);
  const [countries_dialcode, setCountriesDialcode] = useState({ value: '', lable: '', name: '' });

  const [allCountriesList, setAllCountriesList] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [errorCodeMessage, setErrorCodeMessage] = useState('');
  const [loader, setLoader] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [allDialCode, setAllDialCode] = useState({});
  const [confirmationResult, setConfirmationResult] = useState({});
  const [termsData, setTermsData] = useState(false);
  const [privacyData, setPrivacyData] = useState(false);
  const [campaignURL, setCampaignURL] = useState(false);
  const [error, setError] = useState("");
  // var firebaseConfig = {
  //   apiKey: 'AIzaSyBw-xOChQWFv8oS8HF56HffCyc9FaW0I9w',
  //   authDomain: "localhost",
  //   // The value of `databaseURL` depends on the location of the database
  //   databaseURL: 'https://fantasyji-esports.firebaseio.com',
  //   projectId: 'fantasyji-esports',
  //   storageBucket: 'fantasyji-esports.appspot.com',

  //   appId: "1:454874517092:ios:18d91115f592bfa854d971",
  //   // For Firebase JavaScript SDK v7.20.0 and later, `measurementId` is an optional field
  // };

  // const auth = getAuth();
  // useEffect(() => {
  //   window.recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
  //     'size': 'invisible',
  //     'callback': (response) => {
  //       // reCAPTCHA solved, allow signInWithPhoneNumber.
  //       onLogin();
  //     }
  //   }, auth);

  // }, []);

  // useEffect(() => {


  // }, []);



  // Initialize Firebase Authentication and get a reference to the service


  useEffect(async () => {

    var frontendURL =
      window.location.href.split("/")[
      window.location.href.split("/").length - 1
      ];
    setCampaignURL(frontendURL);
    let data = {
      filter: {},
      sort: "asc",
      sortBy: "name",
      skip: 0,
      limit: 10,
      page: 1,
    };
    let response = await countryList(data);
    if (response) {
      console.log(response)
      const options = [];
      response?.list?.map((item, index) => {
        setAllCountriesList(response?.list);
        const imgUrl = url.imageUrl + item?.flag?.default;
        options.push({
          value: item?.dialingCode,
          label: (
            <div>
              <img src={imgUrl} style={{ width: "15px" }} /> {item?.dialingCode}
            </div>
          ),
          name: (
            <div>
              <img src={imgUrl} style={{ width: "25px", marginRight: '10px' }} /> <b>{item?.name}</b> ({item?.dialingCode})
            </div>
          ),
        });
      });
      setCountriesDialcode(options?.[0])
      // console.log(options?.[0])

      setAllDialCode(options?.[0]);
      setDialCode(options?.[0]?.value);
      setCountries(options);
    }
  }, []);
  const confimOTP = async (otp, data) => {
    confirmationResult.confirm(otp).then(async (result) => {
      // User signed in successfully.

      data.isNewUser = true;
      data.isVerified = true;
      console.log("data=IN=>", data);

      console.log("result==>", result);
      const user = result.user;
      let response = await signInInternational(data);

      if (response) {
        // Adjust.trackEvent({
        //   eventToken: 'nxuhll'
        // })
        if (response.token) {
          localStorage.setItem('gamerjiToken', response.token);
          window.location.href = '/';

        } else {
          setError(response?.data?.errors ? response?.data?.errors?.[0]?.msg : t('error_something_went_wrong'));
        }

      } else {
        setError(response?.data?.errors ? response?.data?.errors?.[0]?.msg : t('error_something_went_wrong'));
      }
      return true;
      // ...
    }).catch((error) => {
      console.log("error==>", error);
      return false;
      // User couldn't sign in (bad verification code?)
      // ...
    });
  }
  // const onLogin = async () => {
  //   let data = {
  //     username: phonenumber,
  //     type: "otpRequest",
  //     phoneCode: dialCode,
  //     // 'password':'aaa'
  //   };

  //   console.log("onLogin==>", data);
  //   if (dialCode == "+91" || dialCode == "91") {

  //     if (phonenumber === "") {
  //       setError("Please enter valid mobile number");
  //     } else if (phonenumber?.length < 10) {
  //       setError("Please enter valid mobile number");
  //     } else {
  //       let response = await signIn(data);
  //       if (response && response?.status === undefined) {
  //         setRedirect(true);
  //         localStorage.setItem('currentOTPtype', 'login');
  //         let countryObj = allCountriesList?.filter(
  //           (val) => val?.dialingCode === dialCode
  //         )?.[0];
  //         localStorage.setItem('userCountryObj', JSON.stringify(countryObj));
  //         let newUrlIS = window.location.origin + "/otp";
  //         window.history.pushState({}, null, newUrlIS);
  //       } else {
  //         setError(response?.statusText ? response?.statusText : "Something Went Wrong! Please try again ...");
  //       }
  //     }

  //   } else {

  //     if (phonenumber === "") {
  //       setError("Please enter valid mobile number");
  //     } else if (phonenumber?.length < 8) {
  //       setError("Please enter valid mobile number");
  //     } else {
  //       let response = await signIn(data);
  //       if (response && response?.status === undefined) {
  //         setRedirect(true);
  //         localStorage.setItem('currentOTPtype', 'login');
  //         let countryObj = allCountriesList?.filter(
  //           (val) => val?.dialingCode === dialCode
  //         )?.[0];
  //         localStorage.setItem('userCountryObj', JSON.stringify(countryObj));
  //         let newUrlIS = window.location.origin + "/otp";
  //         window.history.pushState({}, null, newUrlIS);
  //       } else {
  //         setError(response?.statusText ? response?.statusText : "Something Went Wrong! Please try again ...");
  //       }
  //     }
  //     // if (phonenumber === "") {
  //     //   setError("Please enter valid mobile number");
  //     // } else if (phonenumber?.length < 8) {
  //     //   setError("Please enter valid mobile number");
  //     // } else {
  //     //   let response = await checkAppUser(data);
  //     //   if (response) {
  //     //     console.log("response", response);
  //     //     if (response?.item) {

  //     //       if (response?.item?.isUserExists == false) {
  //     //         setError("User not exist");
  //     //       } else {
  //     //         let countryObj = allCountriesList?.filter(
  //     //           (val) => val?.dialingCode === dialCode
  //     //         )?.[0];
  //     //         localStorage.setItem('userCountryObj', JSON.stringify(countryObj));
  //     //         console.log("auth", auth);
  //     //         const appVerifier = window.recaptchaVerifier;
  //     //         console.log("appVerifier", appVerifier);
  //     //         signInWithPhoneNumber(auth, dialCode + phonenumber, appVerifier)
  //     //           .then((confirmationResult) => {
  //     //             setConfirmationResult(confirmationResult);
  //     //             setRedirect(true);
  //     //             // confirmationResult.confirm("111111").then((result) => {
  //     //             //   // User signed in successfully.
  //     //             //   console.log("result==>",result);
  //     //             //   const user = result.user;
  //     //             //   // ...
  //     //             // }).catch((error) => {
  //     //             //   console.log("error==>",error);
  //     //             //   // User couldn't sign in (bad verification code?)
  //     //             //   // ...
  //     //             // });
  //     //             console.log("confirmationResult", confirmationResult);
  //     //             localStorage.setItem('currentOTPtype', 'login');
  //     //             localStorage.setItem('confirmationResult', confirmationResult);
  //     //             let newUrlIS = window.location.origin + "/otp";
  //     //             window.history.pushState({}, null, newUrlIS);
  //     //             // SMS sent. Prompt user to type the code from the message, then sign the
  //     //             // user in with confirmationResult.confirm(code).
  //     //             // window.confirmationResult = confirmationResult;
  //     //             // ...
  //     //           }).catch((error) => {
  //     //             console.log("error==>", error);
  //     //             // Error; SMS not sent
  //     //             // ...
  //     //           });


  //     //       }


  //     //     }

  //     //     // setRedirect(true);
  //     //     // localStorage.setItem('currentOTPtype', 'login');
  //     //     // let newUrlIS = window.location.origin + "/otp";
  //     //     // window.history.pushState({}, null, newUrlIS);
  //     //   } else {
  //     //     // setError(response?.statusText ? response?.statusText : "Something Went Wrong! Please try again ...");
  //     //   }
  //     // }

  //   }

  // };


  const onLogin = async () => {
    let data = {
      username: phonenumber,
      type: "otpRequest",
      phoneCode: dialCode,
      // 'password':'aaa'
    };

    console.log("onLogin==>", data);
    // if (dialCode == "+91" || dialCode == "91") {

    //   if (phonenumber === "") {
    //     setError("Please enter valid mobile number");
    //   } else if (phonenumber?.length < 10) {
    //     setError("Please enter valid mobile number");
    //   } else {
    //     // setLoader(true);
    //     let response = await checkAppUser(data);
    //     setLoader(false);
    //     if (response) {
    //       console.log("response", response);
    //       if (response?.item) {

    //         if (response?.item?.isUserExists == false) {
    //           setError("User not exist");
    //         } else {
    //           ///  console.log("auth", auth);
    //           const appVerifier = window.recaptchaVerifier;
    //           console.log("appVerifier", appVerifier);
    //           signInWithPhoneNumber(auth, dialCode + phonenumber, appVerifier)
    //             .then((confirmationResult) => {
    //               setConfirmationResult(confirmationResult);
    //               setRedirect(true);
    //               // confirmationResult.confirm("111111").then((result) => {
    //               //   // User signed in successfully.
    //               //   console.log("result==>",result);
    //               //   const user = result.user;
    //               //   // ...
    //               // }).catch((error) => {
    //               //   console.log("error==>",error);
    //               //   // User couldn't sign in (bad verification code?)
    //               //   // ...
    //               // });

    //               localStorage.setItem('currentOTPtype', 'login');

    //               // localStorage.setItem('userCountryObj', JSON.stringify(countryObj));
    //               localStorage.setItem('confirmationResult', confirmationResult);
    //               let newUrlIS = window.location.origin + "/otp";
    //               window.history.pushState({}, null, newUrlIS);
    //               // SMS sent. Prompt user to type the code from the message, then sign the
    //               // user in with confirmationResult.confirm(code).
    //               // window.confirmationResult = confirmationResult;
    //               // ...
    //             }).catch((error) => {
    //               console.log("error==>", error);
    //               // Error; SMS not sent
    //               // ...
    //             });


    //         }


    //       }

    //       //   // setRedirect(true);
    //       //   // localStorage.setItem('currentOTPtype', 'login');
    //       //   // let newUrlIS = window.location.origin + "/otp";
    //       //   // window.history.pushState({}, null, newUrlIS);
    //     } else {
    //       // setError(response?.statusText ? response?.statusText : "Something Went Wrong! Please try again ...");
    //     }
    //     // let response = await signIn(data);
    //     // if (response && response?.status === undefined) {
    //     //   setRedirect(true);
    //     //   localStorage.setItem('currentOTPtype', 'login');
    //     //   let countryObj = allCountriesList?.filter(
    //     //     (val) => val?.dialingCode === dialCode
    //     //   )?.[0];
    //     //   localStorage.setItem('userCountryObj', JSON.stringify(countryObj));
    //     //   let newUrlIS = window.location.origin + "/otp";
    //     //   window.history.pushState({}, null, newUrlIS);
    //     // } else {
    //     //   setError(response?.statusText ? response?.statusText : "Something Went Wrong! Please try again ...");
    //     // }
    //   }

    // } else {

    //   if (phonenumber === "") {
    //     setError("Please enter valid mobile number");
    //   } else if (phonenumber?.length < 8) {
    //     setError("Please enter valid mobile number");
    //   } else {
    //     // setLoader(true);
    //     let response = await checkAppUser(data);
    //     setLoader(false);
    //     if (response) {
    //       console.log("response", response);
    //       if (response?.item) {

    //         if (response?.item?.isUserExists == true) {
    //           setError("User already exist");
    //         } else {
    //           console.log("auth", auth);
    //           const appVerifier = window.recaptchaVerifier;
    //           console.log("appVerifier", appVerifier);
    //           signInWithPhoneNumber(auth, dialCode + phonenumber, appVerifier)
    //             .then((confirmationResult) => {
    //               setConfirmationResult(confirmationResult);
    //               setRedirect(true);
    //               // confirmationResult.confirm("111111").then((result) => {
    //               //   // User signed in successfully.
    //               //   console.log("result==>",result);
    //               //   const user = result.user;
    //               //   // ...
    //               // }).catch((error) => {
    //               //   console.log("error==>",error);
    //               //   // User couldn't sign in (bad verification code?)
    //               //   // ...
    //               // });

    //               localStorage.setItem('currentOTPtype', 'login');

    //               //  localStorage.setItem('userCountryObj', JSON.stringify(countryObj));
    //               localStorage.setItem('confirmationResult', confirmationResult);
    //               let newUrlIS = window.location.origin + "/otp";
    //               window.history.pushState({}, null, newUrlIS);
    //               // SMS sent. Prompt user to type the code from the message, then sign the
    //               // user in with confirmationResult.confirm(code).
    //               // window.confirmationResult = confirmationResult;
    //               // ...
    //             }).catch((error) => {
    //               console.log("error==>", error);
    //               // Error; SMS not sent
    //               // ...
    //             });


    //         }


    //       }

    //       //   // setRedirect(true);
    //       //   // localStorage.setItem('currentOTPtype', 'login');
    //       //   // let newUrlIS = window.location.origin + "/otp";
    //       //   // window.history.pushState({}, null, newUrlIS);
    //     } else {
    //       // setError(response?.statusText ? response?.statusText : "Something Went Wrong! Please try again ...");
    //     }
    //     // let response = await signIn(data);
    //     // if (response && response?.status === undefined) {
    //     //   setRedirect(true);
    //     //   localStorage.setItem('currentOTPtype', 'login');
    //     //   let countryObj = allCountriesList?.filter(
    //     //     (val) => val?.dialingCode === dialCode
    //     //   )?.[0];
    //     //   localStorage.setItem('userCountryObj', JSON.stringify(countryObj));
    //     //   let newUrlIS = window.location.origin + "/otp";
    //     //   window.history.pushState({}, null, newUrlIS);
    //     // } else {
    //     //   setError(response?.statusText ? response?.statusText : "Something Went Wrong! Please try again ...");
    //     // }
    //   }
    //   // if (phonenumber === "") {
    //   //   setError("Please enter valid mobile number");
    //   // } else if (phonenumber?.length < 8) {
    //   //   setError("Please enter valid mobile number");
    //   // } else {
    //   //   let response = await checkAppUser(data);
    //   //   if (response) {
    //   //     console.log("response", response);
    //   //     if (response?.item) {

    //   //       if (response?.item?.isUserExists == false) {
    //   //         setError("User not exist");
    //   //       } else {
    //   //         let countryObj = allCountriesList?.filter(
    //   //           (val) => val?.dialingCode === dialCode
    //   //         )?.[0];
    //   //         localStorage.setItem('userCountryObj', JSON.stringify(countryObj));
    //   //         console.log("auth", auth);
    //   //         const appVerifier = window.recaptchaVerifier;
    //   //         console.log("appVerifier", appVerifier);
    //   //         signInWithPhoneNumber(auth, dialCode + phonenumber, appVerifier)
    //   //           .then((confirmationResult) => {
    //   //             setConfirmationResult(confirmationResult);
    //   //             setRedirect(true);
    //   //             // confirmationResult.confirm("111111").then((result) => {
    //   //             //   // User signed in successfully.
    //   //             //   console.log("result==>",result);
    //   //             //   const user = result.user;
    //   //             //   // ...
    //   //             // }).catch((error) => {
    //   //             //   console.log("error==>",error);
    //   //             //   // User couldn't sign in (bad verification code?)
    //   //             //   // ...
    //   //             // });
    //   //             console.log("confirmationResult", confirmationResult);
    //   //             localStorage.setItem('currentOTPtype', 'login');
    //   //             localStorage.setItem('confirmationResult', confirmationResult);
    //   //             let newUrlIS = window.location.origin + "/otp";
    //   //             window.history.pushState({}, null, newUrlIS);
    //   //             // SMS sent. Prompt user to type the code from the message, then sign the
    //   //             // user in with confirmationResult.confirm(code).
    //   //             // window.confirmationResult = confirmationResult;
    //   //             // ...
    //   //           }).catch((error) => {
    //   //             console.log("error==>", error);
    //   //             // Error; SMS not sent
    //   //             // ...
    //   //           });


    //   //       }


    //   //     }

    //   //     // setRedirect(true);
    //   //     // localStorage.setItem('currentOTPtype', 'login');
    //   //     // let newUrlIS = window.location.origin + "/otp";
    //   //     // window.history.pushState({}, null, newUrlIS);
    //   //   } else {
    //   //     // setError(response?.statusText ? response?.statusText : "Something Went Wrong! Please try again ...");
    //   //   }
    //   // }

    // }

    if (dialCode == "+91" || dialCode == "91") {
      if (phonenumber === "") {
        setError("Please enter valid mobile number");
      } else if (phonenumber?.length < 10) {
        setError("Please enter valid mobile number");
      } else {
        let response = await signIn(data);
        setLoader(false);
        if (response && response?.success === true) {
          setRedirect(true);
          localStorage.setItem('currentOTPtype', 'login');
          let countryObj = allCountriesList?.filter(
            (val) => val?.dialingCode === dialCode
          )?.[0];
          localStorage.setItem('userCountryObj', JSON.stringify(countryObj));
          let newUrlIS = window.location.origin + "/otp";
          window.history.pushState({}, null, newUrlIS);
        } else {
          setError(response?.data?.errors ? response?.data?.errors?.[0]?.msg : t('error_Oops_something_went_wrong_please_try_again'));
        }
      }
    } else {

      if (phonenumber === "") {
        setError("Please enter valid mobile number");
      } else if (phonenumber?.length < 8) {
        setError("Please enter valid mobile number");
      } else {
        // setLoader(true);
        let response = await signIn(data);
        setLoader(false);
        if (response && response?.success === true) {
          setRedirect(true);
          localStorage.setItem('currentOTPtype', 'login');
          let countryObj = allCountriesList?.filter(
            (val) => val?.dialingCode === dialCode
          )?.[0];
          localStorage.setItem('userCountryObj', JSON.stringify(countryObj));
          let newUrlIS = window.location.origin + "/otp";
          window.history.pushState({}, null, newUrlIS);
        } else {
          setError(response?.data?.errors ? response?.data?.errors?.[0]?.msg : t('error_Oops_something_went_wrong_please_try_again'));
        }

      }

    }
  };

  const handleChangeCountry = (selectedOption) => {
    setDialCode(selectedOption?.value);
    setAllDialCode(selectedOption);
  };

  const onChangePhone = (val) => {
    val.length <= 10 && setPhonenumber(val);
  };

  const onClickPrivacy = async () => {
    setLoader(true);
    let res = await PrivacyData();
    if (res) {
      setPrivacyData(res?.item?.description);
    }
    setShowTerms(false);
    setShowPrivacy(true);
    setLoader(false);
  };

  const onClickTerms = async () => {
    setLoader(true);
    let res = await TermsData();
    if (res) {
      setTermsData(res?.item?.description);
    }
    setShowPrivacy(false);
    setShowTerms(true);
    setLoader(false);
  };

  const goback = () => {
    setShowPrivacy(false);
    setShowTerms(false);
  };

  const fieldChange = (e) => {
    setCouponCode(e.target.value);
  };

  const getSignUpode = async () => {
    setErrorCodeMessage('');
    if (couponCode !== "") {
      let response = await getsignUpCode({ name: couponCode, type: "signupCode" });
      if (response && response?.status === undefined) {
        setErrorCodeMessage('success');
      } else {
        setErrorCodeMessage('notcorrect');
      }
    } else {
      setErrorCodeMessage('error');
    }
  };

  const signUp = async () => {
    let country = allCountriesList?.filter(
      (val) => val?.dialingCode === dialCode
    )?.[0]?._id;
    let data = {
      type: "otpRequest",
      username: phonenumber,
      phoneCode: dialCode,
      country: country,
      code: couponCode,
    };
    if (dialCode == "+91" || dialCode == "91") {
      let response = await onSignUp(data);
      if (response && response?.status === undefined) {
        setRedirect(true);
        localStorage.setItem('currentOTPtype', 'signup');
        let newUrlIS = window.location.origin + "/otp";
        window.history.pushState({}, null, newUrlIS);
      } else {
        console.log('err::', response)
        setError(response?.statusText ? response?.statusText : t('error_Oops_something_went_wrong_please_try_again'));
      }
    } else {

      // if (phonenumber === "") {
      //   setError("Please enter valid mobile number");
      // } else if (phonenumber?.length < 8) {
      //   setError("Please enter valid mobile number");
      // } else {
      //   let response = await checkAppUser(data);
      //   if (response) {
      //     console.log("response", response);
      //     if (response?.item) {

      //       if (response?.item?.isUserExists == false) {
      //         setError("User not exist");
      //       } else {
      //         console.log("auth", auth);
      //         const appVerifier = window.recaptchaVerifier;
      //         console.log("appVerifier", appVerifier);
      //         signInWithPhoneNumber(auth, dialCode + phonenumber, appVerifier)
      //           .then((confirmationResult) => {
      //             setConfirmationResult(confirmationResult);
      //             setRedirect(true);
      //             // confirmationResult.confirm("111111").then((result) => {
      //             //   // User signed in successfully.
      //             //   console.log("result==>",result);
      //             //   const user = result.user;
      //             //   // ...
      //             // }).catch((error) => {
      //             //   console.log("error==>",error);
      //             //   // User couldn't sign in (bad verification code?)
      //             //   // ...
      //             // });

      //             localStorage.setItem('currentOTPtype', 'signup');


      //             localStorage.setItem('confirmationResult', confirmationResult);
      //             let newUrlIS = window.location.origin + "/otp";
      //             window.history.pushState({}, null, newUrlIS);
      //             // SMS sent. Prompt user to type the code from the message, then sign the
      //             // user in with confirmationResult.confirm(code).
      //             // window.confirmationResult = confirmationResult;
      //             // ...
      //           }).catch((error) => {
      //             console.log("error==>", error);
      //             // Error; SMS not sent
      //             // ...
      //           });


      //       }


      //     }

      //     // setRedirect(true);
      //     // localStorage.setItem('currentOTPtype', 'login');
      //     // let newUrlIS = window.location.origin + "/otp";
      //     // window.history.pushState({}, null, newUrlIS);
      //   } else {
      //     // setError(response?.statusText ? response?.statusText : "Something Went Wrong! Please try again ...");
      //   }
      // }
    }

  };

  const goSignUP = () => {
    setShowRegister(!showRegister);
    setPhonenumber('');
    // setDialCode("+91");
    setError('');
    // let newUrlIS = window.location.origin + "/register";
    // window.history.pushState({}, null, newUrlIS);
    //window.location.href = "/register";
    window.location.href = "/register/" + campaignURL;

  };
  const goSignUPPhone = () => {
    setShowRegister(!showRegister);
    setPhonenumber('');
    //  setDialCode("+91");
    setError('');
    // let newUrlIS = window.location.origin + "/";
    // window.history.pushState({}, null, newUrlIS);
    window.location.href = "/signin-email/" + campaignURL;

  };
  const responseGoogleOnSuccess = async (googleData) => {
    setLoginData(googleData);
    localStorage.setItem('loginData', JSON.stringify(googleData));
    let payload = {
      "googleData": googleData.profileObj,
      "campaignId": campaignId,
      "platform": "webapp",
    }
    let response = await requestGoogleLogin(payload);
    if (response) {
      if (response?.success === true) {
        localStorage.setItem('gamerjiToken', response.token);
        if (response.isSingup === true) {
          window.location.href = '/sign-up';
        } else {
          window.location.href = '/';
        }
      } else {
        setError(response?.data?.errors ? response?.data?.errors?.[0]?.msg : t('error_Oops_something_went_wrong_please_try_again'));
      }



      console.log("responseGoogleOnSuccess====response==>", response);
      //  setLoginData(response);
      // localStorage.setItem('loginData', JSON.stringify(response));
    }
    // const res = await fetch('/api/google-login', {
    //   method: 'POST',
    //   body: JSON.stringify({
    //     token: googleData.tokenId,
    //   }),
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    // });

    // const data = await res.json();


    console.log("responseGoogleOnSuccess==>", googleData);
  };

  const responseGoogleOnFailure = (response) => {
    console.log("responseGoogleOnFailure==>", response);
    setError(response?.data?.errors ? response?.data?.errors?.[0]?.msg : t('error_Oops_something_went_wrong_please_try_again'));
  };

  const handleLogout = () => {
    localStorage.removeItem('loginData');
    setLoginData(null);
  };

  const createCountryListPopup = (countries) => {
    // console.log(countries_dialcode)
    return (
      <div className="country_list_popup_component">
        <div className="country_list_popup">
          <div className="country_list_popup_header">
            <div></div>
            <div className="country_list_popup_header_text">Select Country</div>
            <div>
              <svg
                onClick={(e) => setTagCountriesDialcodePopup(false)}
                style={{ cursor: 'pointer', }}
                id="" data-name="cancel (1)" xmlns="http://www.w3.org/2000/svg" width="30.001" height="30" viewBox="0 0 34.001 34">
                <path id="Path_2963" data-name="Path 2963" d="M34,17A17,17,0,1,0,17,34,17,17,0,0,0,34,17Z" transform="translate(0 -0.003)" fill="#ef1f36" />
                <path id="Path_2964" data-name="Path 2964" d="M150.574,135.871l4.97-5.77a1.5,1.5,0,0,0-2.279-1.963l-4.676,5.428-4.676-5.428a1.5,1.5,0,1,0-2.279,1.963l4.97,5.77-4.97,5.77a1.5,1.5,0,0,0,2.279,1.963l4.676-5.428,4.676,5.428a1.5,1.5,0,0,0,2.279-1.963Z" transform="translate(-131.589 -118.87)" fill="#fff" />
              </svg>
            </div>
          </div>

          <div className="country_list_popup_body">
            {
              countries.map((value, i) => {
                return (
                  <div
                    className="country_list_popup_body_text" key={i}
                    onClick={(e) => {
                      setCountriesDialcode(value);
                      setDialCode(value.value);
                      setTagCountriesDialcodePopup(false);
                    }}
                  >
                    {value.name}
                    {/* Hello */}
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="inner-wrap">
      {tag_countries_dialcode_popup ? createCountryListPopup(countries) : ''}
      {redirect ? (
        <OTP code={dialCode} confimOTP={confimOTP} phonenumber={phonenumber} />
      ) : (
        <Fragment>
          {showTerms === true ? (
            loader === true ? (
              <Loader />
            ) : (
              <Fragment>
                <div className="header">
                  <div className="back-btn">
                    <a onClick={() => goback()}>
                      <img src={Previous} alt="" />
                    </a>
                  </div>
                  <h2>Terms & Conditions</h2>
                  <div className="wallet-btn"></div>
                </div>
                <div className="body mCustomScrollbar _mCS_1 bottom-btn-body">
                  {ReactHtmlParser(termsData)}
                </div>
              </Fragment>
            )
          ) : showPrivacy === true ? (
            loader === true ? (
              <Loader />
            ) : (
              <Fragment>
                <div className="header">
                  <div className="back-btn">
                    <a onClick={() => goback()}>
                      <img src={Previous} alt="" />
                    </a>
                  </div>
                  <h2>Privacy Policy</h2>
                  <div className="wallet-btn"></div>
                </div>
                <div className="body mCustomScrollbar _mCS_1 bottom-btn-body">
                  {ReactHtmlParser(privacyData)}
                </div>
              </Fragment>
            )
          ) : showRegister === true ? (
            <Fragment>
              <div className="header">
                <div className="logo">
                  <Link to="/">
                    <img src={Logo} className="d-block mx-auto" alt="" style={{ width: '150px' }} />
                  </Link>
                </div>
              </div>
              <div className="body mCustomScrollbar _mCS_1 country_drop">
                <div className="row mt-4" style={{ paddingTop: '60px' }}>
                  <div className="col-4 col-md-4 pr-0">
                    <div className="form-group">
                      <label>Country Code</label>
                      {/* <Select
                        value={allDialCode}
                        onChange={handleChangeCountry}
                        options={countries}
                        isSearchable={false}
                      /> */}
                      <button className="sign_in_country_code_popup_button"
                        onClick={(e) => { setTagCountriesDialcodePopup(true) }}
                      >
                        {countries_dialcode.label}
                        <svg className="" width="25" height="25" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path id="" d="M34 18.34a2 2 0 0 0-2.82 0L24 25.42l-7.08-7.08a2 2 0 1 0-2.82 2.84l8.48 8.48a2.003 2.003 0 0 0 2.19.438c.243-.101.464-.25.65-.438L34 21.18a2 2 0 0 0 0-2.84z" fill="#D3D3D3"></path>
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div className="col-8 col-md-8">
                    <div className="form-group">
                      <label>Mobile Number</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Enter Mobile Number"
                        onKeyDown={(e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                        value={phonenumber}
                        onChange={(e) => onChangePhone(e.target.value)}
                        style={{ height: '42px' }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="row"
                  style={{ borderRadius: "30px", margin: "0 -20px" }}
                >
                  <div className="col-12 col-md-12">
                    <div className="form-group ">
                      <label>Coupon Code</label>
                      <div className="code-field">
                        <input
                          type="text"
                          className="form-control"
                          value={couponCode}
                          onChange={(e) => fieldChange(e)}
                          placeholder="Enter Coupon Code"
                          required=""
                          style={{ textTransform: 'uppercase' }}
                        />
                        <button
                          type="submit"
                          className="text-dark"
                          style={{ backgroundColor: '#FFC609' }}
                          onClick={getSignUpode}
                        >
                          APPLY
                        </button>
                      </div>
                      {errorCodeMessage === 'success' ? (
                        <p className="text-success">
                          Coupon Code Applied Successfully!
                        </p>
                      ) : errorCodeMessage === 'notcorrect' ? (
                        <p className="text-success">
                          Please Apply Correct Coupon Code!
                        </p>
                      ) : errorCodeMessage === 'error' ? (
                        <p className="text-secondary">
                          Please enter coupon code!
                        </p>
                      ) : (<p></p>)}
                    </div>
                  </div>
                </div>
                {error !== "" && <Error error={error} />}
                <div className="form-group mt-2">
                  <button onClick={signUp} className="btn btn-dark main-btn">
                    REGISTER <img src={RightArrow} alt="" />
                  </button>
                </div>
                <p className="text-center">
                  By Registering, I agree to GamerJi's{" "}
                  <b className="pointer" onClick={() => onClickTerms()}>
                    Terms and Conditions
                  </b>{" "}
                  and{" "}
                  <b className="pointer" onClick={() => onClickPrivacy()}>
                    Privacy Policy
                  </b>{" "}
                </p>
                <div id="sign-up-button"></div>
                <p className="text-center mt-2">
                  Already User?{" "}
                  <b
                    className="pointer"
                    onClick={() => goSignUP()}
                  >
                    Login Now!
                  </b>
                </p>
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <div className="header">
                <div className="logo">
                  <Link to="/">
                    <img src={Logo} className="d-block mx-auto" alt="" style={{ width: '150px' }} />
                  </Link>
                </div>
              </div>
              <div className="body mCustomScrollbar _mCS_1 country_drop">
                <div className="row mt-4" style={{ paddingTop: '60px' }}>
                  <div className="col-4 col-md-4 pr-0">
                    <div className="form-group">
                      <label style={{ fontWeight: '400', color: 'var(--dark)' }}>Country Code</label>
                      {/* <Select
                        value={allDialCode}
                        onChange={handleChangeCountry}
                        options={countries}
                        isSearchable={false}
                        style={{ height: '48px' }}
                      /> */}
                      <button className="sign_in_country_code_popup_button"
                        onClick={(e) => { setTagCountriesDialcodePopup(true) }}
                      >
                        {countries_dialcode.label}
                        <svg className="" width="25" height="25" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path id="" d="M34 18.34a2 2 0 0 0-2.82 0L24 25.42l-7.08-7.08a2 2 0 1 0-2.82 2.84l8.48 8.48a2.003 2.003 0 0 0 2.19.438c.243-.101.464-.25.65-.438L34 21.18a2 2 0 0 0 0-2.84z" fill="#D3D3D3"></path>
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div className="col-8 col-md-8">
                    <div className="form-group">
                      <label style={{ fontWeight: '400', color: 'var(--dark)' }}>Mobile Number</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Enter Mobile Number"
                        onKeyDown={(e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                        value={phonenumber}
                        onChange={(e) => onChangePhone(e.target.value)}
                        style={{ height: '42px' }}
                      />
                    </div>
                  </div>
                </div>
                {error !== "" && <Error error={error} />}
                <div className="form-group mt-2">
                  <div id="sign-in-button"></div>
                  <button onClick={onLogin} className="btn btn-dark main-btn">
                    LOGIN <img src={RightArrow} alt="" />
                  </button>
                </div>
                {/* <p className="text-center">
                  By Registering, I agree to GamerJi's{" "}
                  <b className="pointer" onClick={() => onClickTerms()}>
                    Terms and Conditions
                  </b>{" "}
                  and{" "}
                  <b className="pointer" onClick={() => onClickPrivacy()}>
                    Privacy Policy
                  </b>{" "}
                </p> */}
                <p className="text-center mt-2">
                  Don't have an account?{" "}
                  <b
                    className="error pointer"
                    onClick={() => goSignUP()}
                  >
                    SIGN UP
                  </b>
                </p>
                <div className="form-group mt-2">
                  <button onClick={e => goSignUPPhone(e)} className="btn btn-dark main-btn">
                  LOGIN WITH EMAIL <img src={RightArrow} alt="" />
                  </button>
                </div>
                {/* {
                  dialCode !== '+91' && dialCode !== "" &&
                  (
                    loginData ? (
                      <div>
                        <h3>You logged in as {loginData.email}</h3>
                        <button onClick={handleLogout}>Logout</button>
                      </div>
                    ) : (
                      <p className="text-center mt-2">
                        <GoogleLogin
                          clientId={'454874517092-tk61r0pb6f79ecte31ugdcde36old1c5.apps.googleusercontent.com'}
                          onSuccess={responseGoogleOnSuccess}
                          onFailure={responseGoogleOnFailure}
                          cookiePolicy={'single_host_origin'}
                          theme={'dark'}
                          width={'460'}
                          height={'60'}
                          shape={'circle'}
                        // uxMode={'redirect'}
                        // redirectUri={"http://localhost:3006/register/gamerji-chirag"}

                        >

                          <span className="buttonText"> Login with Google</span>
                        </GoogleLogin>
                      </p>
                    )
                  )
                } */}
              </div>
            </Fragment>
          )}
        </Fragment>
      )}
    </div>
  );
}
export default SignInCampaign;
