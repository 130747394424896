import React from "react";
import ReactDOM from "react-dom";
import "react-intl-tel-input/dist/main.css";
// import { Row, Col } from "react-bootstrap";
import {
  applyPromoCode, 
} from "../apis";
import { constants } from "../constants/constants";
import Cancel from "../assets/images/round_not_qualified.png";
import RightArrow from "../assets/images/right-arrow.svg";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Advertisement from "../component/advertisement";

import './more.css';
import { withTranslation } from "react-i18next";
import { events } from "../utils/appEvents";
import { eventTracking } from "../firebaseAnalytics";
class ApplyPromoCode extends React.Component {
	constructor(props) {
    super(props);

    this.state = {
      promo_code: '', 
    };

    this.onChangePromoCode = this.onChangePromoCode.bind(this);
    this.onClickSubmit = this.onClickSubmit.bind(this);
  }

	componentDidMount() {
    // this.getListofDailyRewardsApiCall();
  }

  onChangePromoCode(e) {
    // console.log(e.target.value)
    this.setState({ promo_code: e.target.value, })
  }

  onClickSubmit() {
    const { t } = this.props;
    console.log(this.state.promo_code)
    if(this.state.promo_code === null || this.state.promo_code === "") {
      toast.error(t('error_Promo_Code_is_Empty'), {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      eventTracking(events.apply_promo_code_submit,{EP_PromoCodeID:this.state.promo_code.toUpperCase()});
      this.applyPromoCodeApiCall({code: this.state.promo_code.toUpperCase()})
    }
  }

	async applyPromoCodeApiCall(data) {
    const { t } = this.props;
		let response = await applyPromoCode(data);
		console.log('Apply Promo Code: ', response)
    if(response.success === true) {
      // toast.success('Promo Code Applied - Successful!!!', {
      //   position: "top-center",
      //   autoClose: 2000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });
      this.props.onClickCancelIconAPC(false);
    } else {
      toast.error(t('error_coupon_not_found'), {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
	}
	
	render() {
		const { t } = this.props;

		return (
			<div className="jvic_main_div">
        <ToastContainer />
        <div className="jvic_popup">
          <div className="jvic_header">
            <div className=""></div>
            <div className="jvic_header_text">{t('right_hand_drop_down.apply_promo_code')}</div>
            <div className="jvic_cancel_icon">
              <img src={Cancel} onClick={(e) => this.props.onClickCancelIconAPC(true)} />
            </div>
          </div>

          <div className="jvic_body">
            <div className="all_sub_screens_div_related_to_ads">
              <div className="jvic_body_scrollable_component">
                <div className="jvic_text">{t('right_hand_drop_down.promo_code')}</div>
                <input 
                  className="jvic_input" 
                  placeholder={t('right_hand_drop_down.enter_promo_code')}
                  value={this.state.promo_code.toLocaleUpperCase()}
                  onChange={this.onChangePromoCode}
                />
                <button className="jvic_button" onClick={(e) => this.onClickSubmit()}>
                {t('right_hand_drop_down.submit')} <img src={RightArrow} alt="" className="mCS_img_loaded" />
                </button>
              </div>
            </div>
            {
              !JSON.parse(localStorage.getItem("premiumUser")) && 
            
            <Advertisement
              screen={
                localStorage.getItem('apk_screens_list') === null ?
                  { code: 'no_results' }
                  :
                  JSON.parse(localStorage.getItem('apk_screens_list')).applyPromoCodePopup
              }
              screen_div_class_name='.all_sub_screens_div_related_to_ads'
              screen_type='sub'
            />
  }
          </div>
        </div>
			</div>
		);
	}
}
export default  withTranslation()(ApplyPromoCode);
// export default DailyLoginRewards;
