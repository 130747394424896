import React, { Fragment, useEffect, useState } from "react";
import DateIcon from "../assets/images/date-icon.svg";
import RightArrow from "../assets/images/right-arrow.svg";
import SignUpBg from "../assets/images/signup-bg.png";
import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
import "react-intl-tel-input/dist/main.css";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  countryList,
  getAllFreeAvatar,
  getAllStateList,
  getsignUpCode,
  getUpdateProfile,
  geolocation,
} from "../apis";
import url from "../constants/url";
import Select from "react-select";
import { getProfiledateFormat } from "../component/common";
import Loader from "../component/loader";
import LanguagePopup from "./LanguagePopup";
import { constants } from "../constants/constants";
import roller from "../assets/images/rolling.gif";

function SignUp() {
  const [loader, setLoader] = useState(false)
  const [allData, setAllData] = useState(null);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [errorCodeMessage, setErrorCodeMessage] = useState(false);
  const [allAvatars, setAllAvatars] = useState([]);
  const [allStates, setAllStates] = useState([]);
  const [avatarId, setAvatarId] = useState("");
  const [showLanguagePopup, setShowLanguagePopup] = useState(true);
  const [country, setCountry] = useState("");
  const [dialCode, setDialCode] = useState("+91");
  const [countries, setCountries] = useState([]);
  const [allCountriesList, setAllCountriesList] = useState([]);
  const [allDialCode, setAllDialCode] = useState({});
  const [userCountryObj, setUserCountryObj] = useState(null);
  const [userLoginData, setUserLoginData] = useState(undefined);

  const [isCountryAvailable, setIsCountryAvailable] = useState(false);
  const tagAutomaticCountrySelect = false;

  const [tag_countries_dialcode_popup, setTagCountriesDialcodePopup] =
    useState(false);
  const [tag_state_dialcode_popup, setTagStateDialcodePopup] = useState(false);
  const [countries_dialcode, setCountriesDialcode] = useState({
    value: "",
    label: "",
    name: "",
  });
  const [isbusy, setIsBusy] = useState(false);
  const [selectedState, setSelectedState] = useState(null);

  useEffect(async () => {
    setLoader(true)
    try{

      
    let tag = JSON.parse(
      localStorage.getItem("languageData")
    )?.showLanguagePopup;
    let userCountryObj = JSON.parse(localStorage.getItem("userCountryObj"));
    let loginData = JSON.parse(localStorage.getItem("loginData"));
    // console.log(userCountryObj);
    setUserCountryObj(userCountryObj);
    setUserLoginData(loginData);

    // console.log("userCountryObj==>", userCountryObj);

    const isObjectEmpty = (objectName) => {
      return Object.keys(objectName).length === 0;
    };

    // console.log("userCountryObj==> check", isObjectEmpty(userCountryObj));

    // if (
    //   userCountryObj != undefined &&
    //   userCountryObj != "undefined" &&
    //   userCountryObj != null &&
    //   userCountryObj != "null" &&
    //   userCountryObj != "" &&
    //   isObjectEmpty(userCountryObj)
    // ) {
    //   setUserCountryObj(null);
    // } else {
    //   setUserCountryObj(null);
    // }

    // let data = {
    //   filter: {},
    //   sort: "asc",
    //   sortBy: "name",
    //   skip: 0,
    //   limit: 10,
    //   page: 1,
    // };

    // let response = await countryList(data);

    // if (tagAutomaticCountrySelect) {
    //   let location = await geolocation();
    //   console.log("location: ", location);

    //   if (
    //     response &&
    //     response?.list &&
    //     response?.list?.length > 0 &&
    //     location
    //   ) {
    //     let check = true;
    //     setAllCountriesList(response?.list);
    //     // setCountries(options);

    //     response?.list?.forEach((element) => {
    //       if (element?.code === location.country_code) {
    //         check = false;
    //         const imgUrl = url.imageUrl + element?.flag?.default;
    //         setDialCode(element?.dialingCode);
    //         setCountriesDialcode({
    //           value: element?.dialingCode,
    //           label: (
    //             <div>
    //               <img src={imgUrl} style={{ width: "15px" }} />{" "}
    //               {element?.dialingCode}
    //             </div>
    //           ),
    //           name: (
    //             <div>
    //               <img
    //                 src={imgUrl}
    //                 style={{ width: "25px", marginRight: "10px" }}
    //               />{" "}
    //               <b>{element?.name}</b> ({element?.dialingCode})
    //             </div>
    //           ),
    //         });
    //       }
    //     });

    //     if (check) setCountryData(response);
    //     else setIsCountryAvailable(true);
    //   } else {
    //     setCountryData(response);
    //   }
    // } else {
    //   if (response) {
    //     const options = [];
    //     response?.list?.map((item, index) => {
    //       setAllCountriesList(response?.list);
    //       const imgUrl = url.imageUrl + item?.flag?.default;
    //       options.push({
    //         value: item?.dialingCode,
    //         label: (
    //           <div>
    //             <img
    //               src={imgUrl}
    //               style={{ width: "20px", marginRight: "10px" }}
    //             />{" "}
    //             {item?.name}
    //           </div>
    //         ),
    //         name: (
    //           <div>
    //             <img
    //               src={imgUrl}
    //               style={{ width: "25px", marginRight: "10px" }}
    //             />{" "}
    //             <b>{item?.name}</b>
    //           </div>
    //         ),
    //       });
    //     });
    //     // setAllDialCode(options?.[0]);
    //     // setDialCode(response?.list?.[0].dialingCode);
    //     // setCountries(options);
    //     let index = null;
    //     options.filter((item, ind) => {
    //       if (item?.value == "+91") {
    //         index = ind;
    //         return index;
    //       }
    //     });
    //     setCountriesDialcode(options?.[index]);
    //     setCountries(options);
    //     // console.log(options?.[0])

    //     setAllDialCode(options?.[index]);
    //     setDialCode(options?.[index]?.value);
    //   }
    // }

    // if (tag === false) setShowLanguagePopup(false);
    // else setShowLanguagePopup(true);

    // let res = await getAllStateList({
    //   skip: 0,
    //   limit: 100,
    //   sortBy: "name",
    //   sort: "asc",
    // });
    // if (res) {
    //   console.log(countries_dialcode);
    //   setSelectedState(res?.list[0]);
    //   setAllStates(res?.list);
    // }

    let avatars = await getAllFreeAvatar({
      skip: 0,
      limit: 10,
      allAvatar: false,
    });
    // console.log("avatars: ", avatars);
    if (avatars) {
      let temp = [];
      avatars?.list?.map((item, index) => {
        temp.push({
          logo: item?.img?.default,
          name: item?.name,
          id: item?._id,
        });
      });
      setAllAvatars(temp);
    }
  }catch(error){

  }
  finally{
    setLoader(false)
  }
  }, []);

  // useEffect(() => {
  //   if (avatarId === "") {
  //     setDisableSubmit(true);
  //   } else if (allData?.state && allData?.state === "") {
  //     setDisableSubmit(true);
  //   } else if (allData?.dob && allData?.dob === "") {
  //     setDisableSubmit(true);
  //   } else if (allData?.email && allData?.email === "") {
  //     setDisableSubmit(true);
  //   } else if (allData?.gamerjiName && allData?.gamerjiName === "") {
  //     setDisableSubmit(true);
  //   } else {
  //     setDisableSubmit(false);
  //   }
  // }, [allData, avatarId]);

  const setCountryData = (response) => {
    if (response) {
      console.log(response);
      const options = [];
      response?.list?.forEach((item, index) => {
        setAllCountriesList(response?.list);
        const imgUrl = url.imageUrl + item?.flag?.default;
        options.push({
          value: item?.dialingCode,
          label: (
            <div>
              <img src={imgUrl} style={{ width: "15px" }} /> {item?.dialingCode}
            </div>
          ),
          name: (
            <div>
              <img
                src={imgUrl}
                style={{ width: "25px", marginRight: "10px" }}
              />{" "}
              <b>{item?.name}</b> ({item?.dialingCode})
            </div>
          ),
        });
      });
      setCountries(options);
      setCountriesDialcode(options?.[0]);
      // console.log(options?.[0])

      setAllDialCode(options?.[0]);
      setDialCode(options?.[0]?.value);
    }
  };

  const onSignUp = async () => {
    setIsBusy(true);
    var check = true;
    let country = allCountriesList?.filter(
      (val) => val?.dialingCode === dialCode
    )?.[0]?._id;
    let countryObj = allCountriesList?.filter(
      (val) => val?.dialingCode === dialCode
    )?.[0];

    setCountry(country);

    if (
      allData?.gamerjiName === "" ||
      allData?.gamerjiName === undefined ||
      allData?.gamerjiName === null
    ) {
      check = false;
      setIsBusy(false);
      toast.error(constants.error_Add_your_Gamerji_name, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    if (avatarId === "") {
      check = false;
      setIsBusy(false);
      toast.error(constants.error_Select_your_Gamerji_Avatar, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    if (check) {
      if (disableSubmit === true) {
        setIsBusy(false);
        setErrorMessage(true);
      } else {
        setErrorMessage(false);
        console.log("userLoginData==>", userLoginData);

        let data = {
          avatar: avatarId,
          // dateOfBirth: allData?.dob, //"12/01/1994",
          // email: allData?.email,
          gamerjiName: allData?.gamerjiName,
          isDetailsFilled: true,
          // phone: allData?.phone,
          //phoneCode: dialCode,
        };
        console.log(dialCode);
        // if (userCountryObj === null) {
        //   data.phoneCode = dialCode;
        //   if (dialCode === "+91" || dialCode === "91") {
        //     data.address = { country: country, state: selectedState?._id };
        //   } else {
        //     data.address = { country: country };
        //   }

        //   data.isEmail = true;
        //   localStorage.setItem("countryId", JSON.stringify(country));
        // }
        // else {
        //   if (selectedState?._id) {
        //     data.address = { country: country, state: selectedState?._id };
        //   }
        // }

        let response = await getUpdateProfile(data);
        console.log(response)
        if (response?.success) {
          // localStorage.setItem("userCountryObj", JSON.stringify(countryObj));
          setIsBusy(false);
          window.location.href = "/";
        } else {
          setIsBusy(false);
          toast.error(response?.data?.errors?.[0]?.msg, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
    }
  };

  const fieldChange = (e, key) => {
    var value = e.target.value;
    let temp = { ...allData };
    temp[key] = value;
    setAllData(temp);
  };

  const dobSelect = (e) => {
    var value = e.target.value;
    let temp = { ...allData };
    temp["dob"] = getProfiledateFormat(new Date(value));
    setAllData(temp);
  };

  const handleChangeCountry = (selectedOption) => {
    setDialCode(selectedOption?.value);
    setAllDialCode(selectedOption);
  };

  const getSignUpode = async () => {
    if (allData?.code && allData?.code !== "") {
      setErrorCodeMessage(false);
      await getsignUpCode({ name: allData?.code, type: "signupCode" });
    } else {
      setErrorCodeMessage(true);
    }
  };

  const createCountryListPopup = (countries) => {
    // console.log(countries_dialcode)
    return (
      <div className="country_list_popup_component">
        <div className="country_list_popup">
          <div className="country_list_popup_header">
            <div></div>
            <div className="country_list_popup_header_text">Select Country</div>
            <div>
              <svg
                onClick={() => setTagCountriesDialcodePopup(false)}
                style={{ cursor: "pointer" }}
                id=""
                data-name="cancel (1)"
                xmlns="http://www.w3.org/2000/svg"
                width="30.001"
                height="30"
                viewBox="0 0 34.001 34"
              >
                <path
                  id="Path_2963"
                  data-name="Path 2963"
                  d="M34,17A17,17,0,1,0,17,34,17,17,0,0,0,34,17Z"
                  transform="translate(0 -0.003)"
                  fill="#ef1f36"
                />
                <path
                  id="Path_2964"
                  data-name="Path 2964"
                  d="M150.574,135.871l4.97-5.77a1.5,1.5,0,0,0-2.279-1.963l-4.676,5.428-4.676-5.428a1.5,1.5,0,1,0-2.279,1.963l4.97,5.77-4.97,5.77a1.5,1.5,0,0,0,2.279,1.963l4.676-5.428,4.676,5.428a1.5,1.5,0,0,0,2.279-1.963Z"
                  transform="translate(-131.589 -118.87)"
                  fill="#fff"
                />
              </svg>
            </div>
          </div>

          <div className="country_list_popup_body">
            {countries.map((value, i) => {
              return (
                <div
                  className="country_list_popup_body_text"
                  key={i}
                  onClick={() => {
                    console.log(value);
                    setCountriesDialcode(value);
                    setDialCode(value.value);
                    setTagCountriesDialcodePopup(false);
                  }}
                >
                  {value.name}
                  {/* Hello */}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };
  const createStaetListPopup = (countries) => {
    // console.log(countries_dialcode)
    return (
      <div className="country_list_popup_component">
        <div className="country_list_popup">
          <div className="country_list_popup_header">
            <div></div>
            <div className="country_list_popup_header_text">Select State</div>
            <div>
              <svg
                onClick={() => setTagStateDialcodePopup(false)}
                style={{ cursor: "pointer" }}
                id=""
                data-name="cancel (1)"
                xmlns="http://www.w3.org/2000/svg"
                width="30.001"
                height="30"
                viewBox="0 0 34.001 34"
              >
                <path
                  id="Path_2963"
                  data-name="Path 2963"
                  d="M34,17A17,17,0,1,0,17,34,17,17,0,0,0,34,17Z"
                  transform="translate(0 -0.003)"
                  fill="#ef1f36"
                />
                <path
                  id="Path_2964"
                  data-name="Path 2964"
                  d="M150.574,135.871l4.97-5.77a1.5,1.5,0,0,0-2.279-1.963l-4.676,5.428-4.676-5.428a1.5,1.5,0,1,0-2.279,1.963l4.97,5.77-4.97,5.77a1.5,1.5,0,0,0,2.279,1.963l4.676-5.428,4.676,5.428a1.5,1.5,0,0,0,2.279-1.963Z"
                  transform="translate(-131.589 -118.87)"
                  fill="#fff"
                />
              </svg>
            </div>
          </div>

          <div className="country_list_popup_body">
            {allStates.map((value, i) => {
              return (
                <div
                  className="country_list_popup_body_text"
                  key={i}
                  onClick={() => {
                    console.log(value);
                    setSelectedState(value);
                    setTagStateDialcodePopup(false);
                  }}
                >
                  {value.name}
                  {/* Hello */}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  if(loader) return <Loader />

  return (
    <div className="signup-main">
      <ToastContainer />
      {tag_countries_dialcode_popup ? createCountryListPopup(countries) : ""}
      {/* {tag_state_dialcode_popup ? createStaetListPopup(allStates) : ""} */}
      <div className="body bg-dark" style={{ paddingTop: "0" }}>
      {/* <div dangerouslySetInnerHTML={{__html:`<a  onLoad="mp.getUserAuthCode(['OPEN_ID','UNION_ID','USER_NAME','USER_BIRTHDAY','USER_MSISDN'])">Login</a>`}}/> */}
        <div
          className="row bg-dark"
          style={{
            backgroundImage: `url(${SignUpBg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "right -420px",
            padding: "10px",
            marginLeft:'0',
            marginRight:'0'
          }}
        >
          <div
            className="col-12 col-md-12 text-light"
            style={{ paddingTop: "5em" }}
          >
            <h1>Hey!</h1>
            <h3>Welcome to EasyPaisa</h3>
          </div>

          <div
            className="row mt-4 align-items-center justify-content-center"
            style={{ paddingBottom: "25px", position: "relative" }}
          >
            <div
              className="bg-primary text-dark yellow-signup-box"
              style={{ marginBottom: "12px", width:'90%' }}
            >
              <p style={{ marginBottom: "25px" }}>
                First, lets give yourself a unique gaming identity by having a
                easypaisa username
              </p>
            </div>
            <div
              className="col-12 d-flex align-items-center justify-content-center"
              style={{ width: "91%", position: "absolute", bottom: "0" }}
            >
              <div className="form-group d-flex w-100">
                <input
                  type="email"
                  className="form-control yellow-signup-box"
                  onChange={(e) => fieldChange(e, "gamerjiName")}
                  placeholder="Enter Easypaisa Username"
                  required=""
                />
                {/* <small>
                Note: "Gamerji UserName" is a unique name and cannot be changed
                once selected
              </small> */}
              </div>

            </div>
          </div>

          {/* {userCountryObj === null ? (
            <div
              className="row mt-4 align-items-center justify-content-center"
              style={{ paddingBottom: "25px", position: "relative" }}
            >
              <div
                className="bg-primary text-dark yellow-signup-box"
                style={{ marginBottom: "12px" }}
              >
                <p style={{ marginBottom: "25px" }}>
                  Please select your country to see tournaments that are
                  customized for your region.
                </p>
              </div>
              <div
                className="col-12 d-flex align-items-center justify-content-center"
                style={{ width: "91%", position: "absolute", bottom: "0" }}
              >
                <div className="form-group d-flex w-100">

                  {tagAutomaticCountrySelect ? (
                    isCountryAvailable ? (
                      <div
                        className="sign_in_country_code_popup_button text-center"
                        style={{
                          width: "80%",
                          height: "42px",
                          margin: "auto",
                          border: "1px solid var(--color_black)",
                        }}
                      >
                        {countries_dialcode.label}
                      </div>
                    ) : countries_dialcode.label ? (
                      <button
                        className="sign_in_country_code_popup_button"
                        onClick={(e) => {
                          setTagCountriesDialcodePopup(true);
                        }}
                        style={{
                          width: "80%",
                          height: "42px",
                          margin: "auto",
                          border: "1px solid var(--color_black)",
                        }}
                      >
                        {countries_dialcode.label}
                        <svg
                          className=""
                          width="25"
                          height="25"
                          viewBox="0 0 45 45"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            id=""
                            d="M34 18.34a2 2 0 0 0-2.82 0L24 25.42l-7.08-7.08a2 2 0 1 0-2.82 2.84l8.48 8.48a2.003 2.003 0 0 0 2.19.438c.243-.101.464-.25.65-.438L34 21.18a2 2 0 0 0 0-2.84z"
                            fill="#D3D3D3"
                          ></path>
                        </svg>
                      </button>
                    ) : (
                      <div className="sign_in_country_code_popup_button">
                        <Spinner
                          animation="border"
                          style={{ width: "20px", height: "20px" }}
                        />
                      </div>
                    )
                  ) : (
                    <button
                      className="sign_in_country_code_popup_button"
                      onClick={(e) => {
                        setTagCountriesDialcodePopup(true);
                      }}
                      style={{
                        width: "80%",
                        height: "42px",
                        margin: "auto",
                        border: "1px solid var(--color_black)",
                      }}
                    >
                      {countries_dialcode?.label}
                      <svg
                        className=""
                        width="25"
                        height="25"
                        viewBox="0 0 45 45"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          id=""
                          d="M34 18.34a2 2 0 0 0-2.82 0L24 25.42l-7.08-7.08a2 2 0 1 0-2.82 2.84l8.48 8.48a2.003 2.003 0 0 0 2.19.438c.243-.101.464-.25.65-.438L34 21.18a2 2 0 0 0 0-2.84z"
                          fill="#D3D3D3"
                        ></path>
                      </svg>
                    </button>
                  )}
                </div>
              </div>
            </div>
          ) : (
            ""
          )} */}
          {console.log(userCountryObj, '--------------------')}
          {/* {userCountryObj !== null && (userCountryObj?.dialingCode === "+91" ||
            userCountryObj?.dialingCode === "91") && (
              <div
                className="row mt-4 align-items-center justify-content-center"
                style={{ paddingBottom: "25px", position: "relative" }}
              >
                <div
                  className="bg-primary text-dark yellow-signup-box"
                  style={{ marginBottom: "12px" }}
                >
                  <p style={{ marginBottom: "25px" }}>
                    Please select your state to see tournaments that are
                    customized for your region.
                  </p>
                </div>
                <div
                  className="col-12 d-flex align-items-center justify-content-center"
                  style={{ width: "91%", position: "absolute", bottom: "0" }}
                >
                  <div className="form-group d-flex w-100">
                    {
                      <button
                        className="sign_in_country_code_popup_button"
                        onClick={(e) => {
                          setTagStateDialcodePopup(true);
                        }}
                        style={{
                          width: "80%",
                          height: "42px",
                          margin: "auto",
                          border: "1px solid var(--color_black)",
                        }}
                      >
                        {selectedState?.name}
                        <svg
                          className=""
                          width="25"
                          height="25"
                          viewBox="0 0 45 45"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            id=""
                            d="M34 18.34a2 2 0 0 0-2.82 0L24 25.42l-7.08-7.08a2 2 0 1 0-2.82 2.84l8.48 8.48a2.003 2.003 0 0 0 2.19.438c.243-.101.464-.25.65-.438L34 21.18a2 2 0 0 0 0-2.84z"
                            fill="#D3D3D3"
                          ></path>
                        </svg>
                      </button>
                    }
                  </div>
                </div>
              </div>
            )} */}
          <div
            className="d-flex mt-3"
            style={{
              height: "450px",
              position: "relative",
              overflow: "hidden",
            }}
          >
            <div
              className="bg-primary text-dark yellow-signup-box"
              style={{ height: "400px", width:'90%' }}
            >
              <p>
                Now, choose a cool avatar to go with your profile! You can
                always change this or unlock new ones.
              </p>

              <div
                className="bg-light signup_main_items yellow-signup-box custom_scroll"
                style={{
                  // position: "absolute",
                  width: "95%",
                  // right: "0",
                  // left: "-25px",
                  // bottom: "-40px",
                  height: "330px",
                  textAlign: "center",
                  overflowX: "auto",
                  overflowY: "auto",
                }}
              >
                <div className="scrolling-wrapper row flex-row flex-nowrap">
                  {allAvatars?.map((item, index) => {
                    return (
                      <div
                        className="col-5 pb-3"
                        style={{ margin: "0px 8px", padding: "0px 8px" }}
                        key={index}
                      >
                        <div
                          className={`item p-2 ${item.id === avatarId ? "activeAvatarOne" : ""
                            }`}
                          style={{ width: "120px" }}
                          onClick={() => setAvatarId(item?.id)}
                        >
                          {item.id === avatarId && (
                            <i className="fa fa-check-circle" />
                          )}
                          <img src={url.imageUrl + item?.logo} />
                        </div>
                        <p
                          className="mt-2"
                          style={{
                            textAlign: "center",
                            fontWeight: "bold",
                            width: "130px",
                          }}
                        >
                          {item?.name}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            {errorMessage === true && (
              <p className="text-secondary">Please enter required data! </p>
            )}
          </div>
          <div className="row signup-submit">
            <button
              className="btn main-btn signup_button"
              onClick={onSignUp}
              disabled={isbusy}
            >
              SUBMIT{" "}
              {!isbusy ? (
                <img src={RightArrow} alt="" />
              ) : (
                <img src={roller} alt="" style={{ maxWidth: 30 }} />
              )}
              {/* SUBMIT <img src={RightArrow} alt="" /> */}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default SignUp;
