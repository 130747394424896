import React from "react";
import ReactDOM from "react-dom";
import "react-intl-tel-input/dist/main.css";
import { Row, Col } from "react-bootstrap";
import {
  woeUsersList,
} from "../apis";
import Loader from "../component/loader";

import Advertisement from "../component/advertisement";

import woe_top_profiles_avatar from "../assets/images/woe_top_profiles_avatar.jpg";
import './world-of-esports.css';
import Previous from "../assets/images/previous.svg";
import { useTranslation, Trans, withTranslation } from "react-i18next";
class WOEAllTopProfiles extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      WOEActiveScreen: this.props.WOEActiveScreen,

      limit: 10,
      top_profiles: [],
      limitUsers: 0,
      tag_top_profiles_loader: false,
    };

    // this.onChangeWOEActiveScreen = this.onChangeWOEActiveScreen.bind(this);
  }

  componentDidMount() {
    this.woeUsersListApiCall();
  }

  async woeUsersListApiCall() {
    this.setState({ tag_top_profiles_loader: true })
    console.log(this.state.tag_top_profiles_loader)
    let data = {
      skip: this.state.top_profiles.length,
      limit: 10,
      type: ['appUser'],
      sort: 'desc',
      sortBy: 'followers',
      action: 'topProfile',
    };

    let response = await woeUsersList(data);
    console.log(data)
    console.log(response);
    if (response?.success) {
      console.log('list' in response, "respn")
      if ('list' in response) {
       
        this.setState({ top_profiles: [...this.state.top_profiles,...response?.list], });
        this.setState({limitUsers:response?.list?.length})
        this.setState({ tag_top_profiles_loader: false })
        console.log(this.state.tag_top_profiles_loader)
        
      }
    }
    
    this.setState({ tag_top_profiles_loader: false, })
   
  }

  createWOEAllTopProfiles() {
    const { t } = this.props;

    return (
      <div className="inner-wrap woe_main_div_screen_2">
        <div className="header">
          <div className="back-btn"
            // // style={{ transform: 'rotate(180deg)', cursor: 'pointer' }}
            onClick={(e) => this.props.onChangeWOEActiveScreen(0)}>
            {/* <Link to="/">
              <img src={Previous} alt="" />
            </Link> */}
            <img src={Previous} alt="" />

            {/* <svg xmlns="http://www.w3.org/2000/svg" width="21.334" height="18.014" viewBox="0 0 21.334 18.014">
              <g id="ico" transform="translate(21.334 18.013) rotate(-180)">
                <path id="union" d="M8.321,17.721.479,9.879A1,1,0,0,1,.125,8.541,1.005,1.005,0,0,1,.411,8.2L8.321.293A1,1,0,0,1,9.735,1.706L3.417,8.025H20.334a1,1,0,1,1,0,2H3.454l6.281,6.281a1,1,0,0,1-1.414,1.415Z" transform="translate(0 0)" fill="#fff" />
              </g>
            </svg> */}
          </div>
          <h2>{t('woe.top_profiles')}</h2>
          <div className="wallet-btn">
            {/* <Link to="">
              <img src={Wallet} alt="" />
              Wallet
            </Link> */}
          </div>
        </div>
            {console.log(this.state.top_profiles.length)}
        <div className="body bottom-btn-body woe_main_div_screen_2_body" style={{ paddingTop: '0', }}>
          <div className="all_sub_screens_div_related_to_ads">
            <Row className="m-0" style={{ padding: '20px 0', justifyContent: 'center' }}>
              {
                this.state.top_profiles.map((value, i) => {
                  return (
                    <Col className="woe_screens_box_col p-2 m-0" xl={6} lg={6} md={6} sm={6} xs={12} key={i}>
                      <div className="woe_top_profiles_box_screen_2" key={i}
                        onClick={(e) => { 
                          let allpage = [...JSON.parse(localStorage.getItem("currentPage"))];
                          var count = allpage.findIndex((d) => d === window.location.pathname);
                          count === -1 && allpage.push(window.location.pathname);
                          localStorage.setItem("currentPage", JSON.stringify(allpage));
                          window.location.href = `/user-profile/${value._id}`; 
                        }}
                      >
                        <img
                          src={woe_top_profiles_avatar}
                          // style={{ width: '35px', height: '35px' }}
                          alt="profile"
                          className="woe_top_profiles_image_screen_2"
                        />
                        <div className="woe_top_profiles_box_col_screen_2">
                          <div className="woe_top_profiles_player_gamerji_name">
                            {value?.gamerjiName}
                          </div>
                          <div className="woe_top_profiles_player_followers">
                            {value?.followers} {value?.followers == '' ? '' : t('woe.followers')}
                          </div>
                        </div>
                        <div className="woe_top_profiles_follow_button_screen_2">{value?.isFollow ? `${t('woe.following')}` : `${t('woe.follow')}`}</div>
                      </div>
                    </Col>
                  )
                })
              }
            </Row>
            {console.log("limit users", this.state.limitUsers)}
            {this.state.limitUsers >= 10 && (
                      <Row className="woe_screen_1_load_more_button_row justify-content-center m-0" style={{ padding: '10px' }}>
                      
                        
                        <button
                        className="woe_screen_1_load_more_button"
                        onClick={() => this.woeUsersListApiCall()}
                      >
                        {t('tournaments.load_more')}
                      </button>
                      
                    </Row>
                     
                    
                )}
                
          </div>
          {
             !JSON.parse(localStorage.getItem("premiumUser")) && 
          
          <Advertisement
            screen={
              localStorage.getItem('apk_screens_list') === null ?
                { code: 'no_results' }
                :
                JSON.parse(localStorage.getItem('apk_screens_list')).viewAllTopProfiles
            }
            screen_div_class_name='.all_sub_screens_div_related_to_ads'
            screen_type='sub'
          />
  }
        </div>
      </div>
    )
  }

  render() {
    return (
      <div>
        {console.log(this.state.tag_top_profiles_loader)}
        {this.state.tag_top_profiles_loader ? <Loader /> : ''}
        {this.createWOEAllTopProfiles()}
       
      </div>
    );
  }
}
// export default WOEAllTopProfiles;
export default withTranslation()(WOEAllTopProfiles);