import { Captioncard } from "./Captioncard";
import { PlayerCard } from "./PlayersCard";
import rightArrow from "../../assets/images/right-arrow.svg";
import { onCreateScrim } from "../../apis";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Previous from "../../assets/images/previous.svg";
import { useTranslation } from "react-i18next";
import Cookies from 'js-cookie'
import { constants } from "../../constants/constants";

export const ScrimSquadRegistration = ({ scrimData, players, setShowTeam }) => {
  const [joinedPlayers, setJoinedPlayers] = useState([]);
  const {t} = useTranslation()
  const currentLang =  Cookies.get('i18next') || 'EN'

  const handleInputChange = (event) => {
    const value = event.target.value;
    setTeamName(value);
    console.log(event.target.value);
  };

  const [teamName, setTeamName] = useState("");
  const navigate = useNavigate()
  var gameid =
    window.location.href.split("/")[window.location.href.split("/").length - 1];
  // console.log("total_players: ", this.state.total_players);
  console.log("gameid: ", gameid);

  const SaveScrimTeam = async () => {
    const gameNames = JSON.parse(localStorage.getItem("userInGameNames"));
    var user_data = JSON.parse(localStorage.getItem(constants.profileLite));
    var scrim_data = JSON.parse(localStorage.getItem("scrimData"));
    var players_data = JSON.parse(localStorage.getItem("players_data"));
    console.log(players);
    console.log("scrim Data", scrim_data)

    const isPlayerAlreadyJoined = joinedPlayers.some(
      (player) => player.userId === players_data[0]?.userId
    );
      console.log("playes data", players_data)
    const isAnyPlayerEmpty = players_data.slice(0,players).some(
      (player) => !player.inGameName || !player.gamerJiName
    );
    console.log(isAnyPlayerEmpty);

    if (teamName.trim() === "" || isAnyPlayerEmpty) {
      if (teamName.trim() === "") {
        showError(t('error_team_name_is_required'));
      } else if (isPlayerAlreadyJoined) {
        showError(t('error_you_have_already_joined_a_team'));
      } else {
        showError(t('error_please_enter_players_details'));
      }
      return;
    }
    players_data = players_data.filter((item,index) => item?.userId!='')
    console.log(players_data, "jhkd");
    console.log(scrim_data?._id, "whejkk");

    let team = players_data.map((player) => ({
      userId: player.userId,
      inGameName: player.inGameName,
      gamerJiName: player.gamerJiName,
      country: "India",
      mobileNo: player.email ? player.email : player.mobileNo,
      email: player.email ? player.email : player.mobileNo,
    }));
    var user_data = JSON.parse(localStorage.getItem(constants.profileLite));
    localStorage.removeItem('players_data');
    localStorage.removeItem('scrimData');
    
    let data = {
      scrimId: scrim_data?._id,
      userId: user_data.item.user_id,
      usersInGameName: gameNames[0].userINGameName,
      gamerJiName: user_data?.item.gamerjiName,
      team: team,
      teamName: teamName,
      mobileNo :user_data?.item?.phone ? user_data?.item?.phone : user_data?.item?.email
    };

    let res = await onCreateScrim(data);
    console.log(res);

    if (res.success === true) {
      setJoinedPlayers([...joinedPlayers, players_data[0]]);
      if (teamName.trim() !== "") {
        // window.location.href = "/scrim-details/" + scrim_data?._id;
        setShowTeam()
        navigate("/scrim-details/" + scrim_data?._id)
      } else {
        console.log("Team name is empty");
      }
    } else {
      console.log(res.data.errors[0]);
      showError(res.data.errors[0].msg);
    }
  };

  const showError = (err) => {
    toast.error(err, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <div
      className="custom-popup join-team custom_scroll show"
      id="contact_popup"
      style={{ padding: "0px", height: "100vh" }}
    >
      <div className="header">
        <div className="back-btn">
          <Link to="#" onClick={() => setShowTeam()}>
            <img src={Previous} alt="" />
          </Link>
        </div>
        <h2>{t('team_register.team_registeration')}</h2>
        <div className="wallet-btn"></div>
      </div>

      <div
        className="body bottom-btn-body"
        style={{
          width: "100%",
          padding: 0,
          margin: 0,
          top: 65,
          position: "absolute",
        }}
      >
        <div className="all_sub_screens_div_related_to_ads">
          <div className="squad_registration_body_scrollable_component mb-4">
            <div className="width8 relative">
              <input
                className="width scrim-team-name"
                type="text"
                maxLength={64}
                onChange={handleInputChange}
              />
              <div className="scrim-team-name-title">{t('team_register.team_name')} :</div>
            </div>
            <Captioncard data={scrimData} />

            <PlayerCard players={players} />

            <div className="row squad_registration_caption_player_row">
              <button
                style={currentLang?.toLowerCase() == "ar" ? { width: "80%", margin: "0px 48px 20px 0px" } : { width: "80%", margin: "0px 0px 20px 48px"}}
                className="btn btn-dark main-btn"
                onClick={() => SaveScrimTeam()}
              >
                {t('team_register.save_team')}{" "}
                <img src={rightArrow} alt="" className="mCS_img_loaded" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
