import React, { Fragment, useEffect, useState } from "react";
import Logo from "../assets/images/logo.svg";
// import OtpImg from "../assets/images/otp-image.svg";
import "react-intl-tel-input/dist/main.css";
import { Link } from "react-router-dom";
import {
  signIn,
  onSignUp,
  // signInInternational,
  // signUpInternational,
} from "../apis";
import SignUp from "./signup";
import rightArrow from "../assets/images/right-arrow.svg";
import Adjust from "@adjustcom/adjust-web-sdk";
import Loader from "../component/loader";
import roller from "../assets/images/rolling.gif";
import { useTranslation, Trans } from "react-i18next";

function OTP(props) {
  const { t } = useTranslation()
  // const [otpNo, setOtpNo] = useState([0, 0, 0, 0, 0, 0]);
  const [otpNumber, setOtpNumber] = useState("");
  const [timer, setTimer] = useState(30);
  const [errorMsg, setErrorMsg] = useState("");
  const [loader, setLoader] = useState(false);
  const [isbusy, setIsBusy] = useState(false);

  useEffect(() => {
    var count = timer > 0 && setInterval(() => setTimer(timer - 1), 1000);
    return () => clearInterval(count);
  }, [timer]);

  useEffect(() => {
    console.log(document.referrer, "hy");
    const path = localStorage.getItem("currentOTPtype");
    console.log(path);
    console.log(props.phonenumber);
    if (props.phonenumber === undefined && path === "signup") {
      window.location.href = "/register";
    } else if (props.phonenumber === undefined && path === "login") {
      window.location.href = "/";
    }
  }, []);

  function getCodeBoxElement(index) {
    return document.getElementById("codeBox" + index);
  }

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    props.onBack()
  }

  useEffect(() => {
    // window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, []);

  async function onSubmit() {
    setErrorMsg("");
    // setLoader(true);
    if (otpNumber === '') {
      setErrorMsg(t('error_enter_code'))
      return;
    }
    let data = {
      username: props.phonenumber,
      phoneCode: props.code,
      //"code": props.couponCode,
      otp: otpNumber,
      type: "validateOTP",
    };
    console.log(data.username);

    if (localStorage.getItem("currentOTPtype") === "login") {
      setIsBusy(true);
      let response = await signIn(data);
      setLoader(false);
      if (response && response?.success == true) {
        setIsBusy(false);
        localStorage.setItem("gamerjiToken", response.token);
        localStorage.setItem("countryId", JSON.stringify(response?.item?.address?.country));
        window.location.href = "/";
      } else {
        setIsBusy(false);
        setErrorMsg(
          response?.data?.errors
            ? response?.data?.errors?.[0]?.msg
            : "Something went wrong!"
        );
      }
    } else {
      let response = await onSignUp(data);
      // setLoader(false);
      setIsBusy(true);
      if (response && response?.success == true) {
        setIsBusy(false);
        localStorage.setItem("gamerjiToken", response.token);
        localStorage.setItem("gamerjiUserInfo", JSON.stringify(response));
        window.location.href = "/sign-up";
        localStorage.setItem('gamerjiToken', response.token);
        localStorage.setItem('gamerjiUserInfo', JSON.stringify(response));
        localStorage.setItem("countryId", JSON.stringify(response?.item?.address?.country));

        window.location.href = '/sign-up';

      } else {
        setIsBusy(false);
        setErrorMsg(
          response?.data?.errors
            ? response?.data?.errors?.[0]?.msg
            : "Something went wrong!"
        );
      }
    }
  }

  // async function onKeyUpEvent(index, event) {
  //   const eventCode = event.which || event.keyCode;
  //   let temp = [...otpNo];
  //   if (getCodeBoxElement(index).value.length === 1) {
  //     temp[index - 1] = event.target.value;
  //     setOtpNo(temp);
  //     if (index !== 6) {
  //       getCodeBoxElement(index + 1).focus();
  //     } else {
  //       getCodeBoxElement(index).blur();
  //       // let data = {
  //       //   "username": props.phonenumber,
  //       //   "otp": temp.join(''),
  //       //   "type": "validateOTP"
  //       // }
  //       // let response = await signIn(data);
  //       // if(response){
  //       //   localStorage.setItem('gamerjiToken', response.token);
  //       //   if(response.item.isDetailsFilled === true) {
  //       window.location.href = "/";
  //       //   } else {
  //       window.location.href = "/sign-up";
  //       //   }
  //       // }
  //       // setRedirect(true);
  //     }
  //   }
  //   if (eventCode === 8 && index !== 1) {
  //     getCodeBoxElement(index - 1).focus();
  //   }
  // }

  async function resetOTP() {
    let data = {
      username: props.phonenumber,
      type: "otpRequest",
      phoneCode: props.code,
      // 'password':'aaa'
    };

    let response = await signIn(data);
    setTimer(30);
  }

  // function onFocusEvent(index) {
  //   for (var item = 1; item < index; item++) {
  //     const currentElement = getCodeBoxElement(item);
  //     if (!currentElement.value) {
  //       currentElement.focus();
  //       break;
  //     }
  //   }
  // }

  const onChangeOtpNumber = (val) => {
    val.length <= 6 && setOtpNumber(val);
  };

  return (
    <Fragment>
      <div className="header">
        <div className="logo">
          <Link to="">
            <img
              src={Logo}
              className="d-block mx-auto"
              alt=""
              style={{ width: "150px" }}
            />
          </Link>
        </div>
      </div>
      <div
        className="body mCustomScrollbar _mCS_1"
        style={{ paddingTop: "30px" }}
      >
        <div className="otp-title">Enter Verification Code</div>
        <p className="text-center mb-1">
          We have sent a verification code on your number <br />
          <b>
            {props.code} - {props.phonenumber}
          </b>
        </p>
        <p className="text-center">Enter the verification code you received</p>

        <input
          className="otp_input_single_box text-dark"
          placeholder="Enter Verification Code"
          type="number"
          onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
          onChange={(e) => onChangeOtpNumber(e.target.value)}
          value={otpNumber}
        />

        <div className="row">
          <button
            style={{ width: "90%", margin: "0px auto 20px auto" }}
            className="btn btn-dark main-btn"
            onClick={() => onSubmit()}
          >
            SUBMIT{" "}
            {!isbusy ? (
              <img src={rightArrow} alt="" className="mCS_img_loaded" />
            ) : (
              <img src={roller} alt="" style={{ maxWidth: 30 }} />
            )}
          </button>
        </div>
        {errorMsg !== "" && <p className="text-center error">{errorMsg}</p>}
        <p className="text-center" style={{ marginBottom: "0.5rem" }}>
          Didn't receive the Verification Code?
        </p>
        {timer === 0 ? (
          <p className="text-center">
            <a className="text-secondary pointer" onClick={resetOTP}>
              <b>
                <u>Resend Verification Code</u>
              </b>
            </a>
          </p>
        ) : (
          <p className="text-center">
            Request for a new one in {timer} Seconds
          </p>
        )}
      </div>
    </Fragment>
  );
}
export default OTP;
