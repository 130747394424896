import React, { Fragment, useEffect, useState } from "react";
import AllGames from "../assets/images/all-games.svg";
import GameIconSmall1 from "../assets/images/game-icon-small1.svg";
import GameIconSmall2 from "../assets/images/game-icon-small2.svg";
import { Link, useNavigate } from "react-router-dom";
import { allGames, getUserInGameName, html5SettingsList } from "../apis";
import { convertNumberToMillions, dateFormat, kFormatter } from "../component/common";
import url from "../constants/url";
import Loader from "../component/loader";
// import { useTranslation } from "react-i18next";
import { useTranslation, Trans, withTranslation } from "react-i18next";
import { eventTracking } from "../firebaseAnalytics";
import { events } from "../utils/appEvents";

function Allgames(props) {
  const [loader, setLoader] = useState(false);
  const [allData, setAllData] = useState([]);
  const { t } = useTranslation();
  useEffect(() => {
    EventTracking();
  }, []);
  const EventTracking = () => {
    eventTracking(events.all_games_menu);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoader(true);
    let temp = [];
    // ***********************Free Games are not in the Project ********************************
    // let response = await html5SettingsList();
    // // console.log('Free Games: ', response);

    // if (response) {
    //   response?.list?.length &&
    //     temp.push({
    //       id: "freeGame001",
    //       img: url.imageUrl + response?.list?.[0]?.featuredImage?.default,
    //       name: response?.list?.[0]?.title,
    //       tournaments:   'games-categories',
    //      // tournaments: response?.list?.[0]?.isTournament === true ? 'tournaments' : 'games-categories',
    //       join: convertNumberToMillions(response && 'totalJoinedPlayers' in response ?
    //         response?.totalJoinedPlayers : 0),
    //     })
    // }

    let data = {
      pagination: {
        pageNo: 1,
        recordPerPage: 100,
        sortBy: "order",
        sortDirection: "asc",
      },
    };
    let response = await allGames(data);
    // console.log('All Games: ', response);
    if (response) {
      response?.data?.length &&
        response?.data?.map(async (item, index) => {
          temp.push({
            id: item._id,
            img: url.imageUrl + item?.featuredImage?.default,
            tournamentFeaturedImage: url.imageUrl + item?.tournamentFeaturedImage?.default,
            name: item?.name,
            tournamentGameID:item?.tournamentGameID,
            isTournament: item?.isTournament,
            tournaments: 'tournaments',
            join: convertNumberToMillions(item && 'playedCount' in item ? item?.playedCount : 0),
          });
        });
      let featured = [];
      response?.data?.length &&
        response?.data?.map(async (item, index) => {
          featured.push({
            id: item._id,
            tournamentFeaturedImage: url.imageUrl + item?.tournamentFeaturedImage?.default,
          });
        });
      localStorage.setItem("tournamentImgs", JSON.stringify(featured));

      setLoader(false);
    }
    setAllData(temp);
  };
  const navigate = useNavigate();
  return (
    <Fragment>
      <h2 className="icon-title">
        <span className="icon">
          <img src={AllGames} alt="" />
        </span>
        {t("home.all_games")}
      </h2>

      {loader ? (
        <Loader />
      ) : (
        <div className="games_items">
          {allData?.map((item, index) => {
            return (
              <div className="item" key={index}>
                {/* // eslint-disable-next-line */}
                <Link
                  onClick={() => {
                    eventTracking(events.games, {
                      EP_GameName: item?.name,
                      EP_GameID: item?.id,
                    });
                    // navigate(`/${item?.tournaments}/${item?.id}`);
                    // localStorage.setItem("activeGameId", item?.id);
                    // localStorage.setItem("activeGameName", item?.name);
                    
                  }}
                  to={
                    `/${item?.tournaments}/${item?.id}`} 
                    state={{
                     tournamentGameID:item?.tournamentGameID,
                     _id: item?.id,
                     name: item?.name,
                     isTournament:item?.isTournament
   
   
                     }} 
                  className="d-block pointer">
                  <div className="box">
                    {/* <marquee> */}
                    <b className="all_games_page_all_games_title text_animation_container">{item?.name}</b>
                    {/* </marquee> */}
                    <div className="img">
                      <img src={item.img} alt="" />
                      <div className="img-caption">
                        <div className="img-caption-item text_animation_container">
                          <img src={GameIconSmall1} alt="" />
                          <p>
                            <span style={{ fontWeight: "700" }}>{item?.join}</span> <span>{t("home.played")}</span>{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      )}
    </Fragment>
  );
}
export default Allgames;
