import { useEffect, useState } from "react";
import { getCollegeList } from "../apis";
import { useTranslation } from "react-i18next";

export const CollegePopup = (props) => {
  const { onSelectItem } = props;
  const[ collegeList, setCollegeList] = useState([]);
  const [close, setClose] = useState(true);
 const [loading, setLoading] = useState(false)
 const [limitData, setLimitData] = useState(0)
 const [searchQuery, setSearchQuery] = useState('')
 const {t} = useTranslation()

  const fetchData = async () => {
    setLoading(true);
    
   
      
      // call college list api
      let res = await getCollegeList({
        "sort": "desc",                              
        "skip": collegeList?.length,
        "limit": 10,
        "sortBy": "createdAt",
     
        "q": searchQuery
      });
      if (res) {
        console.log("College: ", res?.list);
        setCollegeList([...collegeList,...res?.list]);
        setLimitData(res?.list?.length)
      }
      setLoading(false);
    

  }

useEffect(() =>{
  fetchData()
},[])
  const closeModal = () => {
    setClose(false);
  };
  const handleItem = (item) => {
    console.log(item.name);
    onSelectItem(item);
    closeModal();
  };

  const onSearchHandler = async()=>{
    console.log(searchQuery)
    setLoading(true);
    
   
      
      // call college list api
      let res = await getCollegeList({
        // "sort": "desc",                              
        "skip": 0,
        "limit": 10,
        "sortBy": "createdAt",
     
         "q": searchQuery
      });
      if (res) {
        console.log("College: ", res?.list);
        setCollegeList(res?.list)
        setLimitData(res?.list?.length)
       
      }
      setLoading(false);
  }

  return (
<>
    {close && (
        <div className={` country_list_popup_component left ${close ? "show" : "hide"}`}>
        <div className="country_list_popup color">
          <div className="country_list_popup_header">
            <div></div>
            <div className="country_list_popup_header_text color">{t('error_select_collage')} </div>
            <div className="button-section" >
            <button  onClick={() => closeModal()}  className="close-button"></button>  
            </div>
          </div>
  
          <div className="country_list_popup_body">
            <div className="searchCollege">
              <input placeholder="Search.."  onChange={(e)=> setSearchQuery(e.target.value)}/> 
              <button onClick={onSearchHandler}>{t('leaderboard.search')}</button>
            </div>
            {collegeList.length> 0 ?  collegeList?.map((item, index) => {
              return (
                <div
                  className="country_list_popup_body_text"
                  key={index}
                  value={item?._id}
                  onClick={() => handleItem(item)}
               
                >
        
  {item.name}
               
                </div>
              );
            })
          : <p style={{marginTop:'35%', fontSize:'20px', textAlign:'center'}}>{t('notFound.no_records_found')} found</p>}

        


          </div>
          {
            limitData >=10 ?
            <button className="btn bg-yellow" onClick={fetchData}>{loading ? "Loading..." : "Load More"}</button>
            : <></>
          }
          
        </div>
      </div>
    )}
</>
  );
};
