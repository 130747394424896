import React, { Fragment, useEffect, useState } from "react";
import Previous from "../assets/images/previous.svg";
import Loader from "../component/loader";
import { Link } from "react-router-dom";
import { getSearchUserList } from "../apis";
import url from "../constants/url";
import { useTranslation } from "react-i18next";

function SearchUser(props) {
  const [searchUserList, setSearchUserData] = useState(null);
  const [userList, setUserData] = useState([]);
  const [searchTrue, setSeachTrue] = useState(false)

  const [limitData, setlimitData] = useState(20);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const getLoadMore = () => {
    // setlimitData(limitData + 20);
  //   if(searchText) onSearchByinput()
  //  else
     onClickSearch()
  };

  useEffect(()=>{
    onClickSearch()
  },[])

  const onSearchByinput = async()=>{
    console.log(searchText)
    setLoading(true)
    let data = {
      skip: 0,
      limit: 10,
      type: "appUser", q: searchText
    };
    let res1 = await getSearchUserList(data);
    
    if (res1?.success) {
      console.log("res1==>", res1);
      // setTodayData(res1);
      setSearchUserData(res1);
      if (res1?.list?.length > 0)
        setUserData([...userList,...res1.list]);
    }
    setLoading(false)
  }

  const onClickSearch = async () => {
  
    setLoading(true)
    let data = {
      skip: userList.length,
      limit: 10,
      type: "appUser", 
      q: searchText
    };
    let res1 = await getSearchUserList(data);
    
    if (res1?.success) {
      console.log("res1==>", res1);
      // setTodayData(res1);
      setSearchUserData(res1);
      if (res1?.list?.length > 0)
        setUserData([...userList, ...res1.list]);
    }
    setLoading(false)
  };

  const openUserProfile = (id) => {
    let allpage = [...JSON.parse(localStorage.getItem("currentPage"))];
    var count = allpage.findIndex((d) => d === window.location.pathname);
    count === -1 && allpage.push(window.location.pathname);
    localStorage.setItem("currentPage", JSON.stringify(allpage));
    window.location.href = `/user-profile/${id}`;
  }

  return (
    <div
      className="inner-wrap"
      style={{ paddingTop: "2rem" }}

    >
      <div className="header">
        <div className="back-btn">
          <Link to="/profile">
            <img src={Previous} alt="" />
          </Link>
        </div>
        <h2>{t('leaderboard.search')}</h2>
        <div className="wallet-btn"></div>
      </div>
     
       <div className="header_main_leader">
       <div
        className=" bg-primary shadow-primary leader_background"
        style={{
          height: "90px",
          marginBottom:"1em"

        }}
      >
        <div className="usersearchbar">
          <input
            type="text"
            placeholder={t('leaderboard.search')+'...'}
            onChange={(e) =>{
              setUserData([])
              setSearchUserData([])
              setSearchText(e.target.value)}}
          />
          <i className="fa fa-search" onClick={() => onSearchByinput()} />
        </div>


      </div>
        
        </div>
        <div
        className="body mCustomScrollbar _mCS_1"
        style={{ borderRadius: "0", paddingBottom: '150px',  paddingTop:"60px"
        }}
      >
      <div
       className="all_sub_screens_div_related_to_ads"
      >
        {loading === true ? (
          // <div className="no_records">
          <Loader />
          // </div>
        ) : ""}
        <div className="tab-content" id="nav-tabContent">
          <div className={`tab-pane active`}>
            <div className="user_list" style={{ top: "20px" }}>

              {loading === false && userList?.length > 0 ? (
                userList?.map((item, index) => {
                  return (
                    <div className="item" key={item._id} onClick={() => openUserProfile(item?._id)}>

                      <div className="inner inner_big">
                        <div className="icon">
                          <img
                            src={
                              url.imageUrl + item?.level?.featuredImage?.default
                            }
                            alt=""
                          />
                        </div>
                        <b>{item?.gamerjiName}</b>


                      </div>

                    </div>
                  );
                })
              ) : (
                <h3 className="no_records">{t('notFound.no_records_found')}</h3>
              )}

              {searchUserList?.count > searchUserList?.list?.length && (
                <div className="load_more text-center">
                  <button className="btn btn-primary" onClick={getLoadMore}>
                    {loading === true ? t('tournaments.loading')+"..." :t('tournaments.load_more')}
                  </button>
                </div>
              )}
            </div>
          </div>

        </div>
      </div>
      </div>
    </div>
  );
}

export default SearchUser;
