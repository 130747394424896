import React, { useEffect, useState, useRef } from "react";
import Loader from "../../component/loader";
import { Link, useParams } from "react-router-dom";
import rightArrow from "../../assets/images/right-arrow.svg";
import Previous from "../../assets/images/previous.svg";
import PIcon1 from "../../assets/images/p_icon_1.png";
import Podium from "../../assets/images/podium.svg";
import Gamer from "../../assets/images/gamer.svg";
import PIcon2 from "../../assets/images/p_icon_2.png";
import { getStatusScrim, scrimLeaderBoard, updateWinners } from "../../apis";
import url from "../../constants/url";
import { ToastContainer, toast } from "react-toastify";
import Success from "../../assets/images/success-payment.png";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Button } from "react-bootstrap";
import  PremiumLogoBlack from '../../assets/images/GoldPremium.png'
import { useTranslation } from "react-i18next";
import Cookies from 'js-cookie'

const ScrimLeaderBoard = () => {
  const params = useParams();
  const [activeTab, setActiveTab] = useState(0);
  const [open, setOpen] = useState(false);
  const [storedRank, setStoredRank] = useState([]);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [rankArray, setRankArray] = useState([]);
  const [idArray, setIdArray] = useState([]);
  const [win, setWin] = useState(localStorage.getItem("win"));
  const [status, setStatus] = useState("");

  const [rankSubmitted, setRankSubmitted] = useState(false);
  const [totalPage, setTotalPage] = useState(1);
  const [loader, setLoader] = useState(false)
  const  [premiumUser, setPremiumUser] = useState(JSON.parse(localStorage.getItem('premiumUser')))
  const {t} = useTranslation()
const currentLang = Cookies.get('i18next') || 'EN'

  const fetchStatus = async () => {
    console.log("called");
    let res = await getStatusScrim({
      scrimId: params?.id,
    });
    console.log(res);
    if (res.success) {
      setStatus(res?.data?.status);
      console.log(status);
    } else {
      showError(res.data[0].msg);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const showSuccess = (error) => {
    toast.success(error, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const check = () => {
    var rankCount = 0;
    for (let key in rankArray) {
      console.log(rankArray[key]);
      if (rankArray[key]) {
        rankCount += 1;
      }
      // let capacity = localStorage.getItem('playerCapacity')
      // localStorage.removeItem('playerCapacity')
      // if (rankArray[key] > capacity) {
      //   return "Please Enter upto " + capacity + " rank";
      // }
    }
    console.log(rankCount);
    if (rankCount == 0) {
      return t('error_please_enter_rank');
    }
    let uniqueItems = [...new Set(rankArray)];
    if (uniqueItems.length !== rankArray.length) {
      return t('error_please_enter_unique_rank');
    }
    
    
  };

  const Confirmation = () => {
    return (
      <div>
        <div
          style={{
            color: "#82878D",
            fontWeight: "800",
            textAlign: "center",
            marginTop: "15px",
            fontSize: "1.2rem",
          }}
        >
          {" "}
          {t('are_you_sure_you_want_to_move_further')}{" "}
        </div>

        <div className="detailsButton">
          <button className="rpBtn" onClick={handleClose}>
            {t('right_hand_drop_down.edit')}
          </button>
          <button
            className="rpBtn"
            style={{ backgroundColor: "#070b28" }}
            onClick={yesHandler}
          >
            {t('right_hand_drop_down.save')}
          </button>
        </div>
      </div>
    );
  };
  const yesHandler = async () => {
    console.log("called");
    setOpen(false);

    var data = [];
    for (let i = 0; i < rankArray.length; i++) {
      var obj = {
        _id: idArray[i],
        rank: rankArray[i],
      };
      data = [...data, obj];
      console.log(data);
    }
    var res = await updateWinners({
      _id: params?.id,
      winners: [...data],
    });
    if (res?.success) {
      console.log(res);
      setWin(false);
      showSuccess(t('ranks_are_submitted'));
      setRankSubmitted(true);
    } else {
      showError(res?.data?.[0]?.msg);
    }
    // let res = await statusUpdate({
    //   status:'Canceled'
    // }, params?.id)
    // console.log(res)
    // navigate('/private-scrim')
    
  };

  useEffect(() => {
    fetchStatus();

    fetchData(page);
  }, []);

  useEffect(() => {
    fetchStatus();

    fetchData(page);
  }, [rankSubmitted]);

  let fetchData = async (page) => {
    setLoader(true)
    console.log(page, totalPage);
    let res = await scrimLeaderBoard({
      scrimId: params?.id,
      page: page,
      limit: 10,
      sortBy: "createdAt",
      sort: "asc",
    });
    console.log(res.data);
    if (res.success) {
      setTotalPage(res.totalPages);
      setData([...data, ...res.data]);
      if (res.data[0]?.rank) {
        setStoredRank(res.data);
        console.log(res.data);

        console.log("called");

        setWin(false);
      }

      console.log(res.data);
      //     var d = [
      //       {
      //         "_id": "1",
      //         "userId": "GSGJ000000001",
      //         "usersInGameName": "hello1",
      //         "gamerJiName": "GamerJiFever",
      //         "levels": null
      //     },
      //     {
      //       "_id": "2",
      //       "userId": "GSGJ000000001",
      //       "usersInGameName": "Fever1",
      //       "gamerJiName": "GamerJiFever",
      //       "levels": null
      //   },
      //   {
      //     "_id": "2",
      //     "userId": "GSGJ000000001",
      //     "usersInGameName": "Fever2",
      //     "gamerJiName": "GamerJiFever",
      //     "levels": null
      // }
      //     ]
      //     setData(d)
      console.log(rankArray, idArray);
    } else {
      showError(res?.data[0]?.msg);
    }
    setLoader(false)
  };

  const SaveButton = () => {
    return (
      <div className="bottom_btn">
        <div className="btn btn-dark main-btn">
          Save
          <img src={rightArrow} alt="" className="mCS_img_loaded" />
        </div>
      </div>
    );
  };

  const RankSubmittedText = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={Success}
          style={{
            width: "20px",
            height: "20px",
            marginTop: "15px",
            marginRight: "5px",
          }}
        />
        <div
          style={{
            color: "#070b28",
            fontWeight: "900",
            textAlign: "center",
            marginTop: "15px",
            fontSize: "1.2rem",
          }}
        >
          {" "}
          {t('ranks_are_submitted')}
        </div>
      </div>
    );
  };

  const AddmoreButton = () => {
    return (
      <div className="">
        <button
          className="btn  bg-primary  scrim-leaderboard-btn  "
          onClick={loadHandler}
        >
          {t('add_more')}
        </button>
      </div>
    );
  };

  const showError = (err) => {
    console.log("showeeror", err);
    toast.error(err, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const loadHandler = () => {
    let p = page;
    setPage(page + 1);
    fetchData(p + 1);
    console.log(data);
  };
  const onChangeHandler = (e, id, ind) => {
    console.log(id, ind, rankArray);
    let arr = [...rankArray];
    console.log(arr);
    arr[ind] = parseInt(e.target.value);
    console.log(arr);
    setRankArray([...arr]);
    let arra1 = [...idArray];
    arra1[ind] = id;
    setIdArray([...arra1]);
  };
  const saveHandler = async () => {
    var error = check();
    if (error) {
      showError(error);
    } else {
      setOpen(true);
    }
  };
  return (
    <>
     <main>
      <div
        className=" bg-primary  scrim-heading"
        style={{
          height: "50px",
          marginBottom: "1em",
          margintop: 0,
        }}
      >
        <div className="inner" style={{textAlign:'center'}}>
          <img
            src={Gamer}
            style={{ width: "30px", height: "30px", marginRight: "0.3rem" }}
            alt="picon1"
          />
          <p style={{fontSize:'10px'}}>{t('private_contest.user_name')}</p>
        </div>
        <div className="inner" style={{textAlign:'center'}}>
          <img
            src={Podium}
            style={{ width: "30px", height: "30px", marginRight: "0.3rem" }}
            alt="picon1"
          />
          <p style={{fontSize:'10px'}}>{t('private_contest.rank')}</p>
        </div>
      </div>
      <div
        className="scrim-body"
        style={{
          height: "500px",
          marginBottom: "1em",
          margintop: 0,
        }}
      >

        { 
          data.length == 0 ? <h6 style={{textAlign:'center', marginTop:'45%', fontSize:'20px'}}>{t('notFound.no_records_found')}</h6> : 
        
        <div
          className={`${activeTab === 2 ? "active" : ""}`}
          style={{
            border: "0.5px solid #dfe4e9",
            borderRadius: "20px",
            boxShadow: "0 3px 6px #b4b6c4",
          }}
        >
          {console.log("ranksubmite", rankSubmitted)}
          {rankSubmitted ? (
            <>
            
              {storedRank.map((d, ind) => (
                <div className="leaderboard-list">
                  <div className="item">
                    <div className="inner">
                      <img
                        src={url.imageUrl + d?.levels?.featuredImage?.default}
                        style={currentLang?.toLowerCase() == "ar" ? {
                          width: "30px",
                          height: "30px",
                          marginLeft: "0.3rem",
                        } : {
                          width: "30px",
                          height: "30px",
                          marginRight: "0.3rem",
                        }}
                        alt=""
                      />
                    </div>
                    <div>
                    <div className="username">
                      <b>{d.usersInGameName}</b>
                      <small>{d.gamerJiName}</small>
                    </div>
                    {premiumUser ? (
                            
                            <div className="ps_player" style={{right:'60%'}}>
                             <img style={{width:'28px', height:'25px', marginLeft:'20px'}} src={PremiumLogoBlack} />{" "}
                       
                             </div>
                   ) : (
                          ""

                        )}
                        </div>
                  </div>
                  <div className="item">
                    {/* <input type="number" maxLength={100} onChange={(e) => onChangeHandler(e,d._id,ind)} value={rankArray[ind]} defaultValue={d?.rank} /> */}
                    {console.log("rank", d.rank)}
                    {status === "In Review" && win ? (
                      <input
                     
                        type="number"
                        onKeyDown={(e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                        maxLength={100}
                      
                        onChange={(e) => onChangeHandler(e, d._id, ind)}
                      />
                    ) : 
                    status.toLowerCase() === 'completed' ? <input
                    type="number"
                    onKeyDown={(e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                    maxLength={100}
                    value={d.rank ? d.rank : '-'}
                    
                  />
                    :(
                      
                      <input
                        type="number"
                        onKeyDown={(e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                        maxLength={100}
                        value={d.rank ? d.rank : '-'}
                        disabled="disabled"
                      />
                    )
                    }
                  </div>
                </div>
              ))}
            </>
          ) : (
            <>
              {
                
            
              data.map((d, ind) => (
                <div className="leaderboard-list 3" >
                  <div className="item">
                    <div className="inner">
                      <img
                        src={url.imageUrl + d?.levels?.featuredImage?.default}
                        style={currentLang?.toLowerCase() == "ar" ? {
                          width: "30px",
                          height: "30px",
                          marginLeft: "0.3rem",
                        } : {
                          width: "30px",
                          height: "30px",
                          marginRight: "0.3rem",
                        }}
                        alt=""
                      />
                    </div>
                    <div>
                    <div className="username">
                      <b>{d.usersInGameName}</b>
                      <small>{d.gamerJiName}</small>
                    </div>
                    {d?.isPremium ? (
                            
                            <div className="ps_player" style={{right:'60%'}}>
                             <img style={{width:'28px', height:'25px', marginLeft:'20px'}} src={PremiumLogoBlack} />{" "}
                       
                             </div>
                   ) : (
                          ""
                        )}
                    </div>
                    
                  </div>
                  <div className="item">
                    {/* <input type="number" maxLength={100} onChange={(e) => onChangeHandler(e,d._id,ind)} value={rankArray[ind]} defaultValue={d?.rank} /> */}
                    {console.log("rank data", d.rank ? 'yes': "no")}
                    {console.log(open)}
                    {status === "In Review" && win  ? (
                      
                      <input
                      className="rankBox"
                        type="number"
                        onKeyDown={(e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                        maxLength={100}
                        onChange={(e) => onChangeHandler(e, d._id, ind)}
                        // value={open ? "": setRankArray[ind]}
                        // disabled =  {open ? "disabled": "!disabled" }
                      />
                    ) : 
                    status.toLowerCase() === 'completed' ? <input
                    // type="number"
                    maxLength={100}
            
                    value={!d?.rank ? "-" : d?.rank ? d?.rank : "-"}
                    
                  /> :
                    (
                      <input
                     
                      style={{ backgroundColor:'#d3d3d3', border:'1px solid #e6e6e6' }}
                        maxLength={100}
                        // value={!d?.rank ? "-" : d?.rank ? d?.rank : "-"}
                        disabled

                      />
                    )}
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
        }
        {page < totalPage ? (
          <button
            className="btn  bg-primary  scrim-leaderboard-btn  "
            onClick={loadHandler}
          >
            {t('tournaments.load_more')}
          </button>
        ) : (
          ""
        )}

        {/* <Confirmation /> */}
        {console.log("status", status, status.toLowerCase() === "completed")}
        {status.toLowerCase() === "completed" || status.toLowerCase() === "canceled" ? (
          ""
        ) : (
          <div className="bottom_btn">
            {open ? (
              <>
                <Confirmation />
              </>
            ) : (
              <div className="btn btn-dark main-btn" onClick={saveHandler}>
                {t('Profile.save')}
                <img src={rightArrow} alt="" className="mCS_img_loaded" />
              </div>
            )}
          </div>
        )}

        {rankSubmitted || status.toLowerCase() == "completed" ? (
          <RankSubmittedText />
        ) : (
          ""
        )}
      </div>
      </main>
    </>
  );
};

export default ScrimLeaderBoard;
