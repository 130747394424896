import React, { Fragment, useEffect, useState } from "react";
import Previous from "../assets/images/previous.svg";
import Loader from "../component/loader";
import { Link } from "react-router-dom";
import { getLeaderBoardList } from "../apis";
import url from "../constants/url";
import { useTranslation, Trans } from "react-i18next";
import Advertisement from "../component/advertisement";
import Gold from '../assets/images/GoldPremium.png'
import { events } from "../utils/appEvents";
import { eventTracking } from "../firebaseAnalytics";
function LeaderBoard(props) {
  const [todayData, setTodayData] = useState(null);
  const [weeklyData, setWeeklyData] = useState(null);
  const [monthlyData, setMonthlyData] = useState(null);
  const [allData, setAllData] = useState(null);
  const [alltabData, setAlltabData] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [limitData, setlimitData] = useState(15);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation()

  useEffect(async () => {
    // fetchTodayData();
    // fetchWeeklyData();
    // fetchMonthlyData();
    // fetchAllData();
  }, []);

  useEffect(async () => {
    if (activeTab === 0) {
      eventTracking(events.today_tab);
      //setLoading(true);
      await fetchTodayData();
      // setLoading(false);
      setSearchText("");
    } else if (activeTab === 1) {
      eventTracking(events.weekly_tab);
      // setLoading(true);
      await fetchWeeklyData();
      // setLoading(false);
      setSearchText("");
    } else if (activeTab === 2) {
      //setLoading(true);
      await fetchMonthlyData();
      eventTracking(events.monthly_tab);
      //setLoading(false);
      setSearchText("");
    } else {
      // setLoading(true);
      await fetchAllData();
      eventTracking(events.all_tab);

      //setLoading(false);
      setSearchText("");
    }
  }, [limitData,activeTab]);

  const fetchTodayData = async () => {
    setLoading(true);
    let data = { skip: 0, limit: limitData, type: "today" };
    let res1 = await getLeaderBoardList("today", data);
    if (res1) {
      setTodayData(res1);
      // setAlltabData(res1);
      setLoading(false);
    }
  };

  const fetchWeeklyData = async () => {
    setLoading(true);
    let data1 = { skip: 0, limit: limitData, type: "byWeek" };
    let res2 = await getLeaderBoardList("weekly", data1);
    if (res2) {
      setWeeklyData(res2);
      setLoading(false);
    }
  };

  const fetchMonthlyData = async () => {
    setLoading(true);
    let data2 = { skip: 0, limit: limitData, type: "monthly" };
    let res3 = await getLeaderBoardList("monthly", data2);
    if (res3) {
      setMonthlyData(res3);
      setLoading(false);
    }
  };

  const fetchAllData = async () => {
    setLoading(true);
    let data3 = { skip: 0, limit: limitData, type: "allTime" };
    let res4 = await getLeaderBoardList("allOver", data3);
    if (res4) {
      console.log("hey");
      setAllData(res4);
      // setTimeout(() => {
      //   console.log("hey");
      //   setAllData(res4);
      // }, 50000);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (activeTab === 0) {
      setAlltabData(todayData);
    } else if (activeTab === 1) {
      setAlltabData(weeklyData);
    } else if (activeTab === 2) {
      setAlltabData(monthlyData);
    } else {
      setAlltabData(allData);
    }
  }, [activeTab]);

  const getLoadMore = () => {
    setlimitData(limitData + 15);
  };

  const onClickSearch = async () => {
    setLoading(true);
    eventTracking(events.search);
    var type =
      activeTab === 0
        ? "today"
        : activeTab === 1
          ? "weekly"
          : activeTab === 2
            ? "monthly"
            : "allTime";
    let data = { skip: 0, limit: 15, type: type, q: searchText };
    let res1 = await getLeaderBoardList(
      type === "allTime" ? "allOver" : type,
      data
    );
    if (res1) {
      if (type == "today") {
        // console.log("setTodayData==>", res1);
        setTodayData(res1);
      }
      if (type == "weekly") {
        setWeeklyData(res1);
      }
      if (type == "monthly") {
        setMonthlyData(res1);
      }
      if (type == "allTime") {
        // console.log("hey");
        setAllData(res1);
      }
      // setTodayData(res1);
      setAlltabData(res1);
      setLoading(false);
    }
  };

  const openUserProfile = (id) => {
    window.location.href = `/user-profile/${id}`;
  };

  return (
    <div
      className="inner-wrap d-flex flex-column"
      style={{ height: "100vh", overflow: "hidden" }}
    >
      {loading === true ? (
        <Loader />
      ) : (
        <>
          <div className="header">
            <div className="back-btn">
              <Link to="/more">
                <img src={Previous} alt="" />
              </Link>
            </div>
            <h2>{t('leaderboard.leaderboard')}</h2>
            <div className="wallet-btn"></div>
          </div>
          <div className="header_main_leader">
            <div
              className="list-group main-tab leader-board"
              style={{ width: "90%", margin: "0 auto" }}
              id="list-tab"
              role="tablist"
            >
              <a
                className={`list-group-item list-group-item-action ${activeTab === 0 ? "active" : ""
                  }`}
                id="list-profile-list"
                data-toggle="list"
                // href="#list-1"
                onClick={() => setActiveTab(0)}
              >
                {t('leaderboard.today')}
              </a>
              <a
                className={`list-group-item list-group-item-action ${activeTab === 1 ? "active" : ""
                  }`}
                id="list-profile-list"
                data-toggle="list"
                href="#list-2"
                onClick={() => setActiveTab(1)}
              >
                {t('leaderboard.by_week')}
              </a>
              <a
                className={`list-group-item list-group-item-action ${activeTab === 2 ? "active" : ""
                  }`}
                id="list-profile-list"
                data-toggle="list"
                href="#list-3"
                onClick={() => setActiveTab(2)}
              >
                {t('leaderboard.monthly')}
              </a>
              <a
                className={`list-group-item list-group-item-action ${activeTab === 3 ? "active" : ""
                  }`}
                id="list-profile-list"
                data-toggle="list"
                href="#list-4"
                onClick={() => setActiveTab(3)}
              >
                {t('leaderboard.all_time')}
              </a>
            </div>
            <div
              className=" bg-primary shadow-primary leader_background"
              style={{
                height: alltabData?.currentUser ? "130px" : "50px",
                marginBottom: alltabData?.currentUser ? "-1em" : "1em",
              }}
            >
              <div className="searchbar">
                <input
                  type="text"
                  placeholder={`${t('leaderboard.search')}...`}
                  onChange={(e) => setSearchText(e.target.value)}
                  value={searchText}
                />
                <div className="searchbar i_div">
                  <i className="fa fa-search" onClick={() => onClickSearch()} />
                </div>
              </div>
              <div
                className="bg-dark text-light player_list_lable"
                style={{
                  width: "90%",
                  margin: "0 auto",
                  fontSize: "16px",
                  fontWeight: "1000",
                }}
              >
                <div className="inner">{t('leaderboard.rank')}</div>
                <div className="inner inner_big">{t('leaderboard.team_name')}</div>
                <div className="inner">{t('leaderboard.points')}</div>
                <div className="inner">{t('leaderboard.level')}</div>
              </div>

              {alltabData?.currentUser && (
                <div className="player_list" style={{ border: "unset" }}>
                  <div
                    className="current_user item"
                    style={{
                      fontSize: "14px",
                      marginLeft: 20,
                      marginRight: 15,
                    }}
                  >
                    <div className="inner">
                      <b>{alltabData?.currentUser?.rank}</b>
                    </div>
                    <div className="inner inner_big">
                      <div className="icon">
                        <img
                          src={
                            url.imageUrl +
                            alltabData?.currentUser?.level?.featuredImage
                              ?.default
                          }
                          alt=""
                          className="image-size"
                        />
                      </div>
                      <b>{alltabData?.currentUser?.user?.gamerjiName}</b>
                      <br />
                      <small>
                        xxxxx{" "}
                        {alltabData?.currentUser?.user?.phone?.slice(
                          alltabData?.currentUser?.user?.phone?.length - 5
                        )}
                      </small>
                    </div>
                    <div className="inner">
                      <b>{alltabData?.currentUser?.points}</b>
                    </div>
                    <div className="inner">
                      <b>{alltabData?.currentUser?.level?.num}</b>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div
            className="body mCustomScrollbar _mCS_1"
            style={{
              borderRadius: "0",
              paddingBottom: "60px",
              paddingTop: alltabData?.currentUser ? "80px" : "70px",
            }}
          >
            <div className="all_sub_screens_div_related_to_ads">
              <div className="tab-content" id="nav-tabContent">
                <div className={`tab-pane ${activeTab === 0 ? "active" : ""}`}>
                  <div className="player_list">
                    {todayData === null ? (
                      <div className="no_records">{t('notFound.no_records_found')}</div>
                    ) : todayData?.list?.length > 0 ? (
                      todayData?.list?.map((item, index) => {
                        return (
                          <div
                            className="item"
                            onClick={() => openUserProfile(item?.user?._id)}
                            key={index}
                          >
                            <div className="inner">
                              <b>{item?.rank}</b>
                            </div>
                            <div className="inner inner_big">
                              <div className="icon">
                                <img
                                  className="image-size"
                                  src={
                                    url.imageUrl +
                                    item?.level?.featuredImage?.default
                                  }
                                  alt=""
                                />
                              </div>
                              <div
                                // className={
                                //   item?.isPremium ? "preminumUserProfile" : ""
                                // }
                                className="row leaderboard-premium"


                              >

                                <div>
                                  <div style={{
                                    display: 'block', width: '5rem', whiteSpace: "nowrap", overflow: "hidden",
                                    textOverflow: "ellipsis"
                                  }}>{item?.user?.gamerjiName}</div>
                                  {/* <br /> */}
                                  <small>
                                    xxxxx{" "}
                                    {item?.user?.phone?.slice(
                                      item?.user?.phone?.length - 5
                                    )}
                                  </small>
                                </div>
                                {item.isPremium && <img className="preminumUserProfile" src={Gold} />}
                              </div>
                            </div>
                            <div className="inner">
                              <b>{item?.points}</b>
                            </div>
                            <div className="inner">
                              <b>{item?.level?.num}</b>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <h3 className="no_records">{t('notFound.no_records_found')}</h3>
                    )}

                    {todayData?.count > todayData?.list?.length && (
                      <div className="load_more text-center">
                        {loading === true ? (
                          ""
                        ) : (
                          <button
                            className="btn btn-primary"
                            onClick={getLoadMore}
                          >
                            {loading === true ? `${t('tournaments.loading')}...` : t('tournaments.load_more')}
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className={`tab-pane ${activeTab === 1 ? "active" : ""}`}>
                  <div className="player_list">
                    {weeklyData === null ? (
                      <div className="no_records">{t('notFound.no_records_found')}</div>
                    ) : weeklyData?.list?.length > 0 ? (
                      weeklyData?.list?.map((item, index) => {
                        return (
                          <div
                            className="item"
                            onClick={() => openUserProfile(item?.user?._id)}
                            key={index}
                          >
                            <div className="inner">
                              <b>{item?.rank}</b>
                            </div>
                            <div className="inner inner_big">
                              <div className="icon">
                                <img
                                  className="image-size"
                                  src={
                                    url.imageUrl +
                                    item?.level?.featuredImage?.default
                                  }
                                  alt=""
                                />
                              </div>
                              <div
                                // className={
                                //   item?.isPremium ? "preminumUserProfile" : ""
                                // }
                                className="row leaderboard-premium"


                              >

                                <div>
                                  <div style={{
                                    display: 'block', width: '5rem', whiteSpace: "nowrap", overflow: "hidden",
                                    textOverflow: "ellipsis"
                                  }}>{item?.user?.gamerjiName}</div>
                                  {/* <br /> */}
                                  <small>
                                    xxxxx{" "}
                                    {item?.user?.phone?.slice(
                                      item?.user?.phone?.length - 5
                                    )}
                                  </small>
                                </div>
                                {item.isPremium && <img className="preminumUserProfile" src={Gold} />}
                              </div>
                            </div>
                            <div className="inner">
                              <b>{item?.points}</b>
                            </div>
                            <div className="inner">
                              <b>{item?.level?.num}</b>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <h3 className="no_records">{t('notFound.no_records_found')}</h3>
                    )}

                    {weeklyData?.count > weeklyData?.list?.length && (
                      <div className="load_more text-center">
                        {loading === true ? (
                          ""
                        ) : (
                          <button
                            className="btn btn-primary"
                            onClick={getLoadMore}
                          >
                            {loading === true ? `${t('tournaments.loading')}...` : t('tournaments.load_more')}
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className={`tab-pane ${activeTab === 2 ? "active" : ""}`}>
                  <div className="player_list">
                    {monthlyData === null ? (
                      <div className="no_records">{t('notFound.no_records_found')}</div>
                    ) : monthlyData?.list?.length > 0 ? (
                      monthlyData?.list?.map((item, index) => {
                        return (
                          <div
                            className="item"
                            onClick={() => openUserProfile(item?.user?._id)}
                            key={index}
                          >
                            <div className="inner">
                              <b>{item?.rank}</b>
                            </div>
                            <div className="inner inner_big">
                              <div className="icon">
                                <img
                                  className="image-size"
                                  src={
                                    url.imageUrl +
                                    item?.level?.featuredImage?.default
                                  }
                                  alt=""
                                />
                              </div>
                              <div
                                // className={
                                //   item?.isPremium ? "preminumUserProfile" : ""
                                // }
                                className="row leaderboard-premium"


                              >

                                <div>
                                  <div style={{
                                    display: 'block', width: '5rem', whiteSpace: "nowrap", overflow: "hidden",
                                    textOverflow: "ellipsis"
                                  }}>{item?.user?.gamerjiName}</div>
                                  {/* <br /> */}
                                  <small>
                                    xxxxx{" "}
                                    {item?.user?.phone?.slice(
                                      item?.user?.phone?.length - 5
                                    )}
                                  </small>
                                </div>
                              </div>
                              {item.isPremium && <img className="preminumUserProfile" src={Gold} />}
                            </div>
                            <div className="inner">
                              <b>{item?.points}</b>
                            </div>
                            <div className="inner">
                              <b>{item?.level?.num}</b>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <h3 className="no_records">{t('notFound.no_records_found')}</h3>
                    )}

                    {monthlyData?.count > monthlyData?.list?.length && (
                      <div className="load_more text-center">
                        {loading === true ? (
                          ""
                        ) : (
                          <button
                            className="btn btn-primary"
                            onClick={getLoadMore}
                          >
                            {loading === true ? `${t('tournaments.loading')}...` : t('tournaments.load_more')}
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className={`tab-pane ${activeTab === 3 ? "active" : ""}`}>
                  <div className="player_list">
                    {allData === null ? (
                      <div className="no_records">{t('notFound.no_records_found')}</div>
                    ) : allData?.list?.length > 0 ? (
                      allData?.list?.map((item, index) => {
                        return (
                          <div
                            className="item"
                            onClick={() => openUserProfile(item?.user?._id)}
                            key={index}
                          >
                            <div className="inner">
                              <b>{item?.rank}</b>
                            </div>
                            <div className="inner inner_big">
                              <div className="icon">
                                <img
                                  src={
                                    url.imageUrl +
                                    item?.level?.featuredImage?.default
                                  }
                                  alt=""
                                  className="image-size"
                                />
                              </div>
                              <div
                                // className={
                                //   item?.isPremium ? "preminumUserProfile" : ""
                                // }
                                className="row leaderboard-premium"

                              >
                                <div>
                                  <div style={{
                                    display: 'block', width: '5rem', whiteSpace: "nowrap", overflow: "hidden",
                                    textOverflow: "ellipsis"
                                  }}>{item?.user?.gamerjiName}</div>
                                  {/* <br /> */}
                                  <small>
                                    xxxxx{" "}
                                    {item?.user?.phone?.slice(
                                      item?.user?.phone?.length - 5
                                    )}
                                  </small>
                                </div>
                              </div>
                              {item.isPremium && < img className="preminumUserProfile" src={Gold} />}

                            </div>
                            <div className="inner">
                              <b>{item?.points}</b>
                            </div>
                            <div className="inner">
                              <b>{item?.level?.num}</b>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <h3 className="no_records">{t('notFound.no_records_found')}</h3>
                    )}

                    {allData?.count > allData?.list?.length && (
                      <div className="load_more text-center">
                        {loading === true ? (
                          ""
                        ) : (
                          <button
                            className="btn btn-primary"
                            onClick={getLoadMore}
                          >
                            {loading === true ? `${t('tournaments.loading')}...` : t('tournaments.load_more')}
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {
              !JSON.parse(localStorage.getItem("premiumUser")) &&

              <Advertisement
                screen={
                  localStorage.getItem("apk_screens_list") === null
                    ? { code: "no_results" }
                    : JSON.parse(localStorage.getItem("apk_screens_list"))
                      .leaderboard
                }
                screen_div_class_name=".all_sub_screens_div_related_to_ads"
                screen_type="sub"
              />
            }
          </div>
        </>
      )}
    </div>
  );
}

export default LeaderBoard;
