import React from 'react'
import ReactSpinner from 'react-bootstrap-spinner'
import Lottie from "reactjs-lottie";
import loderAnimation from '../assets/jsonFiles/loader_animation_with_back.json'
import LoadingOverlay from 'react-loading-overlay';

function Loader(props) {
  // console.log("loading")
  return (
    <div className='loader' style={{backgroundColor:'#071928'}}>
      {/* <ReactSpinner type="border" color='#212529' size="3" /> */}
      <Lottie
        // resizeMode="cover"
        // style={{ height: "100%" }}
        options={{
          animationData: loderAnimation,
          // h:"100%"
        }}
      />
    </div>

  )
}

export default Loader;