// firebaseAnalytics.js
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent, setUserId } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAvkiEgIhC0KlnTSWtfzdX93jphg1ATvf4",
  authDomain: "easypaisa-d2cd2.firebaseapp.com",
  projectId: "easypaisa-d2cd2",
  storageBucket: "easypaisa-d2cd2.appspot.com",
  messagingSenderId: "144506107618",
  appId: "1:144506107618:web:e70438bb821b52cb0290bb",
  measurementId: "G-7ZSCSDC29V",
};

const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);

//log event
const eventTracking = (event, parameters) => {
  var currentDate = new Date().toTimeString();
  var profileData = JSON.parse(localStorage.getItem("profileLite"));
  //console.log(profileData);
  var customParam = { ...parameters, GJ_TimeStamp: currentDate };
  if (profileData != null && profileData?.item?.user_id) {
    customParam.GJ_UserID = profileData?.item?.user_id;
  } else if (profileData != null && profileData?.item?._id) {
    customParam.GJ_UserID = profileData?.item?._id;
  }
  console.log("Event Triggered", event, customParam);
  logEvent(analytics, event, customParam);
};

export { analytics, logEvent, eventTracking };
