import React, { Fragment, useState, useEffect } from 'react';
import Previous from "../assets/images/previous.svg";
import Wallet from "../assets/images/wallet.svg";
import MoneyIcon2 from "../assets/images/money-icon-2.svg";
import Coin from "../assets/images/coin.png";
import { Link } from 'react-router-dom';
import { loadStripe } from "@stripe/stripe-js";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import CheckoutForm from "../component/CheckoutForm";
import helperFunc from '../helperFuncs';
import {
	getClientSecret,
	getCoinStoreDetails,
	createStripeTransactionWeb,
	createStripePaymentIntentWeb
} from "../apis";
import './PaymentGateway.css';

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
//const stripe = require('stripe')('sk_test_51KezD8SB4g2oK7dcab4nYHmaAPcYpqQaBTjUjOHtpbTWDaRkGeXL3jV7wEbA7zYusiFIP8nhaDtTIaYh6DiyebbX00kJvnt6oi');
var stripeAPIKey = helperFunc.getStripeAPIKey()

const stripePromise = loadStripe(stripeAPIKey);
//const stripePromise = loadStripe("pk_test_51KezD8SB4g2oK7dcsmm8TRlmVJFipgCPClcZnnO2xfywtgLG0UKyxEQ99qSO0fPXDWVOO4zQVlifn5uvSwZoldYE00PqEStE5G");


function PaymentOptionsWeb(props) {
	//	const stripe = useStripe();
	const [clientSecret, setClientSecret] = useState("");
	const [coinStore, setCoinStore] = useState("");
	const [coinStoreObjID, setCoinStoreID] = useState("");
	useEffect(() => {
		const clientSecretFromURL = new URLSearchParams(window.location.search).get(
			"payment_intent_client_secret"
		);

		if (clientSecretFromURL) {
			createStripeTransactionWeb(clientSecretFromURL)
		}

	}, []);
	const createStripeTransactionWeb = async (clientSecretFromURL) => {
		var coinStoreId =
			window.location.href.split("/")[
			window.location.href.split("/").length - 1
			];
		let res = await createStripeTransactionWeb({
			coinStore: coinStoreObjID,
			clientSecret: clientSecretFromURL
		});
		if (res) {
			console.log("createStripeTransactionWeb==>res?.item===>", res?.item)
			setClientSecret(res?.item?.clientSecret);

		}
	};
	const fetchClientSecretData = async () => {
		var coinStoreId =
			window.location.href.split("/")[
			window.location.href.split("/").length - 1
			];
		let res = await getClientSecret({ coinStore: coinStoreId });
		if (res) {
			setClientSecret(res?.item?.clientSecret);

		}
	};

	const onSubmitPayment = async () => {
		// var coinStoreId =
		// window.location.href.split("/")[
		//   window.location.href.split("/").length - 1
		// ];
		// setCoinStoreID(coinStoreId);
		// let res = await createStripePaymentIntentWeb({coinStore:coinStoreId});
		// if (res) {
		// 	//setCoinStore(res);
		// 	console.log("res?.item==>",res);
		// 	// const cardDetails1 = {
		//     //     number: '4242424242424242',
		//     //     expMonth: 10,
		//     //     expYear: 23,
		//     //     cvc: '888',
		//     // }
		// 	// stripe.confirmCardPayment(
		// 	// 	res?.item?.clientSecret,
		// 	// 	{
		// 	// 	  payment_method: {card: cardDetails1},
		// 	// 	}
		// 	//   ).then(function(result) {
		// 	// 	if (result.error) {
		// 	// 	  // Display error.message in your UI.
		// 	// 	} else {
		// 	// 	  // The payment has succeeded
		// 	// 	  // Display a success message
		// 	// 	}
		// 	//   });

		// }
	};
	const fetchCoinStoreData = async () => {
		var coinStoreId =
			window.location.href.split("/")[
			window.location.href.split("/").length - 1
			];
		setCoinStoreID(coinStoreId);
		let res = await getCoinStoreDetails(coinStoreId);
		if (res) {
			setCoinStore(res);
			console.log("res?.item==>", res);

		}
	};
	const appearance = {
		theme: 'stripe',
	};
	const options = {
		clientSecret,
		appearance,
	}
	useEffect(() => {
		fetchCoinStoreData();
		fetchClientSecretData();
	}, []);
	const goback = () => {
		window.location.href = "/coin-store";
	};
	return (
		<Fragment>
			  <div className="pg_main_div">
       
        <div className="pg_header">
          <div className="pg_back_arrow_component">
            <div className="back-btn">
              <a className="pg_link" onClick={() => goback()}>
                <img src={Previous} alt="" />
              </a>
            </div>
          </div>
          <div className="pg_header_text">
            Payment Options
          </div>
          <div className=""></div>
        </div>

        <div className="pg_body">

          <div className="all_main_screens_div_related_to_ads">
		  <div className="pg_body_scrollable_component">

<div className="pg_account_details">
  {/* <div className=""> */}
  <div><img src={Coin} alt="" className="coin_img_medium" /> {coinStore?.item?.coins}</div>
  <div>{coinStore?.currency?.symbol} {coinStore?.item?.amount}</div>
  {/* </div> */}
</div>

<div className="col-md-11">
{/* {clientSecret && (
			  <Elements options={options} stripe={stripePromise}>
				  <CheckoutForm coinStoreObjID={coinStoreObjID} />
			  </Elements>
		  )} */}
		  </div>

</div>
          </div>
       
        </div>
      </div>
			 
		</Fragment>
	)
}

export default PaymentOptionsWeb;