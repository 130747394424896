import React from "react";
import ReactDOM from "react-dom";
import "react-intl-tel-input/dist/main.css";
import { ToastContainer, toast } from "react-toastify";
import { RWebShare } from "react-web-share";
import { WhatsappShareButton } from "react-share";
import DiscountReward from "../assets/images/discount-reward.png";
import ReferFriendBg from "../assets/images/refer-friend-bg.png";
import ReferFriendLeftIcon from "../assets/images/refer-friend-left-icon.png";
import ReferFriendRightIcon from "../assets/images/refer-friend-right-icon.png";
import whatsappIcon from "../assets/images/whatsapp-icon.png";
import shareandearn from "../assets/images/share-refer-friend.png";
import { Link } from "react-router-dom";
import { constants } from "../constants/constants";
import { WhatsappIcon } from "react-share";
import { withTranslation } from "react-i18next";
import Advertisement from "../component/advertisement";

import "./more.css";

class ReferAFriend extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      referAmount:
        localStorage.getItem(constants.profileLite) === null
          ? ""
          : JSON.parse(localStorage.getItem(constants.profileLite)).referAmount,
      inviteAmount:
        localStorage.getItem(constants.profileLite) === null
          ? ""
          : JSON.parse(localStorage.getItem(constants.profileLite))
              .inviteAmount,
      referralCode:
        localStorage.getItem(constants.profileLite) === null
          ? ""
          : JSON.parse(localStorage.getItem(constants.profileLite)).item
              ?.referralCode,
    };
  }

  componentDidMount() {
    // this.getListofDailyRewardsApiCall();
  }
  render() {
    const Invitation = () => {
      console.log("hey");
      window.open(
        `whatsapp://send?text= Hey, download this fantastic app GamerJi  and get 0 coins bonus using my referral code. ${this.state.referralCode} You can download it by visiting https://www.gamerji.com`
      );
    };
    const { t } = this.props;

    return (
      <div className="raf_main_div">
        <ToastContainer />
        <div className="raf_header">
          <div className="back-btn arrow-deg">
            <Link to="/more">
              <svg
                id="previous"
                xmlns="http://www.w3.org/2000/svg"
                width="32.007"
                height="29.708"
                viewBox="0 0 32.007 29.708"
              >
                <g
                  id="Group_3407"
                  data-name="Group 3407"
                  transform="translate(0 0)"
                >
                  <g
                    id="Group_3406"
                    data-name="Group 3406"
                    transform="translate(0 0)"
                  >
                    <path
                      id="Path_4454"
                      data-name="Path 4454"
                      d="M.48,103.773A1.649,1.649,0,0,0,0,104.934a1.632,1.632,0,0,0,.48,1.161L13.686,119.3a1.648,1.648,0,1,0,2.331-2.331L5.611,106.584H30.357a1.645,1.645,0,1,0,0-3.29H5.611l10.4-10.406a1.648,1.648,0,0,0-2.331-2.331Z"
                      transform="translate(0 -90.075)"
                      fill="#070B28"
                    />
                  </g>
                </g>
              </svg>
            </Link>
          </div>
          <div className="raf_header_text">{t('right_hand_drop_down.refer_a_friend')}</div>
          <div className=""></div>
        </div>

        <div className="raf_body">
          <div className="all_sub_screens_div_related_to_ads">
            <div className="raf_body_scrollable_component">
              <img className="raf_freinds_img" src={ReferFriendBg} />
              <div className="raf_invite_and_earn_div">
                <div className="raf_body_title">
                {t('right_hand_drop_down.invite_your_friend_earn')}
                </div>
                <div className="raf_earn_numbers">
                  <img src={DiscountReward} />
                  10000
                </div>
                <div className="raf_img_text_row">
                  <div className="raf_img_text_col">
                    <div className="raf_img_round_component">
                      <img
                        className="raf_img_round"
                        src={ReferFriendLeftIcon}
                      />
                    </div>
                    <div className="raf_normal_text">
                    {t('right_hand_drop_down.invite_your_friend_referral_code')}
                    </div>
                  </div>
                  <div className="raf_img_text_col">
                    <div className="raf_img_round_component">
                      <img
                        className="raf_img_round"
                        src={ReferFriendRightIcon}
                      />
                    </div>
                    <div className="raf_normal_text">
                    {t('right_hand_drop_down.friend_signup_you_earn')}{" "}
                      <img
                        className="raf_refer_invite_amout_img"
                        src={DiscountReward}
                      />{" "}
                      {this.state.referAmount} & friends earn{" "}
                      <img
                        className="raf_refer_invite_amout_img"
                        src={DiscountReward}
                      />{" "}
                      {this.state.inviteAmount}
                    </div>
                  </div>
                </div>
                <div className="raf_referral_code_text">{t('right_hand_drop_down.your_referal_code')}</div>

                <div className="raf_referral_code_component padding">
                  <div className="raf_referral_code">
                    {this.state.referralCode}
                  </div>
                  <div
                    className="raf_copy_button"
                    onClick={() => {
                      navigator.clipboard.writeText(this.state.referralCode);
                      toast.info(t('info_Copied'), {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                    }}
                  >
                    {t('reward_store.copy')}
                  </div>
                </div>

                <div className="inviteViaWhatsapp padding margin-top ">
                  {" "}
                  <WhatsappShareButton
                    url={t('right_hand_drop_down.join_text')}
                    quote
                  >
                    {(shareCount) => (
                      <span className="myShareCountWrapper">{shareCount}</span>
                    )}
                    
                    <button className="invite-button">
                    <WhatsappIcon size={26} round={true} className="mr-2"/> {t('right_hand_drop_down.invite_via_whatsapp')}
                    </button>
                  </WhatsappShareButton>
                </div>
                <RWebShare
                  data={{
                    // text: "Hey! Join me on Gamerji for this exciting match. Click on hte following link and let's play together",
                    text:
                    t('right_hand_drop_down.hey_get_app') +
                      this.state.referAmount +
                      t('right_hand_drop_down.get_bonus') +
                      this.state.referralCode +
                      t('right_hand_drop_down.can_download'),
                    url: "https://easypaisa.gamerji.com/",
                    title: t('Profile.join_me'),
                  }}
                  onClick={() => console.info("share successful!")}
                >
                  <div className="inviteViaWhatsapp padding margin-top ">
                    <button className="refer-button ">
                      {" "}
                      <img
                        src={shareandearn}
                        className="button-img arrow-deg"
                        alt="refer-icon"
                      />
                     {t('right_hand_drop_down.refer_a_friend')}
                    </button>
                  </div>
                </RWebShare>
              </div>
            </div>
          </div>
          {
             !JSON.parse(localStorage.getItem("premiumUser")) && 
          
          <Advertisement
            screen={
              localStorage.getItem("apk_screens_list") === null
                ? { code: "no_results" }
                : JSON.parse(localStorage.getItem("apk_screens_list"))
                    .ReferAFriend
            }
            screen_div_class_name=".all_sub_screens_div_related_to_ads"
            screen_type="sub"
          />
  }
        </div>
      </div>
    );
  }
}

export default  withTranslation()(ReferAFriend);
