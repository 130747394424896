import { useProfile } from "./ProfileContext";
import { Link } from "react-router-dom";
import { routehelp } from "../../config/routehelp";
import {
  SearchIcon,
  ShareProfileIcon,
  EditProfileIcon,
} from "../../assets/images/index.js";
import colors from "../../constants/colors";
import url from "../../constants/url";
import { RWebShare } from "react-web-share";
import { useTranslation } from "react-i18next";
export const Navbar = () => {
  const { topLinkClick,allData} = useProfile();
  const {t} = useTranslation()
  return (
    <div className="profile_top row">
      <div className="col-9"></div>
      <div className="col-1 p-0">
        <Link
          // to="#"
          style={{
            height: 50,
            width: 40,
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() => topLinkClick(routehelp.searchuser)}
        >
          <img style={{ height: 24, width: 24 }} src={SearchIcon} />
          <p style={{ marginTop: 3, color: colors.black, fontSize: 10 }}>
            Search
          </p>
        </Link>
      </div>
      <div className="col-1 p-0">
        <RWebShare
          data={{
            text: `${t('Profile.join_text', { name: "easypaisa" })}`,
              url: `${url.siteUrl}user-profile/${allData?.item?._id}`

              ,
            title: t('right_hand_drop_down.join_me'),
          }}
        >
          <Link
            to="#"
            style={{
              height: 50,
              width: 40,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img style={{ height: 24, width: 24 }} src={ShareProfileIcon} />
            <p style={{ marginTop: 3, color: colors.black, fontSize: 10 }}>
              Share
            </p>
          </Link>
        </RWebShare>
      </div>
      <div className="col-1 p-0">
        <Link
          to="/profile-edit"
          style={{
            height: 50,
            width: 40,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img style={{ height: 24, width: 24 }} src={EditProfileIcon} />
          <p style={{ marginTop: 3, color: colors.black, fontSize: 10 }}>
            Edit
          </p>
        </Link>
      </div>
    </div>
  );
};
