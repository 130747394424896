import React from 'react'
import Previous from "../assets/images/previous.svg";
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

const PageNotFopund = () => {
    const navigate = useNavigate()
  return (
    <div
    className="inner-wrap custom_scroll_leader"
    // style={{   overflow: "auto" }}
    id="account_main"
  >
    <div className="inner-wrap">
          <div className="header">
            <div className="back-btn">
              <Link to={'/'}>
                <img src={Previous} alt="" />
              </Link>
            </div>
           
            <div className="wallet-btn"></div>
          </div>
          <div className="body mCustomScrollbar _mCS_1 bottom-btn-body">
            <div className="all_sub_screens_div_related_to_ads">
                <h1>
                    Page Not Found
                </h1>
            </div>
            </div>
            </div>

    </div>
  )
}

export default PageNotFopund