import React, { Fragment, useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Previous from "../assets/images/previous.svg";
import CopyIcon from "../assets/images/copy-icon.svg";
import {
  contestRate,
  getAllLeaderboardsList,
  getContestRate,
  getevents,
  getJoinTournamentRounds,
  getRules,
  tournamentDetailsBox,
  tournamentPlayerList,
  tournamentRounds
} from "../apis";
import {
  dateFormat,
  formatAMPM,
  goback,
  getProfiledateFormat,
} from "../component/common";
import Friendship from "../assets/images/friendship.svg";
import R2 from "../assets/images/r-2.svg";
import R3 from "../assets/images/r-3.svg";
import R4 from "../assets/images/r-4.svg";
import R5 from "../assets/images/r-5.svg";
import Close from "../assets/images/close.svg";
import Gamer from "../assets/images/gamer.svg";
import Gun2 from "../assets/images/gun-2.svg";
import Podium from "../assets/images/podium.svg";
import R1 from "../assets/images/r-1.svg";
import Picon1 from "../assets/images/p_icon_1.png";
import RoundPending from "../assets/images/round_pending.png";
import RoundQualified from "../assets/images/round_qualified.png";
import RoundNotQualified from "../assets/images/round_not_qualified.png";
import url from "../constants/url";
import ReactHtmlParser from "react-html-parser";
// import ContesX from "../assets/images/contest_x.svg";
// import ContesY from "../assets/images/contest_y.svg";
// import ContesI from "../assets/images/contest_i.svg";
import TrophyBlue from "../assets/images/ic_trophy_blue.png";
import TrophyYellow from "../assets/images/ic_trophy_yellow.png";
import List from "../assets/images/list.svg";
import { RWebShare } from "react-web-share";
import Loader from "../component/loader";
import PremiumLogoBlack from "../assets/images/GoldPremium.png";

import Advertisement from "../component/advertisement";
import { constants } from "../constants/constants";
import { useTranslation } from "react-i18next";
import Cookies from 'js-cookie'
import { useProfile } from "../component/ProfileComponent/ProfileContext";
import { useNavigate } from "react-router";

function TournamentDetails(props) {
  const [loader, setLoader] = useState(false);
  const [allData, setAllData] = useState(null);
  const [allRound, setAllRound] = useState([true, false, false]);
  const [allRoudsData, setAllRoudsData] = useState([]);
  const [rate, setRate] = React.useState(0);
  const [showWinners, setShowWinners] = useState(false);
  const [showWinnersContests, setShowWinnersContests] = useState(false);
  const [captainData, setCaptainData] = useState([]);
  // const [captainLevelData, setCaptainLevelData] = useState({});

  const [selectedContestData, setSelectedContestData] = useState({});
  const [allPlayersData, setAllPlayersData] = useState([]);
  const [rules, setRules] = useState("");
  const [premiumUser, setPremiumUser] = useState(
    JSON.parse(localStorage.getItem("premiumUser"))
  );
  const { t } = useTranslation()
  const currentLang = Cookies.get('i18next') || 'EN'
  const {walletDetails} = useProfile()
  const [currenActive, setCurrentActive] = useState(0)
  // console.log("waldhf", walletDetails)
  // const { getTrackProps, handles } = useRanger({
  //   min: 0,
  //   max: 50,
  //   stepSize: 10,
  //   values,
  //   onChange: setValues,
  // });

  useEffect(() => {
    if(walletDetails){
      fetchData();
      fetchRating();
      getRounds()
    }
    
  }, []);

  const fetchData = async () => {
    if (localStorage.getItem("gamerjiToken")) {
      setLoader(true);
      let allpage = [...JSON.parse(localStorage.getItem("currentPage"))];
      var count = allpage.findIndex((d) => d === window.location.pathname);
      count === -1 && allpage.push(window.location.pathname);
      localStorage.setItem("currentPage", JSON.stringify(allpage));

      var gameid = window.location.href.split("/")[window.location.href.split("/").length - 1];
      // console.log(walletDetails?.user_id)
      let response = await tournamentDetailsBox(
        {
          filter: {
            tournamentType: "gamerji",
            user: walletDetails?.user_id,
          },
        },
        gameid
      );
      if (response) {
        // console.log("Tournament Details: ", response);
        // let round = [];
        // response?.contests?.map((item, index) => {
        //   round.push(1);
        // });
        // round.length > 0 ? (round[round.length - 1] = 0) : (round = round);
        // console.log("round.length: ", round.length);
        // console.log("rounds: ", response?.item?.rounds);
        // setAllRound(round);
        setAllData(response);
        setLoader(false);
      } else {
        setLoader(false);
      }

    } else {
      window.location.href = "/sign-in";
    }
  };

  const getRounds = async () => {
    var eventId = window.location.href.split("/")[window.location.href.split("/").length - 1];
    let payload = {
      user:walletDetails?.user_id,
      event: eventId
    };
    let res = await tournamentRounds(payload);
    if (res) {
      if (res?.contests.length > 0) {
        let round = [];
        res?.contests?.map((item, index) => {
          round.push(1);
        });
        setAllRound(round);
        setAllRoudsData(res?.contests)
      }
      // setCaptainData(res?.captainData);
      // setAllPlayersData(res?.list);
    }
  }

  const getPlayerList = async () => {
    if(currenActive != 1){
      setCurrentActive(1)
      setLoader(true);
      var eventId = window.location.href.split("/")[window.location.href.split("/").length - 1];
      let payload = {
        skip: 0,
        limit: 10000,
        event: eventId,
        user: walletDetails.user_id,
        sortBy: "createdAt",
        sort: "desc",
      };
      let res = await tournamentPlayerList(payload);
      //console.log("getAllLeaderboardsList: ", res);
      if (res) {
        setCaptainData(res?.captainData);
        setAllPlayersData(res?.list);
        setLoader(false);
      } else {
        setLoader(false);
      }
    }
  }
  const getEventRules = async () => {
    if(currenActive != 2){
      setCurrentActive(2)
      setLoader(true);
      var eventId = window.location.href.split("/")[window.location.href.split("/").length - 1];
  
      let res = await getRules(eventId);
      if (res) {
        setRules(res?.item?.rules)
        setLoader(false);
      } else {
        setLoader(false);
      }
    }
  }
  const fetchRating = async () => {
    var gameid =
      window.location.href.split("/")[
      window.location.href.split("/").length - 1
      ];

    const res = await getContestRate({
      contest: gameid,
    });
    // console.log(res);
    if (res?.data) {
      setRate(res?.data?.rate);
    }
  };

  const toggleRound = (number) => {
    let temp = [...allRound];
    temp[number] = temp[number] === 1 ? 0 : 1;
    setAllRound(temp);
  };

  const onChangeRate = async (rate) => {
    setRate(rate);
    var gameid =
      window.location.href.split("/")[
      window.location.href.split("/").length - 1
      ];

    const data = { rate: rate, contest: gameid };
    let res = await contestRate(data);
    if (res) {
    }
  };

  let getRoundStatus = (contest, index) => {
    // console.log("allData.contests.length ==>", allData.contests.length);
    // console.log("index ==>", index)
    // console.log(contest)
    if (contest.isFinalRound) {
      return undefined;
    }

    if (contest.status != "completed") {
      return 'Pending';
    }
    if (allRoudsData.length >= index) {
      return 'Qualified';
    } else {
      return 'notQualified';
    }
  };

  let getRoundStatusDesc = (contest, index) => {
    // console.log(contest, '-*------------')
    if (contest.isFinalRound) {
      return undefined;
    }
    // console.log(getRoundStatus(contest, index))
    if (getRoundStatus(contest, index) == "Pending") {
      return t('contest_details.pending');
    }
    if (getRoundStatus(contest, index) == "Qualified") {
      return t('contest_details.qualified_for_next_round');
    }
    return t('contest_details.sorry_you_are_not_qualified');
  };

  let getRoundName = (rounds, contest) => {
    if (rounds.length > 0) {
      for (let i = 0; i < rounds.length; i++) {
        let round = rounds[i];

        for (let j = 0; j < round.matches.length; j++) {
          const match = round.matches[j];

          if (match.matchId == contest.code) {
            return round.name;
          }
        }
      }
    }
  };

  let getProgressValue = (contest) => {
    if (contest.totalJoinedPlayers && contest.totalPlayers) {
      return (100 * contest.totalJoinedPlayers) / contest.totalPlayers + "%";
    } else {
      return 0 + "%";
    }
  };

  const getQulified = (item) => {
    setShowWinnersContests(true);
    setSelectedContestData(item);
  };

  const bannerImg = url.imageUrl + allData?.item?.featuredImage?.default;
  const navigate = useNavigate()
  return (
    <div className="inner-wrap">
      <ToastContainer />
      <div className="header">
        <div className="back-btn">
          <span onClick={() => navigate(-1)}>
            <img src={Previous} alt="" />
          </span>
        </div>
        <h2>{t('header.tournament_details')}</h2>
        <div className="wallet-btn">
          {/* <Link to="/">
            <img src={Wallet} alt="" />
            Wallet
          </Link> */}
        </div>
      </div>
      {loader && <Loader />}
      <div
        className="body mCustomScrollbar _mCS_1 bottom-btn-body"
        style={{ paddingTop: "10px" }}
        id={`${showWinners === true || showWinnersContests === true
          ? "popup_show"
          : ""
          }`}
      >
        {/* <div
          className="banner-img"
          style={{ backgroundImage: `url('${bannerImg}')` }}
        >
           
        </div> */}
        <div
          className="all_sub_screens_div_related_to_ads"
          style={{ height: "100%" }}
        >
          <div className="list-group main-tab" id="list-tab" role="tablist">
            <a
              className="list-group-item list-group-item-action active"
              id="list-home-list"
              data-toggle="list"
              href="#list-1"
            >
              {t('crm.details')}
            </a>
            <a
              className="list-group-item list-group-item-action"
              id="list-profile-list"
              data-toggle="list"
              href="#list-2"
            >
              {t('tournaments.table')}
            </a>
            <a
              className="list-group-item list-group-item-action"
              id="list-profile-list"
              data-toggle="list"
              href="#list-3"
              onClick={() => getPlayerList()}
            >
              {t('contest_details.players')}
            </a>
            <a
              className="list-group-item list-group-item-action"
              id="list-profile-list"
              data-toggle="list"
              href="#list-4"
              onClick={() => getEventRules()}
            >
              {t('contest_details.rules')}
            </a>
          </div>
          <div className="tab-content" id="nav-tabContent">
            <div className="tab-pane active" id="list-1">
              <div className="contest-list contest-tournament-list tournament_details">
                <div className="box">
                  <h3 style={{ fontSize: "16px", fontWeight: "800" }}>
                    {/* <marquee> */}
                    {allData?.item?.title}
                    {/* </marquee> */}
                  </h3>
                  <div className="row tournament_contest_row" style={{ marginBottom: '-15px' }}>
                    <div className="col-4 pr-1">
                      <div className="img" style={{ height: '87%' }}>
                        <img
                          src={
                            url.imageUrl +
                            allData?.item?.featuredImage?.default
                          }
                          alt=""
                          style={{ maxHeight: "135px" }}
                        />
                        <div className="img-caption">
                          <h5>{allData?.gameTypeAry?.name}</h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-8 pb-3">
                      <div className="inner_row pt-0">
                        <div className="inner_item inner_item_medium">
                          {t('contest_details.date')}{" "}
                          <b>
                            {getProfiledateFormat(allData?.item?.startDate)}
                          </b>
                        </div>
                        <div className="inner_item inner_item_medium">
                          {t('contest_details.rounds')} <b>{allData?.item?.rounds}</b>
                        </div>
                        <div className="inner_item inner_item_medium">
                          {t('contest_details.id')} <b>{allData?.item?.shortCode}</b>
                        </div>
                      </div>
                      <div className="inner_row bg-primary">
                        {allData?.item?.titles?.slice(0, 2)?.map((item, index) => {
                          if (item?.name !== "-") {
                            return (
                              <div className="inner_item w-50" key={index}>
                                {item?.name} <b>{item?.value}</b>
                              </div>
                            );
                          }
                        })}
                      </div>
                      <div className="inner_row">
                        <div className="inner_item inner_item_medium">
                          {t('contest_details.prize_pool')}{" "}
                          {allData?.item?.rewardDisplayText != null ?
                            <b style={{ textOverflow: "ellipsis", whiteSpace: 'nowrap', overflow: 'hidden' }}>
                              {allData?.item?.rewardDisplayText}
                            </b>
                            :
                            <b>
                              {(allData?.item?.currency[0]?.outCurrency?.code == "INR" ||
                                allData?.item?.currency[0]?.outCurrency?.code == "inr") &&
                                "₹"}
                              {(allData?.item?.currency[0]?.outCurrency?.code == process.env.REACT_APP_CURRENCY_CODE ||
                                allData?.item?.currency[0]?.outCurrency?.code == process.env.REACT_APP_CURRENCY_CODE_OUT) && (
                                  <img
                                    src={
                                      url.imageUrl +
                                      allData?.item?.currency[0]?.outCurrency?.img?.default
                                    }
                                    alt=""
                                    className="coin_img"
                                  />
                                )}{" "}
                              {allData?.item?.prizePool}
                            </b>}
                        </div>
                        <div
                          className="inner_item pointer inner_item_medium"
                          onClick={() => setShowWinners(true)}
                        >
                          {t('tournaments.winners')}{" "}
                          <b>
                            {" "}
                            {allData?.item?.totalWinners}{" "}
                            <i className="fas fa-chevron-down"></i>
                          </b>
                        </div>
                        <div className="inner_item inner_item_medium">
                          {t('tournaments.join_using')}{" "}
                          {allData?.item?.entryFee === 0 ? (
                            <b>{t('tournaments.free')}</b>
                          ) : (
                            <b>
                              {(allData?.item?.currency[0]?.inCurrency
                                ?.code == "INR" ||
                                allData?.item?.currency[0]?.inCurrency
                                  ?.code == "inr") &&
                                "₹"}
                              {(allData?.item?.currency[0]?.inCurrency
                                ?.code == process.env.REACT_APP_CURRENCY_CODE ||
                                allData?.item?.currency[0]?.inCurrency
                                  ?.code == process.env.REACT_APP_CURRENCY_CODE_OUT) && (
                                  <img
                                    src={
                                      url.imageUrl +
                                      allData?.item?.currency[0]?.inCurrency
                                        ?.img?.default
                                    }
                                    alt=""
                                    className="coin_img"
                                  />
                                )}{" "}
                              {allData?.item?.entryFee}
                            </b>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <h2 className="title mb-3">{t('contest_details.tournament_rounds')}</h2>
              <div className="contest-list contest-toggle tournament_details">
                {allRoudsData.map((item, index) => {
                  return (
                    <div key={index}>
                      <div
                        className={`box ${allRound[index] === 0 && "show"}`}
                      >
                        <div
                          className="contest_toggle_btn row"
                          onClick={() => toggleRound(index)}
                        >
                          <div className="col-5">
                            {item?.contest?.title}
                            {/* {getRoundName(allData?.item?.rounds, item)} */}
                          </div>
                          <div
                            className="col-6 p-0"
                            style={{ fontSize: "14px", textAlign: "right" }}
                          >
                            {getRoundStatus(item?.contest, index) == "Pending" && (
                              <img
                                src={RoundPending}
                                style={{ width: "16px" }}
                              />
                            )}
                            {getRoundStatus(item?.contest, index) == "Qualified" && (
                              <img
                                src={RoundQualified}
                                style={{ width: "16px" }}
                              />
                            )}
                            {getRoundStatus(item?.contest, index) == "notQualified" && (
                              <img
                                src={RoundNotQualified}
                                style={{ width: "16px" }}
                              />
                            )}{" "}
                            {getRoundStatusDesc(item?.contest, index)}
                          </div>
                          <div className="col-1 p-0">
                            <span></span>
                          </div>

                          {/* {item?.game?.name} */}
                          {/* <span> */}

                          {/* <img src={item?.status === 'waiting' ?  ContesI : item?.status === 'joined' ? ContesY : ContesX} alt="" />  */}

                          {/* </span> */}
                        </div>
                        <div className="contest_toggle_txt">
                          <div className="inner_row bg-primary">
                            <div className="inner_item inner_big_25">
                              {t('contest_details.date')} <b>{getProfiledateFormat(item?.contest?.date)}</b>
                            </div>
                            <div className="inner_item inner_big_25">
                              {t('contest_details.time')} <b>{formatAMPM(new Date(item?.contest?.time))}</b>
                            </div>
                            <div className="inner_item inner_big_30">
                              {item?.contest?.isFinalRound ? (
                                <div
                                  className="inner_item pointer inner_item_medium"
                                  onClick={() => setShowWinners(true)}
                                >
                                  {t('contest.winners')}{" "}
                                  <b>
                                    {" "}
                                    {item?.contest?.winnerCount}{" "}
                                    <i className="fas fa-chevron-down"></i>
                                  </b>
                                </div>
                              ) : (
                                <div
                                  className="inner_item pointer inner_item_medium"
                                  onClick={() => getQulified(item?.contest)}
                                >
                                  {t('contest_details.qualification')}{" "}
                                  <b>
                                    {" "}
                                    {item?.contest?.winnerCount}{" "}
                                    <i className="fas fa-chevron-down"></i>
                                  </b>
                                </div>
                              )}
                            </div>
                            <div className="inner_item inner_big_20">
                              {t('contest_details.id')} <b>{allData?.item?.shortCode}</b>
                            </div>
                          </div>
                          {/* <div className="inner_row">
                              <div className="inner_item inner_item_medium">
                                {t('contest_details.prize_pool')}{" "}
                                {allData?.item?.rewardDisplayText != null ?
                                  <b style={{ textOverflow: "ellipsis", whiteSpace: 'nowrap', overflow: 'hidden' }}>
                                    {allData?.item?.rewardDisplayText}
                                  </b>
                                  :
                                  <b>
                                    {(allData?.item?.currency[0]?.outCurrency
                                      ?.code == "INR" ||
                                      allData?.item?.currency[0]?.outCurrency
                                        ?.code == "inr") &&
                                      "₹"}
                                    {(allData?.item?.currency[0]?.outCurrency
                                      ?.code == "coin" ||
                                      allData?.item?.currency[0]?.outCurrency
                                        ?.code == "diamond") && (
                                        <img
                                          src={
                                            url.imageUrl +
                                            allData?.item?.currency[0]?.outCurrency
                                              ?.img?.default
                                          }
                                          alt=""
                                          className="coin_img"
                                        />
                                      )}{" "}
                                    {allData?.item?.prizePool}
                                  </b>}
                              </div>
                              {item?.contest?.isFinalRound ? (
                                <div
                                  className="inner_item pointer inner_item_medium"
                                  onClick={() => setShowWinners(true)}
                                >
                                  {t('contest.winners')}{" "}
                                  <b>
                                    {" "}
                                    {item?.contest?.winnerCount}{" "}
                                    <i className="fas fa-chevron-down"></i>
                                  </b>
                                </div>
                              ) : (
                                <div
                                  className="inner_item pointer inner_item_medium"
                                  onClick={() => getQulified(item?.contest)}
                                >
                                  {t('contest_details.qualification')}{" "}
                                  <b>
                                    {" "}
                                    {item?.contest?.winnerCount}{" "}
                                    <i className="fas fa-chevron-down"></i>
                                  </b>
                                </div>
                              )}
                              <div className="inner_item inner_item_medium">
                                {t('contest_details.id')} <b>{allData?.item?.shortCode}</b>
                              </div>
                            </div> */}
                          <div className="avalible_wrap">
                            <div className="inner">
                              <span
                                style={{ width: getProgressValue(item?.contest) }}
                              ></span>
                            </div>
                            <p className="d-flex justify-content-between">
                              <span>
                                {t('contest_details.players_remaining', {
                                  players: item?.contest?.totalPlayers -
                                    item?.contest?.totalJoinedPlayers
                                })}
                              </span>
                              <span>
                                {t('contest_details.players_joined', { players: item?.contest?.totalJoinedPlayers })}
                              </span>
                            </p>
                          </div>
                          <div className="contest_foot contest_details_foot p-2">
                            <div className="inner inner-text pl-4">
                              <span>{t('contest_details.room_id')}</span>
                              <b style={{ fontSize: "12px" }}>
                                {item?.contest?.roomId === "" ? "-" : item?.contest?.roomId?.length > 25 ? (
                                  <marquee>{item?.contest?.roomId}</marquee>
                                ) : (
                                  item?.contest?.roomId
                                )}{" "}
                                {item?.contest?.roomId != "" && (
                                  <img
                                    src={CopyIcon}
                                    alt=""
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      navigator.clipboard.writeText(
                                        item?.contest?.roomId
                                      );
                                      toast.info(t('info_Copied'), {
                                        position: "top-left",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                      });
                                    }}
                                  />
                                )}
                              </b>
                            </div>
                            <div className="inner inner-text pl-4">
                              <span>{t('contest_details.password')}</span>
                              <b style={{ fontSize: "12px" }}>
                                {item?.contest?.roomPassword === ""
                                  ? "-"
                                  : item?.contest?.roomPassword?.length > 25 ? (
                                    <marquee>{item?.contest?.roomPassword}</marquee>
                                  ) : (
                                    item?.contest?.roomPassword
                                  )}{" "}
                                {item?.contest?.roomPassword != "" && (
                                  <img
                                    src={CopyIcon}
                                    alt=""
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      navigator.clipboard.writeText(
                                        item?.contest?.roomPassword
                                      );
                                      toast.info(t('info_Copied'), {
                                        position: "top-left",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                      });
                                    }}
                                  />
                                )}
                              </b>
                            </div>
                          </div>
                        </div>
                      </div>
                      {allRound[index] === 0 && (
                        <div className="player-icons-captain">
                          <div className="row no-gutters">
                            <div className="col-6">
                              <div className="item" style={{ width: "95px" }}>
                                <div className="icon">
                                  <img src={Gamer} alt="" />
                                </div>
                                {t('contest_details.username')}
                              </div>
                            </div>
                            {/* <div className="col-3">&nbsp; </div> */}
                            <div className="col-2">
                              <div className="item">
                                <div className="icon">
                                  <img src={Gun2} alt="" />
                                </div>
                                {t('contest_details.kills')}
                              </div>
                            </div>
                            <div className="col-2">
                              <div className="item">
                                <div className="icon">
                                  <img src={Podium} alt="" />
                                </div>
                                {t('contest_details.rank')}
                              </div>
                            </div>
                            <div className="col-2">
                              <div className="item">
                                <div className="icon">
                                  <img src={List} alt="" />
                                </div>
                                {t('contest_details.points')}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {allRound[index] === 0 && (
                        <div
                          className="player_captain contest mb-3"
                          style={{ padding: "10px 15px" }}
                        >
                          <div className="row no-gutters">
                            <div className="col-6">
                              <div className="d-flex">
                                <img
                                  className="tournament-username"
                                  src={url.imageUrl + item?.user?.level?.featuredImage?.default}
                                  alt=""
                                />
                                <div className="d-flex flex-column tournament-text">
                                  <b style={{
                                    display: 'block', width: '70px', whiteSpace: "nowrap", overflow: "hidden",
                                    textOverflow: "ellipsis", marginTop: "0.5rem"
                                  }}>
                                    {item?.user?.gamerjiName}
                                  </b>

                                  {/* <small className="d-flex">
                                      {item?.contestUserItem?.user &&
                                        item?.contestUserItem?.user?.phone
                                        ? "xxxxx" +
                                        item?.contestUserItem?.user?.phone.slice(
                                          item?.contestUserItem?.user?.phone
                                            ?.length - 5
                                        )
                                        : ""}
                                    </small> */}
                                  <div className="d-flex">
                                    {allData?.status == "completed" ? (
                                      item?.totalAmount > 0 ? (
                                        <div>
                                          <img
                                            src={TrophyYellow}
                                            alt=""
                                            className="coin_img"
                                          />{" "}
                                          Won{" "}
                                          {allData?.currency?.code == "INR" &&
                                            "₹"}
                                          {allData?.currency?.code ==
                                            process.env.REACT_APP_CURRENCY_CODE && (
                                              <img
                                                src={
                                                  url.imageUrl +
                                                  allData?.currency?.img
                                                    ?.default
                                                }
                                                alt=""
                                                className="coin_img"
                                              />
                                            )}{" "}
                                          {item?.contestUserItem?.totalAmount}
                                        </div>
                                      ) : (
                                        ""
                                      )
                                    ) : (
                                      ""
                                    )}
                                    {premiumUser ? (
                                      <div className="ps_player_front" >
                                        <img
                                          style={currentLang?.toLowerCase() == "ar" ? {
                                            width: "28px",
                                            height: "25px",
                                            marginRight: "20px",
                                          } : {
                                            width: "28px",
                                            height: "25px",
                                            marginLeft: "20px",
                                          }}
                                          src={PremiumLogoBlack}
                                        />{" "}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-2 text-center m-auto">
                              <b>
                                {item?.kills === undefined ||
                                  item?.kills === null ||
                                  item?.kills === 0
                                  ? "-"
                                  : item?.kills}
                              </b>
                            </div>
                            <div className="col-2 text-center m-auto">
                              <b>
                                {item?.rank === undefined ||
                                  item?.rank === null ||
                                  item?.rank === 0
                                  ? "-"
                                  : item?.rank}
                              </b>
                            </div>
                            <div className="col-2 text-center m-auto">
                              <b>
                                {item?.totalPoint ===
                                  undefined ||
                                  item?.totalPoint === null ||
                                  item?.totalPoint === 0
                                  ? "-"
                                  : item?.totalPoint}
                              </b>
                            </div>
                          </div>

                          {/* <div className="load_more text-center">
                            <Link to="/" className="btn btn-primary">
                              Load More
                            </Link>
                          </div> */}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>

              {showWinnersContests === true && (
                <div
                  className="custom-popup rules winner custom_scroll show"
                  id="contact_popup"
                >
                  <div className="popup-head">
                    <h3>{t('contest_details.winning_breakup')}</h3>
                    <button
                      className="close-popup"
                      onClick={() => setShowWinnersContests(false)}
                    >
                      <img src={Close} alt="" />
                    </button>
                  </div>
                  <div className="popup-body bg-yellow">
                    <div className="all_sub_screens_div_related_to_ads">
                      <div className="tab-content" id="nav-tabContent">
                        <div className="tab-pane active" id="prize-1">
                          <div className="pl-4 prize_pool row w-100">
                            <div className={currentLang?.toLowerCase() == "ar" ? "text-left col-6" : "col-6"}>
                              <b>
                                {t('contest_details.rank')} 1 - {selectedContestData.winnerCount}
                              </b>
                            </div>
                            <div className={currentLang?.toLowerCase() == "ar" ? "col-6" : "text-right col-6"}>
                              <b>{t('contest_details.qualified_for_next_round')}</b>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Advertisement
                      screen={
                        localStorage.getItem("apk_screens_list") === null
                          ? { code: "no_results" }
                          : JSON.parse(
                            localStorage.getItem("apk_screens_list")
                          ).prizePoolPopup
                      }
                      screen_div_class_name=".all_sub_screens_div_related_to_ads"
                      screen_type="sub"
                    />
                  </div>
                </div>
              )}

              {showWinners === true && (
                <div
                  className="custom-popup rules winner custom_scroll show"
                  id="contact_popup"
                >
                  <div className="popup-head">
                    <h3>{t('contest_details.winning_breakup')}</h3>
                    <button
                      className="close-popup"
                      onClick={() => setShowWinners(false)}
                    >
                      <img src={Close} alt="" />
                    </button>
                  </div>
                  <div className="popup-body bg-yellow">
                    <div className="all_sub_screens_div_related_to_ads">
                      {allData?.item?.pointpool &&
                        allData?.item?.pointpool?.length !== 0 && (
                          <div
                            className="list-group main-tab w-90"
                            id="list-tab"
                            role="tablist"
                          >
                            <a
                              className="list-group-item list-group-item-action active"
                              id="list-home-list"
                              data-toggle="list"
                              href="#prize-1"
                            >
                              {t('contest_details.prize_pool')}
                            </a>
                            <a
                              className="list-group-item list-group-item-action"
                              id="list-profile-list"
                              data-toggle="list"
                              href="#point-2"
                            >
                              {t('contest_details.points')}
                            </a>
                          </div>
                        )}

                      <div className="tab-content" id="nav-tabContent">
                        <div className="tab-pane active" id="prize-1">
                          <div className="prize_pool mb-3 text-center p-2 w-90">
                            <h5 className="text-center" style={{ textOverflow: "ellipsis", whiteSpace: 'nowrap', overflow: 'hidden' }}>{t('contest_details.prize_pool')}</h5>
                            {allData?.item?.rewardDisplayText != null ?
                              <h6
                                className="text-center"
                                style={{ fontWeight: "800" }}
                              >{allData?.item?.rewardDisplayText}
                              </h6>

                              :
                              <h6
                                className="text-center"
                                style={{ fontWeight: "800" }}
                              >
                                {(allData?.item?.currency?.[0]?.outCurrency
                                  ?.code == "INR" ||
                                  allData?.item?.currency?.[0]?.outCurrency
                                    ?.code == "INR") &&
                                  "₹"}
                                {(allData?.item?.currency?.[0]?.outCurrency
                                  ?.code ==process.env.REACT_APP_CURRENCY_CODE ||
                                  allData?.item?.currency?.[0]?.outCurrency
                                    ?.code == process.env.REACT_APP_CURRENCY_CODE_OUT) && (
                                    <img
                                      src={
                                        url.imageUrl +
                                        allData?.item?.currency?.[0]?.outCurrency
                                          ?.img?.default
                                      }
                                      alt=""
                                      className="coin_img_big mr-1"
                                    />
                                  )}{" "}
                                {allData?.item?.prizePool}
                              </h6>}

                            <div className="fee">
                              {allData?.item?.prizePooInfo?.map(
                                (item, index) => {
                                  return (
                                    <p key={index}>
                                      <b>
                                        {t('contest_details.rank')}:{" "}
                                        {item?.rankTo !== "0" &&
                                          item?.rankTo != item?.rankFrom
                                          ? item?.rankFrom +
                                          "-" +
                                          item?.rankTo
                                          : item?.rankFrom}
                                      </b>
                                      <b>
                                        {(allData?.item?.currency[0]
                                          ?.outCurrency?.code == "INR" ||
                                          allData?.item?.currency[0]
                                            ?.outCurrency?.code == "INR") &&
                                          "₹"}
                                        {(allData?.item?.currency[0]
                                          ?.outCurrency?.code == process.env.REACT_APP_CURRENCY_CODE ||
                                          allData?.item?.currency[0]
                                            ?.outCurrency?.code ==
                                            process.env.REACT_APP_CURRENCY_CODE_OUT) && (
                                            <img
                                              src={
                                                url.imageUrl +
                                                allData?.item?.currency[0]
                                                  ?.outCurrency?.img?.default
                                              }
                                              alt=""
                                              // className="coin_img"
                                              style={{ width: '15px' }}
                                            />
                                          )}{" "}
                                        {item?.amount}
                                      </b>
                                    </p>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </div>
                        {allData?.item?.pointpool && (
                          <div className="tab-pane" id="point-2">
                            <div className="table-responsive total_table total_table_border mb-3 w-90">
                              <table>
                                <tbody>
                                  {allData?.item?.pointpool?.map(
                                    (item1, index1) => {
                                      return (
                                        <tr key={index1}>
                                          <td className="text-left">
                                            <b>
                                              {t('contest_details.rank')}:{" "}
                                              {item1?.rankTo !== "0"
                                                ? item1?.rankFrom +
                                                "-" +
                                                item1?.rankTo
                                                : item1?.rankFrom}
                                            </b>
                                          </td>
                                          <th className="text-right">
                                            {item1?.point}
                                          </th>
                                        </tr>
                                      );
                                    }
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <Advertisement
                      screen={
                        localStorage.getItem("apk_screens_list") === null
                          ? { code: "no_results" }
                          : JSON.parse(
                            localStorage.getItem("apk_screens_list")
                          ).prizePoolPopup
                      }
                      screen_div_class_name=".all_sub_screens_div_related_to_ads"
                      screen_type="sub"
                    />
                  </div>
                </div>
              )}
              <RWebShare
                data={{
                  text: "Hey! Join me on Gamerji for this exciting match. Click on hte following link and let's play together",
                  url: `${url.siteUrl}?code=${allData?.item?.shortCode}`,
                  title: "Join me on Gamreji",
                }}
                onClick={() => console.info("share successful!")}
              >
                <div className="row friendship_items">
                  <div className="col-12">
                    <div className="box winning_breakup_popup_toggle">
                      <div className="icon">
                        <img src={Friendship} alt="" />
                      </div>
                      {t('contest_details.share_with_friends')}
                    </div>
                  </div>
                </div>
              </RWebShare>

              {rate === 0 ? (
                <Fragment>
                  <h2 className="title mb-3 mt-4">
                    {t('contest_details.rate_gamerji_e_sports_private_limited')}{" "}
                  </h2>
                  <div className="r-wrap">
                    <div className="r-image">
                      <div className="img" onClick={() => onChangeRate(1)}>
                        <img src={R1} alt="" />
                      </div>
                      <div className="img" onClick={() => onChangeRate(2)}>
                        <img src={R2} alt="" />
                      </div>
                      <div className="img" onClick={() => onChangeRate(3)}>
                        <img src={R3} alt="" />
                      </div>
                      <div className="img" onClick={() => onChangeRate(4)}>
                        <img src={R4} alt="" />
                      </div>
                      <div className="img" onClick={() => onChangeRate(5)}>
                        <img src={R5} alt="" />
                      </div>
                    </div>
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <h2 className="title mb-3 mt-4">{t('contest_details.you_provided_ratings')} </h2>
                  <div className="r-wrap">
                    <div className="text-center">
                      <div className="img">
                        <img
                          src={
                            rate === 1
                              ? R1
                              : rate === 2
                                ? R2
                                : rate === 3
                                  ? R3
                                  : rate === 4
                                    ? R4
                                    : R5
                          }
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </Fragment>
              )}
              {/* <div
                  {...getTrackProps({
                    style: {
                      height: "4px",
                      background: "#ddd",
                      boxShadow: "inset 0 1px 2px rgba(0,0,0,.6)",
                      borderRadius: "2px",
                    },
                  })}
                >
                  {handles.map(({ getHandleProps }) => (
                    <button
                      {...getHandleProps({
                        style: {
                          width: "16px",
                          height: "19px",
                          backgroundSize: "auto 100%",
                          // outline: "none",
                          // borderRadius: "100%",
                          // background: "linear-gradient(to bottom, #eee 45%, #ddd 55%)",
                          // border: "solid 1px #888",
                          backgroundImage: `url("../assets/images/range-btn.svg")`,
                        },
                      })}
                    />
                  ))}
                </div> */}
              {/* <div className="r-range">
                    <div id="slider"></div>
                    <img src={RangeDot} className="d-block w-100"/>
                  </div> 
              </div>*/}
            </div>

            <div className="tab-pane" id="list-2">
              <img
                src={url.imageUrl + allData?.item?.eventTableImage?.default}
              />
              {/* <div className="round-box">
                <div className="round-head">
                  <div className="item">Round 1</div>
                  <div className="item">Round 2</div>
                  <div className="item">Round 3</div>
                  <div className="item">Round 4</div>
                </div>
                <div className="dtc">
                  <div className="round-chart">
                    <div className="col col_black">
                      <div className="grp">
                        <div className="label">
                          Top 50% <img src={RoundArrowBlack} alt="" />
                        </div>
                        <div className="item">R 1 A</div>
                        <div className="item">R 1 B</div>
                      </div>
                      <div className="grp">
                        <div className="label">
                          Top 50% <img src={RoundArrowBlack} alt="" />
                        </div>
                        <div className="item">R 1 C</div>
                        <div className="item">R 1 D</div>
                      </div>
                      <div className="grp">
                        <div className="label">
                          Top 50% <img src={RoundArrowBlack} alt="" />
                        </div>
                        <div className="item">R 1 E</div>
                        <div className="item">R 1 F</div>
                      </div>
                      <div className="grp">
                        <div className="label">
                          Top 50% <img src={RoundArrowBlack} alt="" />
                        </div>
                        <div className="item">R 1 G</div>
                        <div className="item">R 1 H</div>
                      </div>
                    </div>
                    <div className="col col_blue">
                      <div className="grp">
                        <div className="label">
                          Top 50% <img src={RoundArrowBlue} alt="" />
                        </div>
                        <div className="item">R 2 A</div>
                        <div className="item">R 2 B</div>
                      </div>
                      <div className="grp">
                        <div className="label">
                          Top 50% <img src={RoundArrowBlue} alt="" />
                        </div>
                        <div className="item">R 2 C</div>
                        <div className="item">R 2 D</div>
                      </div>
                    </div>
                    <div className="col col_red">
                      <div className="grp">
                        <div className="label">
                          Top 50% <img src={RoundArrowRed} alt="" />
                        </div>
                        <div className="item">R 3 A</div>
                        <div className="item">R 3 B</div>
                      </div>
                    </div>
                    <div className="col col_green">
                      <div className="grp">
                        <div className="item">FINAL</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>

            <div className="tab-pane" id="list-3">
              <div className="player_icons">
                <div className="row no-gutters">
                  <div className="col-6">
                    <div className="item" style={{ width: "95px" }}>
                      <div className="icon">
                        <img src={Gamer} alt="" />
                      </div>
                      {t('contest_details.username')}
                    </div>
                  </div>
                  {/* <div className="col-2">&nbsp; </div> */}
                  <div className="col-2">
                    <div className="item">
                      <div className="icon">
                        <img src={Gun2} alt="" />
                      </div>
                      {t('contest_details.kills')}
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="item">
                      <div className="icon">
                        <img src={Podium} alt="" />
                      </div>
                      {t('contest_details.rank')}
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="item">
                      <div className="icon">
                        <img src={List} alt="" />
                      </div>
                      {t('contest_details.points')}
                    </div>
                  </div>
                </div>
              </div>

              <div className="player_list contest">
                {/* {console.log(captainData, captainLevelData)} */}
                {/* {captainData?.map((item, index) => {
                    console.log('item:', item)
                    // if (captainData && captainData._id == item._id) {
                      return ( */}
                <div className="item active">
                  <div className="inner inner_big">
                    <div className="icon">
                      <img
                        style={{ width: "30px", height: "30px" }}
                        src={url.imageUrl + captainData?.user?.featured_image}
                        alt=""
                      />
                    </div>
                    <div className="d-flex flex-column tournament-text">
                      <b>{captainData?.user?.gamerjiName}</b>

                      {/* <small>
                          {captainData?.user && captainData?.user?.phone
                            ? "xxxxx" +
                            captainData?.user?.phone.slice(
                              captainData?.user?.phone?.length - 5
                            )
                            : ""}
                          {allData?.status == "completed" ? (
                            captainData?.totalAmount > 0 ? (
                              <div>
                                <img
                                  src={TrophyYellow}
                                  alt=""
                                  className="coin_img"
                                />{" "}
                                Won {allData?.currency?.code == "INR" && "₹"}
                                {allData?.currency?.code == "coin" && (
                                  <img
                                    src={
                                      url.imageUrl +
                                      allData?.currency?.img?.default
                                    }
                                    alt=""
                                    className="coin_img"
                                  />
                                )}{" "}
                                {captainData?.totalAmount}
                              </div>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                        </small> */}
                    </div>
                    {premiumUser ? (
                      <div className="ps_player">
                        <img
                          style={currentLang?.toLowerCase() == "ar" ? {
                            width: "28px",
                            height: "25px",
                            marginRight: "20px",
                          } : {
                            width: "28px",
                            height: "25px",
                            marginLeft: "20px",
                          }}
                          src={PremiumLogoBlack}
                        />{" "}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="inner">
                    <b>
                      {captainData?.kills === null || captainData?.kills === 0
                        ? "-"
                        : captainData?.kills}
                    </b>
                  </div>
                  <div className="inner">
                    <b>
                      {captainData?.rank === null || captainData?.rank === 0
                        ? "-"
                        : captainData?.rank}
                    </b>
                  </div>
                  <div className="inner">
                    <b>
                      {captainData?.points === null ||
                        captainData?.points === 0
                        ? "-"
                        : captainData?.points}
                    </b>
                  </div>
                </div>
                {/* );
                    // } */}
                {/* })} */}
                {/* {console.log("*************************allPlayersData", allPlayersData)} */}
                {allPlayersData?.map(
                  (item, index) =>
                    // if (captainData.length > 0 && captainData[0]._id !== item._id) {
                    //   return (
                    item?.user?.user_id != captainData?.user?.user_id && (
                      <div className="item" key={index}>
                        <div className="inner inner_big">
                          <div className="icon">
                            <img
                              style={{ width: "30px", height: "30px" }}
                              src={url.imageUrl + item?.user?.level?.featuredImage?.default}
                              alt=""
                            />
                          </div>
                          <div class="d-flex flex-column">
                            <b>{item?.user?.gamerjiName}</b>
                            <br />
                            {/* <small>
                                {item?.user && item?.user?.phone
                                  ? "xxxxx" +
                                  item?.user?.phone.slice(
                                    item?.user?.phone?.length - 5
                                  )
                                  : ""}
                                {allData?.status == "completed" ? (
                                  item?.totalAmount > 0 ? (
                                    <div>
                                      <img
                                        src={TrophyBlue}
                                        alt=""
                                        className="coin_img"
                                      />{" "}
                                      Won{" "}
                                      {allData?.currency?.code == "INR" && "₹"}
                                      {allData?.currency?.code == "coin" && (
                                        <img
                                          src={
                                            url.imageUrl +
                                            allData?.currency?.img?.default
                                          }
                                          alt=""
                                          className="coin_img"
                                        />
                                      )}{" "}
                                      {item?.totalAmount}
                                    </div>
                                  ) : (
                                    ""
                                  )
                                ) : (
                                  ""
                                )}
                              </small> */}
                          </div>
                          {item?.isPremium ? (
                            <div className="ps_player">
                              <img
                                style={currentLang?.toLowerCase() == "ar" ? {
                                  width: "28px",
                                  height: "25px",
                                  marginRight: "20px",
                                } : {
                                  width: "28px",
                                  height: "25px",
                                  marginLeft: "20px",
                                }}
                                src={PremiumLogoBlack}
                              />{" "}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="inner">
                          <b>
                            {item?.kills === null || item?.kills === 0
                              ? "-"
                              : item?.kills}
                          </b>
                        </div>
                        <div className="inner">
                          <b>
                            {item?.rank === null || item?.rank === 0
                              ? "-"
                              : item?.rank}
                          </b>
                        </div>
                        <div className="inner">
                          <b>
                            {item?.points === null || item?.points === 0
                              ? "-"
                              : item?.points}
                          </b>
                        </div>
                      </div>
                    )

                  //   );
                  // }
                )}
                {/* <div className="load_more text-center">
                  <Link to="/" className="btn btn-primary">
                    Load More
                  </Link>
                </div> */}
              </div>
            </div>

            <div className="tab-pane" id="list-4">
              <div className="tab_content">
                <p>{ReactHtmlParser(rules)}</p>
              </div>
            </div>
          </div>
        </div>
        <Advertisement
          screen={
            localStorage.getItem("apk_screens_list") === null
              ? { code: "no_results" }
              : JSON.parse(localStorage.getItem("apk_screens_list"))
                .tournamentDetails
          }
          screen_div_class_name=".all_sub_screens_div_related_to_ads"
          screen_type="sub"
        />
      </div>

    </div>
  );
}

export default TournamentDetails;
