import { constants } from "../constants/constants";

export function isLoggedinUser() {
  if (localStorage.getItem('gamerjiToken') === null) {
    return false;
  } else {
    return true;
  }
}

const month = [
  "Jan",
  "Feb",
  "March",
  "April",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

const fullmonth = [
  "January",
  "Febuary",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}

export function htmlTime(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var strTime = hours + ':' + minutes;
  return strTime;
}

export const getTournamentFullDateTime = (date, time) => { //12th June 2020, 10:00 AM
  var dt = new Date(date);
  return dt.getDate() + " " + fullmonth[dt.getMonth()] + " " + dt.getFullYear() + ", " + formatAMPM(new Date(time));
};

export const getConvertionDateTime = (date) => { //12th June 2020, 10:00 AM
  var dt = new Date(date);
  return dt.getDate() + " " + month[dt.getMonth()] + " " + dt.getFullYear() + " " + formatAMPM(new Date(date));
};

export const getTournamentDateTime = (date, time) => { //12th June 2020, 10:00 AM
  var dt = new Date(date);
  return dt.getDate() + " " + month[dt.getMonth()] + " " + dt.getFullYear() + " " + formatAMPM(new Date(time));
};

export const dateFormat = (date) => {
  var dt = new Date(date);
  return dt.getDate() + " " + month[dt.getMonth()] + " " + dt.getFullYear();
};

export const dateFullFormat = (date) => {
  var dt = new Date(date);
  return dt.getDate() + " " + fullmonth[dt.getMonth()] + " " + dt.getFullYear();
};

export function convertNumberToMillions(labelValue) {

  if (labelValue < 1e3) return labelValue;
  if (labelValue >= 1e3 && labelValue < 1e6) return +(labelValue / 1e3).toFixed(1) + "K";
  if (labelValue >= 1e6 && labelValue < 1e9) return +(labelValue / 1e6).toFixed(1) + "M";
  if (labelValue >= 1e9 && labelValue < 1e12) return +(labelValue / 1e9).toFixed(1) + "B";
  if (labelValue >= 1e12) return +(labelValue / 1e12).toFixed(1) + "T";
}
export function kFormatter(num) {


  return Math.abs(Number(num)) >= 1.0e+9

    ? (Math.abs(Number(num)) / 1.0e+9).toFixed(1) + "B"
    // Six Zeroes for Millions 
    : Math.abs(Number(num)) >= 1.0e+6

      ? (Math.abs(Number(num)) / 1.0e+6).toFixed(1) + "M"
      // Three Zeroes for Thousands
      : Math.abs(Number(num)) >= 1.0e+3

        ? (Math.abs(Number(num)) / 1.0e+3).toFixed(1) + "K"

        : Math.abs(Number(num));
}

export function removeArrayItem(arr, what) {
  var found = arr.indexOf(what);

  while (found !== -1) {
    arr.splice(found, 1);
    found = arr.indexOf(what);
  }
}

export function getProfiledateFormat(val) {
  var today = new Date(val);

  return (
    ("0" + today.getDate()).slice(-2) +
    "/" +
    ("0" + (today.getMonth() + 1)).slice(-2) +
    "/" +
    today.getFullYear()
  );
}

export function getHTMLdateFormat(val) {
  var today = new Date(val);

  return (
    today.getFullYear() +
    "-" +
    ("0" + (today.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + today.getDate()).slice(-2)
  );
}

export const goback = () => {
  let allpage = [...JSON.parse(localStorage.getItem("currentPage"))];
  removeArrayItem(allpage, allpage[allpage.length - 1]);
  localStorage.setItem("currentPage", JSON.stringify(allpage));

  window.location.href = JSON.parse(localStorage.getItem("currentPage"))[
    JSON.parse(localStorage.getItem("currentPage")).length - 1
  ];
};

export const checkDobNstateConditions = (screen, uniqueI) => {
  var user_data = JSON.parse(localStorage.getItem("profileLite"));
  var profileBasicDetails = JSON.parse(localStorage.getItem(constants.profileBasics));
  // var gameNames = JSON.parse(localStorage.getItem('userInGameNames'));

  var tag_show_dobnsgtate_popup = false;

  // console.log("(user_data?.state === null || user_data?.state === undefined)",user_data?.item)
  console.log( tag_show_dobnsgtate_popup)

  // var temp_state_value = user_data.state;


  if (user_data?.item?.dateOfBirth === null || user_data?.item?.dateOfBirth === undefined) {
    tag_show_dobnsgtate_popup = true;
    console.log( tag_show_dobnsgtate_popup)

  }

  // if ((profileBasicDetails?.country?.code === 'IN' || profileBasicDetails?.country?.dialingCode === '+91')
  // //  && (user_data?.item?.address?.state === null || user_data?.item?.address?.state === undefined)
  //  ) {
  //   tag_show_dobnsgtate_popup = true;
  //   console.log( tag_show_dobnsgtate_popup)

  // }

  if (screen === 'tournaments-contests') {
    var temp_in_game_name = null;

    if (localStorage.getItem('activeGameId') !== null) {
      temp_in_game_name = uniqueI
      // gameNames?.forEach(element => {
      //   if (element.game === localStorage.getItem('activeGameId'))
      //     temp_in_game_name = element.userINGameName;
      // })
    }

    if (temp_in_game_name === null || temp_in_game_name === undefined) {
      // console.log("rntered", gameNames)
      tag_show_dobnsgtate_popup = true;
    }
  } else
    if (screen === 'coin-store-buy-item' || screen === 'coin-store-redeem-item') {
      console.log( tag_show_dobnsgtate_popup)
      //  if (user_data?.item?.email === null || user_data?.item?.email === undefined || !user_data?.item?.isEmailVerified) {
      //   tag_show_dobnsgtate_popup = true;
      // }

      // if(screen === 'coin-store-redeem-item') {
      if (user_data.item?.phone === null || user_data.item?.phone === undefined || !user_data?.item?.isMobileVerified) {
        tag_show_dobnsgtate_popup = true;
        console.log( tag_show_dobnsgtate_popup)
      }
      if (user_data?.item?.email === null || user_data?.item?.email === undefined || !user_data?.item?.isEmailVerified) {
        console.log( tag_show_dobnsgtate_popup)
        tag_show_dobnsgtate_popup = true;
      }
      // }
      console.log( tag_show_dobnsgtate_popup, user_data.item?.phone , user_data?.item?.email, !user_data?.item?.isMobileVerified, !user_data?.item?.isEmailVerified )
    }
  if (screen === 'subscription-buy-pack') {

    if (user_data.item?.phone === null || user_data.item?.phone === undefined || !user_data?.item?.isMobileVerified) {
      tag_show_dobnsgtate_popup = true;
    }

  }
  console.log(tag_show_dobnsgtate_popup )
  return tag_show_dobnsgtate_popup;
}

export function bannerRedirection(banner_data) {
  //console.log("banner_data", banner_data);
  var url_redirection = '';
  switch (banner_data?.screen?.code) {
    case 'account':
      url_redirection = '/account';
      break;

    case 'addBalance':
      url_redirection = '';
      break;

    case 'addChangeUsernamePopup':
      url_redirection = '';
      break;

    case 'allGames':
      url_redirection = '/';
      break;

    case 'applyPromoCodePopup':
      url_redirection = '';
      break;

    case 'coinStore':
      url_redirection = '/coin-store';
      break;

    case 'collegeLeagues':
      url_redirection = '';
      break;

    case 'collegeLeaguesDetails':
      url_redirection = '/collegiate-details';
      break;

    case 'contestDetails':
      url_redirection = '/contest-details';
      break;

    case 'contestsList':
      let gameType = banner_data?.gameType
      let gameArray = new Array(gameType);
      localStorage.setItem("activeGameId", banner_data?.gameType?.game?._id);
      localStorage.setItem("activeGame", banner_data?.gameType?.game?.name);
      localStorage.setItem("activeGameTypeName", banner_data?.gameType?.name);
      localStorage.setItem("activeGameType", JSON.stringify(gameArray));
      url_redirection = '/tournament/' + banner_data?.gameType?._id;
      break;

    case 'customerCare':
      url_redirection = '/customer-care';
      break;

    case 'dailyLoginRewards':
      url_redirection = '/daily-login-rewards';
      break;

    case 'dailyLoginRewardsPopup':
      url_redirection = '';
      break;

    case 'dobAndStateValidation':
      url_redirection = '';
      break;

    case 'editProfile':
      url_redirection = '/profile-edit';
      break;

    // Logic for World of eSports Sub Screens
    case 'esportsNewsDetails':
      url_redirection = '';
      break;

    case 'friends':
      url_redirection = '/friends';
      break;

    case 'gameType':
      url_redirection = '';
      break;

    case 'gamerjiPoints':
      url_redirection = '/gamerji-points';
      break;

    case 'htmlCategories':
      url_redirection = '';
      break;

    case 'htmlGames':
      url_redirection = '';
      break;

    case 'howToPlay':
      url_redirection = '';
      break;

    case 'infoPopup':
      url_redirection = '';
      break;

    case 'joinContestWalletPopup':
      url_redirection = '';
      break;

    case 'joinTournamentWalletPopup':
      url_redirection = '';
      break;

    case 'joinViaInviteCodePopup':
      url_redirection = '';
      break;

    case 'leaderboard':
      url_redirection = '/leader-board';
      break;

    case 'legality':
      url_redirection = '/terms';
      break;

    case 'linkBankOrUpiAccount':
      url_redirection = '';
      break;

    case 'mobileAndEmailVerify':
      url_redirection = '';
      break;

    case 'more':
      url_redirection = '/more';
      break;

    case 'myContests':
      localStorage.setItem("activeGameId", banner_data?.game?._id);
      localStorage.setItem("activeGame", banner_data?.game?.name);
      localStorage.setItem("activeGameType", JSON.stringify(banner_data?.gameType));
      url_redirection = '/my-contest';
      break;

    case 'myRecentTransactions':
      url_redirection = '';
      break;

    case 'myRewards':
      url_redirection = '/coin-store';
      break;

    case 'otherUserProfile':
      url_redirection = '/user-profile';
      break;

    case 'paymentGateway':
      url_redirection = '';
      break;

    case 'paymentOptions':
      url_redirection = '';
      break;

    case 'prizePoolPopup':
      url_redirection = '';
      break;

    case 'profile':
      url_redirection = '/profile';
      break;

    case 'profileInsights':
      url_redirection = '';
      break;

    case 'raiseAComplaint':
      url_redirection = '/customer-care';
      break;

    case 'redeemRewardsPopup':
      url_redirection = '';
      break;

    case 'rewardStore':
      url_redirection = '/coin-store';
      break;

    case 'rulesPopup':
      url_redirection = '';
      break;

    case 'selectFavoriteGamePopup':
      url_redirection = '';
      break;

    case 'singleContest':
      url_redirection = '';
      break;

    case 'singleTournament':
      url_redirection = '';
      break;

    case 'squadRegistration':
      url_redirection = '';
      break;

    case 'streamOnGamerji':
      url_redirection = '';
      break;

    case 'ticketDetails':
      url_redirection = '/customer-care';
      break;

    case 'tournamentDetails':
      url_redirection = '/tounament-details';
      break;

    case 'tournamentLists':
      localStorage.setItem("activeGameId", banner_data?.gameType?.game?._id);
      localStorage.setItem("activeGame", banner_data?.gameType?.game?.name);
      localStorage.setItem("activeGameType", JSON.stringify(banner_data?.gameType));
      url_redirection = '/tournament/all';
      break;

    case 'tournamentTimingPopup':
      url_redirection = '';
      break;

    case 'URLRedirect':
      url_redirection = '';
      break;

    case 'updateEmailPopup':
      url_redirection = '';
      break;

    case 'verifyEmail':
      url_redirection = '';
      break;

    case 'videos':
      url_redirection = '';
      break;

    // 
    case 'viewAllEsportsNews':
      url_redirection = '';
      break;

    case 'viewAllMedals':
      url_redirection = '';
      break;

    // 
    case 'viewAllTopProfiles':
      url_redirection = '';
      break;

    // 
    case 'viewAllVideos':
      url_redirection = '';
      break;

    case 'withdrawal':
      url_redirection = '';
      break;

    case 'worldOfEsports':
      url_redirection = '/world-of-esports';
      break;

    case 'directDownloadAPKs':
      url_redirection = '';
      break;

  }
  return url_redirection;
}

export function getGeolocation() {
  if ("geolocation" in navigator) {
    navigator.geolocation.getCurrentPosition(
      function (position) {
        // console.log(position);
        return position;
      },
      function (error) {
        console.error("Error Code = " + error.code + " - " + error.message);
        return null;
      }
    );
  } else {
    return null;
  }
}
